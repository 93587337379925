import { stringify } from 'querystring'
import { CustomerSearch } from './pages/admin/customer/[criterion]/index.page'
import { KitSearch } from './components/--admin--/kit/kit-type-badge'
import { DateStamp } from './helpers/date'
import { ProductType, Shipment } from '@/gql'

const allRoutes = {
  /* -- Account -- */
  account: '/account',
  accountActivationComplete: '/account?activation',
  accountOrders: '/account/orders',
  accountResultsHistory: '/account/results-history',
  accountSettings: '/account/settings',
  accountActivateKit: ({ kitRequestId, kitId }: Parameters<RoutesFunctions['accountActivateKit']>[0]) =>
    kitId
      ? `/account/test/activate?kitRequestId=${kitRequestId}&kitId=${kitId}`
      : `/account/test/activate?kitRequestId=${kitRequestId}`,
  accountResults: ({ orderedProductId }: Parameters<RoutesFunctions['accountResults']>[0]) =>
    `/account/${orderedProductId}/results`,
  accountResultsTestInfo: ({
    name,
    tests,
    orderedProductId,
  }: Parameters<RoutesFunctions['accountResultsTestInfo']>[0]) => `/account/${orderedProductId}/${name}?${tests}`,
  accountQuestionnaireTake: ({ id }: Parameters<RoutesFunctions['accountQuestionnaireTake']>[0]) =>
    `/account/questionnaire/${id}/take`,
  accountQuestionnaireResult: ({ id }: Parameters<RoutesFunctions['accountQuestionnaireResult']>[0]) =>
    `/account/questionnaire/${id}/result`,
  activation: '/user/activation',

  /* -- Admin -- */
  admin: '/admin/',
  adminRecentShipments: '/admin/order/shipment/recent-shipments',
  adminCgm: ({ cgmOrderedProductId }: Parameters<RoutesFunctions['adminCgm']>[0]) =>
    `/admin/cgm/${cgmOrderedProductId}`,
  adminCgmReport: ({ cgmOrderedProductId }: Parameters<RoutesFunctions['adminCgmReport']>[0]) =>
    `/admin/cgm/${cgmOrderedProductId}/cgm-report`,
  adminPreviewCgmResults: ({ cgmOrderedProductId }: Parameters<RoutesFunctions['adminPreviewCgmResults']>[0]) =>
    `/admin/cgm/${cgmOrderedProductId}/preview-cgm-results`,
  adminDeveloperGraphQl: '/admin/developer/graphql',
  adminLogin: '/admin/login',
  adminIntegrationRoyalMail: '/admin/integration/royal-mail',
  adminIntegrationTDL: '/admin/integration/tdl',
  adminKits: '/admin/kit',
  adminKit: ({ id }: Parameters<RoutesFunctions['adminKit']>[0]) => `/admin/kit/${id}`,
  adminKitTimeline: ({ id }: Parameters<RoutesFunctions['adminKitTimeline']>[0]) => `/admin/kit/${id}/timeline`,
  adminKitAudit: ({ id }: Parameters<RoutesFunctions['adminKitAudit']>[0]) => `/admin/kit/${id}/audit`,
  adminKitNotes: ({ id }: Parameters<RoutesFunctions['adminKitNotes']>[0]) => `/admin/kit/${id}/notes`,
  adminKitGenerateId: '/admin/kit/generate-kit-id',
  adminSearchKits: (params: Parameters<RoutesFunctions['adminSearchKits']>[0]) =>
    `/admin/kit?${new URLSearchParams(params as string[][]).toString()}`,
  adminLogs: '/admin/logs',
  adminOrderCreate: '/admin/order/create',
  adminBespokeTestLink: '/admin/order/bespoke-test-link',
  adminOrderCreateWithEmail: ({ email }: Parameters<RoutesFunctions['adminOrderCreateWithEmail']>[0]) =>
    `/admin/order/create/${email}`,
  adminOrderCreateConfirmation: '/admin/order/create/confirmation',
  adminOrders: '/admin/order',
  adminOrder: ({ id }: Parameters<RoutesFunctions['adminOrder']>[0]) => `/admin/order/${id}`,
  adminOrderEdit: ({ id }: Parameters<RoutesFunctions['adminOrderEdit']>[0]) => `/admin/order/${id}/edit`,
  adminOrderDetails: ({ id }: Parameters<RoutesFunctions['adminOrderDetails']>[0]) => `/admin/order/${id}/details`,
  adminOrderTimeline: ({ id }: Parameters<RoutesFunctions['adminOrderTimeline']>[0]) => `/admin/order/${id}/timeline`,
  adminOrderAudit: ({ id }: Parameters<RoutesFunctions['adminOrderAudit']>[0]) => `/admin/order/${id}/audit`,
  adminOrderNotes: ({ id }: Parameters<RoutesFunctions['adminOrderNotes']>[0]) => `/admin/order/${id}/notes`,

  adminTests: '/admin/tests',
  adminTest: ({ orderedTestProductId }: Parameters<RoutesFunctions['adminTest']>[0]) =>
    `/admin/tests/${orderedTestProductId}`,
  adminTestReporting: '/admin/tests/reporting',
  adminTestReport: ({ orderedTestProductId }: Parameters<RoutesFunctions['adminTestReport']>[0]) =>
    `/admin/tests/${orderedTestProductId}/test-report`,
  adminTestTdlFiles: ({ orderedTestProductId }: Parameters<RoutesFunctions['adminTestTdlFiles']>[0]) =>
    `/admin/tests/${orderedTestProductId}/tdl-files`,
  adminTdlFile: ({ fileName }: Parameters<RoutesFunctions['adminTdlFile']>[0]) => `/admin/tests/tdl-result/${fileName}`,

  adminPreviewResult: (params: Parameters<RoutesFunctions['adminPreviewResult']>[0]) =>
    `/admin/tests/${params.orderedTestProductId}/preview-result`,
  adminCustomer: ({ criterion }: Parameters<RoutesFunctions['adminCustomer']>[0]) => `/admin/customer/${criterion}`,
  adminCustomerEdit: ({ id }: Parameters<RoutesFunctions['adminCustomerEdit']>[0]) => `/admin/customer/${id}/edit`,
  adminCustomerNotes: ({ id }: Parameters<RoutesFunctions['adminCustomerNotes']>[0]) => `/admin/customer/${id}/notes`,
  adminCustomerTimeline: ({ id }: Parameters<RoutesFunctions['adminCustomerTimeline']>[0]) =>
    `/admin/customer/${id}/timeline`,
  adminCustomerAudit: ({ id }: Parameters<RoutesFunctions['adminCustomerAudit']>[0]) => `/admin/customer/${id}/audit`,
  adminCustomers: '/admin/customer',
  adminSearchCustomers: (params: Parameters<RoutesFunctions['adminSearchCustomers']>[0]) =>
    `/admin/customer?${new URLSearchParams(params as string[][]).toString()}`,

  adminSales: '/admin/sales',
  adminSalesByOrderDownloadCSV: ({
    startDate,
    endDate,
  }: Parameters<RoutesFunctions['adminSalesByOrderDownloadCSV']>[0]) =>
    `/admin/sales/download/from-${startDate}-to-${endDate}/by-order`,
  adminSalesByLineItemDownloadCSV: ({
    startDate,
    endDate,
  }: Parameters<RoutesFunctions['adminSalesByLineItemDownloadCSV']>[0]) =>
    `/admin/sales/download/from-${startDate}-to-${endDate}/by-line-item`,
  adminSalesByTestProductDownloadCSV: ({
    startDate,
    endDate,
  }: Parameters<RoutesFunctions['adminSalesByTestProductDownloadCSV']>[0]) =>
    `/admin/sales/download/from-${startDate}-to-${endDate}/by-test-ordered-product`,

  adminShipment: '/admin/order/shipment',
  adminShipmentDownloadCSV: ({ id }: Parameters<RoutesFunctions['adminShipmentDownloadCSV']>[0]) =>
    `/admin/order/shipment/${id}/download`,
  adminShipmentPackingList: ({ id }: Parameters<RoutesFunctions['adminShipmentPackingList']>[0]) =>
    `/admin/order/shipment/${id}/packing-list`,

  // Remainder in alphabetical order
  activateKitsList: '/account/activate',
  activateKit: '/activate',
  claimKit: ({ id, allowSignup }: Parameters<RoutesFunctions['claimKit']>[0]) =>
    `/account/claim-kit?id=${id}${allowSignup === 'true' && '&allowSignup=true'}`,
  about: '/meet-your-team',
  cart: '/checkout/cart',
  blackFridayWeekTerms: '/black-friday-week-terms',
  cgmGettingStarted: '/learn/continuous-glucose-monitor/getting-started',
  cgmFAQ: '/learn/continuous-glucose-monitor/cgm-common-questions',
  cgmOGTT: '/learn/continuous-glucose-monitor/glucose-challenge',
  checkout: '/checkout/details',
  checkoutDetails: '/checkout/details',
  checkoutPayment: '/checkout/payment',
  checkoutUser: '/checkout/user',
  clinics: '/partners/clinics',

  //Coaching Products
  coach: '/coach',
  coachingProduct: ({ slug }: Parameters<RoutesFunctions['coachingProduct']>[0]) => `/coach/${slug}`,

  confirmation: '/checkout/confirmation',
  contactForm: 'https://support.selph.co.uk/hc/en-gb/requests/new',
  contact: '/contact-us',
  cookie: '/cookie-policy',

  deliveryTrackingRoyalMail: ({ id }: Parameters<RoutesFunctions['deliveryTrackingRoyalMail']>[0]) =>
    `http://www.royalmail.com/portal/rm/track?trackNumber=${id}`,

  email: (emailAddress: Parameters<RoutesFunctions['email']>[0]) => `mailto:${emailAddress}`,

  facebook: 'https://www.facebook.com/people/Selph/100088870254983/',
  giftCard: '/gift-card',
  giftCardBalance: '/gift-card/balance-check',
  giftCardTerms: '/gift-card/gift-card-terms',
  help: 'https://support.selph.co.uk',
  instagram: 'https://www.instagram.com/selphhealthuk/',

  // Learn-Hub
  learn: '/learn',
  learnCategory: ({ slug }: Parameters<RoutesFunctions['learnCategory']>[0]) => `/learn/${slug}`,
  blogPost: ({ path }: Parameters<RoutesFunctions['blogPost']>[0]) => `/${path}`,

  login: '/user/login',
  loginWith: ({ redirect, ...props }: Parameters<RoutesFunctions['loginWith']>[0]) => {
    if (redirect) {
      props = { ...props, redirect: redirect }
    }
    return '/user/login?' + stringify(props)
  },

  order: ({ id }: Parameters<RoutesFunctions['order']>[0]) => `/account/orders/${id}`,
  orderInvoice: ({ id }: Parameters<RoutesFunctions['orderInvoice']>[0]) => `/account/orders/${id}/invoice`,
  offers: '/offers',
  passwordRecovery: '/user/password-recovery',
  passwordReset: '/user/password-reset',
  phone: (phoneNumber: Parameters<RoutesFunctions['phone']>[0]) => `tel:${phoneNumber}`,

  privacy: '/privacy-policy',
  products: '/tests',
  product: ({ slug, type, modifiers, addOns }: Parameters<RoutesFunctions['product']>[0]) => {
    const modifiersString = modifiers ? Array.from(modifiers).join(',') : ''
    const addOnsString = addOns ? Array.from(addOns).join(',') : ''

    return `${
      {
        LAB_TEST: '/tests',
        BESPOKE_LAB_TEST: '/tests',
        SUPPLEMENT: '/supplements',
        COACHING: '/coach',
        CGM: '/continuous-glucose-monitors',
        GIFT_CARD: '',
      }[type]
    }/${slug}${modifiers || addOns ? '?' : ''}${modifiers?.size ? `modifiers=${modifiersString}` : ''}${
      modifiers && addOns ? '&' : ''
    }${addOns?.size ? `addOns=${addOnsString}` : ''}`
  },
  libreLinkAppStore: 'https://apps.apple.com/uk/app/freestyle-librelink-gb/id1307017454',
  libreLinkGooglePlay: 'https://play.google.com/store/apps/details?id=com.freestylelibre.app.gb&hl=en_GB&gl=US&pli=1',

  redirectFromPayment: '/checkout/redirect-from-payment',
  register: '/user/register',
  root: '/',
  supplements: '/supplements',
  superdrug: '/partners/superdrug',
  superdrugBooking: 'https://appt.healthclinics.superdrug.com/bookings.php?compid=8345',
  tdl: '/partners/tdl',
  team: '/meet-your-team',
  'dr-alasdair-scott': '/meet-your-team/dr-alasdair-scott',
  'dr-claire-merrifield': '/meet-your-team/dr-claire-merrifield',
  'terms-of-service': '/terms-of-service',

  // Tests
  tests: '/tests',
  gutHealthTests: '/tests/gut-health-tests',
  generalHealthTests: '/tests/general-health-tests',
  metabolicHealthTests: '/tests/metabolic-health-tests',
  mensHealthTests: '/tests/mens-health-tests',
  womensHealthTests: '/tests/womens-health-tests',
  stoolTests: '/tests/stool-tests',
  bloodTests: '/tests/blood-tests',

  twitter: 'https://twitter.com/selphhealthuk',
  tiktok: 'https://www.tiktok.com/@selphhealthuk?lang=en',

  /* -- UI -- */
  ui: '/ui',
  uiAccordion: '/ui/accordion',
  uiBadge: '/ui/badge',
  uiButton: '/ui/button',
  uiCarousel: '/ui/carousel',
  uiCurrency: '/ui/currency',
  uiDivider: '/ui/divider',
  uiForm: '/ui/form',
  uiFormAdvanced: '/ui/form-advanced',
  uiFormValidation: '/ui/form-validation',
  uiGrid: '/ui/grid',
  uiGallery: '/ui/gallery',
  uiHeading: '/ui/heading',
  uiImage: '/ui/image',
  uiLink: '/ui/link',
  uiList: '/ui/list',
  uiMarkdown: '/ui/markdown',
  uiMessage: '/ui/message',
  uiModal: '/ui/modal',
  uiNavbar: '/ui/navbar',
  uiNotification: '/ui/notification',
  uiPage: '/ui/page',
  uiParagraph: '/ui/paragraph',
  uiPanel: '/ui/panel',
  uiProgressModal: '/ui/progress-modal',
  uiQuiz: '/ui/quiz',
  uiResultSlider: '/ui/result-slider',
  uiTable: '/ui/table',
  uiTabMenu: '/ui/tab-menu',
  uiTimeline: '/ui/timeline',
  uiTooltip: '/ui/tooltip',

  verifyUser: '/user/verify',
}

export const routes: Routes = allRoutes

export type RoutesType = Partial<typeof allRoutes>

export type RoutesLink =
  | Omit<keyof RoutesType, keyof RoutesFunctions>
  | ['accountActivateKit', Parameters<RoutesFunctions['accountActivateKit']>[0]]
  | ['accountResults', Parameters<RoutesFunctions['accountResults']>[0]]
  | ['accountResultsTestInfo', Parameters<RoutesFunctions['accountResultsTestInfo']>[0]]
  | ['accountQuestionnaireTake', Parameters<RoutesFunctions['accountQuestionnaireTake']>[0]]
  | ['accountQuestionnaireResult', Parameters<RoutesFunctions['accountQuestionnaireResult']>[0]]
  | ['adminCgm', Parameters<RoutesFunctions['adminCgm']>[0]]
  | ['adminCgmReport', Parameters<RoutesFunctions['adminCgmReport']>[0]]
  | ['adminPreviewCgmResults', Parameters<RoutesFunctions['adminPreviewCgmResults']>[0]]
  | ['adminCustomer', Parameters<RoutesFunctions['adminCustomer']>[0]]
  | ['adminCustomerEdit', Parameters<RoutesFunctions['adminCustomerEdit']>[0]]
  | ['adminCustomerNotes', Parameters<RoutesFunctions['adminCustomerNotes']>[0]]
  | ['adminCustomerTimeline', Parameters<RoutesFunctions['adminCustomerTimeline']>[0]]
  | ['adminCustomerAudit', Parameters<RoutesFunctions['adminCustomerAudit']>[0]]
  | ['adminOrder', Parameters<RoutesFunctions['adminOrder']>[0]]
  | ['adminOrderEdit', Parameters<RoutesFunctions['adminOrderEdit']>[0]]
  | ['adminOrderDetails', Parameters<RoutesFunctions['adminOrderDetails']>[0]]
  | ['adminOrderTimeline', Parameters<RoutesFunctions['adminOrderTimeline']>[0]]
  | ['adminOrderAudit', Parameters<RoutesFunctions['adminOrderAudit']>[0]]
  | ['adminOrderNotes', Parameters<RoutesFunctions['adminOrderNotes']>[0]]
  | ['adminOrderCreateWithEmail', Parameters<RoutesFunctions['adminOrderCreateWithEmail']>[0]]
  | ['adminKit', Parameters<RoutesFunctions['adminKit']>[0]]
  | ['adminKitTimeline', Parameters<RoutesFunctions['adminKitTimeline']>[0]]
  | ['adminKitAudit', Parameters<RoutesFunctions['adminKitAudit']>[0]]
  | ['adminKitNotes', Parameters<RoutesFunctions['adminKitNotes']>[0]]
  | ['adminTest', Parameters<RoutesFunctions['adminTest']>[0]]
  | ['adminSearchCustomers', Parameters<RoutesFunctions['adminSearchCustomers']>[0]]
  | ['adminSearchKits', Parameters<RoutesFunctions['adminSearchKits']>[0]]
  | ['adminSalesByOrderDownloadCSV', Parameters<RoutesFunctions['adminSalesByOrderDownloadCSV']>[0]]
  | ['adminSalesByLineItemDownloadCSV', Parameters<RoutesFunctions['adminSalesByLineItemDownloadCSV']>[0]]
  | ['adminSalesByTestProductDownloadCSV', Parameters<RoutesFunctions['adminSalesByTestProductDownloadCSV']>[0]]
  | ['adminShipmentDownloadCSV', Parameters<RoutesFunctions['adminShipmentDownloadCSV']>[0]]
  | ['adminShipmentPackingList', Parameters<RoutesFunctions['adminShipmentPackingList']>[0]]
  | ['adminTestReport', Parameters<RoutesFunctions['adminTestReport']>[0]]
  | ['adminPreviewResult', Parameters<RoutesFunctions['adminPreviewResult']>[0]]
  | ['adminTestTdlFiles', Parameters<RoutesFunctions['adminTestTdlFiles']>[0]]
  | ['adminTdlFile', Parameters<RoutesFunctions['adminTdlFile']>[0]]
  | ['blogPost', Parameters<RoutesFunctions['blogPost']>[0]]
  | ['claimKit', Parameters<RoutesFunctions['claimKit']>[0]]
  | ['coachingProduct', Parameters<RoutesFunctions['coachingProduct']>[0]]
  | ['deliveryTrackingRoyalMail', Parameters<RoutesFunctions['deliveryTrackingRoyalMail']>[0]]
  | ['learnCategory', Parameters<RoutesFunctions['learnCategory']>[0]]
  | ['loginWith', Parameters<RoutesFunctions['loginWith']>[0]]
  | ['order', Parameters<RoutesFunctions['order']>[0]]
  | ['orderInvoice', Parameters<RoutesFunctions['orderInvoice']>[0]]
  | ['phone', Parameters<RoutesFunctions['phone']>[0]]
  | ['product', Parameters<RoutesFunctions['product']>[0]]
  | ['email', Parameters<RoutesFunctions['email']>[0]]

export type RoutesFunctions = {
  accountActivateKit: ({ kitRequestId, kitId }: { kitRequestId: string; kitId?: string }) => string
  accountResults: ({ orderedProductId }: { orderedProductId: string }) => string
  adminCgm: ({ cgmOrderedProductId }: { cgmOrderedProductId: string }) => string
  adminCgmReport: ({ cgmOrderedProductId }: { cgmOrderedProductId: string }) => string
  accountResultsTestInfo: ({
    name,
    tests,
    orderedProductId,
  }: {
    name: string
    tests?: string
    orderedProductId: string
  }) => string
  accountQuestionnaireTake: ({ id }: { id: string }) => string
  accountQuestionnaireResult: ({ id }: { id: string }) => string
  adminCustomer: ({ criterion }: { criterion: string }) => string
  adminCustomerEdit: ({ id }: { id: string }) => string
  adminCustomerNotes: ({ id }: { id: string }) => string
  adminCustomerTimeline: ({ id }: { id: string }) => string
  adminCustomerAudit: ({ id }: { id: string }) => string
  adminKit: ({ id }: { id: string }) => string
  adminKitTimeline: ({ id }: { id: string }) => string
  adminKitAudit: ({ id }: { id: string }) => string
  adminKitNotes: ({ id }: { id: string }) => string
  adminOrder: ({ id }: { id: string }) => string
  adminOrderEdit: ({ id }: { id: string }) => string
  adminOrderDetails: ({ id }: { id: string }) => string
  adminOrderTimeline: ({ id }: { id: string }) => string
  adminOrderAudit: ({ id }: { id: string }) => string
  adminOrderNotes: ({ id }: { id: string }) => string
  adminOrderCreateWithEmail: ({ email }: { email: string }) => string
  adminTest: ({ orderedTestProductId }: { orderedTestProductId: string }) => string
  adminTestReport: ({ orderedTestProductId }: { orderedTestProductId: string }) => string
  adminPreviewResult: ({ orderedTestProductId }: { orderedTestProductId: string }) => string
  adminTestTdlFiles: ({ orderedTestProductId }: { orderedTestProductId: string }) => string
  adminTdlFile: ({ fileName }: { fileName: string }) => string
  adminPreviewCgmResults: ({
    cgmOrderedProductId,
    testReminder,
  }: {
    cgmOrderedProductId: string
    testReminder?: string
  }) => string
  adminSearchCustomers: (params: CustomerSearch) => string
  adminSearchKits: (params: KitSearch) => string
  adminSalesByOrderDownloadCSV: ({ startDate, endDate }: { startDate: DateStamp; endDate: DateStamp }) => string
  adminSalesByLineItemDownloadCSV: ({ startDate, endDate }: { startDate: DateStamp; endDate: DateStamp }) => string
  adminSalesByTestProductDownloadCSV: ({ startDate, endDate }: { startDate: DateStamp; endDate: DateStamp }) => string
  adminShipmentDownloadCSV: ({ id }: { id: Shipment['id'] }) => string
  adminShipmentPackingList: ({ id }: { id: Shipment['id'] }) => string

  blogPost: ({ path }: { path: string }) => string
  claimKit: ({ id, allowSignup }: { id: string; allowSignup: string }) => string
  coachingProduct: ({ slug }: { slug: string }) => string
  deliveryTrackingRoyalMail: ({ id }: { id: string }) => string
  email: (email: string) => string
  learnCategory: ({ slug }: { slug: string }) => string
  loginWith: ({
    redirect,
    ...props
  }: { href?: string } & {
    [key: string]: string
  }) => string
  order: ({ id }: { id: string }) => string
  orderInvoice: ({ id }: { id: string }) => string
  phone: (phoneNumber: string) => string
  product: ({
    slug,
    type,
    modifiers,
    addOns,
  }: {
    slug: string
    type: ProductType
    modifiers?: Set<string>
    addOns?: Set<string>
  }) => string
}

export type RoutesStrings = {
  [K in keyof Omit<keyof RoutesType, keyof RoutesFunctions>]?: string
}

export type Routes = RoutesFunctions | RoutesStrings

export const getHref = (to: RoutesLink) => {
  return Array.isArray(to)
    ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      (routes as RoutesFunctions)[to[0] as keyof RoutesFunctions](to[1])
    : ((routes as Routes as RoutesStrings)[to as keyof RoutesStrings] as string)
}

export default routes
