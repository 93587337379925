import { LinkColors } from '../ui/link'
import { RoutesLink } from '../../routes'
import * as UI from '@/ui'
import { TestProductsQuery } from '@/gql'

export type ProductLinkProps = {
  slug: TestProductsQuery['products'][0]['slug']
  type: TestProductsQuery['products'][0]['type']
  modifiers?: Set<string>
  addOns?: Set<string>
  children: React.ReactNode
  color?: LinkColors
  className?: string
  onClick?: () => void
}

export const ProductLink = ({
  className,
  slug,
  type,
  modifiers,
  addOns,
  color,
  children,
  onClick,
}: ProductLinkProps) => {
  const linkTo: RoutesLink = ['product', { slug, type, modifiers, addOns }]
  return (
    <UI.Link color={color} to={linkTo} onClick={onClick} className={className}>
      {children}
    </UI.Link>
  )
}

export default ProductLink
