import TPLogo from '/public/images/TP-Logo.svg?svgr'
import { TrustPilotData } from './trustpilot-box'

export const TPMicroReview = ({ trustData }: { trustData: TrustPilotData }) => {
  return (
    <a target="_blank" href={trustData.url} className="h-6 w-fit mx-auto flex items-baseline text-sm font-helvetica">
      <div className="font-normal mr-1">See our</div>

      <div className="font-semibold mr-1"> {`${trustData.reviews.toLocaleString()}`}</div>
      <div className="font-medium mr-1.5"> reviews on</div>

      <span title="Trustpilot">
        <TPLogo width="80" className={`mx-auto relative top-[2px]`} />
        <span className="sr-only">Trustpilot Logo</span>
      </span>
    </a>
  )
}

export default TPMicroReview
