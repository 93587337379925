import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
const defaultOptions = {} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
}

export type AccessLogEntry = {
  id: Scalars['ID']
  time?: Maybe<Scalars['String']>
  user: User
}

export type ActivateKitInput = {
  biologicalSex: BiologicalSex
  dob: Scalars['String']
  extraData?: InputMaybe<Scalars['String']>
  firstName: Scalars['String']
  kitId: Scalars['ID']
  kitRequestId: Scalars['ID']
  lastName: Scalars['String']
  returnByPost: Scalars['Boolean']
  sampleDate?: InputMaybe<Scalars['String']>
  trackingNumber?: InputMaybe<Scalars['String']>
  tuliAppointmentDateTime?: InputMaybe<Scalars['String']>
  tuliAppointmentId?: InputMaybe<Scalars['ID']>
}

export type ActivateKitResponse = {
  kit: Kit
  message?: Maybe<Scalars['String']>
  success: Scalars['Boolean']
}

export type AddKitNoteInput = {
  kitId: Scalars['ID']
  note: Scalars['String']
  subject?: InputMaybe<Scalars['String']>
}

export type AddKitNoteResponse = {
  success: Scalars['Boolean']
}

export type Address = {
  city: Scalars['String']
  line1: Scalars['String']
  line2?: Maybe<Scalars['String']>
  postcode: Scalars['String']
}

export type AddressInput = {
  city: Scalars['String']
  line1: Scalars['String']
  line2?: InputMaybe<Scalars['String']>
  postcode: Scalars['String']
}

export type AmendHl7Input = {
  hl7: Scalars['String']
  id: Scalars['ID']
}

export type AmendHl7Response = {
  tdlResult: TdlResult
}

/** A single test analysis */
export type Analysis = {
  description: Scalars['String']
  id: Scalars['ID']
  messageEquivocal?: Maybe<Scalars['String']>
  messageHigh?: Maybe<Scalars['String']>
  messageHighRed?: Maybe<Scalars['String']>
  messageLow?: Maybe<Scalars['String']>
  messageLowRed?: Maybe<Scalars['String']>
  messageNegative?: Maybe<Scalars['String']>
  messageNormal?: Maybe<Scalars['String']>
  messagePositive?: Maybe<Scalars['String']>
  name: Scalars['String']
  scalePoints: Array<ScalePoint>
  scaleType: ScaleType
  units?: Maybe<Scalars['String']>
}

export enum AnalysisLocation {
  Home = 'HOME',
  Lab = 'LAB',
}

export type AnalysisRange = {
  fromVal?: Maybe<Scalars['Float']>
  toVal?: Maybe<Scalars['Float']>
  type?: Maybe<RangeType>
}

export type AnalysisResult = {
  analysisCode: Scalars['String']
  /**
   * Nullable since we might not always have a definition for everything that we
   * get from TDL
   */
  analysisDefinition?: Maybe<Analysis>
  calculatedLevel?: Maybe<RangeType>
  calculatedLevelLabel?: Maybe<Scalars['String']>
  centileRanges?: Maybe<Array<CentileRange>>
  hl7filename?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  notes: Array<Scalars['String']>
  obxStatus?: Maybe<ObxStatus>
  ranges?: Maybe<Array<AnalysisRange>>
  raw: Array<Scalars['String']>
  /** Raw value from OBX.7 "Reference range" */
  referenceRangeRaw?: Maybe<Scalars['String']>
  /**
   * Scale points as per the analysis definition, merged with
   * range data from TDL if available.
   * Null for string results
   */
  scalePoints?: Maybe<Array<ScalePoint>>
  units?: Maybe<Scalars['String']>
  valueNumeric?: Maybe<Scalars['Float']>
  valueText?: Maybe<Scalars['String']>
}

export type Article = {
  author: TeamMember
  body: Scalars['String']
  categories: Array<Scalars['String']>
  hero: Image
  metaDescription?: Maybe<Scalars['String']>
  metaTitle?: Maybe<Scalars['String']>
  path: Scalars['String']
  publishedAt: Scalars['String']
  readingTimeMinutes: Scalars['Int']
  relatedArticles?: Maybe<Array<Article>>
  relatedProducts?: Maybe<Array<Product>>
  reviewer?: Maybe<TeamMember>
  slug: Scalars['String']
  summary: Scalars['String']
  takeawayHeader?: Maybe<Scalars['String']>
  takeaways?: Maybe<Scalars['String']>
  template: Scalars['String']
  title: Scalars['String']
  updatedAt?: Maybe<Scalars['String']>
}

export type BasketDiscount = {
  amount: Scalars['Int']
  code: Scalars['String']
  description?: Maybe<Scalars['String']>
  failureMessage?: Maybe<Scalars['String']>
  giftCard?: Maybe<GiftCard>
}

export type BasketLine = {
  /** The  product name at the time that this BasketLine was created */
  capturedProductName: Scalars['String']
  /** The  product price at the time that this BasketLine was created */
  capturedProductPrice: Scalars['Int']
  modifiers: Array<BasketLineModifier>
  product: Product
  quantity: Scalars['Int']
  totalPrice: Scalars['Int']
}

export type BasketLineModifier = {
  code: Scalars['ID']
  name: Scalars['String']
  price: Scalars['Int']
}

export type BasketPriceDetails = {
  discount?: Maybe<BasketDiscount>
  lines: Array<BasketLine>
  requiresAccountActivation: Scalars['Boolean']
  requiresShipping: Scalars['Boolean']
  subTotal: Scalars['Int']
  total: Scalars['Int']
}

export type BespokeLabTestProduct = Product & {
  active: Scalars['Boolean']
  allowPurchase: Scalars['Boolean']
  blogArticles: Array<Article>
  blogs: Array<Scalars['String']>
  blogsHeader?: Maybe<Scalars['String']>
  description: Scalars['String']
  featuredImage: Image
  features?: Maybe<Array<Scalars['String']>>
  intro?: Maybe<Scalars['String']>
  metaDescription?: Maybe<Scalars['String']>
  metaTitle?: Maybe<Scalars['String']>
  name: Scalars['String']
  otherImages?: Maybe<Array<Image>>
  price: Scalars['Int']
  reviews: Array<ProductReview>
  showInListings: Scalars['Boolean']
  sku: Scalars['ID']
  slug: Scalars['String']
  type: ProductType
  updatedAt: Scalars['String']
}

export enum BiologicalSex {
  F = 'F',
  M = 'M',
  U = 'U',
}

export type BloodCollectionOption = BasketLineModifier & {
  bloodDrawType: BloodDrawType
  clinicId?: Maybe<Scalars['String']>
  code: Scalars['ID']
  description: Scalars['String']
  id: Scalars['ID']
  name: Scalars['String']
  price: Scalars['Int']
  provider?: Maybe<Scalars['String']>
}

export enum BloodDrawType {
  Capillary = 'CAPILLARY',
  Venous = 'VENOUS',
}

export type CalculateOrderInput = {
  discountCode?: InputMaybe<Scalars['ID']>
  orderLines: Array<OrderLineInput>
}

export type CalculatedRequest = {
  kitSku: Scalars['ID']
  requestCodes: Array<Scalars['ID']>
}

export type CancelKitRequestInput = {
  kitRequestId: Scalars['ID']
}

export type CancelKitRequestResponse = {
  kitRequest: KitRequest
}

export type CancelKitResponse = {
  kit: Kit
  success: Scalars['Boolean']
}

export type CancelLabTestOrderedProductResponse = {
  success: Scalars['Boolean']
}

export type CentileRange = {
  centile: Scalars['Int']
  value?: Maybe<Scalars['Float']>
}

export type CgmAnalysis = {
  analysisCode: Scalars['String']
  analysisDefinition: CgmAnalysisDefinition
  calculatedCentile?: Maybe<Scalars['Int']>
  calculatedLevel?: Maybe<CgmAnalysisLevel>
  centileRanges?: Maybe<Array<CgmCentileRange>>
  definedRanges?: Maybe<Array<CgmDefinedRange>>
  value?: Maybe<Scalars['Float']>
}

export type CgmAnalysisDefinition = {
  code: Scalars['String']
  description: Scalars['String']
  max?: Maybe<Scalars['Float']>
  min?: Maybe<Scalars['Float']>
  name: Scalars['String']
  scale: CgmScaleType
  units?: Maybe<Scalars['String']>
}

export enum CgmAnalysisLevel {
  Borderline = 'BORDERLINE',
  High = 'HIGH',
  Normal = 'NORMAL',
  Optimal = 'OPTIMAL',
  VeryHigh = 'VERY_HIGH',
}

export type CgmAnalysisRun = {
  analyses: Array<CgmAnalysis>
  fromTimestamp: Scalars['String']
  id: Scalars['ID']
  toTimestamp: Scalars['String']
}

export type CgmCentileRange = {
  centile: Scalars['Int']
  value: Scalars['Float']
}

export type CgmDefinedRange = {
  fromVal?: Maybe<Scalars['Float']>
  level: CgmAnalysisLevel
  toVal?: Maybe<Scalars['Float']>
}

export type CgmOrderedProduct = OrderedProduct & {
  analysisRuns: Array<CgmAnalysisRun>
  createdAt: Scalars['String']
  id: Scalars['ID']
  latestReportVersion?: Maybe<ReportVersion>
  order?: Maybe<Order>
  productDefinition: Product
  publishedReportVersion?: Maybe<ReportVersion>
  reportVersions?: Maybe<Array<ReportVersion>>
  user: User
}

export type CgmProduct = Product & {
  active: Scalars['Boolean']
  allowPurchase: Scalars['Boolean']
  blogArticles: Array<Article>
  blogs: Array<Scalars['String']>
  blogsHeader?: Maybe<Scalars['String']>
  description: Scalars['String']
  featuredImage: Image
  features?: Maybe<Array<Scalars['String']>>
  intro?: Maybe<Scalars['String']>
  metaDescription?: Maybe<Scalars['String']>
  metaTitle?: Maybe<Scalars['String']>
  name: Scalars['String']
  price: Scalars['Int']
  reviews: Array<ProductReview>
  showInListings: Scalars['Boolean']
  sku: Scalars['ID']
  slug: Scalars['String']
  type: ProductType
  updatedAt: Scalars['String']
}

export type CgmReportMeta = {
  latestDataTimestamp: Scalars['String']
}

export enum CgmScaleType {
  Centile = 'CENTILE',
  Defined = 'DEFINED',
  Point = 'POINT',
}

export type ChangeUserDetailsInput = {
  biologicalSex?: InputMaybe<BiologicalSex>
  dob?: InputMaybe<Scalars['String']>
  firstName: Scalars['String']
  friendlyName: Scalars['String']
  lastName: Scalars['String']
  userId: Scalars['ID']
}

export type ChangeUserDetailsResponse = {
  user: User
}

export type ChangeUserEmailInput = {
  newEmail: Scalars['String']
  userId: Scalars['ID']
}

export type ChangeUserEmailResponse = {
  success: Scalars['Boolean']
  user: User
}

export type ClaimOffPlatformKitInput = {
  kitId: Scalars['ID']
}

export type ClaimOffPlatformKitResponse = {
  kitRequest: KitRequest
}

export type CoachingProduct = Product & {
  active: Scalars['Boolean']
  allowPurchase: Scalars['Boolean']
  blogArticles: Array<Article>
  blogs: Array<Scalars['String']>
  blogsHeader?: Maybe<Scalars['String']>
  description: Scalars['String']
  featuredImage: Image
  features?: Maybe<Array<Scalars['String']>>
  intro?: Maybe<Scalars['String']>
  metaDescription?: Maybe<Scalars['String']>
  metaTitle?: Maybe<Scalars['String']>
  name: Scalars['String']
  price: Scalars['Int']
  reviews: Array<ProductReview>
  showInListings: Scalars['Boolean']
  sku: Scalars['ID']
  slug: Scalars['String']
  type: ProductType
  updatedAt: Scalars['String']
}

export type CompleteActivationResponse = {
  message?: Maybe<Scalars['String']>
  redirectTo?: Maybe<Scalars['String']>
  success: Scalars['Boolean']
}

export type ConfirmOrderInput = {
  orderId: Scalars['ID']
  shippingAddress: AddressInput
}

export type ConfirmOrderResponse = {
  orderId: Scalars['ID']
}

export type CreateShipmentBatchResponse = {
  message?: Maybe<Scalars['String']>
  shipmentBatch?: Maybe<ShipmentBatch>
  success: Scalars['Boolean']
}

export type CreateUserInput = {
  biologicalSex: BiologicalSex
  dob: Scalars['String']
  firstName: Scalars['String']
  lastName: Scalars['String']
  phone: Scalars['String']
  /** URL to send the user to once their account is activated */
  redirectToAfterActivation?: InputMaybe<Scalars['String']>
}

export type CreateUserResponse = {
  success: Scalars['Boolean']
}

export type DownloadTdlReportResponse = {
  url?: Maybe<Scalars['String']>
}

export type EmailJob = Job & {
  id: Scalars['ID']
  output?: Maybe<Scalars['String']>
  status: Scalars['String']
  template?: Maybe<Scalars['String']>
}

export type FaqItem = {
  answer: Scalars['String']
  question: Scalars['String']
}

export type FidelityJob = Job & {
  id: Scalars['ID']
  orderId?: Maybe<Scalars['ID']>
  status: Scalars['String']
}

export type GenerateKitIdsInput = {
  note?: InputMaybe<Scalars['String']>
  quantity: Scalars['Int']
}

export type GenerateKitIdsResponse = {
  ids: Array<Scalars['ID']>
  success: Scalars['Boolean']
}

export type GiftCard = {
  id: Scalars['ID']
  /** The order in which this was purchased */
  order: Order
  remainingBalance: Scalars['Int']
  startingBalance: Scalars['Int']
}

export type GiftCardBalanceResponse = {
  balance?: Maybe<Scalars['Int']>
}

export type GiftCardOrderedProduct = OrderedProduct & {
  createdAt: Scalars['String']
  giftCard: GiftCard
  id: Scalars['ID']
  order?: Maybe<Order>
  productDefinition: Product
  user: User
}

export type GiftCardProduct = Product & {
  active: Scalars['Boolean']
  allowPurchase: Scalars['Boolean']
  blogArticles: Array<Article>
  blogs: Array<Scalars['String']>
  blogsHeader?: Maybe<Scalars['String']>
  description: Scalars['String']
  featuredImage: Image
  features?: Maybe<Array<Scalars['String']>>
  intro?: Maybe<Scalars['String']>
  metaDescription?: Maybe<Scalars['String']>
  metaTitle?: Maybe<Scalars['String']>
  name: Scalars['String']
  price: Scalars['Int']
  reviews: Array<ProductReview>
  showInListings: Scalars['Boolean']
  sku: Scalars['ID']
  slug: Scalars['String']
  type: ProductType
  updatedAt: Scalars['String']
}

/** Hello world response object */
export type Hello = {
  id: Scalars['ID']
  message: Scalars['String']
  name: Scalars['String']
  version?: Maybe<Scalars['String']>
}

export type IgnoreHl7Response = {
  tdlResult: TdlResult
}

export type Image = {
  name: Scalars['String']
  src: Scalars['String']
}

export type Job = {
  id: Scalars['ID']
  status: Scalars['String']
}

export type Kit = {
  activatedAt?: Maybe<Scalars['String']>
  activatedKitRequest?: Maybe<KitRequest>
  canceled?: Maybe<Scalars['Boolean']>
  /**
   * For off-plaform purchases, the kit request that was created during
   * `claimOffPlatformKit`
   */
  claimedKitRequest?: Maybe<KitRequest>
  /**
   * If the tdlRef matches a 'code' that we recognise, returns the kit definition
   * or null if we don't have a matching kit.
   */
  dispatchedKitDefinition?: Maybe<KitDefinition>
  dispatchedShipment?: Maybe<Shipment>
  id: Scalars['ID']
  jobs: Array<Job>
  notes: Array<Note>
  offPlatformSku?: Maybe<Scalars['String']>
  /** @deprecated No longer populated */
  packedKitRequest?: Maybe<KitRequest>
  tdlExpiry?: Maybe<Scalars['String']>
  tdlLot?: Maybe<Scalars['String']>
  /** Raw QR data in case the components don't make sense */
  tdlQrData?: Maybe<Scalars['String']>
  tdlRef?: Maybe<Scalars['String']>
}

export type KitActivationInfo = Shippable & {
  biologicalSex?: Maybe<BiologicalSex>
  dob?: Maybe<Scalars['String']>
  extraData?: Maybe<Scalars['String']>
  firstName?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  returnByPost?: Maybe<Scalars['Boolean']>
  sampleDate?: Maybe<Scalars['String']>
  shippingLatestEvent?: Maybe<Scalars['String']>
  shippingStatus?: Maybe<Scalars['String']>
  shippinglastUpdate?: Maybe<Scalars['String']>
  trackingMoreId?: Maybe<Scalars['ID']>
  trackingNumber?: Maybe<Scalars['String']>
}

export enum KitActivationStatusReason {
  AlreadyActivated = 'ALREADY_ACTIVATED',
  KitrequestCanceled = 'KITREQUEST_CANCELED',
  KitCanceled = 'KIT_CANCELED',
  KitNotFound = 'KIT_NOT_FOUND',
}

export type KitActivationStatusResponse = {
  canActivate: Scalars['Boolean']
  offPlatformPurchase?: Maybe<Scalars['Boolean']>
  reason?: Maybe<Scalars['String']>
  reasonCode?: Maybe<KitActivationStatusReason>
}

export type KitDefinition = {
  bloodDrawType?: Maybe<BloodDrawType>
  /**
   * Human-friendly description of kit contents
   * e.g. ['1 x yellow-top tube']
   */
  contents?: Maybe<Array<Scalars['String']>>
  labCode: Scalars['String']
  name: Scalars['String']
  sampleTubes: Array<SampleTubeType>
  sampleType: SampleType
  sku: Scalars['String']
}

export type KitRequest = {
  activationInfo?: Maybe<KitActivationInfo>
  calculatedTurnaroundTime: Scalars['Int']
  canceled: Scalars['Boolean']
  /**
   * For off-plaform purchases, the kit that was used to create this
   * KitRequest during `claimOffPlatformKit`
   */
  claimedKit?: Maybe<Kit>
  currentTdlResult?: Maybe<TdlResult>
  id: Scalars['ID']
  /** When activated, the associated Kit */
  kit?: Maybe<Kit>
  kitDefinition: KitDefinition
  orderedProduct?: Maybe<LabTestOrderedProduct>
  /** @deprecated No longer populated */
  packedKit?: Maybe<Kit>
  /** Unflattened request codes reflecting exactly what would be sent to TDL */
  rawRequestCodes?: Maybe<Array<Maybe<Scalars['String']>>>
  reportedTdlResult?: Maybe<TdlResult>
  requestedTests: Array<TestInfo>
  status: KitRequestStatus
  tdlResults: Array<TdlResult>
}

export enum KitRequestStatus {
  /** Kit Request is associated with a kit, but no results have arrived yet */
  Activated = 'ACTIVATED',
  /** Either the kit or packedKit is canceled */
  Canceled = 'CANCELED',
  /** Most recent HL7 for this kit request has a 'CM' (complete) status */
  Complete = 'COMPLETE',
  /** Most recent HL7 for this kit request has an 'A' (some results available) status */
  LabProcessing = 'LAB_PROCESSING',
  /** No kit is associated with this kit request */
  NeedsActivation = 'NEEDS_ACTIVATION',
}

export type KitRequestTdlResultIdMap = {
  kitRequestId: Scalars['ID']
  tdlResultId: Scalars['ID']
}

export type LabTestOrderedProduct = OrderedProduct & {
  blockedForReporting?: Maybe<Scalars['Boolean']>
  bloodCollectionOption?: Maybe<BloodCollectionOption>
  canceled?: Maybe<Scalars['Boolean']>
  createdAt: Scalars['String']
  id: Scalars['ID']
  kitRequests: Array<KitRequest>
  order?: Maybe<Order>
  productDefinition: Product
  reportAnalysesToExclude?: Maybe<Array<Scalars['String']>>
  reportPublishedAt?: Maybe<Scalars['String']>
  reportRecommendations?: Maybe<Scalars['String']>
  reportStatus?: Maybe<LabTestOrderedProductReportStatus>
  reportText?: Maybe<Scalars['String']>
  /** Most recent invocation of `writeReport` */
  reportWrittenAt?: Maybe<Scalars['String']>
  user: User
}

export type LabTestOrderedProductFilter = {
  isActivated?: InputMaybe<Scalars['Boolean']>
  reportStatuses?: InputMaybe<Array<InputMaybe<LabTestOrderedProductReportStatus>>>
}

export enum LabTestOrderedProductReportStatus {
  Draft = 'DRAFT',
  None = 'NONE',
  Published = 'PUBLISHED',
}

export type LabTestOrderedProductUserAction = UserAction & {
  createdAt: Scalars['String']
  orderedProduct: LabTestOrderedProduct
}

export type LabTestOrderedProductsConnection = {
  edges: Array<LabTestOrderedProductsEdge>
  pageInfo: PageInfo
}

export type LabTestOrderedProductsEdge = {
  cursor: Scalars['ID']
  node: LabTestOrderedProduct
}

export type LabTestProduct = Product & {
  active: Scalars['Boolean']
  allowPurchase: Scalars['Boolean']
  analysisLocation: AnalysisLocation
  biomarkers: Scalars['Int']
  blogArticles: Array<Article>
  blogs: Array<Scalars['String']>
  blogsHeader?: Maybe<Scalars['String']>
  /**
   * List of available blood collection options for this product.
   * Will be empty if no blood is collected for this product.
   * First option should be the default.
   */
  bloodCollectionOptions: Array<BloodCollectionOption>
  categories: Array<Scalars['String']>
  description: Scalars['String']
  faqHeader?: Maybe<Scalars['String']>
  faqItems: Array<FaqItem>
  featuredImage: Image
  features?: Maybe<Array<Scalars['String']>>
  intro?: Maybe<Scalars['String']>
  kits: Array<KitDefinition>
  metaDescription?: Maybe<Scalars['String']>
  metaTitle?: Maybe<Scalars['String']>
  name: Scalars['String']
  otherImages: Array<Image>
  personaliseHeader?: Maybe<Scalars['String']>
  price: Scalars['Int']
  recommendedAddons: Array<RecommendedTestAddon>
  reviews: Array<ProductReview>
  sampleLocation: SampleLocation
  sampleTypes: Array<SampleType>
  shoppingFeedImage?: Maybe<Image>
  showInListings: Scalars['Boolean']
  sku: Scalars['ID']
  slug: Scalars['String']
  /** The team member who should be featured on the product page */
  spokesperson: TeamMember
  testGroups: Array<ProductTestGroup>
  /** Highest turnaround time of each test in the panel */
  turnaroundTime: Scalars['Int']
  type: ProductType
  updatedAt: Scalars['String']
  valueProposition: Scalars['String']
  whatsTestedCopy?: Maybe<Scalars['String']>
  whatsTestedHeader?: Maybe<Scalars['String']>
  whoFor?: Maybe<Array<Scalars['String']>>
}

export type LabTestReportMeta = {
  hl7filenames: Array<Scalars['String']>
}

export type MarkKitPackedInput = {
  kitId: Scalars['ID']
  kitRequestId: Scalars['ID']
}

export type MarkKitPackedResponse = {
  kit?: Maybe<Kit>
  kitRequest?: Maybe<KitRequest>
  success: Scalars['Boolean']
}

export type MarkOrderedProductBlockedInput = {
  orderedProductId: Scalars['ID']
  tdlResults: Array<KitRequestTdlResultIdMap>
}

export type MarkOrderedProductBlockedResponse = {
  orderedProduct: LabTestOrderedProduct
}

export type MarkReportVersionViewedInput = {
  reportVersionId: Scalars['ID']
}

export type MarkShipmentDispatchedInput = {
  dispatchDate?: InputMaybe<Scalars['String']>
  externalRef?: InputMaybe<Scalars['String']>
  manifestFilename?: InputMaybe<Scalars['String']>
  /** Price paid (in pennies) */
  pricePaid?: InputMaybe<Scalars['Int']>
  shipmentId: Scalars['ID']
  shippingProvider: ShippingProvider
  trackingNo: Scalars['String']
}

export type MarkShipmentDispatchedResponse = {
  message?: Maybe<Scalars['String']>
  shipment?: Maybe<Shipment>
  success: Scalars['Boolean']
}

export type Mutation = {
  activateKit: ActivateKitResponse
  addKitNote: AddKitNoteResponse
  amendHl7: AmendHl7Response
  cancelKit: CancelKitResponse
  cancelKitRequest: CancelKitRequestResponse
  cancelLabTestOrderedProduct: CancelLabTestOrderedProductResponse
  changeUserDetails: ChangeUserDetailsResponse
  changeUserEmail: ChangeUserEmailResponse
  claimOffPlatformKit: ClaimOffPlatformKitResponse
  completeActivation: CompleteActivationResponse
  confirmOrder: ConfirmOrderResponse
  createShipmentBatch: CreateShipmentBatchResponse
  /** Create a user account, must be authed through Cognito */
  createUser: CreateUserResponse
  downloadReportPdf: DownloadTdlReportResponse
  generateKitIds: GenerateKitIdsResponse
  /**
   * Mark an HL7 file as ignored, this does not delete it but excludes it from
   * consideration in results processing
   */
  ignoreHl7: IgnoreHl7Response
  markKitPacked: MarkKitPackedResponse
  markOrderedProductBlockedForReporting: MarkOrderedProductBlockedResponse
  markShipmentDispatched: MarkShipmentDispatchedResponse
  placeOrder: PlaceOrderResponse
  publishReport?: Maybe<WriteReportResponse>
  /**
   * Make a report version visible to the user
   * The report version must be in the DRAFT state.
   * Sends a notification email unless `silent` is set to true
   */
  publishReportVersion: ReportResponse
  repeatLabTest: RepeatLabTestResponse
  resendActivationEmail: ResendActivationEmailResponse
  reshipOrderItems: ReshipOrderItemsResponse
  testError: Scalars['Boolean']
  unignoreHl7: IgnoreHl7Response
  /**
   * Make a report version invisible to the user
   * The report version must be in the PUBLISHED state
   */
  unpublishReportVersion: ReportResponse
  updateKitActivationInfo: UpdateKitActivationInfoResponse
  updateKitRequestRequests: UpdateKitRequestRequestsResponse
  verifyToken: VerifyTokenResponse
  /**
   * Create or update report input
   * * If no report versions already exist, creates a new DRAFT report version
   * * If the most recent report is DRAFT, updates the content of it
   * * If the most recent report is PUBLISHED, updates the content of it
   * * If the most recent report is LOCKED, creates a new DRAFT report version
   */
  writeCgmReport: ReportResponse
  writeReport: WriteReportResponse
}

export type MutationActivateKitArgs = {
  input: ActivateKitInput
}

export type MutationAddKitNoteArgs = {
  input: AddKitNoteInput
}

export type MutationAmendHl7Args = {
  input: AmendHl7Input
}

export type MutationCancelKitArgs = {
  id: Scalars['ID']
}

export type MutationCancelKitRequestArgs = {
  input: CancelKitRequestInput
}

export type MutationCancelLabTestOrderedProductArgs = {
  id: Scalars['ID']
}

export type MutationChangeUserDetailsArgs = {
  input: ChangeUserDetailsInput
}

export type MutationChangeUserEmailArgs = {
  input: ChangeUserEmailInput
}

export type MutationClaimOffPlatformKitArgs = {
  input: ClaimOffPlatformKitInput
}

export type MutationConfirmOrderArgs = {
  input: ConfirmOrderInput
}

export type MutationCreateUserArgs = {
  input: CreateUserInput
}

export type MutationDownloadReportPdfArgs = {
  tdlResultId: Scalars['ID']
}

export type MutationGenerateKitIdsArgs = {
  input: GenerateKitIdsInput
}

export type MutationIgnoreHl7Args = {
  id: Scalars['String']
}

export type MutationMarkKitPackedArgs = {
  input: MarkKitPackedInput
}

export type MutationMarkOrderedProductBlockedForReportingArgs = {
  input: MarkOrderedProductBlockedInput
}

export type MutationMarkShipmentDispatchedArgs = {
  input: MarkShipmentDispatchedInput
}

export type MutationPlaceOrderArgs = {
  input: PlaceOrderInput
}

export type MutationPublishReportArgs = {
  orderedProductId: Scalars['ID']
  silent?: InputMaybe<Scalars['Boolean']>
  unpublish?: InputMaybe<Scalars['Boolean']>
}

export type MutationPublishReportVersionArgs = {
  input: PublishReportVersionInput
}

export type MutationRepeatLabTestArgs = {
  input: RepeatLabTestInput
}

export type MutationResendActivationEmailArgs = {
  email: Scalars['String']
}

export type MutationReshipOrderItemsArgs = {
  input: ReshipOrderItemsInput
}

export type MutationUnignoreHl7Args = {
  id: Scalars['String']
}

export type MutationUnpublishReportVersionArgs = {
  input: UnpublishReportVersionInput
}

export type MutationUpdateKitActivationInfoArgs = {
  input: UpdateKitActivationInfoInput
}

export type MutationUpdateKitRequestRequestsArgs = {
  input: UpdateKitRequestRequests
}

export type MutationVerifyTokenArgs = {
  token: Scalars['ID']
}

export type MutationWriteCgmReportArgs = {
  input: WriteCgmReportInput
}

export type MutationWriteReportArgs = {
  input: WriteReportInput
}

export type Note = {
  author: Scalars['String']
  createdAt: Scalars['String']
  id: Scalars['ID']
  note: Scalars['String']
  subject?: Maybe<Scalars['String']>
}

export enum ObrStatus {
  Final = 'FINAL',
  Interim = 'INTERIM',
  SomeAvailable = 'SOME_AVAILABLE',
}

export enum ObxStatus {
  Final = 'FINAL',
  Interim = 'INTERIM',
}

export enum OrcStatus {
  Complete = 'COMPLETE',
  InProgress = 'IN_PROGRESS',
  SomeAvailable = 'SOME_AVAILABLE',
}

export type Order = {
  bloodCollectionAddress?: Maybe<Address>
  createdAt: Scalars['String']
  externalRef?: Maybe<Scalars['String']>
  firstName: Scalars['String']
  id: Scalars['ID']
  lastName: Scalars['String']
  orderPriceDetails: BasketPriceDetails
  orderedProducts?: Maybe<Array<OrderedProduct>>
  phone: Scalars['String']
  placedBy?: Maybe<User>
  salesChannel?: Maybe<Scalars['String']>
  shipments: Array<Shipment>
  user: User
}

export type OrderLineInput = {
  modifiers?: InputMaybe<Array<Scalars['String']>>
  quantity: Scalars['Int']
  sku: Scalars['ID']
}

export type OrderListFilter = {
  afterDate?: InputMaybe<Scalars['String']>
  beforeDate?: InputMaybe<Scalars['String']>
}

export type OrderedProduct = {
  createdAt: Scalars['String']
  id: Scalars['ID']
  order?: Maybe<Order>
  productDefinition: Product
  user: User
}

export type PageInfo = {
  endCursor: Scalars['ID']
  hasNextPage: Scalars['Boolean']
  hasPreviousPage: Scalars['Boolean']
  startCursor: Scalars['ID']
}

export type PlaceOrderInput = {
  /** Admin users set this to `true` to indicate that no payment is required */
  adminNoPaymentDue?: InputMaybe<Scalars['Boolean']>
  biologicalSex?: InputMaybe<BiologicalSex>
  /** If an in-home BCO is chosen, the address at which to perform the collection */
  bloodCollectionAddress?: InputMaybe<AddressInput>
  discountCode?: InputMaybe<Scalars['ID']>
  dob?: InputMaybe<Scalars['String']>
  email: Scalars['String']
  externalRef?: InputMaybe<Scalars['String']>
  firstName: Scalars['String']
  lastName: Scalars['String']
  orderLines: Array<OrderLineInput>
  phone: Scalars['String']
  salesChannel?: InputMaybe<Scalars['String']>
  savePaymentMethod?: InputMaybe<Scalars['Boolean']>
  /** If not paying via Stripe, shipping address must be supplied here */
  shippingAddress?: InputMaybe<AddressInput>
}

export type PlaceOrderResponse = {
  /** Stripe client secret, if payment via Stripe is due */
  clientSecret?: Maybe<Scalars['ID']>
  orderId: Scalars['ID']
  orderPriceDetails: BasketPriceDetails
  success: Scalars['Boolean']
}

export type Product = {
  active: Scalars['Boolean']
  allowPurchase: Scalars['Boolean']
  blogArticles: Array<Article>
  /** @deprecated Use blogArticles instead */
  blogs: Array<Scalars['String']>
  blogsHeader?: Maybe<Scalars['String']>
  description: Scalars['String']
  featuredImage: Image
  features?: Maybe<Array<Scalars['String']>>
  intro?: Maybe<Scalars['String']>
  metaDescription?: Maybe<Scalars['String']>
  metaTitle?: Maybe<Scalars['String']>
  name: Scalars['String']
  price: Scalars['Int']
  reviews: Array<ProductReview>
  showInListings: Scalars['Boolean']
  sku: Scalars['ID']
  slug: Scalars['String']
  type: ProductType
  updatedAt: Scalars['String']
}

export type ProductFeature = {
  icon: Scalars['String']
  text: Scalars['String']
}

export type ProductKitToRequest = {
  kitDefinition: KitDefinition
  tests: Array<TestInfoOrTestProfile>
}

export type ProductListFilter = {
  categories?: InputMaybe<Array<Scalars['String']>>
  includeHidden?: InputMaybe<Scalars['Boolean']>
  sampleTypes?: InputMaybe<Array<SampleType>>
  types?: InputMaybe<Array<ProductType>>
}

export type ProductReview = {
  author: Scalars['String']
  content: Scalars['String']
  date: Scalars['String']
  link: Scalars['String']
  rating: Scalars['Int']
  source: Scalars['String']
  title: Scalars['String']
}

/** A group of tests contained in a product definition */
export type ProductTestGroup = {
  analyses: Array<Analysis>
  /** Hide analyses list in product pages */
  hideAnalyses?: Maybe<Scalars['Boolean']>
  name: Scalars['String']
  tests: Array<TestInfo>
  /** Why this particular group of tests is included in this product */
  why: Scalars['String']
}

/** Type of saleable product */
export enum ProductType {
  /** A bespoke lab test product */
  BespokeLabTest = 'BESPOKE_LAB_TEST',
  /** A CGM product */
  Cgm = 'CGM',
  /** A coaching product */
  Coaching = 'COACHING',
  /** A gift card */
  GiftCard = 'GIFT_CARD',
  /** A lab test product */
  LabTest = 'LAB_TEST',
  /** A supplement */
  Supplement = 'SUPPLEMENT',
}

export type PublishReportVersionInput = {
  reason: Scalars['String']
  reportVersionId: Scalars['ID']
  silent?: InputMaybe<Scalars['Boolean']>
}

export type Query = {
  allArticles: Array<Article>
  articleBySlug?: Maybe<Article>
  articlesByPath: Array<Article>
  articlesBySlug: Array<Maybe<Article>>
  bloodCollectionOptions: Array<BloodCollectionOption>
  calculateOrder: BasketPriceDetails
  calculateRequest?: Maybe<CalculatedRequest>
  giftCardBalance?: Maybe<GiftCardBalanceResponse>
  giftCardById?: Maybe<GiftCard>
  /** Hello World */
  hello?: Maybe<Hello>
  kitActivationStatus: KitActivationStatusResponse
  kitById?: Maybe<Kit>
  kitRequestById?: Maybe<KitRequest>
  labTestOrderedProductById?: Maybe<LabTestOrderedProduct>
  labTestOrderedProducts: LabTestOrderedProductsConnection
  labTestOrderedProductsInFlight: LabTestOrderedProductsConnection
  labTestOrderedProductsReadyToReport: LabTestOrderedProductsConnection
  loginRequiredToOrder: Scalars['Boolean']
  orderById?: Maybe<Order>
  orderedProductById?: Maybe<OrderedProduct>
  orders: Array<Order>
  parseHl7: Array<TestResult>
  productBySku?: Maybe<Product>
  productBySlug?: Maybe<Product>
  products: Array<Product>
  productsBySku: Array<Product>
  recentJobs: Array<Job>
  shipmentBatchById?: Maybe<ShipmentBatch>
  shipmentBatches: Array<ShipmentBatch>
  shipments: Array<Shipment>
  shipmentsReadyToShipCount: Scalars['Int']
  tdlResultByFilename?: Maybe<TdlResult>
  tdlResults: Array<TdlResult>
  team: Array<TeamMember>
  teamMemberByHandle?: Maybe<TeamMember>
  teamMemberBySlug?: Maybe<TeamMember>
  testDefinitions: Array<TestInfo>
  userByEmail?: Maybe<User>
  userById?: Maybe<User>
  viewer?: Maybe<Viewer>
}

export type QueryArticleBySlugArgs = {
  slug: Scalars['String']
}

export type QueryArticlesByPathArgs = {
  path: Scalars['String']
}

export type QueryArticlesBySlugArgs = {
  slugs: Array<Scalars['String']>
}

export type QueryCalculateOrderArgs = {
  input: CalculateOrderInput
}

export type QueryCalculateRequestArgs = {
  bloodDrawType?: InputMaybe<BloodDrawType>
  codes: Array<Scalars['ID']>
}

export type QueryGiftCardBalanceArgs = {
  id: Scalars['String']
}

export type QueryGiftCardByIdArgs = {
  id: Scalars['String']
}

export type QueryHelloArgs = {
  name: Scalars['String']
}

export type QueryKitActivationStatusArgs = {
  kitId: Scalars['ID']
}

export type QueryKitByIdArgs = {
  id: Scalars['ID']
}

export type QueryKitRequestByIdArgs = {
  id: Scalars['ID']
}

export type QueryLabTestOrderedProductByIdArgs = {
  id: Scalars['ID']
}

export type QueryLabTestOrderedProductsArgs = {
  filter?: InputMaybe<LabTestOrderedProductFilter>
}

export type QueryLoginRequiredToOrderArgs = {
  email: Scalars['String']
}

export type QueryOrderByIdArgs = {
  orderId: Scalars['ID']
}

export type QueryOrderedProductByIdArgs = {
  id: Scalars['ID']
}

export type QueryOrdersArgs = {
  filter?: InputMaybe<OrderListFilter>
}

export type QueryParseHl7Args = {
  hl7: Scalars['String']
}

export type QueryProductBySkuArgs = {
  sku: Scalars['ID']
}

export type QueryProductBySlugArgs = {
  slug: Scalars['ID']
}

export type QueryProductsArgs = {
  filter?: InputMaybe<ProductListFilter>
}

export type QueryProductsBySkuArgs = {
  skus: Array<Scalars['ID']>
}

export type QueryShipmentBatchByIdArgs = {
  id: Scalars['ID']
}

export type QueryShipmentsArgs = {
  maxDaysOld?: InputMaybe<Scalars['Int']>
  status?: InputMaybe<ShipmentStatus>
}

export type QueryTdlResultByFilenameArgs = {
  filename: Scalars['String']
}

export type QueryTeamMemberByHandleArgs = {
  handle: Scalars['String']
}

export type QueryTeamMemberBySlugArgs = {
  slug: Scalars['String']
}

export type QueryUserByEmailArgs = {
  email: Scalars['String']
}

export type QueryUserByIdArgs = {
  id: Scalars['ID']
}

export enum RangeType {
  /** Only applicable to calculated level, not ranges */
  Equivocal = 'EQUIVOCAL',
  High = 'HIGH',
  HighRed = 'HIGH_RED',
  Low = 'LOW',
  LowRed = 'LOW_RED',
  /** Only applicable to calculated level, not ranges */
  Negative = 'NEGATIVE',
  Normal = 'NORMAL',
  Optimal = 'OPTIMAL',
  /** Only applicable to calculated level, not ranges */
  Positive = 'POSITIVE',
  Unknown = 'UNKNOWN',
  /** Only applicable to calculated level, not ranges */
  WeakPositive = 'WEAK_POSITIVE',
}

export type RecommendedTestAddon = {
  testAddon: TestAddon
  why: Scalars['String']
}

export type RepeatLabTestInput = {
  bloodCollectionOption?: InputMaybe<Scalars['String']>
  items: Array<RepeatLabTestItemInput>
  shippingAddress: AddressInput
}

export type RepeatLabTestItemInput = {
  orderedProductId: Scalars['ID']
  /** List of _tests_ (not test profiles) to request */
  requests: Array<Scalars['ID']>
}

export type RepeatLabTestResponse = {
  message?: Maybe<Scalars['String']>
  orderedProducts?: Maybe<Array<Maybe<LabTestOrderedProduct>>>
  shipment?: Maybe<Shipment>
  success: Scalars['Boolean']
}

export type ReportMeta = CgmReportMeta | LabTestReportMeta

export type ReportResponse = {
  reportVersion: ReportVersion
}

export type ReportVersion = {
  accessLog: Array<AccessLogEntry>
  author: User
  authorInfo: TeamMember
  id: Scalars['ID']
  meta: ReportMeta
  orderedProduct: OrderedProduct
  publishReason?: Maybe<Scalars['String']>
  publishedAt?: Maybe<Scalars['String']>
  recommendations: Scalars['String']
  report: Scalars['String']
  status: ReportVersionStatus
  unpublishReason?: Maybe<Scalars['String']>
  unpublishedAt?: Maybe<Scalars['String']>
  updatedAt: Scalars['String']
}

export enum ReportVersionStatus {
  Draft = 'DRAFT',
  Locked = 'LOCKED',
  Published = 'PUBLISHED',
}

export type ResendActivationEmailResponse = {
  message?: Maybe<Scalars['String']>
  success: Scalars['Boolean']
}

export type ReshipOrderItemsInput = {
  orderLines: Array<OrderLineInput>
}

export type ReshipOrderItemsResponse = {
  message?: Maybe<Scalars['String']>
  shipment?: Maybe<Shipment>
  success: Scalars['Boolean']
}

export enum SampleLocation {
  Clinic = 'CLINIC',
  Home = 'HOME',
}

export enum SampleTubeType {
  Cit = 'CIT',
  Edta = 'EDTA',
  Faeces = 'FAECES',
  Oxa = 'OXA',
  Qfit = 'QFIT',
  Se = 'SE',
  Sh = 'SH',
  Sst = 'SST',
}

/** Type of sample required for a test */
export enum SampleType {
  Blood = 'BLOOD',
  Stool = 'STOOL',
}

export type SavedPaymentMethod = {
  brand: Scalars['String']
  expMonth: Scalars['Int']
  expYear: Scalars['Int']
  id: Scalars['ID']
  last4: Scalars['String']
}

export type ScalePoint = {
  type: ScalePointType
  val: Scalars['Float']
}

export enum ScalePointType {
  High = 'HIGH',
  HighRed = 'HIGH_RED',
  Low = 'LOW',
  LowRed = 'LOW_RED',
  Max = 'MAX',
  Min = 'MIN',
  OptimalHigh = 'OPTIMAL_HIGH',
  OptimalLow = 'OPTIMAL_LOW',
  Unknown = 'UNKNOWN',
}

export enum ScaleType {
  Categorical = 'CATEGORICAL',
  Lin = 'LIN',
  Log = 'LOG',
}

export type Shipment = Shippable & {
  createdAt: Scalars['String']
  dispatchedAt?: Maybe<Scalars['String']>
  /**
   * If this shipment contained lab tests, dispatchedKits contains the
   * kits that were dispatched to fulfil them
   */
  dispatchedKits?: Maybe<Array<Kit>>
  id: Scalars['ID']
  items: Array<ShipmentItem>
  /** @deprecated Use items instead */
  kitRequests: Array<KitRequest>
  order: Order
  shippingAddress: Address
  shippingLatestEvent?: Maybe<Scalars['String']>
  shippingStatus?: Maybe<Scalars['String']>
  shippinglastUpdate?: Maybe<Scalars['String']>
  status: ShipmentStatus
  trackingMoreId?: Maybe<Scalars['ID']>
  trackingNo?: Maybe<Scalars['String']>
}

/** A batch of shipments */
export type ShipmentBatch = {
  createdAt: Scalars['String']
  createdByEmail: Scalars['String']
  id: Scalars['ID']
  shipmentCount: Scalars['Int']
  shipments: Array<Shipment>
}

export type ShipmentItem = {
  id: Scalars['ID']
  shippableItem: ShippableItem
  sku: Scalars['String']
}

export enum ShipmentStatus {
  BatchAssigned = 'BATCH_ASSIGNED',
  Dispatched = 'DISPATCHED',
  Ready = 'READY',
  ThirdPartySubmitted = 'THIRD_PARTY_SUBMITTED',
}

export type Shippable = {
  /** TrackingMore latest event, includes time and location */
  shippingLatestEvent?: Maybe<Scalars['String']>
  shippingStatus?: Maybe<Scalars['String']>
  shippinglastUpdate?: Maybe<Scalars['String']>
  trackingMoreId?: Maybe<Scalars['ID']>
}

export type ShippableItem = KitDefinition | SupplementProduct

export enum ShippingProvider {
  RoyalMail = 'ROYAL_MAIL',
}

export type SupplementProduct = Product & {
  active: Scalars['Boolean']
  allowPurchase: Scalars['Boolean']
  blogArticles: Array<Article>
  blogs: Array<Scalars['String']>
  blogsHeader?: Maybe<Scalars['String']>
  description: Scalars['String']
  featuredImage: Image
  features?: Maybe<Array<Scalars['String']>>
  intro?: Maybe<Scalars['String']>
  metaDescription?: Maybe<Scalars['String']>
  metaTitle?: Maybe<Scalars['String']>
  name: Scalars['String']
  price: Scalars['Int']
  reviews: Array<ProductReview>
  showInListings: Scalars['Boolean']
  sku: Scalars['ID']
  slug: Scalars['String']
  type: ProductType
  updatedAt: Scalars['String']
}

export type TdlPatientName = {
  familyName?: Maybe<Scalars['String']>
  givenName?: Maybe<Scalars['String']>
  middleName?: Maybe<Scalars['String']>
  prefix?: Maybe<Scalars['String']>
  suffix?: Maybe<Scalars['String']>
}

export type TdlRegUploadJob = Job & {
  csv?: Maybe<Scalars['String']>
  filename?: Maybe<Scalars['String']>
  id: Scalars['ID']
  status: Scalars['String']
}

export type TdlResult = {
  cumulativeResults: Array<TestResult>
  filename: Scalars['String']
  id: Scalars['ID']
  /** True if we don't wish to consider this data due to lab errors */
  ignored?: Maybe<Scalars['Boolean']>
  /** If the stated kit is in the system, the Kit entity */
  kit?: Maybe<Kit>
  /** In case the kit is not found, the stated ID will be here */
  kitId?: Maybe<Scalars['ID']>
  labRef: Scalars['String']
  /** ORC.5.1 value */
  labStatus?: Maybe<OrcStatus>
  patientDob?: Maybe<Scalars['String']>
  patientName?: Maybe<TdlPatientName>
  patientSex?: Maybe<Scalars['String']>
  /** Raw HL7 data */
  raw: Scalars['String']
  results: Array<TestResult>
}

export type TeamMember = {
  articles: Array<Article>
  cutoutImage?: Maybe<Image>
  expertise?: Maybe<Array<Maybe<Scalars['String']>>>
  features?: Maybe<Array<Maybe<Scalars['String']>>>
  firstName: Scalars['String']
  fullBio?: Maybe<Scalars['String']>
  gmc?: Maybe<Scalars['String']>
  headImage?: Maybe<Image>
  id: Scalars['String']
  lastName: Scalars['String']
  miniBio: Scalars['String']
  outdoorImage?: Maybe<Image>
  postNominals: Scalars['String']
  primaryImage: Image
  publications?: Maybe<Array<Maybe<Scalars['String']>>>
  role: Scalars['String']
  secondaryImage?: Maybe<Image>
  slug?: Maybe<Scalars['String']>
  statement?: Maybe<Scalars['String']>
  statementHeader?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  updatedAt: Scalars['String']
}

export type TestAddon = BasketLineModifier & {
  code: Scalars['ID']
  name: Scalars['String']
  price: Scalars['Int']
  /** What the price would be if this test was purchased as a standalone product */
  standalonePrice?: Maybe<Scalars['Int']>
  test: TestInfo
}

/** Information about a lab test we can provide */
export type TestInfo = {
  addonPrice?: Maybe<Scalars['Int']>
  analyses: Array<Analysis>
  /** True if this test can be run with a capillary sample */
  capillary?: Maybe<Scalars['Boolean']>
  categories: Array<Scalars['String']>
  description: Scalars['String']
  friendlyName?: Maybe<Scalars['String']>
  id: Scalars['ID']
  instructions?: Maybe<Scalars['String']>
  name: Scalars['String']
  sample: SampleType
  sampleTube: SampleTubeType
  turnaroundTime: Scalars['Int']
}

export type TestInfoOrTestProfile = TestInfo | TestProfile

/** Predefined collection of TestInfos */
export type TestProfile = {
  id: Scalars['String']
  tests: Array<TestInfo>
}

export type TestResult = {
  analyses: Array<AnalysisResult>
  obrStatus?: Maybe<ObrStatus>
  sampleTime?: Maybe<Scalars['String']>
  testCode: Scalars['String']
  /**
   * Nullable since we might not always have a definition for everything that we
   * get from TDL
   */
  testDefinition?: Maybe<TestInfo>
}

export type TuliApptConfEmailJob = Job & {
  id: Scalars['ID']
  status: Scalars['String']
  tuliApptId: Scalars['String']
}

export type UnpublishReportVersionInput = {
  reason: Scalars['String']
  reportVersionId: Scalars['ID']
}

export type UpdateKitActivationInfoInput = {
  biologicalSex?: InputMaybe<BiologicalSex>
  dob?: InputMaybe<Scalars['String']>
  extraData?: InputMaybe<Scalars['String']>
  firstName?: InputMaybe<Scalars['String']>
  kitId: Scalars['ID']
  lastName?: InputMaybe<Scalars['String']>
}

export type UpdateKitActivationInfoResponse = {
  kit: Kit
}

export type UpdateKitRequestRequests = {
  kitRequestId: Scalars['ID']
  requests: Array<Scalars['String']>
}

export type UpdateKitRequestRequestsResponse = {
  kitRequest: KitRequest
}

export type User = {
  activated: Scalars['Boolean']
  activationCodeCreatedAt?: Maybe<Scalars['String']>
  biologicalSex?: Maybe<BiologicalSex>
  dob?: Maybe<Scalars['String']>
  email: Scalars['String']
  firstName?: Maybe<Scalars['String']>
  friendlyName?: Maybe<Scalars['String']>
  id: Scalars['ID']
  lastName?: Maybe<Scalars['String']>
  orderedProducts: Array<OrderedProduct>
  orders: Array<Order>
  phoneNumber?: Maybe<Scalars['String']>
  savedPaymentMethods: Array<SavedPaymentMethod>
}

export type UserAction = {
  createdAt: Scalars['String']
}

export enum VerifyTokenOutcome {
  ConfirmedExistingUser = 'CONFIRMED_EXISTING_USER',
  ErrAlreadyActivated = 'ERR_ALREADY_ACTIVATED',
  ErrLoggedInDifferentUser = 'ERR_LOGGED_IN_DIFFERENT_USER',
  ErrTokenExpired = 'ERR_TOKEN_EXPIRED',
  ErrUnknownToken = 'ERR_UNKNOWN_TOKEN',
  TokenValid = 'TOKEN_VALID',
}

export type VerifyTokenResponse = {
  email?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  outcome: VerifyTokenOutcome
  redirectTo?: Maybe<Scalars['String']>
  success: Scalars['Boolean']
}

export type Viewer = {
  availableActions: Array<UserAction>
  results: Array<LabTestOrderedProduct>
  user?: Maybe<User>
}

export type WriteCgmReportInput = {
  cgmOrderedProductId: Scalars['ID']
  latestDataTimestamp: Scalars['String']
  recommendations: Scalars['String']
  report: Scalars['String']
}

export type WriteReportInput = {
  analysesToExclude?: InputMaybe<Array<Scalars['String']>>
  orderedProductId: Scalars['ID']
  recommendations: Scalars['String']
  report: Scalars['String']
  tdlResults: Array<KitRequestTdlResultIdMap>
}

export type WriteReportResponse = {
  orderedProduct?: Maybe<LabTestOrderedProduct>
}

export type UpdateKitActivationInfoMutationVariables = Exact<{
  input: UpdateKitActivationInfoInput
}>

export type UpdateKitActivationInfoMutation = {
  updateKitActivationInfo: {
    kit: {
      id: string
      activatedKitRequest?: {
        id: string
        activationInfo?: {
          firstName?: string | null
          lastName?: string | null
          dob?: string | null
          biologicalSex?: BiologicalSex | null
        } | null
      } | null
    }
  }
}

export type CalculateRequestQueryVariables = Exact<{
  codes: Array<Scalars['ID']> | Scalars['ID']
  bloodDrawType?: InputMaybe<BloodDrawType>
}>

export type CalculateRequestQuery = { calculateRequest?: { kitSku: string; requestCodes: Array<string> } | null }

export type RepeatLabTestMutationVariables = Exact<{
  input: RepeatLabTestInput
}>

export type RepeatLabTestMutation = {
  repeatLabTest: {
    success: boolean
    message?: string | null
    shipment?: { id: string; status: ShipmentStatus } | null
    orderedProducts?: Array<{ id: string; kitRequests: Array<{ id: string }> } | null> | null
  }
}

export type ShipmentsReadyToShipCountQueryVariables = Exact<{ [key: string]: never }>

export type ShipmentsReadyToShipCountQuery = { shipmentsReadyToShipCount: number }

export type AdminCreateShipmentBatchMutationVariables = Exact<{ [key: string]: never }>

export type AdminCreateShipmentBatchMutation = {
  createShipmentBatch: {
    message?: string | null
    success: boolean
    shipmentBatch?: {
      createdAt: string
      createdByEmail: string
      id: string
      shipmentCount: number
      shipments: Array<{
        id: string
        status: ShipmentStatus
        kitRequests: Array<{
          id: string
          packedKit?: { id: string } | null
          kitDefinition: { sku: string }
          orderedProduct?: {
            id: string
            productDefinition:
              | { sku: string; name: string }
              | { sku: string; name: string }
              | { sku: string; name: string }
              | { sku: string; name: string }
              | { sku: string; name: string }
              | { sku: string; name: string }
          } | null
        }>
        order: {
          createdAt: string
          id: string
          phone: string
          firstName: string
          lastName: string
          user: { email: string }
          orderPriceDetails: { subTotal: number; total: number }
        }
        shippingAddress: { line1: string; line2?: string | null; city: string; postcode: string }
      }>
    } | null
  }
}

export type MarkShipmentDispatchedMutationVariables = Exact<{
  input: MarkShipmentDispatchedInput
}>

export type MarkShipmentDispatchedMutation = { markShipmentDispatched: { message?: string | null; success: boolean } }

export type ShipmentForPackingListFragment = {
  id: string
  kitRequests: Array<{
    id: string
    packedKit?: { id: string } | null
    kitDefinition: { sku: string }
    orderedProduct?: {
      id: string
      productDefinition:
        | { sku: string; name: string }
        | { sku: string; name: string }
        | { sku: string; name: string }
        | { sku: string; name: string }
        | { sku: string; name: string }
        | { sku: string; name: string }
    } | null
  }>
  order: { createdAt: string; id: string; firstName: string; lastName: string; user: { id: string } }
  shippingAddress: { line1: string; line2?: string | null; city: string; postcode: string }
}

export type MarkKitPackedMutationVariables = Exact<{
  input: MarkKitPackedInput
}>

export type MarkKitPackedMutation = {
  markKitPacked: { success: boolean; kitRequest?: { id: string; packedKit?: { id: string } | null } | null }
}

export type AllTestsQueryVariables = Exact<{ [key: string]: never }>

export type AllTestsQuery = {
  testDefinitions: Array<{
    id: string
    name: string
    sample: SampleType
    capillary?: boolean | null
    addonPrice?: number | null
  }>
}

export type UpdateKitRequestsMutationVariables = Exact<{
  input: UpdateKitRequestRequests
}>

export type UpdateKitRequestsMutation = {
  updateKitRequestRequests: { kitRequest: { id: string; rawRequestCodes?: Array<string | null> | null } }
}

export type ProductRelatedContentQueryVariables = Exact<{ [key: string]: never }>

export type ProductRelatedContentQuery = {
  viewer?: {
    user?: {
      orders: Array<{
        orderPriceDetails: {
          lines: Array<{
            product:
              | {
                  blogArticles: Array<{
                    __typename: 'Article'
                    title: string
                    path: string
                    slug: string
                    publishedAt: string
                    updatedAt?: string | null
                    readingTimeMinutes: number
                    categories: Array<string>
                    hero: { src: string }
                  }>
                }
              | {
                  blogArticles: Array<{
                    __typename: 'Article'
                    title: string
                    path: string
                    slug: string
                    publishedAt: string
                    updatedAt?: string | null
                    readingTimeMinutes: number
                    categories: Array<string>
                    hero: { src: string }
                  }>
                }
              | {
                  blogArticles: Array<{
                    __typename: 'Article'
                    title: string
                    path: string
                    slug: string
                    publishedAt: string
                    updatedAt?: string | null
                    readingTimeMinutes: number
                    categories: Array<string>
                    hero: { src: string }
                  }>
                }
              | {
                  blogArticles: Array<{
                    __typename: 'Article'
                    title: string
                    path: string
                    slug: string
                    publishedAt: string
                    updatedAt?: string | null
                    readingTimeMinutes: number
                    categories: Array<string>
                    hero: { src: string }
                  }>
                }
              | {
                  blogArticles: Array<{
                    __typename: 'Article'
                    title: string
                    path: string
                    slug: string
                    publishedAt: string
                    updatedAt?: string | null
                    readingTimeMinutes: number
                    categories: Array<string>
                    hero: { src: string }
                  }>
                }
              | {
                  blogArticles: Array<{
                    __typename: 'Article'
                    title: string
                    path: string
                    slug: string
                    publishedAt: string
                    updatedAt?: string | null
                    readingTimeMinutes: number
                    categories: Array<string>
                    hero: { src: string }
                  }>
                }
          }>
        }
      }>
    } | null
  } | null
}

export type OrderedProductUserActionFragment = {
  orderedProduct: {
    id: string
    reportStatus?: LabTestOrderedProductReportStatus | null
    bloodCollectionOption?: {
      id: string
      code: string
      name: string
      bloodDrawType: BloodDrawType
      clinicId?: string | null
      provider?: string | null
    } | null
    productDefinition:
      | { sku: string; slug: string; type: ProductType; name: string }
      | { sku: string; slug: string; type: ProductType; name: string }
      | { sku: string; slug: string; type: ProductType; name: string }
      | { sku: string; slug: string; type: ProductType; name: string }
      | { sku: string; slug: string; type: ProductType; name: string }
      | { sku: string; slug: string; type: ProductType; name: string }
    order?: { id: string; createdAt: string } | null
    kitRequests: Array<{
      id: string
      status: KitRequestStatus
      kitDefinition: { sku: string; labCode: string; sampleType: SampleType; bloodDrawType?: BloodDrawType | null }
      kit?: { id: string } | null
      requestedTests: Array<{ id: string; instructions?: string | null; turnaroundTime: number }>
      tdlResults: Array<{ filename: string; labStatus?: OrcStatus | null }>
      reportedTdlResult?: { id: string } | null
      currentTdlResult?: { id: string } | null
    }>
  }
}

export type AvailableActionsQueryVariables = Exact<{ [key: string]: never }>

export type AvailableActionsQuery = {
  viewer?: {
    availableActions: Array<{
      __typename: 'LabTestOrderedProductUserAction'
      createdAt: string
      orderedProduct: {
        id: string
        reportStatus?: LabTestOrderedProductReportStatus | null
        bloodCollectionOption?: {
          id: string
          code: string
          name: string
          bloodDrawType: BloodDrawType
          clinicId?: string | null
          provider?: string | null
        } | null
        productDefinition:
          | { sku: string; slug: string; type: ProductType; name: string }
          | { sku: string; slug: string; type: ProductType; name: string }
          | { sku: string; slug: string; type: ProductType; name: string }
          | { sku: string; slug: string; type: ProductType; name: string }
          | { sku: string; slug: string; type: ProductType; name: string }
          | { sku: string; slug: string; type: ProductType; name: string }
        order?: { id: string; createdAt: string } | null
        kitRequests: Array<{
          id: string
          status: KitRequestStatus
          kitDefinition: { sku: string; labCode: string; sampleType: SampleType; bloodDrawType?: BloodDrawType | null }
          kit?: { id: string } | null
          requestedTests: Array<{ id: string; instructions?: string | null; turnaroundTime: number }>
          tdlResults: Array<{ filename: string; labStatus?: OrcStatus | null }>
          reportedTdlResult?: { id: string } | null
          currentTdlResult?: { id: string } | null
        }>
      }
    }>
  } | null
}

export type CgmCardDataQueryVariables = Exact<{
  articleSlugs: Array<Scalars['String']> | Scalars['String']
}>

export type CgmCardDataQuery = {
  viewer?: {
    user?: {
      id: string
      orderedProducts: Array<
        | {
            __typename: 'CgmOrderedProduct'
            id: string
            productDefinition:
              | { name: string }
              | { name: string }
              | { name: string }
              | { name: string }
              | { name: string }
              | { name: string }
            user: {
              email: string
              id: string
              friendlyName?: string | null
              firstName?: string | null
              lastName?: string | null
            }
            order?: {
              id: string
              createdAt: string
              firstName: string
              lastName: string
              user: { email: string; id: string }
              shipments: Array<{
                shippingAddress: { line1: string; line2?: string | null; postcode: string; city: string }
              }>
            } | null
            analysisRuns: Array<{
              id: string
              fromTimestamp: string
              toTimestamp: string
              analyses: Array<{
                analysisCode: string
                calculatedLevel?: CgmAnalysisLevel | null
                calculatedCentile?: number | null
                value?: number | null
                analysisDefinition: {
                  code: string
                  name: string
                  description: string
                  units?: string | null
                  scale: CgmScaleType
                  max?: number | null
                  min?: number | null
                }
                definedRanges?: Array<{
                  fromVal?: number | null
                  toVal?: number | null
                  level: CgmAnalysisLevel
                }> | null
                centileRanges?: Array<{ centile: number; value: number }> | null
              }>
            }>
            latestReportVersion?: {
              id: string
              recommendations: string
              report: string
              status: ReportVersionStatus
              updatedAt: string
              publishedAt?: string | null
              accessLog: Array<{
                time?: string | null
                user: { id: string; firstName?: string | null; lastName?: string | null }
              }>
              authorInfo: {
                firstName: string
                lastName: string
                title?: string | null
                role: string
                primaryImage: { name: string; src: string }
              }
            } | null
          }
        | { __typename: 'GiftCardOrderedProduct'; id: string }
        | { __typename: 'LabTestOrderedProduct'; id: string }
      >
    } | null
  } | null
  articlesBySlug: Array<{ __typename: 'Article'; title: string; path: string; slug: string } | null>
}

export type BlogLinkFragment = { __typename: 'Article'; title: string; path: string; slug: string }

export type HelpfulInstructionsQueryVariables = Exact<{ [key: string]: never }>

export type HelpfulInstructionsQuery = {
  viewer?: {
    user?: {
      orderedProducts: Array<
        | { __typename: 'CgmOrderedProduct'; id: string }
        | { __typename: 'GiftCardOrderedProduct'; id: string }
        | {
            __typename: 'LabTestOrderedProduct'
            id: string
            bloodCollectionOption?: { code: string; provider?: string | null } | null
            kitRequests: Array<{ status: KitRequestStatus; kitDefinition: { sampleType: SampleType } }>
          }
      >
    } | null
  } | null
}

export type HelpfulInstructionProdInfoFragment = {
  __typename: 'LabTestOrderedProduct'
  bloodCollectionOption?: { code: string; provider?: string | null } | null
  kitRequests: Array<{ status: KitRequestStatus; kitDefinition: { sampleType: SampleType } }>
}

export type OrderedProductUserResultFragment = {
  id: string
  reportStatus?: LabTestOrderedProductReportStatus | null
  reportPublishedAt?: string | null
  productDefinition:
    | { sku: string; slug: string; type: ProductType; name: string; description: string }
    | { sku: string; slug: string; type: ProductType; name: string; description: string }
    | { sku: string; slug: string; type: ProductType; name: string; description: string }
    | { sku: string; slug: string; type: ProductType; name: string; description: string }
    | { sku: string; slug: string; type: ProductType; name: string; description: string }
    | { sku: string; slug: string; type: ProductType; name: string; description: string }
  order?: { id: string; createdAt: string } | null
  kitRequests: Array<{
    id: string
    status: KitRequestStatus
    activationInfo?: { firstName?: string | null; lastName?: string | null; dob?: string | null } | null
    kitDefinition: { sku: string; sampleType: SampleType }
    kit?: { id: string; activatedAt?: string | null } | null
    requestedTests: Array<{ id: string; name: string; description: string; turnaroundTime: number }>
    tdlResults: Array<{ filename: string; labStatus?: OrcStatus | null }>
    reportedTdlResult?: { id: string } | null
    currentTdlResult?: { id: string } | null
  }>
}

export type AvailableResultsQueryVariables = Exact<{ [key: string]: never }>

export type AvailableResultsQuery = {
  viewer?: {
    results: Array<{
      id: string
      reportStatus?: LabTestOrderedProductReportStatus | null
      reportPublishedAt?: string | null
      productDefinition:
        | { sku: string; slug: string; type: ProductType; name: string; description: string }
        | { sku: string; slug: string; type: ProductType; name: string; description: string }
        | { sku: string; slug: string; type: ProductType; name: string; description: string }
        | { sku: string; slug: string; type: ProductType; name: string; description: string }
        | { sku: string; slug: string; type: ProductType; name: string; description: string }
        | { sku: string; slug: string; type: ProductType; name: string; description: string }
      order?: { id: string; createdAt: string } | null
      kitRequests: Array<{
        id: string
        status: KitRequestStatus
        activationInfo?: { firstName?: string | null; lastName?: string | null; dob?: string | null } | null
        kitDefinition: { sku: string; sampleType: SampleType }
        kit?: { id: string; activatedAt?: string | null } | null
        requestedTests: Array<{ id: string; name: string; description: string; turnaroundTime: number }>
        tdlResults: Array<{ filename: string; labStatus?: OrcStatus | null }>
        reportedTdlResult?: { id: string } | null
        currentTdlResult?: { id: string } | null
      }>
    }>
  } | null
}

export type ProductBySkuQueryVariables = Exact<{
  sku: Scalars['ID']
}>

export type ProductBySkuQuery = {
  productBySku?:
    | {
        __typename: 'BespokeLabTestProduct'
        sku: string
        name: string
        allowPurchase: boolean
        description: string
        price: number
        type: ProductType
        slug: string
        featuredImage: { name: string; src: string }
      }
    | {
        __typename: 'CgmProduct'
        sku: string
        name: string
        allowPurchase: boolean
        description: string
        price: number
        type: ProductType
        slug: string
        featuredImage: { name: string; src: string }
      }
    | {
        __typename: 'CoachingProduct'
        sku: string
        name: string
        allowPurchase: boolean
        description: string
        price: number
        type: ProductType
        slug: string
        featuredImage: { name: string; src: string }
      }
    | {
        __typename: 'GiftCardProduct'
        sku: string
        name: string
        allowPurchase: boolean
        description: string
        price: number
        type: ProductType
        slug: string
        featuredImage: { name: string; src: string }
      }
    | {
        __typename: 'LabTestProduct'
        sku: string
        name: string
        allowPurchase: boolean
        description: string
        price: number
        type: ProductType
        slug: string
        featuredImage: { name: string; src: string }
      }
    | {
        __typename: 'SupplementProduct'
        sku: string
        name: string
        allowPurchase: boolean
        description: string
        price: number
        type: ProductType
        slug: string
        featuredImage: { name: string; src: string }
      }
    | null
}

export type CheckoutCalculateOrderQueryVariables = Exact<{
  input: CalculateOrderInput
}>

export type CheckoutCalculateOrderQuery = {
  calculateOrder: {
    __typename: 'BasketPriceDetails'
    total: number
    subTotal: number
    requiresShipping: boolean
    requiresAccountActivation: boolean
    lines: Array<{
      __typename: 'BasketLine'
      quantity: number
      totalPrice: number
      product:
        | {
            __typename: 'BespokeLabTestProduct'
            sku: string
            name: string
            slug: string
            description: string
            active: boolean
            price: number
            type: ProductType
            featuredImage: { src: string; name: string }
          }
        | {
            __typename: 'CgmProduct'
            sku: string
            name: string
            slug: string
            description: string
            active: boolean
            price: number
            type: ProductType
            featuredImage: { src: string; name: string }
          }
        | {
            __typename: 'CoachingProduct'
            sku: string
            name: string
            slug: string
            description: string
            active: boolean
            price: number
            type: ProductType
            featuredImage: { src: string; name: string }
          }
        | {
            __typename: 'GiftCardProduct'
            sku: string
            name: string
            slug: string
            description: string
            active: boolean
            price: number
            type: ProductType
            featuredImage: { src: string; name: string }
          }
        | {
            __typename: 'LabTestProduct'
            sku: string
            name: string
            slug: string
            description: string
            active: boolean
            price: number
            type: ProductType
            allowPurchase: boolean
            showInListings: boolean
            intro?: string | null
            metaDescription?: string | null
            metaTitle?: string | null
            features?: Array<string> | null
            categories: Array<string>
            valueProposition: string
            whatsTestedHeader?: string | null
            personaliseHeader?: string | null
            blogsHeader?: string | null
            faqHeader?: string | null
            whatsTestedCopy?: string | null
            biomarkers: number
            sampleLocation: SampleLocation
            analysisLocation: AnalysisLocation
            turnaroundTime: number
            sampleTypes: Array<SampleType>
            whoFor?: Array<string> | null
            featuredImage: { src: string; name: string }
            spokesperson: {
              title?: string | null
              firstName: string
              lastName: string
              role: string
              headImage?: { src: string; name: string } | null
              cutoutImage?: { src: string; name: string } | null
            }
            otherImages: Array<{ src: string; name: string }>
            shoppingFeedImage?: { src: string } | null
            blogArticles: Array<{
              __typename: 'Article'
              title: string
              path: string
              slug: string
              publishedAt: string
              updatedAt?: string | null
              readingTimeMinutes: number
              categories: Array<string>
              hero: { src: string }
            }>
            faqItems: Array<{ question: string; answer: string }>
            testGroups: Array<{
              hideAnalyses?: boolean | null
              name: string
              why: string
              analyses: Array<{ id: string }>
              tests: Array<{
                __typename: 'TestInfo'
                id: string
                name: string
                description: string
                capillary?: boolean | null
                categories: Array<string>
                sample: SampleType
                sampleTube: SampleTubeType
                turnaroundTime: number
                analyses: Array<{ id: string; name: string; description: string }>
              }>
            }>
            reviews: Array<{
              source: string
              date: string
              rating: number
              title: string
              content: string
              link: string
              author: string
            }>
            bloodCollectionOptions: Array<{
              id: string
              code: string
              name: string
              description: string
              price: number
              bloodDrawType: BloodDrawType
            }>
            recommendedAddons: Array<{
              why: string
              testAddon: {
                code: string
                price: number
                standalonePrice?: number | null
                name: string
                test: { friendlyName?: string | null; capillary?: boolean | null; sample: SampleType }
              }
            }>
          }
        | {
            __typename: 'SupplementProduct'
            sku: string
            name: string
            slug: string
            description: string
            active: boolean
            price: number
            type: ProductType
            featuredImage: { src: string; name: string }
          }
      modifiers: Array<{ code: string; name: string; price: number } | { code: string; name: string; price: number }>
    }>
    discount?: {
      __typename: 'BasketDiscount'
      amount: number
      code: string
      description?: string | null
      failureMessage?: string | null
    } | null
  }
}

export type OrderDiscountFragment = {
  __typename: 'BasketDiscount'
  amount: number
  code: string
  description?: string | null
  failureMessage?: string | null
}

export type LineFragment = {
  __typename: 'BasketLine'
  quantity: number
  totalPrice: number
  product:
    | {
        __typename: 'BespokeLabTestProduct'
        sku: string
        name: string
        slug: string
        description: string
        active: boolean
        price: number
        type: ProductType
        featuredImage: { src: string; name: string }
      }
    | {
        __typename: 'CgmProduct'
        sku: string
        name: string
        slug: string
        description: string
        active: boolean
        price: number
        type: ProductType
        featuredImage: { src: string; name: string }
      }
    | {
        __typename: 'CoachingProduct'
        sku: string
        name: string
        slug: string
        description: string
        active: boolean
        price: number
        type: ProductType
        featuredImage: { src: string; name: string }
      }
    | {
        __typename: 'GiftCardProduct'
        sku: string
        name: string
        slug: string
        description: string
        active: boolean
        price: number
        type: ProductType
        featuredImage: { src: string; name: string }
      }
    | {
        __typename: 'LabTestProduct'
        sku: string
        name: string
        slug: string
        description: string
        active: boolean
        price: number
        type: ProductType
        allowPurchase: boolean
        showInListings: boolean
        intro?: string | null
        metaDescription?: string | null
        metaTitle?: string | null
        features?: Array<string> | null
        categories: Array<string>
        valueProposition: string
        whatsTestedHeader?: string | null
        personaliseHeader?: string | null
        blogsHeader?: string | null
        faqHeader?: string | null
        whatsTestedCopy?: string | null
        biomarkers: number
        sampleLocation: SampleLocation
        analysisLocation: AnalysisLocation
        turnaroundTime: number
        sampleTypes: Array<SampleType>
        whoFor?: Array<string> | null
        featuredImage: { src: string; name: string }
        spokesperson: {
          title?: string | null
          firstName: string
          lastName: string
          role: string
          headImage?: { src: string; name: string } | null
          cutoutImage?: { src: string; name: string } | null
        }
        otherImages: Array<{ src: string; name: string }>
        shoppingFeedImage?: { src: string } | null
        blogArticles: Array<{
          __typename: 'Article'
          title: string
          path: string
          slug: string
          publishedAt: string
          updatedAt?: string | null
          readingTimeMinutes: number
          categories: Array<string>
          hero: { src: string }
        }>
        faqItems: Array<{ question: string; answer: string }>
        testGroups: Array<{
          hideAnalyses?: boolean | null
          name: string
          why: string
          analyses: Array<{ id: string }>
          tests: Array<{
            __typename: 'TestInfo'
            id: string
            name: string
            description: string
            capillary?: boolean | null
            categories: Array<string>
            sample: SampleType
            sampleTube: SampleTubeType
            turnaroundTime: number
            analyses: Array<{ id: string; name: string; description: string }>
          }>
        }>
        reviews: Array<{
          source: string
          date: string
          rating: number
          title: string
          content: string
          link: string
          author: string
        }>
        bloodCollectionOptions: Array<{
          id: string
          code: string
          name: string
          description: string
          price: number
          bloodDrawType: BloodDrawType
        }>
        recommendedAddons: Array<{
          why: string
          testAddon: {
            code: string
            price: number
            standalonePrice?: number | null
            name: string
            test: { friendlyName?: string | null; capillary?: boolean | null; sample: SampleType }
          }
        }>
      }
    | {
        __typename: 'SupplementProduct'
        sku: string
        name: string
        slug: string
        description: string
        active: boolean
        price: number
        type: ProductType
        featuredImage: { src: string; name: string }
      }
  modifiers: Array<{ code: string; name: string; price: number } | { code: string; name: string; price: number }>
}

export type BasketLineModifiersFragment = {
  __typename: 'BasketLine'
  modifiers: Array<{ code: string; name: string; price: number } | { code: string; name: string; price: number }>
}

type Product_BespokeLabTestProduct_Fragment = {
  __typename: 'BespokeLabTestProduct'
  sku: string
  name: string
  slug: string
  description: string
  active: boolean
  price: number
  type: ProductType
  featuredImage: { src: string; name: string }
}

type Product_CgmProduct_Fragment = {
  __typename: 'CgmProduct'
  sku: string
  name: string
  slug: string
  description: string
  active: boolean
  price: number
  type: ProductType
  featuredImage: { src: string; name: string }
}

type Product_CoachingProduct_Fragment = {
  __typename: 'CoachingProduct'
  sku: string
  name: string
  slug: string
  description: string
  active: boolean
  price: number
  type: ProductType
  featuredImage: { src: string; name: string }
}

type Product_GiftCardProduct_Fragment = {
  __typename: 'GiftCardProduct'
  sku: string
  name: string
  slug: string
  description: string
  active: boolean
  price: number
  type: ProductType
  featuredImage: { src: string; name: string }
}

type Product_LabTestProduct_Fragment = {
  __typename: 'LabTestProduct'
  sku: string
  name: string
  slug: string
  description: string
  active: boolean
  price: number
  type: ProductType
  allowPurchase: boolean
  showInListings: boolean
  intro?: string | null
  metaDescription?: string | null
  metaTitle?: string | null
  features?: Array<string> | null
  categories: Array<string>
  valueProposition: string
  whatsTestedHeader?: string | null
  personaliseHeader?: string | null
  blogsHeader?: string | null
  faqHeader?: string | null
  whatsTestedCopy?: string | null
  biomarkers: number
  sampleLocation: SampleLocation
  analysisLocation: AnalysisLocation
  turnaroundTime: number
  sampleTypes: Array<SampleType>
  whoFor?: Array<string> | null
  featuredImage: { src: string; name: string }
  spokesperson: {
    title?: string | null
    firstName: string
    lastName: string
    role: string
    headImage?: { src: string; name: string } | null
    cutoutImage?: { src: string; name: string } | null
  }
  otherImages: Array<{ src: string; name: string }>
  shoppingFeedImage?: { src: string } | null
  blogArticles: Array<{
    __typename: 'Article'
    title: string
    path: string
    slug: string
    publishedAt: string
    updatedAt?: string | null
    readingTimeMinutes: number
    categories: Array<string>
    hero: { src: string }
  }>
  faqItems: Array<{ question: string; answer: string }>
  testGroups: Array<{
    hideAnalyses?: boolean | null
    name: string
    why: string
    analyses: Array<{ id: string }>
    tests: Array<{
      __typename: 'TestInfo'
      id: string
      name: string
      description: string
      capillary?: boolean | null
      categories: Array<string>
      sample: SampleType
      sampleTube: SampleTubeType
      turnaroundTime: number
      analyses: Array<{ id: string; name: string; description: string }>
    }>
  }>
  reviews: Array<{
    source: string
    date: string
    rating: number
    title: string
    content: string
    link: string
    author: string
  }>
  bloodCollectionOptions: Array<{
    id: string
    code: string
    name: string
    description: string
    price: number
    bloodDrawType: BloodDrawType
  }>
  recommendedAddons: Array<{
    why: string
    testAddon: {
      code: string
      price: number
      standalonePrice?: number | null
      name: string
      test: { friendlyName?: string | null; capillary?: boolean | null; sample: SampleType }
    }
  }>
}

type Product_SupplementProduct_Fragment = {
  __typename: 'SupplementProduct'
  sku: string
  name: string
  slug: string
  description: string
  active: boolean
  price: number
  type: ProductType
  featuredImage: { src: string; name: string }
}

export type ProductFragment =
  | Product_BespokeLabTestProduct_Fragment
  | Product_CgmProduct_Fragment
  | Product_CoachingProduct_Fragment
  | Product_GiftCardProduct_Fragment
  | Product_LabTestProduct_Fragment
  | Product_SupplementProduct_Fragment

export type CheckoutPlaceOrderMutationVariables = Exact<{
  input: PlaceOrderInput
}>

export type CheckoutPlaceOrderMutation = {
  placeOrder: {
    clientSecret?: string | null
    orderId: string
    success: boolean
    orderPriceDetails: {
      __typename: 'BasketPriceDetails'
      requiresShipping: boolean
      requiresAccountActivation: boolean
      subTotal: number
      total: number
      discount?: { amount: number; code: string; description?: string | null; failureMessage?: string | null } | null
      lines: Array<{
        quantity: number
        totalPrice: number
        product:
          | {
              __typename: 'BespokeLabTestProduct'
              sku: string
              name: string
              slug: string
              description: string
              active: boolean
              price: number
              type: ProductType
              featuredImage: { src: string; name: string }
            }
          | {
              __typename: 'CgmProduct'
              sku: string
              name: string
              slug: string
              description: string
              active: boolean
              price: number
              type: ProductType
              featuredImage: { src: string; name: string }
            }
          | {
              __typename: 'CoachingProduct'
              sku: string
              name: string
              slug: string
              description: string
              active: boolean
              price: number
              type: ProductType
              featuredImage: { src: string; name: string }
            }
          | {
              __typename: 'GiftCardProduct'
              sku: string
              name: string
              slug: string
              description: string
              active: boolean
              price: number
              type: ProductType
              featuredImage: { src: string; name: string }
            }
          | {
              __typename: 'LabTestProduct'
              sku: string
              name: string
              slug: string
              description: string
              active: boolean
              price: number
              type: ProductType
              allowPurchase: boolean
              showInListings: boolean
              intro?: string | null
              metaDescription?: string | null
              metaTitle?: string | null
              features?: Array<string> | null
              categories: Array<string>
              valueProposition: string
              whatsTestedHeader?: string | null
              personaliseHeader?: string | null
              blogsHeader?: string | null
              faqHeader?: string | null
              whatsTestedCopy?: string | null
              biomarkers: number
              sampleLocation: SampleLocation
              analysisLocation: AnalysisLocation
              turnaroundTime: number
              sampleTypes: Array<SampleType>
              whoFor?: Array<string> | null
              featuredImage: { src: string; name: string }
              spokesperson: {
                title?: string | null
                firstName: string
                lastName: string
                role: string
                headImage?: { src: string; name: string } | null
                cutoutImage?: { src: string; name: string } | null
              }
              otherImages: Array<{ src: string; name: string }>
              shoppingFeedImage?: { src: string } | null
              blogArticles: Array<{
                __typename: 'Article'
                title: string
                path: string
                slug: string
                publishedAt: string
                updatedAt?: string | null
                readingTimeMinutes: number
                categories: Array<string>
                hero: { src: string }
              }>
              faqItems: Array<{ question: string; answer: string }>
              testGroups: Array<{
                hideAnalyses?: boolean | null
                name: string
                why: string
                analyses: Array<{ id: string }>
                tests: Array<{
                  __typename: 'TestInfo'
                  id: string
                  name: string
                  description: string
                  capillary?: boolean | null
                  categories: Array<string>
                  sample: SampleType
                  sampleTube: SampleTubeType
                  turnaroundTime: number
                  analyses: Array<{ id: string; name: string; description: string }>
                }>
              }>
              reviews: Array<{
                source: string
                date: string
                rating: number
                title: string
                content: string
                link: string
                author: string
              }>
              bloodCollectionOptions: Array<{
                id: string
                code: string
                name: string
                description: string
                price: number
                bloodDrawType: BloodDrawType
              }>
              recommendedAddons: Array<{
                why: string
                testAddon: {
                  code: string
                  price: number
                  standalonePrice?: number | null
                  name: string
                  test: { friendlyName?: string | null; capillary?: boolean | null; sample: SampleType }
                }
              }>
            }
          | {
              __typename: 'SupplementProduct'
              sku: string
              name: string
              slug: string
              description: string
              active: boolean
              price: number
              type: ProductType
              featuredImage: { src: string; name: string }
            }
      }>
    }
  }
}

export type CheckoutOrderFragment = {
  __typename: 'BasketPriceDetails'
  requiresShipping: boolean
  requiresAccountActivation: boolean
  subTotal: number
  total: number
  discount?: { amount: number; code: string; description?: string | null; failureMessage?: string | null } | null
  lines: Array<{
    quantity: number
    totalPrice: number
    product:
      | {
          __typename: 'BespokeLabTestProduct'
          sku: string
          name: string
          slug: string
          description: string
          active: boolean
          price: number
          type: ProductType
          featuredImage: { src: string; name: string }
        }
      | {
          __typename: 'CgmProduct'
          sku: string
          name: string
          slug: string
          description: string
          active: boolean
          price: number
          type: ProductType
          featuredImage: { src: string; name: string }
        }
      | {
          __typename: 'CoachingProduct'
          sku: string
          name: string
          slug: string
          description: string
          active: boolean
          price: number
          type: ProductType
          featuredImage: { src: string; name: string }
        }
      | {
          __typename: 'GiftCardProduct'
          sku: string
          name: string
          slug: string
          description: string
          active: boolean
          price: number
          type: ProductType
          featuredImage: { src: string; name: string }
        }
      | {
          __typename: 'LabTestProduct'
          sku: string
          name: string
          slug: string
          description: string
          active: boolean
          price: number
          type: ProductType
          allowPurchase: boolean
          showInListings: boolean
          intro?: string | null
          metaDescription?: string | null
          metaTitle?: string | null
          features?: Array<string> | null
          categories: Array<string>
          valueProposition: string
          whatsTestedHeader?: string | null
          personaliseHeader?: string | null
          blogsHeader?: string | null
          faqHeader?: string | null
          whatsTestedCopy?: string | null
          biomarkers: number
          sampleLocation: SampleLocation
          analysisLocation: AnalysisLocation
          turnaroundTime: number
          sampleTypes: Array<SampleType>
          whoFor?: Array<string> | null
          featuredImage: { src: string; name: string }
          spokesperson: {
            title?: string | null
            firstName: string
            lastName: string
            role: string
            headImage?: { src: string; name: string } | null
            cutoutImage?: { src: string; name: string } | null
          }
          otherImages: Array<{ src: string; name: string }>
          shoppingFeedImage?: { src: string } | null
          blogArticles: Array<{
            __typename: 'Article'
            title: string
            path: string
            slug: string
            publishedAt: string
            updatedAt?: string | null
            readingTimeMinutes: number
            categories: Array<string>
            hero: { src: string }
          }>
          faqItems: Array<{ question: string; answer: string }>
          testGroups: Array<{
            hideAnalyses?: boolean | null
            name: string
            why: string
            analyses: Array<{ id: string }>
            tests: Array<{
              __typename: 'TestInfo'
              id: string
              name: string
              description: string
              capillary?: boolean | null
              categories: Array<string>
              sample: SampleType
              sampleTube: SampleTubeType
              turnaroundTime: number
              analyses: Array<{ id: string; name: string; description: string }>
            }>
          }>
          reviews: Array<{
            source: string
            date: string
            rating: number
            title: string
            content: string
            link: string
            author: string
          }>
          bloodCollectionOptions: Array<{
            id: string
            code: string
            name: string
            description: string
            price: number
            bloodDrawType: BloodDrawType
          }>
          recommendedAddons: Array<{
            why: string
            testAddon: {
              code: string
              price: number
              standalonePrice?: number | null
              name: string
              test: { friendlyName?: string | null; capillary?: boolean | null; sample: SampleType }
            }
          }>
        }
      | {
          __typename: 'SupplementProduct'
          sku: string
          name: string
          slug: string
          description: string
          active: boolean
          price: number
          type: ProductType
          featuredImage: { src: string; name: string }
        }
  }>
}

export type CustomerOrderSummaryFragment = {
  id: string
  createdAt: string
  salesChannel?: string | null
  orderPriceDetails: {
    subTotal: number
    total: number
    lines: Array<{
      quantity: number
      totalPrice: number
      product:
        | {
            sku: string
            name: string
            type: ProductType
            slug: string
            price: number
            description: string
            featuredImage: { src: string; name: string }
          }
        | {
            sku: string
            name: string
            type: ProductType
            slug: string
            price: number
            description: string
            featuredImage: { src: string; name: string }
          }
        | {
            sku: string
            name: string
            type: ProductType
            slug: string
            price: number
            description: string
            featuredImage: { src: string; name: string }
          }
        | {
            sku: string
            name: string
            type: ProductType
            slug: string
            price: number
            description: string
            featuredImage: { src: string; name: string }
          }
        | {
            sku: string
            name: string
            type: ProductType
            slug: string
            price: number
            description: string
            featuredImage: { src: string; name: string }
          }
        | {
            sku: string
            name: string
            type: ProductType
            slug: string
            price: number
            description: string
            featuredImage: { src: string; name: string }
          }
      modifiers: Array<{ name: string; code: string; price: number } | { name: string; code: string; price: number }>
    }>
  }
  shipments: Array<{ dispatchedAt?: string | null; trackingNo?: string | null }>
}

type CustomerOrderedProduct_BespokeLabTestProduct_Fragment = {
  name: string
  type: ProductType
  slug: string
  price: number
  description: string
  featuredImage: { src: string; name: string }
}

type CustomerOrderedProduct_CgmProduct_Fragment = {
  name: string
  type: ProductType
  slug: string
  price: number
  description: string
  featuredImage: { src: string; name: string }
}

type CustomerOrderedProduct_CoachingProduct_Fragment = {
  name: string
  type: ProductType
  slug: string
  price: number
  description: string
  featuredImage: { src: string; name: string }
}

type CustomerOrderedProduct_GiftCardProduct_Fragment = {
  name: string
  type: ProductType
  slug: string
  price: number
  description: string
  featuredImage: { src: string; name: string }
}

type CustomerOrderedProduct_LabTestProduct_Fragment = {
  name: string
  type: ProductType
  slug: string
  price: number
  description: string
  featuredImage: { src: string; name: string }
}

type CustomerOrderedProduct_SupplementProduct_Fragment = {
  name: string
  type: ProductType
  slug: string
  price: number
  description: string
  featuredImage: { src: string; name: string }
}

export type CustomerOrderedProductFragment =
  | CustomerOrderedProduct_BespokeLabTestProduct_Fragment
  | CustomerOrderedProduct_CgmProduct_Fragment
  | CustomerOrderedProduct_CoachingProduct_Fragment
  | CustomerOrderedProduct_GiftCardProduct_Fragment
  | CustomerOrderedProduct_LabTestProduct_Fragment
  | CustomerOrderedProduct_SupplementProduct_Fragment

export type DownloadReportMutationVariables = Exact<{
  tdlResultId: Scalars['ID']
}>

export type DownloadReportMutation = { downloadReportPdf: { url?: string | null } }

export type LoginRequiredToOrderQueryVariables = Exact<{
  email: Scalars['String']
}>

export type LoginRequiredToOrderQuery = { loginRequiredToOrder: boolean }

export type UserInfoQueryVariables = Exact<{ [key: string]: never }>

export type UserInfoQuery = {
  viewer?: {
    user?: {
      email: string
      firstName?: string | null
      lastName?: string | null
      friendlyName?: string | null
      dob?: string | null
      phoneNumber?: string | null
      biologicalSex?: BiologicalSex | null
    } | null
  } | null
}

export type ArticleDataBySlugQueryVariables = Exact<{
  slug: Scalars['String']
}>

export type ArticleDataBySlugQuery = {
  articleBySlug?: {
    __typename: 'Article'
    title: string
    metaTitle?: string | null
    template: string
    publishedAt: string
    updatedAt?: string | null
    categories: Array<string>
    takeaways?: string | null
    takeawayHeader?: string | null
    summary: string
    body: string
    hero: { src: string }
    author: {
      firstName: string
      lastName: string
      title?: string | null
      postNominals: string
      role: string
      primaryImage: { src: string; name: string }
    }
    reviewer?: {
      firstName: string
      lastName: string
      title?: string | null
      postNominals: string
      role: string
      primaryImage: { src: string; name: string }
    } | null
    relatedArticles?: Array<{
      __typename: 'Article'
      title: string
      path: string
      slug: string
      publishedAt: string
      updatedAt?: string | null
      readingTimeMinutes: number
      categories: Array<string>
      hero: { src: string }
    }> | null
    relatedProducts?: Array<
      | {
          __typename: 'BespokeLabTestProduct'
          sku: string
          name: string
          type: ProductType
          slug: string
          featuredImage: { src: string }
        }
      | {
          __typename: 'CgmProduct'
          sku: string
          name: string
          type: ProductType
          slug: string
          featuredImage: { src: string }
        }
      | {
          __typename: 'CoachingProduct'
          sku: string
          name: string
          type: ProductType
          slug: string
          featuredImage: { src: string }
        }
      | {
          __typename: 'GiftCardProduct'
          sku: string
          name: string
          type: ProductType
          slug: string
          featuredImage: { src: string }
        }
      | {
          __typename: 'LabTestProduct'
          sku: string
          name: string
          type: ProductType
          slug: string
          featuredImage: { src: string }
        }
      | {
          __typename: 'SupplementProduct'
          sku: string
          name: string
          type: ProductType
          slug: string
          featuredImage: { src: string }
        }
    > | null
  } | null
}

export type AllArticlesPathsQueryVariables = Exact<{ [key: string]: never }>

export type AllArticlesPathsQuery = { allArticles: Array<{ path: string }> }

export type ArticleAttributesFragment = {
  title: string
  metaTitle?: string | null
  template: string
  publishedAt: string
  updatedAt?: string | null
  categories: Array<string>
  takeaways?: string | null
  takeawayHeader?: string | null
  summary: string
  body: string
  hero: { src: string }
  author: {
    firstName: string
    lastName: string
    title?: string | null
    postNominals: string
    role: string
    primaryImage: { src: string; name: string }
  }
  reviewer?: {
    firstName: string
    lastName: string
    title?: string | null
    postNominals: string
    role: string
    primaryImage: { src: string; name: string }
  } | null
  relatedArticles?: Array<{
    __typename: 'Article'
    title: string
    path: string
    slug: string
    publishedAt: string
    updatedAt?: string | null
    readingTimeMinutes: number
    categories: Array<string>
    hero: { src: string }
  }> | null
  relatedProducts?: Array<
    | {
        __typename: 'BespokeLabTestProduct'
        sku: string
        name: string
        type: ProductType
        slug: string
        featuredImage: { src: string }
      }
    | {
        __typename: 'CgmProduct'
        sku: string
        name: string
        type: ProductType
        slug: string
        featuredImage: { src: string }
      }
    | {
        __typename: 'CoachingProduct'
        sku: string
        name: string
        type: ProductType
        slug: string
        featuredImage: { src: string }
      }
    | {
        __typename: 'GiftCardProduct'
        sku: string
        name: string
        type: ProductType
        slug: string
        featuredImage: { src: string }
      }
    | {
        __typename: 'LabTestProduct'
        sku: string
        name: string
        type: ProductType
        slug: string
        featuredImage: { src: string }
      }
    | {
        __typename: 'SupplementProduct'
        sku: string
        name: string
        type: ProductType
        slug: string
        featuredImage: { src: string }
      }
  > | null
}

export type ArticleTeamMemberFragment = {
  firstName: string
  lastName: string
  title?: string | null
  postNominals: string
  role: string
  primaryImage: { src: string; name: string }
}

export type UserOrderedProductByIdQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type UserOrderedProductByIdQuery = {
  orderedProductById?:
    | {
        __typename: 'CgmOrderedProduct'
        id: string
        productDefinition:
          | { name: string }
          | { name: string }
          | { name: string }
          | { name: string }
          | { name: string }
          | { name: string }
        user: {
          email: string
          id: string
          friendlyName?: string | null
          firstName?: string | null
          lastName?: string | null
        }
        order?: {
          id: string
          createdAt: string
          firstName: string
          lastName: string
          user: { email: string; id: string }
          shipments: Array<{
            shippingAddress: { line1: string; line2?: string | null; postcode: string; city: string }
          }>
        } | null
        analysisRuns: Array<{
          id: string
          fromTimestamp: string
          toTimestamp: string
          analyses: Array<{
            analysisCode: string
            calculatedLevel?: CgmAnalysisLevel | null
            calculatedCentile?: number | null
            value?: number | null
            analysisDefinition: {
              code: string
              name: string
              description: string
              units?: string | null
              scale: CgmScaleType
              max?: number | null
              min?: number | null
            }
            definedRanges?: Array<{ fromVal?: number | null; toVal?: number | null; level: CgmAnalysisLevel }> | null
            centileRanges?: Array<{ centile: number; value: number }> | null
          }>
        }>
        latestReportVersion?: {
          id: string
          recommendations: string
          report: string
          status: ReportVersionStatus
          updatedAt: string
          publishedAt?: string | null
          accessLog: Array<{
            time?: string | null
            user: { id: string; firstName?: string | null; lastName?: string | null }
          }>
          authorInfo: {
            firstName: string
            lastName: string
            title?: string | null
            role: string
            primaryImage: { name: string; src: string }
          }
        } | null
      }
    | { __typename: 'GiftCardOrderedProduct'; id: string }
    | {
        __typename: 'LabTestOrderedProduct'
        id: string
        reportStatus?: LabTestOrderedProductReportStatus | null
        reportText?: string | null
        reportPublishedAt?: string | null
        reportRecommendations?: string | null
        reportAnalysesToExclude?: Array<string> | null
        productDefinition:
          | { sku: string; name: string }
          | { sku: string; name: string }
          | { sku: string; name: string }
          | { sku: string; name: string }
          | { sku: string; name: string }
          | { sku: string; name: string }
        order?: { id: string; createdAt: string } | null
        kitRequests: Array<{
          status: KitRequestStatus
          activationInfo?: { firstName?: string | null; lastName?: string | null; dob?: string | null } | null
          kit?: { id: string } | null
          kitDefinition: { sku: string; sampleType: SampleType }
          requestedTests: Array<{ id: string; name: string }>
          currentTdlResult?: {
            id: string
            results: Array<{
              sampleTime?: string | null
              testCode: string
              obrStatus?: ObrStatus | null
              testDefinition?: { id: string; name: string; sample: SampleType } | null
              analyses: Array<{
                analysisCode: string
                obxStatus?: ObxStatus | null
                notes: Array<string>
                referenceRangeRaw?: string | null
                calculatedLevel?: RangeType | null
                calculatedLevelLabel?: string | null
                message?: string | null
                valueText?: string | null
                valueNumeric?: number | null
                hl7filename?: string | null
                analysisDefinition?: {
                  id: string
                  name: string
                  units?: string | null
                  scaleType: ScaleType
                  description: string
                } | null
                scalePoints?: Array<{ val: number; type: ScalePointType }> | null
                ranges?: Array<{ fromVal?: number | null; toVal?: number | null; type?: RangeType | null }> | null
                centileRanges?: Array<{ centile: number; value?: number | null }> | null
              }>
            }>
            cumulativeResults: Array<{
              sampleTime?: string | null
              testCode: string
              obrStatus?: ObrStatus | null
              testDefinition?: { id: string; name: string; sample: SampleType } | null
              analyses: Array<{
                analysisCode: string
                obxStatus?: ObxStatus | null
                notes: Array<string>
                referenceRangeRaw?: string | null
                calculatedLevel?: RangeType | null
                calculatedLevelLabel?: string | null
                message?: string | null
                valueText?: string | null
                valueNumeric?: number | null
                hl7filename?: string | null
                analysisDefinition?: {
                  id: string
                  name: string
                  units?: string | null
                  scaleType: ScaleType
                  description: string
                } | null
                scalePoints?: Array<{ val: number; type: ScalePointType }> | null
                ranges?: Array<{ fromVal?: number | null; toVal?: number | null; type?: RangeType | null }> | null
                centileRanges?: Array<{ centile: number; value?: number | null }> | null
              }>
            }>
          } | null
          reportedTdlResult?: {
            id: string
            results: Array<{
              sampleTime?: string | null
              testCode: string
              obrStatus?: ObrStatus | null
              testDefinition?: { id: string; name: string; sample: SampleType } | null
              analyses: Array<{
                analysisCode: string
                obxStatus?: ObxStatus | null
                notes: Array<string>
                referenceRangeRaw?: string | null
                calculatedLevel?: RangeType | null
                calculatedLevelLabel?: string | null
                message?: string | null
                valueText?: string | null
                valueNumeric?: number | null
                hl7filename?: string | null
                analysisDefinition?: {
                  id: string
                  name: string
                  units?: string | null
                  scaleType: ScaleType
                  description: string
                } | null
                scalePoints?: Array<{ val: number; type: ScalePointType }> | null
                ranges?: Array<{ fromVal?: number | null; toVal?: number | null; type?: RangeType | null }> | null
                centileRanges?: Array<{ centile: number; value?: number | null }> | null
              }>
            }>
            cumulativeResults: Array<{
              sampleTime?: string | null
              testCode: string
              obrStatus?: ObrStatus | null
              testDefinition?: { id: string; name: string; sample: SampleType } | null
              analyses: Array<{
                analysisCode: string
                obxStatus?: ObxStatus | null
                notes: Array<string>
                referenceRangeRaw?: string | null
                calculatedLevel?: RangeType | null
                calculatedLevelLabel?: string | null
                message?: string | null
                valueText?: string | null
                valueNumeric?: number | null
                hl7filename?: string | null
                analysisDefinition?: {
                  id: string
                  name: string
                  units?: string | null
                  scaleType: ScaleType
                  description: string
                } | null
                scalePoints?: Array<{ val: number; type: ScalePointType }> | null
                ranges?: Array<{ fromVal?: number | null; toVal?: number | null; type?: RangeType | null }> | null
                centileRanges?: Array<{ centile: number; value?: number | null }> | null
              }>
            }>
          } | null
        }>
      }
    | null
}

export type UserLabTestOrderedProductFragment = {
  id: string
  reportStatus?: LabTestOrderedProductReportStatus | null
  reportText?: string | null
  reportPublishedAt?: string | null
  reportRecommendations?: string | null
  reportAnalysesToExclude?: Array<string> | null
  productDefinition:
    | { sku: string; name: string }
    | { sku: string; name: string }
    | { sku: string; name: string }
    | { sku: string; name: string }
    | { sku: string; name: string }
    | { sku: string; name: string }
  order?: { id: string; createdAt: string } | null
  kitRequests: Array<{
    status: KitRequestStatus
    activationInfo?: { firstName?: string | null; lastName?: string | null; dob?: string | null } | null
    kit?: { id: string } | null
    kitDefinition: { sku: string; sampleType: SampleType }
    requestedTests: Array<{ id: string; name: string }>
    currentTdlResult?: {
      id: string
      results: Array<{
        sampleTime?: string | null
        testCode: string
        obrStatus?: ObrStatus | null
        testDefinition?: { id: string; name: string; sample: SampleType } | null
        analyses: Array<{
          analysisCode: string
          obxStatus?: ObxStatus | null
          notes: Array<string>
          referenceRangeRaw?: string | null
          calculatedLevel?: RangeType | null
          calculatedLevelLabel?: string | null
          message?: string | null
          valueText?: string | null
          valueNumeric?: number | null
          hl7filename?: string | null
          analysisDefinition?: {
            id: string
            name: string
            units?: string | null
            scaleType: ScaleType
            description: string
          } | null
          scalePoints?: Array<{ val: number; type: ScalePointType }> | null
          ranges?: Array<{ fromVal?: number | null; toVal?: number | null; type?: RangeType | null }> | null
          centileRanges?: Array<{ centile: number; value?: number | null }> | null
        }>
      }>
      cumulativeResults: Array<{
        sampleTime?: string | null
        testCode: string
        obrStatus?: ObrStatus | null
        testDefinition?: { id: string; name: string; sample: SampleType } | null
        analyses: Array<{
          analysisCode: string
          obxStatus?: ObxStatus | null
          notes: Array<string>
          referenceRangeRaw?: string | null
          calculatedLevel?: RangeType | null
          calculatedLevelLabel?: string | null
          message?: string | null
          valueText?: string | null
          valueNumeric?: number | null
          hl7filename?: string | null
          analysisDefinition?: {
            id: string
            name: string
            units?: string | null
            scaleType: ScaleType
            description: string
          } | null
          scalePoints?: Array<{ val: number; type: ScalePointType }> | null
          ranges?: Array<{ fromVal?: number | null; toVal?: number | null; type?: RangeType | null }> | null
          centileRanges?: Array<{ centile: number; value?: number | null }> | null
        }>
      }>
    } | null
    reportedTdlResult?: {
      id: string
      results: Array<{
        sampleTime?: string | null
        testCode: string
        obrStatus?: ObrStatus | null
        testDefinition?: { id: string; name: string; sample: SampleType } | null
        analyses: Array<{
          analysisCode: string
          obxStatus?: ObxStatus | null
          notes: Array<string>
          referenceRangeRaw?: string | null
          calculatedLevel?: RangeType | null
          calculatedLevelLabel?: string | null
          message?: string | null
          valueText?: string | null
          valueNumeric?: number | null
          hl7filename?: string | null
          analysisDefinition?: {
            id: string
            name: string
            units?: string | null
            scaleType: ScaleType
            description: string
          } | null
          scalePoints?: Array<{ val: number; type: ScalePointType }> | null
          ranges?: Array<{ fromVal?: number | null; toVal?: number | null; type?: RangeType | null }> | null
          centileRanges?: Array<{ centile: number; value?: number | null }> | null
        }>
      }>
      cumulativeResults: Array<{
        sampleTime?: string | null
        testCode: string
        obrStatus?: ObrStatus | null
        testDefinition?: { id: string; name: string; sample: SampleType } | null
        analyses: Array<{
          analysisCode: string
          obxStatus?: ObxStatus | null
          notes: Array<string>
          referenceRangeRaw?: string | null
          calculatedLevel?: RangeType | null
          calculatedLevelLabel?: string | null
          message?: string | null
          valueText?: string | null
          valueNumeric?: number | null
          hl7filename?: string | null
          analysisDefinition?: {
            id: string
            name: string
            units?: string | null
            scaleType: ScaleType
            description: string
          } | null
          scalePoints?: Array<{ val: number; type: ScalePointType }> | null
          ranges?: Array<{ fromVal?: number | null; toVal?: number | null; type?: RangeType | null }> | null
          centileRanges?: Array<{ centile: number; value?: number | null }> | null
        }>
      }>
    } | null
  }>
}

export type UserTdlResultFragment = {
  currentTdlResult?: {
    id: string
    results: Array<{
      sampleTime?: string | null
      testCode: string
      obrStatus?: ObrStatus | null
      testDefinition?: { id: string; name: string; sample: SampleType } | null
      analyses: Array<{
        analysisCode: string
        obxStatus?: ObxStatus | null
        notes: Array<string>
        referenceRangeRaw?: string | null
        calculatedLevel?: RangeType | null
        calculatedLevelLabel?: string | null
        message?: string | null
        valueText?: string | null
        valueNumeric?: number | null
        hl7filename?: string | null
        analysisDefinition?: {
          id: string
          name: string
          units?: string | null
          scaleType: ScaleType
          description: string
        } | null
        scalePoints?: Array<{ val: number; type: ScalePointType }> | null
        ranges?: Array<{ fromVal?: number | null; toVal?: number | null; type?: RangeType | null }> | null
        centileRanges?: Array<{ centile: number; value?: number | null }> | null
      }>
    }>
    cumulativeResults: Array<{
      sampleTime?: string | null
      testCode: string
      obrStatus?: ObrStatus | null
      testDefinition?: { id: string; name: string; sample: SampleType } | null
      analyses: Array<{
        analysisCode: string
        obxStatus?: ObxStatus | null
        notes: Array<string>
        referenceRangeRaw?: string | null
        calculatedLevel?: RangeType | null
        calculatedLevelLabel?: string | null
        message?: string | null
        valueText?: string | null
        valueNumeric?: number | null
        hl7filename?: string | null
        analysisDefinition?: {
          id: string
          name: string
          units?: string | null
          scaleType: ScaleType
          description: string
        } | null
        scalePoints?: Array<{ val: number; type: ScalePointType }> | null
        ranges?: Array<{ fromVal?: number | null; toVal?: number | null; type?: RangeType | null }> | null
        centileRanges?: Array<{ centile: number; value?: number | null }> | null
      }>
    }>
  } | null
  reportedTdlResult?: {
    id: string
    results: Array<{
      sampleTime?: string | null
      testCode: string
      obrStatus?: ObrStatus | null
      testDefinition?: { id: string; name: string; sample: SampleType } | null
      analyses: Array<{
        analysisCode: string
        obxStatus?: ObxStatus | null
        notes: Array<string>
        referenceRangeRaw?: string | null
        calculatedLevel?: RangeType | null
        calculatedLevelLabel?: string | null
        message?: string | null
        valueText?: string | null
        valueNumeric?: number | null
        hl7filename?: string | null
        analysisDefinition?: {
          id: string
          name: string
          units?: string | null
          scaleType: ScaleType
          description: string
        } | null
        scalePoints?: Array<{ val: number; type: ScalePointType }> | null
        ranges?: Array<{ fromVal?: number | null; toVal?: number | null; type?: RangeType | null }> | null
        centileRanges?: Array<{ centile: number; value?: number | null }> | null
      }>
    }>
    cumulativeResults: Array<{
      sampleTime?: string | null
      testCode: string
      obrStatus?: ObrStatus | null
      testDefinition?: { id: string; name: string; sample: SampleType } | null
      analyses: Array<{
        analysisCode: string
        obxStatus?: ObxStatus | null
        notes: Array<string>
        referenceRangeRaw?: string | null
        calculatedLevel?: RangeType | null
        calculatedLevelLabel?: string | null
        message?: string | null
        valueText?: string | null
        valueNumeric?: number | null
        hl7filename?: string | null
        analysisDefinition?: {
          id: string
          name: string
          units?: string | null
          scaleType: ScaleType
          description: string
        } | null
        scalePoints?: Array<{ val: number; type: ScalePointType }> | null
        ranges?: Array<{ fromVal?: number | null; toVal?: number | null; type?: RangeType | null }> | null
        centileRanges?: Array<{ centile: number; value?: number | null }> | null
      }>
    }>
  } | null
}

export type UserResultFragment = {
  sampleTime?: string | null
  testCode: string
  obrStatus?: ObrStatus | null
  testDefinition?: { id: string; name: string; sample: SampleType } | null
  analyses: Array<{
    analysisCode: string
    obxStatus?: ObxStatus | null
    notes: Array<string>
    referenceRangeRaw?: string | null
    calculatedLevel?: RangeType | null
    calculatedLevelLabel?: string | null
    message?: string | null
    valueText?: string | null
    valueNumeric?: number | null
    hl7filename?: string | null
    analysisDefinition?: {
      id: string
      name: string
      units?: string | null
      scaleType: ScaleType
      description: string
    } | null
    scalePoints?: Array<{ val: number; type: ScalePointType }> | null
    ranges?: Array<{ fromVal?: number | null; toVal?: number | null; type?: RangeType | null }> | null
    centileRanges?: Array<{ centile: number; value?: number | null }> | null
  }>
}

export type UserAnalysisFragment = {
  analysisCode: string
  obxStatus?: ObxStatus | null
  notes: Array<string>
  referenceRangeRaw?: string | null
  calculatedLevel?: RangeType | null
  calculatedLevelLabel?: string | null
  message?: string | null
  valueText?: string | null
  valueNumeric?: number | null
  hl7filename?: string | null
  analysisDefinition?: {
    id: string
    name: string
    units?: string | null
    scaleType: ScaleType
    description: string
  } | null
  ranges?: Array<{ fromVal?: number | null; toVal?: number | null; type?: RangeType | null }> | null
  scalePoints?: Array<{ val: number; type: ScalePointType }> | null
  centileRanges?: Array<{ centile: number; value?: number | null }> | null
}

export type UserOrderHistoryQueryVariables = Exact<{ [key: string]: never }>

export type UserOrderHistoryQuery = {
  viewer?: {
    user?: {
      orders: Array<{
        id: string
        orderedProducts?: Array<
          | { __typename: 'CgmOrderedProduct'; id: string }
          | { __typename: 'GiftCardOrderedProduct'; id: string }
          | {
              __typename: 'LabTestOrderedProduct'
              id: string
              kitRequests: Array<{
                id: string
                reportedTdlResult?: {
                  cumulativeResults: Array<{
                    testCode: string
                    sampleTime?: string | null
                    analyses: Array<{
                      analysisCode: string
                      valueNumeric?: number | null
                      valueText?: string | null
                      calculatedLevelLabel?: string | null
                      calculatedLevel?: RangeType | null
                      analysisDefinition?: { id: string; name: string; units?: string | null } | null
                    }>
                  }>
                } | null
              }>
            }
        > | null
      }>
    } | null
  } | null
}

export type ClaimOffPlatformKitMutationVariables = Exact<{
  input: ClaimOffPlatformKitInput
}>

export type ClaimOffPlatformKitMutation = { claimOffPlatformKit: { kitRequest: { id: string } } }

export type FriendlyUserNameQueryVariables = Exact<{ [key: string]: never }>

export type FriendlyUserNameQuery = { viewer?: { user?: { friendlyName?: string | null } | null } | null }

export type CustomerOrderByIdQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type CustomerOrderByIdQuery = {
  orderById?: {
    id: string
    createdAt: string
    firstName: string
    lastName: string
    phone: string
    salesChannel?: string | null
    user: { email: string; id: string }
    shipments: Array<{
      id: string
      shippingAddress: { line1: string; line2?: string | null; postcode: string; city: string }
    }>
    orderedProducts?: Array<
      | { __typename: 'CgmOrderedProduct'; id: string }
      | { __typename: 'GiftCardOrderedProduct'; id: string; giftCard: { id: string; startingBalance: number } }
      | { __typename: 'LabTestOrderedProduct'; id: string }
    > | null
    orderPriceDetails: {
      subTotal: number
      total: number
      discount?: { amount: number; code: string; description?: string | null; failureMessage?: string | null } | null
      lines: Array<{
        capturedProductName: string
        capturedProductPrice: number
        quantity: number
        totalPrice: number
        product:
          | {
              sku: string
              name: string
              type: ProductType
              slug: string
              price: number
              description: string
              featuredImage: { src: string; name: string }
            }
          | {
              sku: string
              name: string
              type: ProductType
              slug: string
              price: number
              description: string
              featuredImage: { src: string; name: string }
            }
          | {
              sku: string
              name: string
              type: ProductType
              slug: string
              price: number
              description: string
              featuredImage: { src: string; name: string }
            }
          | {
              sku: string
              name: string
              type: ProductType
              slug: string
              price: number
              description: string
              featuredImage: { src: string; name: string }
            }
          | {
              sku: string
              name: string
              type: ProductType
              slug: string
              price: number
              description: string
              featuredImage: { src: string; name: string }
            }
          | {
              sku: string
              name: string
              type: ProductType
              slug: string
              price: number
              description: string
              featuredImage: { src: string; name: string }
            }
        modifiers: Array<{ code: string; name: string; price: number } | { code: string; name: string; price: number }>
      }>
    }
  } | null
}

export type CustomerOrderDetailedFragment = {
  id: string
  createdAt: string
  firstName: string
  lastName: string
  phone: string
  salesChannel?: string | null
  user: { email: string; id: string }
  shipments: Array<{
    id: string
    shippingAddress: { line1: string; line2?: string | null; postcode: string; city: string }
  }>
  orderedProducts?: Array<
    | { __typename: 'CgmOrderedProduct'; id: string }
    | { __typename: 'GiftCardOrderedProduct'; id: string; giftCard: { id: string; startingBalance: number } }
    | { __typename: 'LabTestOrderedProduct'; id: string }
  > | null
  orderPriceDetails: {
    subTotal: number
    total: number
    discount?: { amount: number; code: string; description?: string | null; failureMessage?: string | null } | null
    lines: Array<{
      capturedProductName: string
      capturedProductPrice: number
      quantity: number
      totalPrice: number
      product:
        | {
            sku: string
            name: string
            type: ProductType
            slug: string
            price: number
            description: string
            featuredImage: { src: string; name: string }
          }
        | {
            sku: string
            name: string
            type: ProductType
            slug: string
            price: number
            description: string
            featuredImage: { src: string; name: string }
          }
        | {
            sku: string
            name: string
            type: ProductType
            slug: string
            price: number
            description: string
            featuredImage: { src: string; name: string }
          }
        | {
            sku: string
            name: string
            type: ProductType
            slug: string
            price: number
            description: string
            featuredImage: { src: string; name: string }
          }
        | {
            sku: string
            name: string
            type: ProductType
            slug: string
            price: number
            description: string
            featuredImage: { src: string; name: string }
          }
        | {
            sku: string
            name: string
            type: ProductType
            slug: string
            price: number
            description: string
            featuredImage: { src: string; name: string }
          }
      modifiers: Array<{ code: string; name: string; price: number } | { code: string; name: string; price: number }>
    }>
  }
}

export type OrderByIdQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type OrderByIdQuery = {
  orderById?: {
    id: string
    createdAt: string
    firstName: string
    lastName: string
    phone: string
    user: { id: string; email: string }
    shipments: Array<{
      id: string
      shippingAddress: { line1: string; line2?: string | null; postcode: string; city: string }
    }>
    orderPriceDetails: {
      subTotal: number
      total: number
      discount?: { amount: number; code: string; description?: string | null; failureMessage?: string | null } | null
      lines: Array<{
        quantity: number
        totalPrice: number
        product:
          | {
              __typename: 'LabTestProduct'
              sku: string
              name: string
              type: ProductType
              slug: string
              price: number
              allowPurchase: boolean
              showInListings: boolean
              description: string
              intro?: string | null
              metaDescription?: string | null
              metaTitle?: string | null
              features?: Array<string> | null
              categories: Array<string>
              valueProposition: string
              whatsTestedHeader?: string | null
              personaliseHeader?: string | null
              blogsHeader?: string | null
              faqHeader?: string | null
              whatsTestedCopy?: string | null
              biomarkers: number
              sampleLocation: SampleLocation
              analysisLocation: AnalysisLocation
              turnaroundTime: number
              sampleTypes: Array<SampleType>
              whoFor?: Array<string> | null
              spokesperson: {
                title?: string | null
                firstName: string
                lastName: string
                role: string
                headImage?: { src: string; name: string } | null
                cutoutImage?: { src: string; name: string } | null
              }
              featuredImage: { src: string; name: string }
              otherImages: Array<{ src: string; name: string }>
              shoppingFeedImage?: { src: string } | null
              blogArticles: Array<{
                __typename: 'Article'
                title: string
                path: string
                slug: string
                publishedAt: string
                updatedAt?: string | null
                readingTimeMinutes: number
                categories: Array<string>
                hero: { src: string }
              }>
              faqItems: Array<{ question: string; answer: string }>
              testGroups: Array<{
                hideAnalyses?: boolean | null
                name: string
                why: string
                analyses: Array<{ id: string }>
                tests: Array<{
                  __typename: 'TestInfo'
                  id: string
                  name: string
                  description: string
                  capillary?: boolean | null
                  categories: Array<string>
                  sample: SampleType
                  sampleTube: SampleTubeType
                  turnaroundTime: number
                  analyses: Array<{ id: string; name: string; description: string }>
                }>
              }>
              reviews: Array<{
                source: string
                date: string
                rating: number
                title: string
                content: string
                link: string
                author: string
              }>
              bloodCollectionOptions: Array<{
                id: string
                code: string
                name: string
                description: string
                price: number
                bloodDrawType: BloodDrawType
              }>
              recommendedAddons: Array<{
                why: string
                testAddon: {
                  code: string
                  price: number
                  standalonePrice?: number | null
                  name: string
                  test: { friendlyName?: string | null; capillary?: boolean | null; sample: SampleType }
                }
              }>
            }
          | {}
        modifiers: Array<{ code: string; name: string; price: number } | { code: string; name: string; price: number }>
      }>
    }
  } | null
}

export type CustomerOrdersQueryVariables = Exact<{ [key: string]: never }>

export type CustomerOrdersQuery = {
  viewer?: {
    user?: {
      id: string
      email: string
      orders: Array<{
        id: string
        createdAt: string
        salesChannel?: string | null
        orderPriceDetails: {
          subTotal: number
          total: number
          lines: Array<{
            quantity: number
            totalPrice: number
            product:
              | {
                  sku: string
                  name: string
                  type: ProductType
                  slug: string
                  price: number
                  description: string
                  featuredImage: { src: string; name: string }
                }
              | {
                  sku: string
                  name: string
                  type: ProductType
                  slug: string
                  price: number
                  description: string
                  featuredImage: { src: string; name: string }
                }
              | {
                  sku: string
                  name: string
                  type: ProductType
                  slug: string
                  price: number
                  description: string
                  featuredImage: { src: string; name: string }
                }
              | {
                  sku: string
                  name: string
                  type: ProductType
                  slug: string
                  price: number
                  description: string
                  featuredImage: { src: string; name: string }
                }
              | {
                  sku: string
                  name: string
                  type: ProductType
                  slug: string
                  price: number
                  description: string
                  featuredImage: { src: string; name: string }
                }
              | {
                  sku: string
                  name: string
                  type: ProductType
                  slug: string
                  price: number
                  description: string
                  featuredImage: { src: string; name: string }
                }
            modifiers: Array<
              { name: string; code: string; price: number } | { name: string; code: string; price: number }
            >
          }>
        }
        shipments: Array<{ dispatchedAt?: string | null; trackingNo?: string | null }>
      }>
    } | null
  } | null
}

export type GetUserResultsQueryVariables = Exact<{ [key: string]: never }>

export type GetUserResultsQuery = {
  viewer?: {
    user?: {
      id: string
      friendlyName?: string | null
      orders: Array<{
        id: string
        orderedProducts?: Array<
          | { __typename: 'CgmOrderedProduct'; id: string }
          | { __typename: 'GiftCardOrderedProduct'; id: string }
          | {
              __typename: 'LabTestOrderedProduct'
              id: string
              reportStatus?: LabTestOrderedProductReportStatus | null
              reportPublishedAt?: string | null
              reportAnalysesToExclude?: Array<string> | null
              kitRequests: Array<{
                id: string
                kitDefinition: { sampleType: SampleType }
                reportedTdlResult?: {
                  id: string
                  results: Array<{
                    testCode: string
                    sampleTime?: string | null
                    obrStatus?: ObrStatus | null
                    testDefinition?: { id: string; name: string } | null
                    analyses: Array<{
                      analysisCode: string
                      obxStatus?: ObxStatus | null
                      notes: Array<string>
                      referenceRangeRaw?: string | null
                      calculatedLevel?: RangeType | null
                      calculatedLevelLabel?: string | null
                      message?: string | null
                      valueText?: string | null
                      valueNumeric?: number | null
                      analysisDefinition?: {
                        id: string
                        name: string
                        units?: string | null
                        scaleType: ScaleType
                        description: string
                      } | null
                      ranges?: Array<{ fromVal?: number | null; toVal?: number | null; type?: RangeType | null }> | null
                      scalePoints?: Array<{ val: number; type: ScalePointType }> | null
                    }>
                  }>
                } | null
              }>
            }
        > | null
      }>
    } | null
  } | null
}

export type UserHistoryOrdersFragment = {
  id: string
  orderedProducts?: Array<
    | { __typename: 'CgmOrderedProduct'; id: string }
    | { __typename: 'GiftCardOrderedProduct'; id: string }
    | {
        __typename: 'LabTestOrderedProduct'
        id: string
        reportStatus?: LabTestOrderedProductReportStatus | null
        reportPublishedAt?: string | null
        reportAnalysesToExclude?: Array<string> | null
        kitRequests: Array<{
          id: string
          kitDefinition: { sampleType: SampleType }
          reportedTdlResult?: {
            id: string
            results: Array<{
              testCode: string
              sampleTime?: string | null
              obrStatus?: ObrStatus | null
              testDefinition?: { id: string; name: string } | null
              analyses: Array<{
                analysisCode: string
                obxStatus?: ObxStatus | null
                notes: Array<string>
                referenceRangeRaw?: string | null
                calculatedLevel?: RangeType | null
                calculatedLevelLabel?: string | null
                message?: string | null
                valueText?: string | null
                valueNumeric?: number | null
                analysisDefinition?: {
                  id: string
                  name: string
                  units?: string | null
                  scaleType: ScaleType
                  description: string
                } | null
                ranges?: Array<{ fromVal?: number | null; toVal?: number | null; type?: RangeType | null }> | null
                scalePoints?: Array<{ val: number; type: ScalePointType }> | null
              }>
            }>
          } | null
        }>
      }
  > | null
}

type UserHistoryProduct_CgmOrderedProduct_Fragment = { __typename: 'CgmOrderedProduct'; id: string }

type UserHistoryProduct_GiftCardOrderedProduct_Fragment = { __typename: 'GiftCardOrderedProduct'; id: string }

type UserHistoryProduct_LabTestOrderedProduct_Fragment = {
  __typename: 'LabTestOrderedProduct'
  id: string
  reportStatus?: LabTestOrderedProductReportStatus | null
  reportPublishedAt?: string | null
  reportAnalysesToExclude?: Array<string> | null
  kitRequests: Array<{
    id: string
    kitDefinition: { sampleType: SampleType }
    reportedTdlResult?: {
      id: string
      results: Array<{
        testCode: string
        sampleTime?: string | null
        obrStatus?: ObrStatus | null
        testDefinition?: { id: string; name: string } | null
        analyses: Array<{
          analysisCode: string
          obxStatus?: ObxStatus | null
          notes: Array<string>
          referenceRangeRaw?: string | null
          calculatedLevel?: RangeType | null
          calculatedLevelLabel?: string | null
          message?: string | null
          valueText?: string | null
          valueNumeric?: number | null
          analysisDefinition?: {
            id: string
            name: string
            units?: string | null
            scaleType: ScaleType
            description: string
          } | null
          ranges?: Array<{ fromVal?: number | null; toVal?: number | null; type?: RangeType | null }> | null
          scalePoints?: Array<{ val: number; type: ScalePointType }> | null
        }>
      }>
    } | null
  }>
}

export type UserHistoryProductFragment =
  | UserHistoryProduct_CgmOrderedProduct_Fragment
  | UserHistoryProduct_GiftCardOrderedProduct_Fragment
  | UserHistoryProduct_LabTestOrderedProduct_Fragment

export type UserHistoryLabTestFragment = {
  __typename: 'LabTestOrderedProduct'
  id: string
  reportStatus?: LabTestOrderedProductReportStatus | null
  reportPublishedAt?: string | null
  reportAnalysesToExclude?: Array<string> | null
  kitRequests: Array<{
    id: string
    kitDefinition: { sampleType: SampleType }
    reportedTdlResult?: {
      id: string
      results: Array<{
        testCode: string
        sampleTime?: string | null
        obrStatus?: ObrStatus | null
        testDefinition?: { id: string; name: string } | null
        analyses: Array<{
          analysisCode: string
          obxStatus?: ObxStatus | null
          notes: Array<string>
          referenceRangeRaw?: string | null
          calculatedLevel?: RangeType | null
          calculatedLevelLabel?: string | null
          message?: string | null
          valueText?: string | null
          valueNumeric?: number | null
          analysisDefinition?: {
            id: string
            name: string
            units?: string | null
            scaleType: ScaleType
            description: string
          } | null
          ranges?: Array<{ fromVal?: number | null; toVal?: number | null; type?: RangeType | null }> | null
          scalePoints?: Array<{ val: number; type: ScalePointType }> | null
        }>
      }>
    } | null
  }>
}

export type UserHistoryKitRequestFragment = {
  id: string
  kitDefinition: { sampleType: SampleType }
  reportedTdlResult?: {
    id: string
    results: Array<{
      testCode: string
      sampleTime?: string | null
      obrStatus?: ObrStatus | null
      testDefinition?: { id: string; name: string } | null
      analyses: Array<{
        analysisCode: string
        obxStatus?: ObxStatus | null
        notes: Array<string>
        referenceRangeRaw?: string | null
        calculatedLevel?: RangeType | null
        calculatedLevelLabel?: string | null
        message?: string | null
        valueText?: string | null
        valueNumeric?: number | null
        analysisDefinition?: {
          id: string
          name: string
          units?: string | null
          scaleType: ScaleType
          description: string
        } | null
        ranges?: Array<{ fromVal?: number | null; toVal?: number | null; type?: RangeType | null }> | null
        scalePoints?: Array<{ val: number; type: ScalePointType }> | null
      }>
    }>
  } | null
}

export type UserHistoryTdlResultFragment = {
  testCode: string
  sampleTime?: string | null
  obrStatus?: ObrStatus | null
  testDefinition?: { id: string; name: string } | null
  analyses: Array<{
    analysisCode: string
    obxStatus?: ObxStatus | null
    notes: Array<string>
    referenceRangeRaw?: string | null
    calculatedLevel?: RangeType | null
    calculatedLevelLabel?: string | null
    message?: string | null
    valueText?: string | null
    valueNumeric?: number | null
    analysisDefinition?: {
      id: string
      name: string
      units?: string | null
      scaleType: ScaleType
      description: string
    } | null
    ranges?: Array<{ fromVal?: number | null; toVal?: number | null; type?: RangeType | null }> | null
    scalePoints?: Array<{ val: number; type: ScalePointType }> | null
  }>
}

export type UserHistoryAnalysisFragment = {
  analysisCode: string
  obxStatus?: ObxStatus | null
  notes: Array<string>
  referenceRangeRaw?: string | null
  calculatedLevel?: RangeType | null
  calculatedLevelLabel?: string | null
  message?: string | null
  valueText?: string | null
  valueNumeric?: number | null
  analysisDefinition?: {
    id: string
    name: string
    units?: string | null
    scaleType: ScaleType
    description: string
  } | null
  ranges?: Array<{ fromVal?: number | null; toVal?: number | null; type?: RangeType | null }> | null
  scalePoints?: Array<{ val: number; type: ScalePointType }> | null
}

export type GetUserDetailsQueryVariables = Exact<{ [key: string]: never }>

export type GetUserDetailsQuery = {
  viewer?: {
    user?: {
      id: string
      email: string
      friendlyName?: string | null
      firstName?: string | null
      lastName?: string | null
      dob?: string | null
      biologicalSex?: BiologicalSex | null
    } | null
  } | null
}

export type ChangeUserDetailsMutationVariables = Exact<{
  input: ChangeUserDetailsInput
}>

export type ChangeUserDetailsMutation = {
  changeUserDetails: {
    user: {
      id: string
      firstName?: string | null
      lastName?: string | null
      friendlyName?: string | null
      dob?: string | null
      biologicalSex?: BiologicalSex | null
    }
  }
}

export type ActivateKitMutationVariables = Exact<{
  input: ActivateKitInput
}>

export type ActivateKitMutation = { activateKit: { message?: string | null; success: boolean; kit: { id: string } } }

export type KitActivationStatusQueryVariables = Exact<{
  kitId: Scalars['ID']
}>

export type KitActivationStatusQuery = {
  kitActivationStatus: { canActivate: boolean; reason?: string | null; offPlatformPurchase?: boolean | null }
}

export type CustomerKitRequestByIdQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type CustomerKitRequestByIdQuery = {
  kitRequestById?: {
    id: string
    status: KitRequestStatus
    kitDefinition: {
      bloodDrawType?: BloodDrawType | null
      sku: string
      labCode: string
      sampleType: SampleType
      sampleTubes: Array<SampleTubeType>
      contents?: Array<string> | null
    }
    requestedTests: Array<{ id: string; name: string; instructions?: string | null }>
    orderedProduct?: {
      productDefinition:
        | { name: string }
        | { name: string }
        | { name: string }
        | { name: string }
        | { name: string }
        | { name: string }
      bloodCollectionOption?: {
        id: string
        code: string
        bloodDrawType: BloodDrawType
        provider?: string | null
        clinicId?: string | null
      } | null
      user: { email: string; phoneNumber?: string | null }
      order?: {
        phone: string
        shipments: Array<{ shippingAddress: { city: string; postcode: string; line1: string; line2?: string | null } }>
      } | null
    } | null
  } | null
}

export type WriteCgmReportMutationVariables = Exact<{
  input: WriteCgmReportInput
}>

export type WriteCgmReportMutation = {
  writeCgmReport: {
    reportVersion: {
      id: string
      recommendations: string
      report: string
      status: ReportVersionStatus
      updatedAt: string
      publishedAt?: string | null
      accessLog: Array<{
        time?: string | null
        user: { id: string; firstName?: string | null; lastName?: string | null }
      }>
      authorInfo: {
        firstName: string
        lastName: string
        title?: string | null
        role: string
        primaryImage: { name: string; src: string }
      }
    }
  }
}

export type UnpublishCgmReportMutationVariables = Exact<{
  input: UnpublishReportVersionInput
}>

export type UnpublishCgmReportMutation = {
  unpublishReportVersion: {
    reportVersion: {
      id: string
      recommendations: string
      report: string
      status: ReportVersionStatus
      updatedAt: string
      publishedAt?: string | null
      accessLog: Array<{
        time?: string | null
        user: { id: string; firstName?: string | null; lastName?: string | null }
      }>
      authorInfo: {
        firstName: string
        lastName: string
        title?: string | null
        role: string
        primaryImage: { name: string; src: string }
      }
    }
  }
}

export type CgmOrderedProductByIdQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type CgmOrderedProductByIdQuery = {
  orderedProductById?:
    | {
        __typename: 'CgmOrderedProduct'
        id: string
        productDefinition:
          | { name: string }
          | { name: string }
          | { name: string }
          | { name: string }
          | { name: string }
          | { name: string }
        user: {
          email: string
          id: string
          friendlyName?: string | null
          firstName?: string | null
          lastName?: string | null
        }
        order?: {
          id: string
          createdAt: string
          firstName: string
          lastName: string
          user: { email: string; id: string }
          shipments: Array<{
            shippingAddress: { line1: string; line2?: string | null; postcode: string; city: string }
          }>
        } | null
        analysisRuns: Array<{
          id: string
          fromTimestamp: string
          toTimestamp: string
          analyses: Array<{
            analysisCode: string
            calculatedLevel?: CgmAnalysisLevel | null
            calculatedCentile?: number | null
            value?: number | null
            analysisDefinition: {
              code: string
              name: string
              description: string
              units?: string | null
              scale: CgmScaleType
              max?: number | null
              min?: number | null
            }
            definedRanges?: Array<{ fromVal?: number | null; toVal?: number | null; level: CgmAnalysisLevel }> | null
            centileRanges?: Array<{ centile: number; value: number }> | null
          }>
        }>
        latestReportVersion?: {
          id: string
          recommendations: string
          report: string
          status: ReportVersionStatus
          updatedAt: string
          publishedAt?: string | null
          accessLog: Array<{
            time?: string | null
            user: { id: string; firstName?: string | null; lastName?: string | null }
          }>
          authorInfo: {
            firstName: string
            lastName: string
            title?: string | null
            role: string
            primaryImage: { name: string; src: string }
          }
        } | null
      }
    | { __typename: 'GiftCardOrderedProduct'; id: string }
    | { __typename: 'LabTestOrderedProduct'; id: string }
    | null
}

export type CgmOrderedProductFragment = {
  __typename: 'CgmOrderedProduct'
  id: string
  productDefinition:
    | { name: string }
    | { name: string }
    | { name: string }
    | { name: string }
    | { name: string }
    | { name: string }
  user: { email: string; id: string; friendlyName?: string | null; firstName?: string | null; lastName?: string | null }
  order?: {
    id: string
    createdAt: string
    firstName: string
    lastName: string
    user: { email: string; id: string }
    shipments: Array<{ shippingAddress: { line1: string; line2?: string | null; postcode: string; city: string } }>
  } | null
  analysisRuns: Array<{
    id: string
    fromTimestamp: string
    toTimestamp: string
    analyses: Array<{
      analysisCode: string
      calculatedLevel?: CgmAnalysisLevel | null
      calculatedCentile?: number | null
      value?: number | null
      analysisDefinition: {
        code: string
        name: string
        description: string
        units?: string | null
        scale: CgmScaleType
        max?: number | null
        min?: number | null
      }
      definedRanges?: Array<{ fromVal?: number | null; toVal?: number | null; level: CgmAnalysisLevel }> | null
      centileRanges?: Array<{ centile: number; value: number }> | null
    }>
  }>
  latestReportVersion?: {
    id: string
    recommendations: string
    report: string
    status: ReportVersionStatus
    updatedAt: string
    publishedAt?: string | null
    accessLog: Array<{
      time?: string | null
      user: { id: string; firstName?: string | null; lastName?: string | null }
    }>
    authorInfo: {
      firstName: string
      lastName: string
      title?: string | null
      role: string
      primaryImage: { name: string; src: string }
    }
  } | null
}

export type ReportVersionFragment = {
  id: string
  recommendations: string
  report: string
  status: ReportVersionStatus
  updatedAt: string
  publishedAt?: string | null
  accessLog: Array<{ time?: string | null; user: { id: string; firstName?: string | null; lastName?: string | null } }>
  authorInfo: {
    firstName: string
    lastName: string
    title?: string | null
    role: string
    primaryImage: { name: string; src: string }
  }
}

export type PublishReportVersionMutationVariables = Exact<{
  input: PublishReportVersionInput
}>

export type PublishReportVersionMutation = {
  publishReportVersion: {
    reportVersion: {
      id: string
      recommendations: string
      report: string
      status: ReportVersionStatus
      updatedAt: string
      publishedAt?: string | null
      accessLog: Array<{
        time?: string | null
        user: { id: string; firstName?: string | null; lastName?: string | null }
      }>
      authorInfo: {
        firstName: string
        lastName: string
        title?: string | null
        role: string
        primaryImage: { name: string; src: string }
      }
    }
  }
}

export type ChangeUserEmailMutationVariables = Exact<{
  input: ChangeUserEmailInput
}>

export type ChangeUserEmailMutation = { changeUserEmail: { success: boolean } }

export type UserByEmailQueryVariables = Exact<{
  email: Scalars['String']
}>

export type UserByEmailQuery = {
  userByEmail?: {
    id: string
    friendlyName?: string | null
    firstName?: string | null
    lastName?: string | null
    dob?: string | null
    biologicalSex?: BiologicalSex | null
    phoneNumber?: string | null
    email: string
    activated: boolean
    activationCodeCreatedAt?: string | null
    orderedProducts: Array<
      | {
          __typename: 'CgmOrderedProduct'
          id: string
          createdAt: string
          publishedReportVersion?: { publishedAt?: string | null } | null
          productDefinition:
            | { sku: string; name: string }
            | { sku: string; name: string }
            | { sku: string; name: string }
            | { sku: string; name: string }
            | { sku: string; name: string }
            | { sku: string; name: string }
        }
      | {
          __typename: 'GiftCardOrderedProduct'
          id: string
          createdAt: string
          productDefinition:
            | { sku: string; name: string }
            | { sku: string; name: string }
            | { sku: string; name: string }
            | { sku: string; name: string }
            | { sku: string; name: string }
            | { sku: string; name: string }
        }
      | {
          __typename: 'LabTestOrderedProduct'
          reportStatus?: LabTestOrderedProductReportStatus | null
          reportPublishedAt?: string | null
          id: string
          createdAt: string
          productDefinition:
            | { sku: string; name: string }
            | { sku: string; name: string }
            | { sku: string; name: string }
            | { sku: string; name: string }
            | { sku: string; name: string }
            | { sku: string; name: string }
        }
    >
    orders: Array<{
      id: string
      createdAt: string
      firstName: string
      lastName: string
      salesChannel?: string | null
      orderPriceDetails: {
        total: number
        lines: Array<{
          quantity: number
          product:
            | { name: string }
            | { name: string }
            | { name: string }
            | { name: string }
            | { name: string }
            | { name: string }
        }>
      }
    }>
  } | null
}

export type UserByIdQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type UserByIdQuery = {
  userById?: {
    id: string
    friendlyName?: string | null
    firstName?: string | null
    lastName?: string | null
    dob?: string | null
    biologicalSex?: BiologicalSex | null
    phoneNumber?: string | null
    email: string
    activated: boolean
    activationCodeCreatedAt?: string | null
    orderedProducts: Array<
      | {
          __typename: 'CgmOrderedProduct'
          id: string
          createdAt: string
          publishedReportVersion?: { publishedAt?: string | null } | null
          productDefinition:
            | { sku: string; name: string }
            | { sku: string; name: string }
            | { sku: string; name: string }
            | { sku: string; name: string }
            | { sku: string; name: string }
            | { sku: string; name: string }
        }
      | {
          __typename: 'GiftCardOrderedProduct'
          id: string
          createdAt: string
          productDefinition:
            | { sku: string; name: string }
            | { sku: string; name: string }
            | { sku: string; name: string }
            | { sku: string; name: string }
            | { sku: string; name: string }
            | { sku: string; name: string }
        }
      | {
          __typename: 'LabTestOrderedProduct'
          reportStatus?: LabTestOrderedProductReportStatus | null
          reportPublishedAt?: string | null
          id: string
          createdAt: string
          productDefinition:
            | { sku: string; name: string }
            | { sku: string; name: string }
            | { sku: string; name: string }
            | { sku: string; name: string }
            | { sku: string; name: string }
            | { sku: string; name: string }
        }
    >
    orders: Array<{
      id: string
      createdAt: string
      firstName: string
      lastName: string
      salesChannel?: string | null
      orderPriceDetails: {
        total: number
        lines: Array<{
          quantity: number
          product:
            | { name: string }
            | { name: string }
            | { name: string }
            | { name: string }
            | { name: string }
            | { name: string }
        }>
      }
    }>
  } | null
}

export type UserInfoFragment = {
  id: string
  friendlyName?: string | null
  firstName?: string | null
  lastName?: string | null
  dob?: string | null
  biologicalSex?: BiologicalSex | null
  phoneNumber?: string | null
  email: string
  activated: boolean
  activationCodeCreatedAt?: string | null
  orderedProducts: Array<
    | {
        __typename: 'CgmOrderedProduct'
        id: string
        createdAt: string
        publishedReportVersion?: { publishedAt?: string | null } | null
        productDefinition:
          | { sku: string; name: string }
          | { sku: string; name: string }
          | { sku: string; name: string }
          | { sku: string; name: string }
          | { sku: string; name: string }
          | { sku: string; name: string }
      }
    | {
        __typename: 'GiftCardOrderedProduct'
        id: string
        createdAt: string
        productDefinition:
          | { sku: string; name: string }
          | { sku: string; name: string }
          | { sku: string; name: string }
          | { sku: string; name: string }
          | { sku: string; name: string }
          | { sku: string; name: string }
      }
    | {
        __typename: 'LabTestOrderedProduct'
        reportStatus?: LabTestOrderedProductReportStatus | null
        reportPublishedAt?: string | null
        id: string
        createdAt: string
        productDefinition:
          | { sku: string; name: string }
          | { sku: string; name: string }
          | { sku: string; name: string }
          | { sku: string; name: string }
          | { sku: string; name: string }
          | { sku: string; name: string }
      }
  >
  orders: Array<{
    id: string
    createdAt: string
    firstName: string
    lastName: string
    salesChannel?: string | null
    orderPriceDetails: {
      total: number
      lines: Array<{
        quantity: number
        product:
          | { name: string }
          | { name: string }
          | { name: string }
          | { name: string }
          | { name: string }
          | { name: string }
      }>
    }
  }>
}

export type AdminSalesOverviewQueryVariables = Exact<{
  filter?: InputMaybe<OrderListFilter>
}>

export type AdminSalesOverviewQuery = { orders: Array<{ __typename: 'Order'; id: string }> }

export type TdlResultsQueryVariables = Exact<{ [key: string]: never }>

export type TdlResultsQuery = {
  tdlResults: Array<{
    id: string
    filename: string
    kitId?: string | null
    labRef: string
    labStatus?: OrcStatus | null
    ignored?: boolean | null
    results: Array<{ __typename: 'TestResult'; testCode: string }>
    kit?: { id: string; activatedAt?: string | null } | null
  }>
}

export type KitByIdQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type KitByIdQuery = {
  kitById?: {
    id: string
    canceled?: boolean | null
    activatedAt?: string | null
    offPlatformSku?: string | null
    jobs: Array<
      | { __typename: 'EmailJob'; id: string; status: string }
      | { __typename: 'FidelityJob'; id: string; status: string }
      | { __typename: 'TdlRegUploadJob'; filename?: string | null; csv?: string | null; id: string; status: string }
      | { __typename: 'TuliApptConfEmailJob'; id: string; status: string }
    >
    claimedKitRequest?: {
      id: string
      orderedProduct?: {
        id: string
        createdAt: string
        user: { id: string; firstName?: string | null; lastName?: string | null; email: string }
      } | null
    } | null
    activatedKitRequest?: {
      id: string
      status: KitRequestStatus
      rawRequestCodes?: Array<string | null> | null
      activationInfo?: {
        firstName?: string | null
        lastName?: string | null
        dob?: string | null
        sampleDate?: string | null
        biologicalSex?: BiologicalSex | null
        trackingNumber?: string | null
        shippingLatestEvent?: string | null
        extraData?: string | null
      } | null
      tdlResults: Array<{ filename: string; labStatus?: OrcStatus | null }>
      currentTdlResult?: {
        labRef: string
        patientDob?: string | null
        patientName?: { givenName?: string | null; familyName?: string | null } | null
        results: Array<{ testCode: string; sampleTime?: string | null }>
      } | null
      requestedTests: Array<{ id: string }>
      kitDefinition: { sku: string; sampleType: SampleType }
      kit?: { id: string; canceled?: boolean | null; activatedAt?: string | null } | null
      orderedProduct?: {
        id: string
        canceled?: boolean | null
        productDefinition:
          | { name: string }
          | { name: string }
          | { name: string }
          | { name: string }
          | { name: string }
          | { name: string }
        user: { firstName?: string | null; lastName?: string | null; id: string }
      } | null
    } | null
    dispatchedShipment?: {
      dispatchedAt?: string | null
      trackingNo?: string | null
      shippingStatus?: string | null
      shippinglastUpdate?: string | null
      shippingLatestEvent?: string | null
      order: { id: string; createdAt: string; firstName: string; lastName: string; user: { id: string } }
    } | null
    dispatchedKitDefinition?: { sku: string; sampleType: SampleType } | null
    notes: Array<{ id: string; createdAt: string; author: string; subject?: string | null; note: string }>
  } | null
}

export type CancelKitMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type CancelKitMutation = { cancelKit: { success: boolean; kit: { canceled?: boolean | null } } }

export type CancelKitRequestMutationVariables = Exact<{
  input: CancelKitRequestInput
}>

export type CancelKitRequestMutation = { cancelKitRequest: { kitRequest: { id: string; status: KitRequestStatus } } }

export type AddKitNoteMutationVariables = Exact<{
  input: AddKitNoteInput
}>

export type AddKitNoteMutation = { addKitNote: { success: boolean } }

export type GenerateKitIdsMutationVariables = Exact<{
  input: GenerateKitIdsInput
}>

export type GenerateKitIdsMutation = { generateKitIds: { ids: Array<string>; success: boolean } }

export type RecentJobsQueryVariables = Exact<{ [key: string]: never }>

export type RecentJobsQuery = {
  recentJobs: Array<
    | { __typename: 'EmailJob'; template?: string | null; id: string; status: string }
    | { __typename: 'FidelityJob'; id: string; status: string }
    | { __typename: 'TdlRegUploadJob'; csv?: string | null; filename?: string | null; id: string; status: string }
    | { __typename: 'TuliApptConfEmailJob'; id: string; status: string }
  >
}

export type AdminOrderByIdQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type AdminOrderByIdQuery = {
  orderById?: {
    id: string
    createdAt: string
    firstName: string
    lastName: string
    phone: string
    salesChannel?: string | null
    externalRef?: string | null
    user: { email: string; id: string }
    bloodCollectionAddress?: { line1: string; line2?: string | null; postcode: string; city: string } | null
    shipments: Array<{
      id: string
      dispatchedAt?: string | null
      status: ShipmentStatus
      trackingNo?: string | null
      shippingLatestEvent?: string | null
      shippingAddress: { line1: string; line2?: string | null; postcode: string; city: string }
      dispatchedKits?: Array<{
        id: string
        tdlQrData?: string | null
        dispatchedKitDefinition?: { sku: string } | null
      }> | null
    }>
    orderPriceDetails: {
      subTotal: number
      total: number
      discount?: {
        amount: number
        code: string
        description?: string | null
        failureMessage?: string | null
        giftCard?: { id: string } | null
      } | null
      lines: Array<{
        capturedProductName: string
        capturedProductPrice: number
        quantity: number
        totalPrice: number
        product:
          | {
              __typename: 'BespokeLabTestProduct'
              sku: string
              name: string
              type: ProductType
              slug: string
              price: number
              description: string
              featuredImage: { src: string; name: string }
            }
          | {
              __typename: 'CgmProduct'
              sku: string
              name: string
              type: ProductType
              slug: string
              price: number
              description: string
              featuredImage: { src: string; name: string }
            }
          | {
              __typename: 'CoachingProduct'
              sku: string
              name: string
              type: ProductType
              slug: string
              price: number
              description: string
              featuredImage: { src: string; name: string }
            }
          | {
              __typename: 'GiftCardProduct'
              sku: string
              name: string
              type: ProductType
              slug: string
              price: number
              description: string
              featuredImage: { src: string; name: string }
            }
          | {
              __typename: 'LabTestProduct'
              sku: string
              name: string
              type: ProductType
              slug: string
              price: number
              description: string
              testGroups: Array<{
                why: string
                tests: Array<{
                  id: string
                  name: string
                  description: string
                  sample: SampleType
                  sampleTube: SampleTubeType
                  turnaroundTime: number
                  analyses: Array<{ id: string; name: string; description: string }>
                }>
              }>
              featuredImage: { src: string; name: string }
            }
          | {
              __typename: 'SupplementProduct'
              sku: string
              name: string
              type: ProductType
              slug: string
              price: number
              description: string
              featuredImage: { src: string; name: string }
            }
        modifiers: Array<{ code: string; name: string; price: number } | { code: string; name: string; price: number }>
      }>
    }
    orderedProducts?: Array<
      | {
          __typename: 'CgmOrderedProduct'
          id: string
          analysisRuns: Array<{ id: string }>
          productDefinition:
            | { name: string }
            | { name: string }
            | { name: string }
            | { name: string }
            | { name: string }
            | { name: string }
        }
      | {
          __typename: 'GiftCardOrderedProduct'
          id: string
          productDefinition:
            | { name: string }
            | { name: string }
            | { name: string }
            | { name: string }
            | { name: string }
            | { name: string }
          giftCard: { id: string; startingBalance: number }
        }
      | {
          __typename: 'LabTestOrderedProduct'
          id: string
          canceled?: boolean | null
          blockedForReporting?: boolean | null
          reportStatus?: LabTestOrderedProductReportStatus | null
          reportText?: string | null
          reportPublishedAt?: string | null
          reportRecommendations?: string | null
          reportAnalysesToExclude?: Array<string> | null
          productDefinition:
            | { name: string; sku: string }
            | { name: string; sku: string }
            | { name: string; sku: string }
            | { name: string; sku: string }
            | { name: string; sku: string }
            | { name: string; sku: string }
          user: {
            email: string
            id: string
            friendlyName?: string | null
            firstName?: string | null
            lastName?: string | null
          }
          bloodCollectionOption?: { code: string } | null
          order?: {
            id: string
            createdAt: string
            firstName: string
            lastName: string
            shipments: Array<{
              shippingAddress: { line1: string; line2?: string | null; postcode: string; city: string }
            }>
          } | null
          kitRequests: Array<{
            id: string
            status: KitRequestStatus
            canceled: boolean
            rawRequestCodes?: Array<string | null> | null
            activationInfo?: {
              firstName?: string | null
              lastName?: string | null
              dob?: string | null
              sampleDate?: string | null
              biologicalSex?: BiologicalSex | null
              trackingNumber?: string | null
              shippingLatestEvent?: string | null
              extraData?: string | null
            } | null
            requestedTests: Array<{ id: string; name: string }>
            kitDefinition: { sku: string; sampleType: SampleType }
            kit?: {
              id: string
              activatedAt?: string | null
              canceled?: boolean | null
              notes: Array<{ id: string; createdAt: string; author: string; note: string; subject?: string | null }>
            } | null
            orderedProduct?: {
              __typename: 'LabTestOrderedProduct'
              id: string
              productDefinition:
                | { __typename: 'BespokeLabTestProduct'; type: ProductType; name: string }
                | { __typename: 'CgmProduct'; type: ProductType; name: string }
                | { __typename: 'CoachingProduct'; type: ProductType; name: string }
                | { __typename: 'GiftCardProduct'; type: ProductType; name: string }
                | {
                    __typename: 'LabTestProduct'
                    type: ProductType
                    name: string
                    testGroups: Array<{
                      name: string
                      tests: Array<{
                        __typename: 'TestInfo'
                        id: string
                        name: string
                        description: string
                        capillary?: boolean | null
                        categories: Array<string>
                        sample: SampleType
                        sampleTube: SampleTubeType
                        turnaroundTime: number
                        analyses: Array<{ id: string; name: string; description: string }>
                      }>
                    }>
                  }
                | { __typename: 'SupplementProduct'; type: ProductType; name: string }
            } | null
            reportedTdlResult?: {
              id: string
              results: Array<{
                sampleTime?: string | null
                testCode: string
                obrStatus?: ObrStatus | null
                testDefinition?: { id: string; name: string; sample: SampleType } | null
                analyses: Array<{
                  analysisCode: string
                  obxStatus?: ObxStatus | null
                  notes: Array<string>
                  referenceRangeRaw?: string | null
                  calculatedLevel?: RangeType | null
                  calculatedLevelLabel?: string | null
                  message?: string | null
                  valueText?: string | null
                  valueNumeric?: number | null
                  hl7filename?: string | null
                  analysisDefinition?: {
                    id: string
                    name: string
                    units?: string | null
                    scaleType: ScaleType
                    description: string
                  } | null
                  scalePoints?: Array<{ val: number; type: ScalePointType }> | null
                  ranges?: Array<{ fromVal?: number | null; toVal?: number | null; type?: RangeType | null }> | null
                  centileRanges?: Array<{ centile: number; value?: number | null }> | null
                }>
              }>
              cumulativeResults: Array<{
                sampleTime?: string | null
                testCode: string
                obrStatus?: ObrStatus | null
                testDefinition?: { id: string; name: string; sample: SampleType } | null
                analyses: Array<{
                  analysisCode: string
                  obxStatus?: ObxStatus | null
                  notes: Array<string>
                  referenceRangeRaw?: string | null
                  calculatedLevel?: RangeType | null
                  calculatedLevelLabel?: string | null
                  message?: string | null
                  valueText?: string | null
                  valueNumeric?: number | null
                  hl7filename?: string | null
                  analysisDefinition?: {
                    id: string
                    name: string
                    units?: string | null
                    scaleType: ScaleType
                    description: string
                  } | null
                  scalePoints?: Array<{ val: number; type: ScalePointType }> | null
                  ranges?: Array<{ fromVal?: number | null; toVal?: number | null; type?: RangeType | null }> | null
                  centileRanges?: Array<{ centile: number; value?: number | null }> | null
                }>
              }>
            } | null
            currentTdlResult?: {
              id: string
              filename: string
              labRef: string
              labStatus?: OrcStatus | null
              patientDob?: string | null
              patientSex?: string | null
              patientName?: { familyName?: string | null; givenName?: string | null } | null
              results: Array<{
                sampleTime?: string | null
                testCode: string
                obrStatus?: ObrStatus | null
                testDefinition?: { id: string; name: string; sample: SampleType } | null
                analyses: Array<{
                  analysisCode: string
                  obxStatus?: ObxStatus | null
                  notes: Array<string>
                  referenceRangeRaw?: string | null
                  calculatedLevel?: RangeType | null
                  calculatedLevelLabel?: string | null
                  message?: string | null
                  valueText?: string | null
                  valueNumeric?: number | null
                  hl7filename?: string | null
                  analysisDefinition?: {
                    id: string
                    name: string
                    units?: string | null
                    scaleType: ScaleType
                    description: string
                  } | null
                  scalePoints?: Array<{ val: number; type: ScalePointType }> | null
                  ranges?: Array<{ fromVal?: number | null; toVal?: number | null; type?: RangeType | null }> | null
                  centileRanges?: Array<{ centile: number; value?: number | null }> | null
                }>
              }>
              cumulativeResults: Array<{
                sampleTime?: string | null
                testCode: string
                obrStatus?: ObrStatus | null
                testDefinition?: { id: string; name: string; sample: SampleType } | null
                analyses: Array<{
                  analysisCode: string
                  obxStatus?: ObxStatus | null
                  notes: Array<string>
                  referenceRangeRaw?: string | null
                  calculatedLevel?: RangeType | null
                  calculatedLevelLabel?: string | null
                  message?: string | null
                  valueText?: string | null
                  valueNumeric?: number | null
                  hl7filename?: string | null
                  analysisDefinition?: {
                    id: string
                    name: string
                    units?: string | null
                    scaleType: ScaleType
                    description: string
                  } | null
                  scalePoints?: Array<{ val: number; type: ScalePointType }> | null
                  ranges?: Array<{ fromVal?: number | null; toVal?: number | null; type?: RangeType | null }> | null
                  centileRanges?: Array<{ centile: number; value?: number | null }> | null
                }>
              }>
            } | null
          }>
        }
    > | null
    placedBy?: { email: string; friendlyName?: string | null } | null
  } | null
}

export type GiftCardOrderDetailsFragment = { giftCard: { id: string; startingBalance: number } }

type AdminOrderedProduct_BespokeLabTestProduct_Fragment = {
  __typename: 'BespokeLabTestProduct'
  sku: string
  name: string
  type: ProductType
  slug: string
  price: number
  description: string
  featuredImage: { src: string; name: string }
}

type AdminOrderedProduct_CgmProduct_Fragment = {
  __typename: 'CgmProduct'
  sku: string
  name: string
  type: ProductType
  slug: string
  price: number
  description: string
  featuredImage: { src: string; name: string }
}

type AdminOrderedProduct_CoachingProduct_Fragment = {
  __typename: 'CoachingProduct'
  sku: string
  name: string
  type: ProductType
  slug: string
  price: number
  description: string
  featuredImage: { src: string; name: string }
}

type AdminOrderedProduct_GiftCardProduct_Fragment = {
  __typename: 'GiftCardProduct'
  sku: string
  name: string
  type: ProductType
  slug: string
  price: number
  description: string
  featuredImage: { src: string; name: string }
}

type AdminOrderedProduct_LabTestProduct_Fragment = {
  __typename: 'LabTestProduct'
  sku: string
  name: string
  type: ProductType
  slug: string
  price: number
  description: string
  testGroups: Array<{
    why: string
    tests: Array<{
      id: string
      name: string
      description: string
      sample: SampleType
      sampleTube: SampleTubeType
      turnaroundTime: number
      analyses: Array<{ id: string; name: string; description: string }>
    }>
  }>
  featuredImage: { src: string; name: string }
}

type AdminOrderedProduct_SupplementProduct_Fragment = {
  __typename: 'SupplementProduct'
  sku: string
  name: string
  type: ProductType
  slug: string
  price: number
  description: string
  featuredImage: { src: string; name: string }
}

export type AdminOrderedProductFragment =
  | AdminOrderedProduct_BespokeLabTestProduct_Fragment
  | AdminOrderedProduct_CgmProduct_Fragment
  | AdminOrderedProduct_CoachingProduct_Fragment
  | AdminOrderedProduct_GiftCardProduct_Fragment
  | AdminOrderedProduct_LabTestProduct_Fragment
  | AdminOrderedProduct_SupplementProduct_Fragment

export type AllProductsQueryVariables = Exact<{
  filter?: InputMaybe<ProductListFilter>
}>

export type AllProductsQuery = {
  products: Array<
    | {
        __typename: 'BespokeLabTestProduct'
        sku: string
        name: string
        slug: string
        type: ProductType
        price: number
        description: string
        allowPurchase: boolean
      }
    | {
        __typename: 'CgmProduct'
        sku: string
        name: string
        slug: string
        type: ProductType
        price: number
        description: string
        allowPurchase: boolean
      }
    | {
        __typename: 'CoachingProduct'
        sku: string
        name: string
        slug: string
        type: ProductType
        price: number
        description: string
        allowPurchase: boolean
      }
    | {
        __typename: 'GiftCardProduct'
        sku: string
        name: string
        slug: string
        type: ProductType
        price: number
        description: string
        allowPurchase: boolean
      }
    | {
        __typename: 'LabTestProduct'
        name: string
        sku: string
        slug: string
        type: ProductType
        price: number
        description: string
        allowPurchase: boolean
        sampleTypes: Array<SampleType>
        testGroups: Array<{
          name: string
          tests: Array<{
            __typename: 'TestInfo'
            id: string
            name: string
            description: string
            capillary?: boolean | null
            categories: Array<string>
            sample: SampleType
            sampleTube: SampleTubeType
            turnaroundTime: number
            analyses: Array<{ id: string; name: string; description: string }>
          }>
        }>
        bloodCollectionOptions: Array<{
          id: string
          code: string
          name: string
          description: string
          price: number
          bloodDrawType: BloodDrawType
        }>
      }
    | {
        __typename: 'SupplementProduct'
        sku: string
        name: string
        slug: string
        type: ProductType
        price: number
        description: string
        allowPurchase: boolean
      }
  >
}

export type LabTestDataFragment = {
  __typename: 'LabTestProduct'
  sampleTypes: Array<SampleType>
  testGroups: Array<{
    name: string
    tests: Array<{
      __typename: 'TestInfo'
      id: string
      name: string
      description: string
      capillary?: boolean | null
      categories: Array<string>
      sample: SampleType
      sampleTube: SampleTubeType
      turnaroundTime: number
      analyses: Array<{ id: string; name: string; description: string }>
    }>
  }>
  bloodCollectionOptions: Array<{
    id: string
    code: string
    name: string
    description: string
    price: number
    bloodDrawType: BloodDrawType
  }>
}

export type UserByEmailForAdminOrderQueryVariables = Exact<{
  email: Scalars['String']
}>

export type UserByEmailForAdminOrderQuery = {
  userByEmail?: {
    id: string
    email: string
    activated: boolean
    orders: Array<{
      id: string
      createdAt: string
      firstName: string
      lastName: string
      phone: string
      shipments: Array<{ shippingAddress: { city: string; line1: string; line2?: string | null; postcode: string } }>
    }>
  } | null
}

export type ShipmentBatchesQueryVariables = Exact<{ [key: string]: never }>

export type ShipmentBatchesQuery = {
  shipmentBatches: Array<{ createdAt: string; createdByEmail: string; id: string; shipmentCount: number }>
}

export type RecentShipmentsQueryVariables = Exact<{
  maxDaysOld?: InputMaybe<Scalars['Int']>
}>

export type RecentShipmentsQuery = {
  shipments: Array<{
    id: string
    status: ShipmentStatus
    createdAt: string
    kitRequests: Array<{
      orderedProduct?: {
        id: string
        productDefinition:
          | { name: string; sku: string }
          | { name: string; sku: string }
          | { name: string; sku: string }
          | { name: string; sku: string }
          | { name: string; sku: string }
          | { name: string; sku: string }
        order?: {
          orderPriceDetails: {
            lines: Array<{
              quantity: number
              product:
                | { sku: string }
                | { sku: string }
                | { sku: string }
                | { sku: string }
                | { sku: string }
                | { sku: string }
            }>
          }
        } | null
      } | null
      kitDefinition: { sku: string }
    }>
    order: { createdAt: string; id: string; firstName: string; lastName: string; user: { id: string; email: string } }
    shippingAddress: { line1: string; line2?: string | null; city: string; postcode: string }
  }>
}

export type AdminDownloadByLineItemQueryVariables = Exact<{
  filter?: InputMaybe<OrderListFilter>
}>

export type AdminDownloadByLineItemQuery = {
  orders: Array<{
    __typename: 'Order'
    createdAt: string
    id: string
    user: { id: string }
    orderPriceDetails: {
      __typename: 'BasketPriceDetails'
      lines: Array<{
        quantity: number
        totalPrice: number
        product:
          | { __typename: 'BespokeLabTestProduct'; sku: string; name: string }
          | { __typename: 'CgmProduct'; sku: string; name: string }
          | { __typename: 'CoachingProduct'; sku: string; name: string }
          | { __typename: 'GiftCardProduct'; sku: string; name: string }
          | { __typename: 'LabTestProduct'; sku: string; name: string }
          | { __typename: 'SupplementProduct'; sku: string; name: string }
        modifiers: Array<{ code: string } | { code: string }>
      }>
    }
  }>
}

export type AdminDownloadByOrderQueryVariables = Exact<{
  filter?: InputMaybe<OrderListFilter>
}>

export type AdminDownloadByOrderQuery = {
  orders: Array<{
    __typename: 'Order'
    id: string
    createdAt: string
    user: { id: string }
    orderPriceDetails: {
      __typename: 'BasketPriceDetails'
      total: number
      lines: Array<{
        quantity: number
        product:
          | { __typename: 'BespokeLabTestProduct'; sku: string }
          | { __typename: 'CgmProduct'; sku: string }
          | { __typename: 'CoachingProduct'; sku: string }
          | { __typename: 'GiftCardProduct'; sku: string }
          | { __typename: 'LabTestProduct'; sku: string }
          | { __typename: 'SupplementProduct'; sku: string }
      }>
      discount?: { amount: number; code: string } | null
    }
  }>
}

export type AdminDownloadByOrderedProductQueryVariables = Exact<{
  filter?: InputMaybe<OrderListFilter>
}>

export type AdminDownloadByOrderedProductQuery = {
  orders: Array<{
    __typename: 'Order'
    createdAt: string
    id: string
    user: { id: string }
    orderedProducts?: Array<
      | { __typename: 'CgmOrderedProduct'; id: string }
      | { __typename: 'GiftCardOrderedProduct'; id: string }
      | {
          __typename: 'LabTestOrderedProduct'
          id: string
          reportStatus?: LabTestOrderedProductReportStatus | null
          reportPublishedAt?: string | null
          productDefinition:
            | { name: string; sku: string }
            | { name: string; sku: string }
            | { name: string; sku: string }
            | { name: string; sku: string }
            | { name: string; sku: string }
            | { name: string; sku: string }
          kitRequests: Array<{
            status: KitRequestStatus
            activationInfo?: { dob?: string | null; biologicalSex?: BiologicalSex | null } | null
            kit?: { activatedAt?: string | null } | null
            kitDefinition: { sampleType: SampleType }
          }>
          bloodCollectionOption?: { code: string } | null
        }
    > | null
  }>
}

export type AdminDownloadLabTestOrderedProductFragment = {
  __typename: 'LabTestOrderedProduct'
  id: string
  reportStatus?: LabTestOrderedProductReportStatus | null
  reportPublishedAt?: string | null
  productDefinition:
    | { name: string; sku: string }
    | { name: string; sku: string }
    | { name: string; sku: string }
    | { name: string; sku: string }
    | { name: string; sku: string }
    | { name: string; sku: string }
  kitRequests: Array<{
    status: KitRequestStatus
    activationInfo?: { dob?: string | null; biologicalSex?: BiologicalSex | null } | null
    kit?: { activatedAt?: string | null } | null
    kitDefinition: { sampleType: SampleType }
  }>
  bloodCollectionOption?: { code: string } | null
}

export type SalesSummaryQueryVariables = Exact<{
  filter?: InputMaybe<OrderListFilter>
}>

export type SalesSummaryQuery = {
  orders: Array<{
    id: string
    createdAt: string
    orderPriceDetails: { total: number }
    orderedProducts?: Array<{ id: string } | { id: string } | { id: string }> | null
  }>
}

export type LabTestOrderedProductByIdQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type LabTestOrderedProductByIdQuery = {
  labTestOrderedProductById?: {
    __typename: 'LabTestOrderedProduct'
    id: string
    canceled?: boolean | null
    blockedForReporting?: boolean | null
    reportStatus?: LabTestOrderedProductReportStatus | null
    reportText?: string | null
    reportPublishedAt?: string | null
    reportRecommendations?: string | null
    reportAnalysesToExclude?: Array<string> | null
    user: {
      email: string
      id: string
      friendlyName?: string | null
      firstName?: string | null
      lastName?: string | null
    }
    bloodCollectionOption?: { code: string } | null
    productDefinition:
      | { sku: string; name: string }
      | { sku: string; name: string }
      | { sku: string; name: string }
      | { sku: string; name: string }
      | { sku: string; name: string }
      | { sku: string; name: string }
    order?: {
      id: string
      createdAt: string
      firstName: string
      lastName: string
      shipments: Array<{ shippingAddress: { line1: string; line2?: string | null; postcode: string; city: string } }>
    } | null
    kitRequests: Array<{
      id: string
      status: KitRequestStatus
      canceled: boolean
      rawRequestCodes?: Array<string | null> | null
      activationInfo?: {
        firstName?: string | null
        lastName?: string | null
        dob?: string | null
        sampleDate?: string | null
        biologicalSex?: BiologicalSex | null
        trackingNumber?: string | null
        shippingLatestEvent?: string | null
        extraData?: string | null
      } | null
      requestedTests: Array<{ id: string; name: string }>
      kitDefinition: { sku: string; sampleType: SampleType }
      kit?: {
        id: string
        activatedAt?: string | null
        canceled?: boolean | null
        notes: Array<{ id: string; createdAt: string; author: string; note: string; subject?: string | null }>
      } | null
      orderedProduct?: {
        __typename: 'LabTestOrderedProduct'
        id: string
        productDefinition:
          | { __typename: 'BespokeLabTestProduct'; type: ProductType; name: string }
          | { __typename: 'CgmProduct'; type: ProductType; name: string }
          | { __typename: 'CoachingProduct'; type: ProductType; name: string }
          | { __typename: 'GiftCardProduct'; type: ProductType; name: string }
          | {
              __typename: 'LabTestProduct'
              type: ProductType
              name: string
              testGroups: Array<{
                name: string
                tests: Array<{
                  __typename: 'TestInfo'
                  id: string
                  name: string
                  description: string
                  capillary?: boolean | null
                  categories: Array<string>
                  sample: SampleType
                  sampleTube: SampleTubeType
                  turnaroundTime: number
                  analyses: Array<{ id: string; name: string; description: string }>
                }>
              }>
            }
          | { __typename: 'SupplementProduct'; type: ProductType; name: string }
      } | null
      reportedTdlResult?: {
        id: string
        results: Array<{
          sampleTime?: string | null
          testCode: string
          obrStatus?: ObrStatus | null
          testDefinition?: { id: string; name: string; sample: SampleType } | null
          analyses: Array<{
            analysisCode: string
            obxStatus?: ObxStatus | null
            notes: Array<string>
            referenceRangeRaw?: string | null
            calculatedLevel?: RangeType | null
            calculatedLevelLabel?: string | null
            message?: string | null
            valueText?: string | null
            valueNumeric?: number | null
            hl7filename?: string | null
            analysisDefinition?: {
              id: string
              name: string
              units?: string | null
              scaleType: ScaleType
              description: string
            } | null
            scalePoints?: Array<{ val: number; type: ScalePointType }> | null
            ranges?: Array<{ fromVal?: number | null; toVal?: number | null; type?: RangeType | null }> | null
            centileRanges?: Array<{ centile: number; value?: number | null }> | null
          }>
        }>
        cumulativeResults: Array<{
          sampleTime?: string | null
          testCode: string
          obrStatus?: ObrStatus | null
          testDefinition?: { id: string; name: string; sample: SampleType } | null
          analyses: Array<{
            analysisCode: string
            obxStatus?: ObxStatus | null
            notes: Array<string>
            referenceRangeRaw?: string | null
            calculatedLevel?: RangeType | null
            calculatedLevelLabel?: string | null
            message?: string | null
            valueText?: string | null
            valueNumeric?: number | null
            hl7filename?: string | null
            analysisDefinition?: {
              id: string
              name: string
              units?: string | null
              scaleType: ScaleType
              description: string
            } | null
            scalePoints?: Array<{ val: number; type: ScalePointType }> | null
            ranges?: Array<{ fromVal?: number | null; toVal?: number | null; type?: RangeType | null }> | null
            centileRanges?: Array<{ centile: number; value?: number | null }> | null
          }>
        }>
      } | null
      currentTdlResult?: {
        id: string
        filename: string
        labRef: string
        labStatus?: OrcStatus | null
        patientDob?: string | null
        patientSex?: string | null
        patientName?: { familyName?: string | null; givenName?: string | null } | null
        results: Array<{
          sampleTime?: string | null
          testCode: string
          obrStatus?: ObrStatus | null
          testDefinition?: { id: string; name: string; sample: SampleType } | null
          analyses: Array<{
            analysisCode: string
            obxStatus?: ObxStatus | null
            notes: Array<string>
            referenceRangeRaw?: string | null
            calculatedLevel?: RangeType | null
            calculatedLevelLabel?: string | null
            message?: string | null
            valueText?: string | null
            valueNumeric?: number | null
            hl7filename?: string | null
            analysisDefinition?: {
              id: string
              name: string
              units?: string | null
              scaleType: ScaleType
              description: string
            } | null
            scalePoints?: Array<{ val: number; type: ScalePointType }> | null
            ranges?: Array<{ fromVal?: number | null; toVal?: number | null; type?: RangeType | null }> | null
            centileRanges?: Array<{ centile: number; value?: number | null }> | null
          }>
        }>
        cumulativeResults: Array<{
          sampleTime?: string | null
          testCode: string
          obrStatus?: ObrStatus | null
          testDefinition?: { id: string; name: string; sample: SampleType } | null
          analyses: Array<{
            analysisCode: string
            obxStatus?: ObxStatus | null
            notes: Array<string>
            referenceRangeRaw?: string | null
            calculatedLevel?: RangeType | null
            calculatedLevelLabel?: string | null
            message?: string | null
            valueText?: string | null
            valueNumeric?: number | null
            hl7filename?: string | null
            analysisDefinition?: {
              id: string
              name: string
              units?: string | null
              scaleType: ScaleType
              description: string
            } | null
            scalePoints?: Array<{ val: number; type: ScalePointType }> | null
            ranges?: Array<{ fromVal?: number | null; toVal?: number | null; type?: RangeType | null }> | null
            centileRanges?: Array<{ centile: number; value?: number | null }> | null
          }>
        }>
      } | null
    }>
  } | null
}

export type LabTestOrderedProductFragment = {
  __typename: 'LabTestOrderedProduct'
  id: string
  canceled?: boolean | null
  blockedForReporting?: boolean | null
  reportStatus?: LabTestOrderedProductReportStatus | null
  reportText?: string | null
  reportPublishedAt?: string | null
  reportRecommendations?: string | null
  reportAnalysesToExclude?: Array<string> | null
  user: { email: string; id: string; friendlyName?: string | null; firstName?: string | null; lastName?: string | null }
  bloodCollectionOption?: { code: string } | null
  productDefinition:
    | { sku: string; name: string }
    | { sku: string; name: string }
    | { sku: string; name: string }
    | { sku: string; name: string }
    | { sku: string; name: string }
    | { sku: string; name: string }
  order?: {
    id: string
    createdAt: string
    firstName: string
    lastName: string
    shipments: Array<{ shippingAddress: { line1: string; line2?: string | null; postcode: string; city: string } }>
  } | null
  kitRequests: Array<{
    id: string
    status: KitRequestStatus
    canceled: boolean
    rawRequestCodes?: Array<string | null> | null
    activationInfo?: {
      firstName?: string | null
      lastName?: string | null
      dob?: string | null
      sampleDate?: string | null
      biologicalSex?: BiologicalSex | null
      trackingNumber?: string | null
      shippingLatestEvent?: string | null
      extraData?: string | null
    } | null
    requestedTests: Array<{ id: string; name: string }>
    kitDefinition: { sku: string; sampleType: SampleType }
    kit?: {
      id: string
      activatedAt?: string | null
      canceled?: boolean | null
      notes: Array<{ id: string; createdAt: string; author: string; note: string; subject?: string | null }>
    } | null
    orderedProduct?: {
      __typename: 'LabTestOrderedProduct'
      id: string
      productDefinition:
        | { __typename: 'BespokeLabTestProduct'; type: ProductType; name: string }
        | { __typename: 'CgmProduct'; type: ProductType; name: string }
        | { __typename: 'CoachingProduct'; type: ProductType; name: string }
        | { __typename: 'GiftCardProduct'; type: ProductType; name: string }
        | {
            __typename: 'LabTestProduct'
            type: ProductType
            name: string
            testGroups: Array<{
              name: string
              tests: Array<{
                __typename: 'TestInfo'
                id: string
                name: string
                description: string
                capillary?: boolean | null
                categories: Array<string>
                sample: SampleType
                sampleTube: SampleTubeType
                turnaroundTime: number
                analyses: Array<{ id: string; name: string; description: string }>
              }>
            }>
          }
        | { __typename: 'SupplementProduct'; type: ProductType; name: string }
    } | null
    reportedTdlResult?: {
      id: string
      results: Array<{
        sampleTime?: string | null
        testCode: string
        obrStatus?: ObrStatus | null
        testDefinition?: { id: string; name: string; sample: SampleType } | null
        analyses: Array<{
          analysisCode: string
          obxStatus?: ObxStatus | null
          notes: Array<string>
          referenceRangeRaw?: string | null
          calculatedLevel?: RangeType | null
          calculatedLevelLabel?: string | null
          message?: string | null
          valueText?: string | null
          valueNumeric?: number | null
          hl7filename?: string | null
          analysisDefinition?: {
            id: string
            name: string
            units?: string | null
            scaleType: ScaleType
            description: string
          } | null
          scalePoints?: Array<{ val: number; type: ScalePointType }> | null
          ranges?: Array<{ fromVal?: number | null; toVal?: number | null; type?: RangeType | null }> | null
          centileRanges?: Array<{ centile: number; value?: number | null }> | null
        }>
      }>
      cumulativeResults: Array<{
        sampleTime?: string | null
        testCode: string
        obrStatus?: ObrStatus | null
        testDefinition?: { id: string; name: string; sample: SampleType } | null
        analyses: Array<{
          analysisCode: string
          obxStatus?: ObxStatus | null
          notes: Array<string>
          referenceRangeRaw?: string | null
          calculatedLevel?: RangeType | null
          calculatedLevelLabel?: string | null
          message?: string | null
          valueText?: string | null
          valueNumeric?: number | null
          hl7filename?: string | null
          analysisDefinition?: {
            id: string
            name: string
            units?: string | null
            scaleType: ScaleType
            description: string
          } | null
          scalePoints?: Array<{ val: number; type: ScalePointType }> | null
          ranges?: Array<{ fromVal?: number | null; toVal?: number | null; type?: RangeType | null }> | null
          centileRanges?: Array<{ centile: number; value?: number | null }> | null
        }>
      }>
    } | null
    currentTdlResult?: {
      id: string
      filename: string
      labRef: string
      labStatus?: OrcStatus | null
      patientDob?: string | null
      patientSex?: string | null
      patientName?: { familyName?: string | null; givenName?: string | null } | null
      results: Array<{
        sampleTime?: string | null
        testCode: string
        obrStatus?: ObrStatus | null
        testDefinition?: { id: string; name: string; sample: SampleType } | null
        analyses: Array<{
          analysisCode: string
          obxStatus?: ObxStatus | null
          notes: Array<string>
          referenceRangeRaw?: string | null
          calculatedLevel?: RangeType | null
          calculatedLevelLabel?: string | null
          message?: string | null
          valueText?: string | null
          valueNumeric?: number | null
          hl7filename?: string | null
          analysisDefinition?: {
            id: string
            name: string
            units?: string | null
            scaleType: ScaleType
            description: string
          } | null
          scalePoints?: Array<{ val: number; type: ScalePointType }> | null
          ranges?: Array<{ fromVal?: number | null; toVal?: number | null; type?: RangeType | null }> | null
          centileRanges?: Array<{ centile: number; value?: number | null }> | null
        }>
      }>
      cumulativeResults: Array<{
        sampleTime?: string | null
        testCode: string
        obrStatus?: ObrStatus | null
        testDefinition?: { id: string; name: string; sample: SampleType } | null
        analyses: Array<{
          analysisCode: string
          obxStatus?: ObxStatus | null
          notes: Array<string>
          referenceRangeRaw?: string | null
          calculatedLevel?: RangeType | null
          calculatedLevelLabel?: string | null
          message?: string | null
          valueText?: string | null
          valueNumeric?: number | null
          hl7filename?: string | null
          analysisDefinition?: {
            id: string
            name: string
            units?: string | null
            scaleType: ScaleType
            description: string
          } | null
          scalePoints?: Array<{ val: number; type: ScalePointType }> | null
          ranges?: Array<{ fromVal?: number | null; toVal?: number | null; type?: RangeType | null }> | null
          centileRanges?: Array<{ centile: number; value?: number | null }> | null
        }>
      }>
    } | null
  }>
}

export type KitRequestsProductDefinitionFragment = {
  __typename: 'LabTestOrderedProduct'
  productDefinition:
    | { __typename: 'BespokeLabTestProduct'; type: ProductType; name: string }
    | { __typename: 'CgmProduct'; type: ProductType; name: string }
    | { __typename: 'CoachingProduct'; type: ProductType; name: string }
    | { __typename: 'GiftCardProduct'; type: ProductType; name: string }
    | {
        __typename: 'LabTestProduct'
        type: ProductType
        name: string
        testGroups: Array<{
          name: string
          tests: Array<{
            __typename: 'TestInfo'
            id: string
            name: string
            description: string
            capillary?: boolean | null
            categories: Array<string>
            sample: SampleType
            sampleTube: SampleTubeType
            turnaroundTime: number
            analyses: Array<{ id: string; name: string; description: string }>
          }>
        }>
      }
    | { __typename: 'SupplementProduct'; type: ProductType; name: string }
}

export type CancelTestPanelMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type CancelTestPanelMutation = { cancelLabTestOrderedProduct: { success: boolean } }

export type PublishReportMutationVariables = Exact<{
  orderedProductId: Scalars['ID']
  unpublish?: InputMaybe<Scalars['Boolean']>
  silent?: InputMaybe<Scalars['Boolean']>
}>

export type PublishReportMutation = {
  publishReport?: {
    orderedProduct?: {
      id: string
      reportStatus?: LabTestOrderedProductReportStatus | null
      reportText?: string | null
      reportRecommendations?: string | null
      kitRequests: Array<{ currentTdlResult?: { id: string } | null; reportedTdlResult?: { id: string } | null }>
    } | null
  } | null
}

export type UserOrderHistoryByIdQueryVariables = Exact<{
  userId: Scalars['ID']
}>

export type UserOrderHistoryByIdQuery = {
  userById?: {
    orders: Array<{
      id: string
      orderedProducts?: Array<
        | { __typename: 'CgmOrderedProduct'; id: string }
        | { __typename: 'GiftCardOrderedProduct'; id: string }
        | {
            __typename: 'LabTestOrderedProduct'
            id: string
            kitRequests: Array<{
              id: string
              reportedTdlResult?: {
                cumulativeResults: Array<{
                  testCode: string
                  sampleTime?: string | null
                  analyses: Array<{
                    analysisCode: string
                    valueNumeric?: number | null
                    valueText?: string | null
                    calculatedLevelLabel?: string | null
                    calculatedLevel?: RangeType | null
                    analysisDefinition?: { id: string; name: string; units?: string | null } | null
                  }>
                }>
              } | null
            }>
          }
      > | null
    }>
  } | null
}

export type HistoryOrderFragment = {
  id: string
  orderedProducts?: Array<
    | { __typename: 'CgmOrderedProduct'; id: string }
    | { __typename: 'GiftCardOrderedProduct'; id: string }
    | {
        __typename: 'LabTestOrderedProduct'
        id: string
        kitRequests: Array<{
          id: string
          reportedTdlResult?: {
            cumulativeResults: Array<{
              testCode: string
              sampleTime?: string | null
              analyses: Array<{
                analysisCode: string
                valueNumeric?: number | null
                valueText?: string | null
                calculatedLevelLabel?: string | null
                calculatedLevel?: RangeType | null
                analysisDefinition?: { id: string; name: string; units?: string | null } | null
              }>
            }>
          } | null
        }>
      }
  > | null
}

type HistoryProduct_CgmOrderedProduct_Fragment = { __typename: 'CgmOrderedProduct'; id: string }

type HistoryProduct_GiftCardOrderedProduct_Fragment = { __typename: 'GiftCardOrderedProduct'; id: string }

type HistoryProduct_LabTestOrderedProduct_Fragment = {
  __typename: 'LabTestOrderedProduct'
  id: string
  kitRequests: Array<{
    id: string
    reportedTdlResult?: {
      cumulativeResults: Array<{
        testCode: string
        sampleTime?: string | null
        analyses: Array<{
          analysisCode: string
          valueNumeric?: number | null
          valueText?: string | null
          calculatedLevelLabel?: string | null
          calculatedLevel?: RangeType | null
          analysisDefinition?: { id: string; name: string; units?: string | null } | null
        }>
      }>
    } | null
  }>
}

export type HistoryProductFragment =
  | HistoryProduct_CgmOrderedProduct_Fragment
  | HistoryProduct_GiftCardOrderedProduct_Fragment
  | HistoryProduct_LabTestOrderedProduct_Fragment

export type HistoryLabTestFragment = {
  __typename: 'LabTestOrderedProduct'
  id: string
  kitRequests: Array<{
    id: string
    reportedTdlResult?: {
      cumulativeResults: Array<{
        testCode: string
        sampleTime?: string | null
        analyses: Array<{
          analysisCode: string
          valueNumeric?: number | null
          valueText?: string | null
          calculatedLevelLabel?: string | null
          calculatedLevel?: RangeType | null
          analysisDefinition?: { id: string; name: string; units?: string | null } | null
        }>
      }>
    } | null
  }>
}

export type HistoryAnalysisFragment = {
  analysisCode: string
  valueNumeric?: number | null
  valueText?: string | null
  calculatedLevelLabel?: string | null
  calculatedLevel?: RangeType | null
  analysisDefinition?: { id: string; name: string; units?: string | null } | null
}

export type LabTestOrderedProductTdlFilesQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type LabTestOrderedProductTdlFilesQuery = {
  labTestOrderedProductById?: {
    __typename: 'LabTestOrderedProduct'
    id: string
    canceled?: boolean | null
    productDefinition:
      | { __typename: 'BespokeLabTestProduct'; name: string }
      | { __typename: 'CgmProduct'; name: string }
      | { __typename: 'CoachingProduct'; name: string }
      | { __typename: 'GiftCardProduct'; name: string }
      | { __typename: 'LabTestProduct'; name: string }
      | { __typename: 'SupplementProduct'; name: string }
    order?: { __typename: 'Order'; id: string } | null
    kitRequests: Array<{
      __typename: 'KitRequest'
      kit?: { __typename: 'Kit'; id: string } | null
      kitDefinition: { __typename: 'KitDefinition'; name: string; sku: string; sampleType: SampleType }
      tdlResults: Array<{
        __typename: 'TdlResult'
        id: string
        filename: string
        labRef: string
        labStatus?: OrcStatus | null
        ignored?: boolean | null
        results: Array<{ __typename: 'TestResult'; testCode: string }>
      }>
    }>
  } | null
}

export type LabTestTdlResultsFragment = {
  __typename: 'LabTestOrderedProduct'
  id: string
  canceled?: boolean | null
  productDefinition:
    | { __typename: 'BespokeLabTestProduct'; name: string }
    | { __typename: 'CgmProduct'; name: string }
    | { __typename: 'CoachingProduct'; name: string }
    | { __typename: 'GiftCardProduct'; name: string }
    | { __typename: 'LabTestProduct'; name: string }
    | { __typename: 'SupplementProduct'; name: string }
  order?: { __typename: 'Order'; id: string } | null
  kitRequests: Array<{
    __typename: 'KitRequest'
    kit?: { __typename: 'Kit'; id: string } | null
    kitDefinition: { __typename: 'KitDefinition'; name: string; sku: string; sampleType: SampleType }
    tdlResults: Array<{
      __typename: 'TdlResult'
      id: string
      filename: string
      labRef: string
      labStatus?: OrcStatus | null
      ignored?: boolean | null
      results: Array<{ __typename: 'TestResult'; testCode: string }>
    }>
  }>
}

export type TdlResultFragment = {
  reportedTdlResult?: {
    id: string
    results: Array<{
      sampleTime?: string | null
      testCode: string
      obrStatus?: ObrStatus | null
      testDefinition?: { id: string; name: string; sample: SampleType } | null
      analyses: Array<{
        analysisCode: string
        obxStatus?: ObxStatus | null
        notes: Array<string>
        referenceRangeRaw?: string | null
        calculatedLevel?: RangeType | null
        calculatedLevelLabel?: string | null
        message?: string | null
        valueText?: string | null
        valueNumeric?: number | null
        hl7filename?: string | null
        analysisDefinition?: {
          id: string
          name: string
          units?: string | null
          scaleType: ScaleType
          description: string
        } | null
        scalePoints?: Array<{ val: number; type: ScalePointType }> | null
        ranges?: Array<{ fromVal?: number | null; toVal?: number | null; type?: RangeType | null }> | null
        centileRanges?: Array<{ centile: number; value?: number | null }> | null
      }>
    }>
    cumulativeResults: Array<{
      sampleTime?: string | null
      testCode: string
      obrStatus?: ObrStatus | null
      testDefinition?: { id: string; name: string; sample: SampleType } | null
      analyses: Array<{
        analysisCode: string
        obxStatus?: ObxStatus | null
        notes: Array<string>
        referenceRangeRaw?: string | null
        calculatedLevel?: RangeType | null
        calculatedLevelLabel?: string | null
        message?: string | null
        valueText?: string | null
        valueNumeric?: number | null
        hl7filename?: string | null
        analysisDefinition?: {
          id: string
          name: string
          units?: string | null
          scaleType: ScaleType
          description: string
        } | null
        scalePoints?: Array<{ val: number; type: ScalePointType }> | null
        ranges?: Array<{ fromVal?: number | null; toVal?: number | null; type?: RangeType | null }> | null
        centileRanges?: Array<{ centile: number; value?: number | null }> | null
      }>
    }>
  } | null
  currentTdlResult?: {
    id: string
    filename: string
    labRef: string
    labStatus?: OrcStatus | null
    patientDob?: string | null
    patientSex?: string | null
    patientName?: { familyName?: string | null; givenName?: string | null } | null
    results: Array<{
      sampleTime?: string | null
      testCode: string
      obrStatus?: ObrStatus | null
      testDefinition?: { id: string; name: string; sample: SampleType } | null
      analyses: Array<{
        analysisCode: string
        obxStatus?: ObxStatus | null
        notes: Array<string>
        referenceRangeRaw?: string | null
        calculatedLevel?: RangeType | null
        calculatedLevelLabel?: string | null
        message?: string | null
        valueText?: string | null
        valueNumeric?: number | null
        hl7filename?: string | null
        analysisDefinition?: {
          id: string
          name: string
          units?: string | null
          scaleType: ScaleType
          description: string
        } | null
        scalePoints?: Array<{ val: number; type: ScalePointType }> | null
        ranges?: Array<{ fromVal?: number | null; toVal?: number | null; type?: RangeType | null }> | null
        centileRanges?: Array<{ centile: number; value?: number | null }> | null
      }>
    }>
    cumulativeResults: Array<{
      sampleTime?: string | null
      testCode: string
      obrStatus?: ObrStatus | null
      testDefinition?: { id: string; name: string; sample: SampleType } | null
      analyses: Array<{
        analysisCode: string
        obxStatus?: ObxStatus | null
        notes: Array<string>
        referenceRangeRaw?: string | null
        calculatedLevel?: RangeType | null
        calculatedLevelLabel?: string | null
        message?: string | null
        valueText?: string | null
        valueNumeric?: number | null
        hl7filename?: string | null
        analysisDefinition?: {
          id: string
          name: string
          units?: string | null
          scaleType: ScaleType
          description: string
        } | null
        scalePoints?: Array<{ val: number; type: ScalePointType }> | null
        ranges?: Array<{ fromVal?: number | null; toVal?: number | null; type?: RangeType | null }> | null
        centileRanges?: Array<{ centile: number; value?: number | null }> | null
      }>
    }>
  } | null
}

export type ResultFragment = {
  sampleTime?: string | null
  testCode: string
  obrStatus?: ObrStatus | null
  testDefinition?: { id: string; name: string; sample: SampleType } | null
  analyses: Array<{
    analysisCode: string
    obxStatus?: ObxStatus | null
    notes: Array<string>
    referenceRangeRaw?: string | null
    calculatedLevel?: RangeType | null
    calculatedLevelLabel?: string | null
    message?: string | null
    valueText?: string | null
    valueNumeric?: number | null
    hl7filename?: string | null
    analysisDefinition?: {
      id: string
      name: string
      units?: string | null
      scaleType: ScaleType
      description: string
    } | null
    scalePoints?: Array<{ val: number; type: ScalePointType }> | null
    ranges?: Array<{ fromVal?: number | null; toVal?: number | null; type?: RangeType | null }> | null
    centileRanges?: Array<{ centile: number; value?: number | null }> | null
  }>
}

export type AnalysisFragment = {
  analysisCode: string
  obxStatus?: ObxStatus | null
  notes: Array<string>
  referenceRangeRaw?: string | null
  calculatedLevel?: RangeType | null
  calculatedLevelLabel?: string | null
  message?: string | null
  valueText?: string | null
  valueNumeric?: number | null
  hl7filename?: string | null
  analysisDefinition?: {
    id: string
    name: string
    units?: string | null
    scaleType: ScaleType
    description: string
  } | null
  scalePoints?: Array<{ val: number; type: ScalePointType }> | null
  ranges?: Array<{ fromVal?: number | null; toVal?: number | null; type?: RangeType | null }> | null
  centileRanges?: Array<{ centile: number; value?: number | null }> | null
}

export type WriteReportMutationVariables = Exact<{
  input: WriteReportInput
}>

export type WriteReportMutation = {
  writeReport: {
    orderedProduct?: {
      id: string
      reportStatus?: LabTestOrderedProductReportStatus | null
      reportPublishedAt?: string | null
      reportText?: string | null
      reportRecommendations?: string | null
      reportAnalysesToExclude?: Array<string> | null
      kitRequests: Array<{
        id: string
        reportedTdlResult?: {
          id: string
          results: Array<{
            sampleTime?: string | null
            testCode: string
            obrStatus?: ObrStatus | null
            testDefinition?: { id: string; name: string; sample: SampleType } | null
            analyses: Array<{
              analysisCode: string
              obxStatus?: ObxStatus | null
              notes: Array<string>
              referenceRangeRaw?: string | null
              calculatedLevel?: RangeType | null
              calculatedLevelLabel?: string | null
              message?: string | null
              valueText?: string | null
              valueNumeric?: number | null
              hl7filename?: string | null
              analysisDefinition?: {
                id: string
                name: string
                units?: string | null
                scaleType: ScaleType
                description: string
              } | null
              scalePoints?: Array<{ val: number; type: ScalePointType }> | null
              ranges?: Array<{ fromVal?: number | null; toVal?: number | null; type?: RangeType | null }> | null
              centileRanges?: Array<{ centile: number; value?: number | null }> | null
            }>
          }>
          cumulativeResults: Array<{
            sampleTime?: string | null
            testCode: string
            obrStatus?: ObrStatus | null
            testDefinition?: { id: string; name: string; sample: SampleType } | null
            analyses: Array<{
              analysisCode: string
              obxStatus?: ObxStatus | null
              notes: Array<string>
              referenceRangeRaw?: string | null
              calculatedLevel?: RangeType | null
              calculatedLevelLabel?: string | null
              message?: string | null
              valueText?: string | null
              valueNumeric?: number | null
              hl7filename?: string | null
              analysisDefinition?: {
                id: string
                name: string
                units?: string | null
                scaleType: ScaleType
                description: string
              } | null
              scalePoints?: Array<{ val: number; type: ScalePointType }> | null
              ranges?: Array<{ fromVal?: number | null; toVal?: number | null; type?: RangeType | null }> | null
              centileRanges?: Array<{ centile: number; value?: number | null }> | null
            }>
          }>
        } | null
        currentTdlResult?: {
          id: string
          filename: string
          labRef: string
          labStatus?: OrcStatus | null
          patientDob?: string | null
          patientSex?: string | null
          patientName?: { familyName?: string | null; givenName?: string | null } | null
          results: Array<{
            sampleTime?: string | null
            testCode: string
            obrStatus?: ObrStatus | null
            testDefinition?: { id: string; name: string; sample: SampleType } | null
            analyses: Array<{
              analysisCode: string
              obxStatus?: ObxStatus | null
              notes: Array<string>
              referenceRangeRaw?: string | null
              calculatedLevel?: RangeType | null
              calculatedLevelLabel?: string | null
              message?: string | null
              valueText?: string | null
              valueNumeric?: number | null
              hl7filename?: string | null
              analysisDefinition?: {
                id: string
                name: string
                units?: string | null
                scaleType: ScaleType
                description: string
              } | null
              scalePoints?: Array<{ val: number; type: ScalePointType }> | null
              ranges?: Array<{ fromVal?: number | null; toVal?: number | null; type?: RangeType | null }> | null
              centileRanges?: Array<{ centile: number; value?: number | null }> | null
            }>
          }>
          cumulativeResults: Array<{
            sampleTime?: string | null
            testCode: string
            obrStatus?: ObrStatus | null
            testDefinition?: { id: string; name: string; sample: SampleType } | null
            analyses: Array<{
              analysisCode: string
              obxStatus?: ObxStatus | null
              notes: Array<string>
              referenceRangeRaw?: string | null
              calculatedLevel?: RangeType | null
              calculatedLevelLabel?: string | null
              message?: string | null
              valueText?: string | null
              valueNumeric?: number | null
              hl7filename?: string | null
              analysisDefinition?: {
                id: string
                name: string
                units?: string | null
                scaleType: ScaleType
                description: string
              } | null
              scalePoints?: Array<{ val: number; type: ScalePointType }> | null
              ranges?: Array<{ fromVal?: number | null; toVal?: number | null; type?: RangeType | null }> | null
              centileRanges?: Array<{ centile: number; value?: number | null }> | null
            }>
          }>
        } | null
      }>
    } | null
  }
}

export type BlockForReportingMutationVariables = Exact<{
  input: MarkOrderedProductBlockedInput
}>

export type BlockForReportingMutation = {
  markOrderedProductBlockedForReporting: { orderedProduct: { id: string; blockedForReporting?: boolean | null } }
}

export type LabTestOrderedProductsInFlightQueryVariables = Exact<{ [key: string]: never }>

export type LabTestOrderedProductsInFlightQuery = {
  labTestOrderedProductsInFlight: {
    __typename: 'LabTestOrderedProductsConnection'
    edges: Array<{
      node: {
        __typename: 'LabTestOrderedProduct'
        id: string
        canceled?: boolean | null
        reportStatus?: LabTestOrderedProductReportStatus | null
        reportPublishedAt?: string | null
        productDefinition:
          | { name: string }
          | { name: string }
          | { name: string }
          | { name: string }
          | { name: string }
          | { name: string }
        order?: { id: string } | null
        kitRequests: Array<{
          id: string
          status: KitRequestStatus
          canceled: boolean
          activationInfo?: {
            firstName?: string | null
            lastName?: string | null
            shippingLatestEvent?: string | null
            trackingNumber?: string | null
          } | null
          requestedTests: Array<{ id: string; name: string; turnaroundTime: number }>
          kitDefinition: { sku: string; sampleType: SampleType }
          kit?: {
            id: string
            activatedAt?: string | null
            canceled?: boolean | null
            notes: Array<{ id: string; createdAt: string; author: string; subject?: string | null; note: string }>
          } | null
          orderedProduct?: {
            __typename: 'LabTestOrderedProduct'
            id: string
            productDefinition:
              | { __typename: 'BespokeLabTestProduct'; type: ProductType; name: string }
              | { __typename: 'CgmProduct'; type: ProductType; name: string }
              | { __typename: 'CoachingProduct'; type: ProductType; name: string }
              | { __typename: 'GiftCardProduct'; type: ProductType; name: string }
              | {
                  __typename: 'LabTestProduct'
                  type: ProductType
                  name: string
                  testGroups: Array<{
                    name: string
                    tests: Array<{
                      __typename: 'TestInfo'
                      id: string
                      name: string
                      description: string
                      capillary?: boolean | null
                      categories: Array<string>
                      sample: SampleType
                      sampleTube: SampleTubeType
                      turnaroundTime: number
                      analyses: Array<{ id: string; name: string; description: string }>
                    }>
                  }>
                }
              | { __typename: 'SupplementProduct'; type: ProductType; name: string }
          } | null
          tdlResults: Array<{ filename: string; labStatus?: OrcStatus | null }>
          reportedTdlResult?: {
            id: string
            results: Array<{
              sampleTime?: string | null
              testCode: string
              obrStatus?: ObrStatus | null
              testDefinition?: { id: string; name: string; sample: SampleType } | null
              analyses: Array<{
                analysisCode: string
                obxStatus?: ObxStatus | null
                notes: Array<string>
                referenceRangeRaw?: string | null
                calculatedLevel?: RangeType | null
                calculatedLevelLabel?: string | null
                message?: string | null
                valueText?: string | null
                valueNumeric?: number | null
                hl7filename?: string | null
                analysisDefinition?: {
                  id: string
                  name: string
                  units?: string | null
                  scaleType: ScaleType
                  description: string
                } | null
                scalePoints?: Array<{ val: number; type: ScalePointType }> | null
                ranges?: Array<{ fromVal?: number | null; toVal?: number | null; type?: RangeType | null }> | null
                centileRanges?: Array<{ centile: number; value?: number | null }> | null
              }>
            }>
            cumulativeResults: Array<{
              sampleTime?: string | null
              testCode: string
              obrStatus?: ObrStatus | null
              testDefinition?: { id: string; name: string; sample: SampleType } | null
              analyses: Array<{
                analysisCode: string
                obxStatus?: ObxStatus | null
                notes: Array<string>
                referenceRangeRaw?: string | null
                calculatedLevel?: RangeType | null
                calculatedLevelLabel?: string | null
                message?: string | null
                valueText?: string | null
                valueNumeric?: number | null
                hl7filename?: string | null
                analysisDefinition?: {
                  id: string
                  name: string
                  units?: string | null
                  scaleType: ScaleType
                  description: string
                } | null
                scalePoints?: Array<{ val: number; type: ScalePointType }> | null
                ranges?: Array<{ fromVal?: number | null; toVal?: number | null; type?: RangeType | null }> | null
                centileRanges?: Array<{ centile: number; value?: number | null }> | null
              }>
            }>
          } | null
          currentTdlResult?: {
            id: string
            filename: string
            labRef: string
            labStatus?: OrcStatus | null
            patientDob?: string | null
            patientSex?: string | null
            patientName?: { familyName?: string | null; givenName?: string | null } | null
            results: Array<{
              sampleTime?: string | null
              testCode: string
              obrStatus?: ObrStatus | null
              testDefinition?: { id: string; name: string; sample: SampleType } | null
              analyses: Array<{
                analysisCode: string
                obxStatus?: ObxStatus | null
                notes: Array<string>
                referenceRangeRaw?: string | null
                calculatedLevel?: RangeType | null
                calculatedLevelLabel?: string | null
                message?: string | null
                valueText?: string | null
                valueNumeric?: number | null
                hl7filename?: string | null
                analysisDefinition?: {
                  id: string
                  name: string
                  units?: string | null
                  scaleType: ScaleType
                  description: string
                } | null
                scalePoints?: Array<{ val: number; type: ScalePointType }> | null
                ranges?: Array<{ fromVal?: number | null; toVal?: number | null; type?: RangeType | null }> | null
                centileRanges?: Array<{ centile: number; value?: number | null }> | null
              }>
            }>
            cumulativeResults: Array<{
              sampleTime?: string | null
              testCode: string
              obrStatus?: ObrStatus | null
              testDefinition?: { id: string; name: string; sample: SampleType } | null
              analyses: Array<{
                analysisCode: string
                obxStatus?: ObxStatus | null
                notes: Array<string>
                referenceRangeRaw?: string | null
                calculatedLevel?: RangeType | null
                calculatedLevelLabel?: string | null
                message?: string | null
                valueText?: string | null
                valueNumeric?: number | null
                hl7filename?: string | null
                analysisDefinition?: {
                  id: string
                  name: string
                  units?: string | null
                  scaleType: ScaleType
                  description: string
                } | null
                scalePoints?: Array<{ val: number; type: ScalePointType }> | null
                ranges?: Array<{ fromVal?: number | null; toVal?: number | null; type?: RangeType | null }> | null
                centileRanges?: Array<{ centile: number; value?: number | null }> | null
              }>
            }>
          } | null
        }>
      }
    }>
  }
}

export type LabTestOrderedProductSummaryFragment = {
  __typename: 'LabTestOrderedProduct'
  id: string
  canceled?: boolean | null
  reportStatus?: LabTestOrderedProductReportStatus | null
  reportPublishedAt?: string | null
  productDefinition:
    | { name: string }
    | { name: string }
    | { name: string }
    | { name: string }
    | { name: string }
    | { name: string }
  order?: { id: string } | null
  kitRequests: Array<{
    id: string
    status: KitRequestStatus
    canceled: boolean
    activationInfo?: {
      firstName?: string | null
      lastName?: string | null
      shippingLatestEvent?: string | null
      trackingNumber?: string | null
    } | null
    requestedTests: Array<{ id: string; name: string; turnaroundTime: number }>
    kitDefinition: { sku: string; sampleType: SampleType }
    kit?: {
      id: string
      activatedAt?: string | null
      canceled?: boolean | null
      notes: Array<{ id: string; createdAt: string; author: string; subject?: string | null; note: string }>
    } | null
    orderedProduct?: {
      __typename: 'LabTestOrderedProduct'
      id: string
      productDefinition:
        | { __typename: 'BespokeLabTestProduct'; type: ProductType; name: string }
        | { __typename: 'CgmProduct'; type: ProductType; name: string }
        | { __typename: 'CoachingProduct'; type: ProductType; name: string }
        | { __typename: 'GiftCardProduct'; type: ProductType; name: string }
        | {
            __typename: 'LabTestProduct'
            type: ProductType
            name: string
            testGroups: Array<{
              name: string
              tests: Array<{
                __typename: 'TestInfo'
                id: string
                name: string
                description: string
                capillary?: boolean | null
                categories: Array<string>
                sample: SampleType
                sampleTube: SampleTubeType
                turnaroundTime: number
                analyses: Array<{ id: string; name: string; description: string }>
              }>
            }>
          }
        | { __typename: 'SupplementProduct'; type: ProductType; name: string }
    } | null
    tdlResults: Array<{ filename: string; labStatus?: OrcStatus | null }>
    reportedTdlResult?: {
      id: string
      results: Array<{
        sampleTime?: string | null
        testCode: string
        obrStatus?: ObrStatus | null
        testDefinition?: { id: string; name: string; sample: SampleType } | null
        analyses: Array<{
          analysisCode: string
          obxStatus?: ObxStatus | null
          notes: Array<string>
          referenceRangeRaw?: string | null
          calculatedLevel?: RangeType | null
          calculatedLevelLabel?: string | null
          message?: string | null
          valueText?: string | null
          valueNumeric?: number | null
          hl7filename?: string | null
          analysisDefinition?: {
            id: string
            name: string
            units?: string | null
            scaleType: ScaleType
            description: string
          } | null
          scalePoints?: Array<{ val: number; type: ScalePointType }> | null
          ranges?: Array<{ fromVal?: number | null; toVal?: number | null; type?: RangeType | null }> | null
          centileRanges?: Array<{ centile: number; value?: number | null }> | null
        }>
      }>
      cumulativeResults: Array<{
        sampleTime?: string | null
        testCode: string
        obrStatus?: ObrStatus | null
        testDefinition?: { id: string; name: string; sample: SampleType } | null
        analyses: Array<{
          analysisCode: string
          obxStatus?: ObxStatus | null
          notes: Array<string>
          referenceRangeRaw?: string | null
          calculatedLevel?: RangeType | null
          calculatedLevelLabel?: string | null
          message?: string | null
          valueText?: string | null
          valueNumeric?: number | null
          hl7filename?: string | null
          analysisDefinition?: {
            id: string
            name: string
            units?: string | null
            scaleType: ScaleType
            description: string
          } | null
          scalePoints?: Array<{ val: number; type: ScalePointType }> | null
          ranges?: Array<{ fromVal?: number | null; toVal?: number | null; type?: RangeType | null }> | null
          centileRanges?: Array<{ centile: number; value?: number | null }> | null
        }>
      }>
    } | null
    currentTdlResult?: {
      id: string
      filename: string
      labRef: string
      labStatus?: OrcStatus | null
      patientDob?: string | null
      patientSex?: string | null
      patientName?: { familyName?: string | null; givenName?: string | null } | null
      results: Array<{
        sampleTime?: string | null
        testCode: string
        obrStatus?: ObrStatus | null
        testDefinition?: { id: string; name: string; sample: SampleType } | null
        analyses: Array<{
          analysisCode: string
          obxStatus?: ObxStatus | null
          notes: Array<string>
          referenceRangeRaw?: string | null
          calculatedLevel?: RangeType | null
          calculatedLevelLabel?: string | null
          message?: string | null
          valueText?: string | null
          valueNumeric?: number | null
          hl7filename?: string | null
          analysisDefinition?: {
            id: string
            name: string
            units?: string | null
            scaleType: ScaleType
            description: string
          } | null
          scalePoints?: Array<{ val: number; type: ScalePointType }> | null
          ranges?: Array<{ fromVal?: number | null; toVal?: number | null; type?: RangeType | null }> | null
          centileRanges?: Array<{ centile: number; value?: number | null }> | null
        }>
      }>
      cumulativeResults: Array<{
        sampleTime?: string | null
        testCode: string
        obrStatus?: ObrStatus | null
        testDefinition?: { id: string; name: string; sample: SampleType } | null
        analyses: Array<{
          analysisCode: string
          obxStatus?: ObxStatus | null
          notes: Array<string>
          referenceRangeRaw?: string | null
          calculatedLevel?: RangeType | null
          calculatedLevelLabel?: string | null
          message?: string | null
          valueText?: string | null
          valueNumeric?: number | null
          hl7filename?: string | null
          analysisDefinition?: {
            id: string
            name: string
            units?: string | null
            scaleType: ScaleType
            description: string
          } | null
          scalePoints?: Array<{ val: number; type: ScalePointType }> | null
          ranges?: Array<{ fromVal?: number | null; toVal?: number | null; type?: RangeType | null }> | null
          centileRanges?: Array<{ centile: number; value?: number | null }> | null
        }>
      }>
    } | null
  }>
}

export type LabTestOrderedProductsReadyForReportingQueryVariables = Exact<{ [key: string]: never }>

export type LabTestOrderedProductsReadyForReportingQuery = {
  labTestOrderedProductsReadyToReport: {
    __typename: 'LabTestOrderedProductsConnection'
    edges: Array<{
      node: {
        id: string
        blockedForReporting?: boolean | null
        reportStatus?: LabTestOrderedProductReportStatus | null
        reportPublishedAt?: string | null
        productDefinition:
          | { name: string }
          | { name: string }
          | { name: string }
          | { name: string }
          | { name: string }
          | { name: string }
        order?: { id: string } | null
        kitRequests: Array<{
          status: KitRequestStatus
          requestedTests: Array<{ id: string }>
          activationInfo?: { firstName?: string | null; lastName?: string | null } | null
          reportedTdlResult?: { id: string } | null
          currentTdlResult?: {
            id: string
            results: Array<{
              sampleTime?: string | null
              testCode: string
              obrStatus?: ObrStatus | null
              testDefinition?: { id: string; name: string; sample: SampleType } | null
              analyses: Array<{
                analysisCode: string
                obxStatus?: ObxStatus | null
                notes: Array<string>
                referenceRangeRaw?: string | null
                calculatedLevel?: RangeType | null
                calculatedLevelLabel?: string | null
                message?: string | null
                valueText?: string | null
                valueNumeric?: number | null
                hl7filename?: string | null
                analysisDefinition?: {
                  id: string
                  name: string
                  units?: string | null
                  scaleType: ScaleType
                  description: string
                } | null
                scalePoints?: Array<{ val: number; type: ScalePointType }> | null
                ranges?: Array<{ fromVal?: number | null; toVal?: number | null; type?: RangeType | null }> | null
                centileRanges?: Array<{ centile: number; value?: number | null }> | null
              }>
            }>
          } | null
        }>
      }
    }>
  }
}

export type TdlResultsByFilenameQueryVariables = Exact<{
  filename: Scalars['String']
}>

export type TdlResultsByFilenameQuery = {
  tdlResultByFilename?: {
    __typename: 'TdlResult'
    filename: string
    id: string
    ignored?: boolean | null
    kitId?: string | null
    labRef: string
    labStatus?: OrcStatus | null
    patientDob?: string | null
    patientSex?: string | null
    raw: string
    kit?: {
      __typename: 'Kit'
      activatedKitRequest?: {
        __typename: 'KitRequest'
        orderedProduct?: {
          __typename: 'LabTestOrderedProduct'
          id: string
          user: { id: string }
          productDefinition:
            | { name: string }
            | { name: string }
            | { name: string }
            | { name: string }
            | { name: string }
            | { name: string }
        } | null
        kitDefinition: { __typename: 'KitDefinition'; sku: string; sampleType: SampleType }
      } | null
    } | null
    patientName?: {
      __typename: 'TdlPatientName'
      familyName?: string | null
      givenName?: string | null
      middleName?: string | null
    } | null
  } | null
}

export type TdlResultsFileFragment = {
  __typename: 'TdlResult'
  filename: string
  id: string
  ignored?: boolean | null
  kitId?: string | null
  labRef: string
  labStatus?: OrcStatus | null
  patientDob?: string | null
  patientSex?: string | null
  raw: string
  kit?: {
    __typename: 'Kit'
    activatedKitRequest?: {
      __typename: 'KitRequest'
      orderedProduct?: {
        __typename: 'LabTestOrderedProduct'
        id: string
        user: { id: string }
        productDefinition:
          | { name: string }
          | { name: string }
          | { name: string }
          | { name: string }
          | { name: string }
          | { name: string }
      } | null
      kitDefinition: { __typename: 'KitDefinition'; sku: string; sampleType: SampleType }
    } | null
  } | null
  patientName?: {
    __typename: 'TdlPatientName'
    familyName?: string | null
    givenName?: string | null
    middleName?: string | null
  } | null
}

export type IgnoreHl7FileMutationVariables = Exact<{
  id: Scalars['String']
}>

export type IgnoreHl7FileMutation = { ignoreHl7: { tdlResult: { id: string; ignored?: boolean | null } } }

export type UnignoreHl7FileMutationVariables = Exact<{
  id: Scalars['String']
}>

export type UnignoreHl7FileMutation = { unignoreHl7: { tdlResult: { id: string; ignored?: boolean | null } } }

export type ParseHl7DataQueryVariables = Exact<{
  hl7: Scalars['String']
}>

export type ParseHl7DataQuery = {
  parseHl7: Array<{
    __typename: 'TestResult'
    sampleTime?: string | null
    testCode: string
    obrStatus?: ObrStatus | null
    testDefinition?: { id: string; name: string; sample: SampleType } | null
    analyses: Array<{
      analysisCode: string
      obxStatus?: ObxStatus | null
      notes: Array<string>
      referenceRangeRaw?: string | null
      calculatedLevel?: RangeType | null
      calculatedLevelLabel?: string | null
      message?: string | null
      valueText?: string | null
      valueNumeric?: number | null
      hl7filename?: string | null
      analysisDefinition?: {
        id: string
        name: string
        units?: string | null
        scaleType: ScaleType
        description: string
      } | null
      scalePoints?: Array<{ val: number; type: ScalePointType }> | null
      ranges?: Array<{ fromVal?: number | null; toVal?: number | null; type?: RangeType | null }> | null
      centileRanges?: Array<{ centile: number; value?: number | null }> | null
    }>
  }>
}

export type AmendHl7FileMutationVariables = Exact<{
  input: AmendHl7Input
}>

export type AmendHl7FileMutation = {
  amendHl7: { __typename: 'AmendHl7Response'; tdlResult: { __typename: 'TdlResult'; id: string; raw: string } }
}

export type ProductListAdminQueryVariables = Exact<{
  filter?: InputMaybe<ProductListFilter>
}>

export type ProductListAdminQuery = {
  products: Array<
    | {
        __typename: 'BespokeLabTestProduct'
        sku: string
        name: string
        slug: string
        price: number
        description: string
        featuredImage: { src: string }
      }
    | {
        __typename: 'CgmProduct'
        sku: string
        name: string
        slug: string
        price: number
        description: string
        featuredImage: { src: string }
      }
    | {
        __typename: 'CoachingProduct'
        sku: string
        name: string
        slug: string
        price: number
        description: string
        featuredImage: { src: string }
      }
    | {
        __typename: 'GiftCardProduct'
        sku: string
        name: string
        slug: string
        price: number
        description: string
        featuredImage: { src: string }
      }
    | {
        __typename: 'LabTestProduct'
        sku: string
        name: string
        slug: string
        price: number
        description: string
        testGroups: Array<{ tests: Array<{ id: string }> }>
        recommendedAddons: Array<{ testAddon: { code: string; name: string; price: number } }>
        featuredImage: { src: string }
      }
    | {
        __typename: 'SupplementProduct'
        sku: string
        name: string
        slug: string
        price: number
        description: string
        featuredImage: { src: string }
      }
  >
}

export type ProductListHekaQueryVariables = Exact<{
  filter?: InputMaybe<ProductListFilter>
}>

export type ProductListHekaQuery = {
  products: Array<
    | {
        __typename: 'BespokeLabTestProduct'
        sku: string
        name: string
        slug: string
        price: number
        description: string
      }
    | { __typename: 'CgmProduct'; sku: string; name: string; slug: string; price: number; description: string }
    | { __typename: 'CoachingProduct'; sku: string; name: string; slug: string; price: number; description: string }
    | { __typename: 'GiftCardProduct'; sku: string; name: string; slug: string; price: number; description: string }
    | {
        __typename: 'LabTestProduct'
        valueProposition: string
        biomarkers: number
        sampleLocation: SampleLocation
        analysisLocation: AnalysisLocation
        turnaroundTime: number
        sampleTypes: Array<SampleType>
        whoFor?: Array<string> | null
        sku: string
        name: string
        slug: string
        price: number
        description: string
        faqItems: Array<{ question: string; answer: string }>
        testGroups: Array<{
          name: string
          why: string
          analyses: Array<{ id: string }>
          tests: Array<{
            id: string
            name: string
            description: string
            categories: Array<string>
            sample: SampleType
            sampleTube: SampleTubeType
            turnaroundTime: number
            analyses: Array<{ id: string; name: string; description: string }>
          }>
        }>
      }
    | { __typename: 'SupplementProduct'; sku: string; name: string; slug: string; price: number; description: string }
  >
}

export type ProductListQueryVariables = Exact<{
  filter?: InputMaybe<ProductListFilter>
}>

export type ProductListQuery = {
  products: Array<
    | {
        __typename: 'BespokeLabTestProduct'
        sku: string
        name: string
        slug: string
        price: number
        description: string
        featuredImage: { src: string }
      }
    | {
        __typename: 'CgmProduct'
        sku: string
        name: string
        slug: string
        price: number
        description: string
        featuredImage: { src: string }
      }
    | {
        __typename: 'CoachingProduct'
        sku: string
        name: string
        slug: string
        price: number
        description: string
        featuredImage: { src: string }
      }
    | {
        __typename: 'GiftCardProduct'
        sku: string
        name: string
        slug: string
        price: number
        description: string
        featuredImage: { src: string }
      }
    | {
        __typename: 'LabTestProduct'
        sku: string
        name: string
        slug: string
        price: number
        description: string
        shoppingFeedImage?: { src: string } | null
        featuredImage: { src: string }
      }
    | {
        __typename: 'SupplementProduct'
        sku: string
        name: string
        slug: string
        price: number
        description: string
        featuredImage: { src: string }
      }
  >
}

export type MailchimpSaveCalculateOrderQueryVariables = Exact<{
  input: CalculateOrderInput
}>

export type MailchimpSaveCalculateOrderQuery = {
  calculateOrder: {
    __typename: 'BasketPriceDetails'
    total: number
    subTotal: number
    lines: Array<{
      quantity: number
      totalPrice: number
      product: { sku: string } | { sku: string } | { sku: string } | { sku: string } | { sku: string } | { sku: string }
      modifiers: Array<{ code: string } | { code: string }>
    }>
  }
}

export type ConfirmUserOrderMutationVariables = Exact<{
  input: ConfirmOrderInput
}>

export type ConfirmUserOrderMutation = { confirmOrder: { __typename: 'ConfirmOrderResponse'; orderId: string } }

export type CoachProductBySlugQueryVariables = Exact<{
  slug: Scalars['ID']
}>

export type CoachProductBySlugQuery = {
  productBySlug?:
    | {
        __typename: 'BespokeLabTestProduct'
        sku: string
        active: boolean
        type: ProductType
        name: string
        slug: string
        price: number
        description: string
        featuredImage: { src: string; name: string }
      }
    | {
        __typename: 'CgmProduct'
        sku: string
        active: boolean
        type: ProductType
        name: string
        slug: string
        price: number
        description: string
        featuredImage: { src: string; name: string }
      }
    | {
        __typename: 'CoachingProduct'
        sku: string
        active: boolean
        type: ProductType
        name: string
        slug: string
        price: number
        description: string
        featuredImage: { src: string; name: string }
      }
    | {
        __typename: 'GiftCardProduct'
        sku: string
        active: boolean
        type: ProductType
        name: string
        slug: string
        price: number
        description: string
        featuredImage: { src: string; name: string }
      }
    | {
        __typename: 'LabTestProduct'
        sku: string
        active: boolean
        type: ProductType
        name: string
        slug: string
        price: number
        description: string
        featuredImage: { src: string; name: string }
      }
    | {
        __typename: 'SupplementProduct'
        sku: string
        active: boolean
        type: ProductType
        name: string
        slug: string
        price: number
        description: string
        featuredImage: { src: string; name: string }
      }
    | null
}

export type CoachProductsQueryVariables = Exact<{
  filter?: InputMaybe<ProductListFilter>
}>

export type CoachProductsQuery = {
  products: Array<
    | {
        __typename: 'BespokeLabTestProduct'
        active: boolean
        description: string
        name: string
        price: number
        sku: string
        slug: string
        type: ProductType
        featuredImage: { name: string; src: string }
      }
    | {
        __typename: 'CgmProduct'
        active: boolean
        description: string
        name: string
        price: number
        sku: string
        slug: string
        type: ProductType
        featuredImage: { name: string; src: string }
      }
    | {
        __typename: 'CoachingProduct'
        active: boolean
        description: string
        name: string
        price: number
        sku: string
        slug: string
        type: ProductType
        featuredImage: { name: string; src: string }
      }
    | {
        __typename: 'GiftCardProduct'
        active: boolean
        description: string
        name: string
        price: number
        sku: string
        slug: string
        type: ProductType
        featuredImage: { name: string; src: string }
      }
    | {
        __typename: 'LabTestProduct'
        active: boolean
        description: string
        name: string
        price: number
        sku: string
        slug: string
        type: ProductType
        featuredImage: { name: string; src: string }
      }
    | {
        __typename: 'SupplementProduct'
        active: boolean
        description: string
        name: string
        price: number
        sku: string
        slug: string
        type: ProductType
        featuredImage: { name: string; src: string }
      }
  >
}

export type CgmProductBySlugQueryVariables = Exact<{
  slug: Scalars['ID']
}>

export type CgmProductBySlugQuery = {
  productBySlug?:
    | {
        __typename: 'BespokeLabTestProduct'
        sku: string
        active: boolean
        type: ProductType
        name: string
        slug: string
        price: number
        description: string
        allowPurchase: boolean
        featuredImage: { src: string; name: string }
        blogArticles: Array<{
          __typename: 'Article'
          title: string
          path: string
          slug: string
          publishedAt: string
          updatedAt?: string | null
          readingTimeMinutes: number
          categories: Array<string>
          hero: { src: string }
        }>
      }
    | {
        __typename: 'CgmProduct'
        sku: string
        active: boolean
        type: ProductType
        name: string
        slug: string
        price: number
        description: string
        allowPurchase: boolean
        featuredImage: { src: string; name: string }
        blogArticles: Array<{
          __typename: 'Article'
          title: string
          path: string
          slug: string
          publishedAt: string
          updatedAt?: string | null
          readingTimeMinutes: number
          categories: Array<string>
          hero: { src: string }
        }>
      }
    | {
        __typename: 'CoachingProduct'
        sku: string
        active: boolean
        type: ProductType
        name: string
        slug: string
        price: number
        description: string
        allowPurchase: boolean
        featuredImage: { src: string; name: string }
        blogArticles: Array<{
          __typename: 'Article'
          title: string
          path: string
          slug: string
          publishedAt: string
          updatedAt?: string | null
          readingTimeMinutes: number
          categories: Array<string>
          hero: { src: string }
        }>
      }
    | {
        __typename: 'GiftCardProduct'
        sku: string
        active: boolean
        type: ProductType
        name: string
        slug: string
        price: number
        description: string
        allowPurchase: boolean
        featuredImage: { src: string; name: string }
        blogArticles: Array<{
          __typename: 'Article'
          title: string
          path: string
          slug: string
          publishedAt: string
          updatedAt?: string | null
          readingTimeMinutes: number
          categories: Array<string>
          hero: { src: string }
        }>
      }
    | {
        __typename: 'LabTestProduct'
        sku: string
        active: boolean
        type: ProductType
        name: string
        slug: string
        price: number
        description: string
        allowPurchase: boolean
        featuredImage: { src: string; name: string }
        blogArticles: Array<{
          __typename: 'Article'
          title: string
          path: string
          slug: string
          publishedAt: string
          updatedAt?: string | null
          readingTimeMinutes: number
          categories: Array<string>
          hero: { src: string }
        }>
      }
    | {
        __typename: 'SupplementProduct'
        sku: string
        active: boolean
        type: ProductType
        name: string
        slug: string
        price: number
        description: string
        allowPurchase: boolean
        featuredImage: { src: string; name: string }
        blogArticles: Array<{
          __typename: 'Article'
          title: string
          path: string
          slug: string
          publishedAt: string
          updatedAt?: string | null
          readingTimeMinutes: number
          categories: Array<string>
          hero: { src: string }
        }>
      }
    | null
}

export type CheckBalanceQueryVariables = Exact<{
  id: Scalars['String']
}>

export type CheckBalanceQuery = {
  giftCardBalance?: { __typename: 'GiftCardBalanceResponse'; balance?: number | null } | null
}

export type GiftCardsProductsQueryVariables = Exact<{
  filter?: InputMaybe<ProductListFilter>
}>

export type GiftCardsProductsQuery = {
  products: Array<
    | {
        __typename: 'BespokeLabTestProduct'
        sku: string
        name: string
        slug: string
        type: ProductType
        price: number
        description: string
        featuredImage: { __typename: 'Image'; src: string; name: string }
      }
    | {
        __typename: 'CgmProduct'
        sku: string
        name: string
        slug: string
        type: ProductType
        price: number
        description: string
        featuredImage: { __typename: 'Image'; src: string; name: string }
      }
    | {
        __typename: 'CoachingProduct'
        sku: string
        name: string
        slug: string
        type: ProductType
        price: number
        description: string
        featuredImage: { __typename: 'Image'; src: string; name: string }
      }
    | {
        __typename: 'GiftCardProduct'
        sku: string
        name: string
        slug: string
        type: ProductType
        price: number
        description: string
        featuredImage: { __typename: 'Image'; src: string; name: string }
      }
    | {
        __typename: 'LabTestProduct'
        sku: string
        name: string
        slug: string
        type: ProductType
        price: number
        description: string
        featuredImage: { __typename: 'Image'; src: string; name: string }
      }
    | {
        __typename: 'SupplementProduct'
        sku: string
        name: string
        slug: string
        type: ProductType
        price: number
        description: string
        featuredImage: { __typename: 'Image'; src: string; name: string }
      }
  >
}

export type TestProductSummaryBySlugQueryVariables = Exact<{
  slug: Scalars['ID']
}>

export type TestProductSummaryBySlugQuery = {
  productBySlug?:
    | { __typename: 'BespokeLabTestProduct' }
    | { __typename: 'CgmProduct' }
    | { __typename: 'CoachingProduct' }
    | { __typename: 'GiftCardProduct' }
    | {
        __typename: 'LabTestProduct'
        sku: string
        name: string
        slug: string
        featuredImage: { src: string; name: string }
      }
    | { __typename: 'SupplementProduct' }
    | null
}

export type MainPageArticlesQueryVariables = Exact<{
  articleSlugs: Array<Scalars['String']> | Scalars['String']
}>

export type MainPageArticlesQuery = {
  articlesBySlug: Array<{
    __typename: 'Article'
    title: string
    path: string
    slug: string
    publishedAt: string
    updatedAt?: string | null
    readingTimeMinutes: number
    categories: Array<string>
    hero: { src: string }
  } | null>
}

export type CgmPageDataQueryVariables = Exact<{
  articleSlugs: Array<Scalars['String']> | Scalars['String']
}>

export type CgmPageDataQuery = {
  articlesBySlug: Array<{
    __typename: 'Article'
    title: string
    path: string
    slug: string
    publishedAt: string
    updatedAt?: string | null
    readingTimeMinutes: number
    categories: Array<string>
    hero: { src: string }
  } | null>
}

export type GeneralHealthPageDataQueryVariables = Exact<{
  path: Scalars['String']
  buttonSlugs: Array<Scalars['String']> | Scalars['String']
}>

export type GeneralHealthPageDataQuery = {
  allArticles: Array<{
    __typename: 'Article'
    title: string
    path: string
    slug: string
    publishedAt: string
    updatedAt?: string | null
    readingTimeMinutes: number
    categories: Array<string>
    hero: { src: string }
  }>
  headerButtons: Array<{ path: string; slug: string } | null>
}

export type GutHealthPageDataQueryVariables = Exact<{
  path: Scalars['String']
  allSlugs: Array<Scalars['String']> | Scalars['String']
}>

export type GutHealthPageDataQuery = {
  articlesByPath: Array<{
    __typename: 'Article'
    title: string
    path: string
    slug: string
    publishedAt: string
    updatedAt?: string | null
    readingTimeMinutes: number
    categories: Array<string>
    hero: { src: string }
  }>
  articlesBySlug: Array<{
    __typename: 'Article'
    title: string
    path: string
    slug: string
    publishedAt: string
    updatedAt?: string | null
    readingTimeMinutes: number
    categories: Array<string>
    hero: { src: string }
  } | null>
}

export type HealthOptimisationPageDataQueryVariables = Exact<{
  path: Scalars['String']
  buttonSlugs: Array<Scalars['String']> | Scalars['String']
}>

export type HealthOptimisationPageDataQuery = {
  allArticles: Array<{
    __typename: 'Article'
    title: string
    path: string
    slug: string
    publishedAt: string
    updatedAt?: string | null
    readingTimeMinutes: number
    categories: Array<string>
    hero: { src: string }
  }>
  headerButtons: Array<{ path: string; slug: string } | null>
}

export type LearnHubPageDataQueryVariables = Exact<{
  trendingSlugs: Array<Scalars['String']> | Scalars['String']
}>

export type LearnHubPageDataQuery = {
  allArticles: Array<{
    __typename: 'Article'
    summary: string
    title: string
    path: string
    slug: string
    publishedAt: string
    updatedAt?: string | null
    readingTimeMinutes: number
    categories: Array<string>
    hero: { src: string }
  }>
  trendingArticles: Array<{
    __typename: 'Article'
    title: string
    path: string
    slug: string
    publishedAt: string
    updatedAt?: string | null
    readingTimeMinutes: number
    categories: Array<string>
    hero: { src: string }
  } | null>
}

export type BloodSugarPageDataQueryVariables = Exact<{
  path: Scalars['String']
  buttonSlugs: Array<Scalars['String']> | Scalars['String']
}>

export type BloodSugarPageDataQuery = {
  allArticles: Array<{
    __typename: 'Article'
    title: string
    path: string
    slug: string
    publishedAt: string
    updatedAt?: string | null
    readingTimeMinutes: number
    categories: Array<string>
    hero: { src: string }
  }>
  headerButtons: Array<{ path: string; slug: string } | null>
}

export type MetabolicHealthPageDataQueryVariables = Exact<{
  path: Scalars['String']
  allSlugs: Array<Scalars['String']> | Scalars['String']
}>

export type MetabolicHealthPageDataQuery = {
  articlesByPath: Array<{
    __typename: 'Article'
    title: string
    path: string
    slug: string
    publishedAt: string
    updatedAt?: string | null
    readingTimeMinutes: number
    categories: Array<string>
    hero: { src: string }
  }>
  articlesBySlug: Array<{
    __typename: 'Article'
    title: string
    path: string
    slug: string
    publishedAt: string
    updatedAt?: string | null
    readingTimeMinutes: number
    categories: Array<string>
    hero: { src: string }
  } | null>
}

export type TestingPageDataQueryVariables = Exact<{
  path: Scalars['String']
  buttonSlugs: Array<Scalars['String']> | Scalars['String']
}>

export type TestingPageDataQuery = {
  allArticles: Array<{
    __typename: 'Article'
    title: string
    path: string
    slug: string
    publishedAt: string
    updatedAt?: string | null
    readingTimeMinutes: number
    categories: Array<string>
    hero: { src: string }
  }>
  headerButtons: Array<{ path: string; slug: string } | null>
}

export type TeamMemberBySlugQueryVariables = Exact<{
  slug: Scalars['String']
}>

export type TeamMemberBySlugQuery = {
  teamMemberBySlug?: {
    id: string
    firstName: string
    lastName: string
    slug?: string | null
    title?: string | null
    role: string
    postNominals: string
    gmc?: string | null
    miniBio: string
    fullBio?: string | null
    statementHeader?: string | null
    statement?: string | null
    features?: Array<string | null> | null
    publications?: Array<string | null> | null
    expertise?: Array<string | null> | null
    primaryImage: { name: string; src: string }
    secondaryImage?: { name: string; src: string } | null
    outdoorImage?: { name: string; src: string } | null
    articles: Array<{
      __typename: 'Article'
      title: string
      path: string
      slug: string
      publishedAt: string
      updatedAt?: string | null
      readingTimeMinutes: number
      categories: Array<string>
      hero: { src: string }
    }>
  } | null
}

export type TeamQueryVariables = Exact<{ [key: string]: never }>

export type TeamQuery = {
  team: Array<{
    id: string
    firstName: string
    lastName: string
    slug?: string | null
    title?: string | null
    miniBio: string
    role: string
    postNominals: string
    primaryImage: { name: string; src: string }
  }>
}

export type TestProductBySlugQueryVariables = Exact<{
  slug: Scalars['ID']
}>

export type TestProductBySlugQuery = {
  productBySlug?:
    | { __typename: 'BespokeLabTestProduct' }
    | { __typename: 'CgmProduct' }
    | { __typename: 'CoachingProduct' }
    | { __typename: 'GiftCardProduct' }
    | {
        __typename: 'LabTestProduct'
        sku: string
        name: string
        type: ProductType
        slug: string
        price: number
        allowPurchase: boolean
        showInListings: boolean
        description: string
        intro?: string | null
        metaDescription?: string | null
        metaTitle?: string | null
        features?: Array<string> | null
        categories: Array<string>
        valueProposition: string
        whatsTestedHeader?: string | null
        personaliseHeader?: string | null
        blogsHeader?: string | null
        faqHeader?: string | null
        whatsTestedCopy?: string | null
        biomarkers: number
        sampleLocation: SampleLocation
        analysisLocation: AnalysisLocation
        turnaroundTime: number
        sampleTypes: Array<SampleType>
        whoFor?: Array<string> | null
        spokesperson: {
          title?: string | null
          firstName: string
          lastName: string
          role: string
          headImage?: { src: string; name: string } | null
          cutoutImage?: { src: string; name: string } | null
        }
        featuredImage: { src: string; name: string }
        otherImages: Array<{ src: string; name: string }>
        shoppingFeedImage?: { src: string } | null
        blogArticles: Array<{
          __typename: 'Article'
          title: string
          path: string
          slug: string
          publishedAt: string
          updatedAt?: string | null
          readingTimeMinutes: number
          categories: Array<string>
          hero: { src: string }
        }>
        faqItems: Array<{ question: string; answer: string }>
        testGroups: Array<{
          hideAnalyses?: boolean | null
          name: string
          why: string
          analyses: Array<{ id: string }>
          tests: Array<{
            __typename: 'TestInfo'
            id: string
            name: string
            description: string
            capillary?: boolean | null
            categories: Array<string>
            sample: SampleType
            sampleTube: SampleTubeType
            turnaroundTime: number
            analyses: Array<{ id: string; name: string; description: string }>
          }>
        }>
        reviews: Array<{
          source: string
          date: string
          rating: number
          title: string
          content: string
          link: string
          author: string
        }>
        bloodCollectionOptions: Array<{
          id: string
          code: string
          name: string
          description: string
          price: number
          bloodDrawType: BloodDrawType
        }>
        recommendedAddons: Array<{
          why: string
          testAddon: {
            code: string
            price: number
            standalonePrice?: number | null
            name: string
            test: { friendlyName?: string | null; capillary?: boolean | null; sample: SampleType }
          }
        }>
      }
    | { __typename: 'SupplementProduct' }
    | null
}

export type AllTestProductsQueryVariables = Exact<{
  filter?: InputMaybe<ProductListFilter>
}>

export type AllTestProductsQuery = {
  products: Array<
    { slug: string } | { slug: string } | { slug: string } | { slug: string } | { slug: string } | { slug: string }
  >
}

export type AllBlogCardsQueryVariables = Exact<{ [key: string]: never }>

export type AllBlogCardsQuery = {
  allArticles: Array<{
    __typename: 'Article'
    title: string
    path: string
    slug: string
    publishedAt: string
    updatedAt?: string | null
    readingTimeMinutes: number
    categories: Array<string>
    hero: { src: string }
  }>
}

export type AllArticlesSitemapQueryVariables = Exact<{ [key: string]: never }>

export type AllArticlesSitemapQuery = {
  allArticles: Array<{ path: string; publishedAt: string; updatedAt?: string | null }>
}

export type AllProductsSitemapQueryVariables = Exact<{
  filter?: InputMaybe<ProductListFilter>
}>

export type AllProductsSitemapQuery = {
  products: Array<
    | { type: ProductType; slug: string; updatedAt: string }
    | { type: ProductType; slug: string; updatedAt: string }
    | { type: ProductType; slug: string; updatedAt: string }
    | { type: ProductType; slug: string; updatedAt: string }
    | { type: ProductType; slug: string; updatedAt: string }
    | { type: ProductType; slug: string; updatedAt: string }
  >
}

export type AllTeamMembersSitemapQueryVariables = Exact<{ [key: string]: never }>

export type AllTeamMembersSitemapQuery = { team: Array<{ slug?: string | null; updatedAt: string }> }

export type SupplementProductPageDataQueryVariables = Exact<{
  slug: Scalars['ID']
  articleSlugs: Array<Scalars['String']> | Scalars['String']
}>

export type SupplementProductPageDataQuery = {
  productBySlug?:
    | {
        __typename: 'BespokeLabTestProduct'
        sku: string
        active: boolean
        type: ProductType
        name: string
        slug: string
        price: number
        description: string
        allowPurchase: boolean
        featuredImage: { src: string; name: string }
      }
    | {
        __typename: 'CgmProduct'
        sku: string
        active: boolean
        type: ProductType
        name: string
        slug: string
        price: number
        description: string
        allowPurchase: boolean
        featuredImage: { src: string; name: string }
      }
    | {
        __typename: 'CoachingProduct'
        sku: string
        active: boolean
        type: ProductType
        name: string
        slug: string
        price: number
        description: string
        allowPurchase: boolean
        featuredImage: { src: string; name: string }
      }
    | {
        __typename: 'GiftCardProduct'
        sku: string
        active: boolean
        type: ProductType
        name: string
        slug: string
        price: number
        description: string
        allowPurchase: boolean
        featuredImage: { src: string; name: string }
      }
    | {
        __typename: 'LabTestProduct'
        sku: string
        active: boolean
        type: ProductType
        name: string
        slug: string
        price: number
        description: string
        allowPurchase: boolean
        featuredImage: { src: string; name: string }
      }
    | {
        __typename: 'SupplementProduct'
        sku: string
        active: boolean
        type: ProductType
        name: string
        slug: string
        price: number
        description: string
        allowPurchase: boolean
        featuredImage: { src: string; name: string }
      }
    | null
  articlesBySlug: Array<{
    __typename: 'Article'
    title: string
    path: string
    slug: string
    publishedAt: string
    updatedAt?: string | null
    readingTimeMinutes: number
    categories: Array<string>
    hero: { src: string }
  } | null>
}

export type TestProductFragment = {
  __typename: 'LabTestProduct'
  sku: string
  name: string
  type: ProductType
  slug: string
  price: number
  allowPurchase: boolean
  showInListings: boolean
  description: string
  intro?: string | null
  metaDescription?: string | null
  metaTitle?: string | null
  features?: Array<string> | null
  categories: Array<string>
  valueProposition: string
  whatsTestedHeader?: string | null
  personaliseHeader?: string | null
  blogsHeader?: string | null
  faqHeader?: string | null
  whatsTestedCopy?: string | null
  biomarkers: number
  sampleLocation: SampleLocation
  analysisLocation: AnalysisLocation
  turnaroundTime: number
  sampleTypes: Array<SampleType>
  whoFor?: Array<string> | null
  spokesperson: {
    title?: string | null
    firstName: string
    lastName: string
    role: string
    headImage?: { src: string; name: string } | null
    cutoutImage?: { src: string; name: string } | null
  }
  featuredImage: { src: string; name: string }
  otherImages: Array<{ src: string; name: string }>
  shoppingFeedImage?: { src: string } | null
  blogArticles: Array<{
    __typename: 'Article'
    title: string
    path: string
    slug: string
    publishedAt: string
    updatedAt?: string | null
    readingTimeMinutes: number
    categories: Array<string>
    hero: { src: string }
  }>
  faqItems: Array<{ question: string; answer: string }>
  testGroups: Array<{
    hideAnalyses?: boolean | null
    name: string
    why: string
    analyses: Array<{ id: string }>
    tests: Array<{
      __typename: 'TestInfo'
      id: string
      name: string
      description: string
      capillary?: boolean | null
      categories: Array<string>
      sample: SampleType
      sampleTube: SampleTubeType
      turnaroundTime: number
      analyses: Array<{ id: string; name: string; description: string }>
    }>
  }>
  reviews: Array<{
    source: string
    date: string
    rating: number
    title: string
    content: string
    link: string
    author: string
  }>
  bloodCollectionOptions: Array<{
    id: string
    code: string
    name: string
    description: string
    price: number
    bloodDrawType: BloodDrawType
  }>
  recommendedAddons: Array<{
    why: string
    testAddon: {
      code: string
      price: number
      standalonePrice?: number | null
      name: string
      test: { friendlyName?: string | null; capillary?: boolean | null; sample: SampleType }
    }
  }>
}

export type TestInfoFragment = {
  __typename: 'TestInfo'
  id: string
  name: string
  description: string
  capillary?: boolean | null
  categories: Array<string>
  sample: SampleType
  sampleTube: SampleTubeType
  turnaroundTime: number
  analyses: Array<{ id: string; name: string; description: string }>
}

export type BloodCollectionOptionsQueryVariables = Exact<{ [key: string]: never }>

export type BloodCollectionOptionsQuery = {
  bloodCollectionOptions: Array<{
    id: string
    code: string
    name: string
    description: string
    price: number
    bloodDrawType: BloodDrawType
  }>
}

export type BloodCollectionOptionsFragment = {
  __typename: 'LabTestProduct'
  bloodCollectionOptions: Array<{
    id: string
    code: string
    name: string
    description: string
    price: number
    bloodDrawType: BloodDrawType
  }>
}

export type RecommendedAddonsFragment = {
  __typename: 'LabTestProduct'
  recommendedAddons: Array<{
    why: string
    testAddon: {
      code: string
      price: number
      standalonePrice?: number | null
      name: string
      test: { friendlyName?: string | null; capillary?: boolean | null; sample: SampleType }
    }
  }>
}

export type BlogCardFragment = {
  __typename: 'Article'
  title: string
  path: string
  slug: string
  publishedAt: string
  updatedAt?: string | null
  readingTimeMinutes: number
  categories: Array<string>
  hero: { src: string }
}

export type AllAvailableTestsQueryVariables = Exact<{ [key: string]: never }>

export type AllAvailableTestsQuery = {
  testDefinitions: Array<{
    __typename: 'TestInfo'
    id: string
    name: string
    friendlyName?: string | null
    description: string
    capillary?: boolean | null
    categories: Array<string>
    sample: SampleType
    sampleTube: SampleTubeType
    turnaroundTime: number
    addonPrice?: number | null
    analyses: Array<{ id: string; name: string; description: string }>
  }>
}

export type TestsPageDataQueryVariables = Exact<{
  filter?: InputMaybe<ProductListFilter>
  articleSlugs: Array<Scalars['String']> | Scalars['String']
}>

export type TestsPageDataQuery = {
  tests: Array<
    | {
        __typename: 'BespokeLabTestProduct'
        sku: string
        name: string
        slug: string
        type: ProductType
        price: number
        description: string
      }
    | {
        __typename: 'CgmProduct'
        sku: string
        name: string
        slug: string
        type: ProductType
        price: number
        description: string
      }
    | {
        __typename: 'CoachingProduct'
        sku: string
        name: string
        slug: string
        type: ProductType
        price: number
        description: string
      }
    | {
        __typename: 'GiftCardProduct'
        sku: string
        name: string
        slug: string
        type: ProductType
        price: number
        description: string
      }
    | {
        __typename: 'LabTestProduct'
        categories: Array<string>
        biomarkers: number
        sampleLocation: SampleLocation
        turnaroundTime: number
        sampleTypes: Array<SampleType>
        sku: string
        name: string
        slug: string
        type: ProductType
        price: number
        description: string
        testGroups: Array<{ name: string }>
        bloodCollectionOptions: Array<{
          id: string
          code: string
          name: string
          description: string
          price: number
          bloodDrawType: BloodDrawType
        }>
      }
    | {
        __typename: 'SupplementProduct'
        sku: string
        name: string
        slug: string
        type: ProductType
        price: number
        description: string
      }
  >
  articles: Array<{
    __typename: 'Article'
    title: string
    path: string
    slug: string
    publishedAt: string
    updatedAt?: string | null
    readingTimeMinutes: number
    categories: Array<string>
    hero: { src: string }
  } | null>
}

export type TestProductsQueryVariables = Exact<{
  filter?: InputMaybe<ProductListFilter>
}>

export type TestProductsQuery = {
  products: Array<
    | {
        __typename: 'BespokeLabTestProduct'
        sku: string
        name: string
        slug: string
        type: ProductType
        price: number
        description: string
      }
    | {
        __typename: 'CgmProduct'
        sku: string
        name: string
        slug: string
        type: ProductType
        price: number
        description: string
      }
    | {
        __typename: 'CoachingProduct'
        sku: string
        name: string
        slug: string
        type: ProductType
        price: number
        description: string
      }
    | {
        __typename: 'GiftCardProduct'
        sku: string
        name: string
        slug: string
        type: ProductType
        price: number
        description: string
      }
    | {
        __typename: 'LabTestProduct'
        categories: Array<string>
        biomarkers: number
        sampleLocation: SampleLocation
        turnaroundTime: number
        sampleTypes: Array<SampleType>
        sku: string
        name: string
        slug: string
        type: ProductType
        price: number
        description: string
        testGroups: Array<{ name: string }>
        bloodCollectionOptions: Array<{
          id: string
          code: string
          name: string
          description: string
          price: number
          bloodDrawType: BloodDrawType
        }>
      }
    | {
        __typename: 'SupplementProduct'
        sku: string
        name: string
        slug: string
        type: ProductType
        price: number
        description: string
      }
  >
}

type LabTestProduct_BespokeLabTestProduct_Fragment = {
  __typename: 'BespokeLabTestProduct'
  sku: string
  name: string
  slug: string
  type: ProductType
  price: number
  description: string
}

type LabTestProduct_CgmProduct_Fragment = {
  __typename: 'CgmProduct'
  sku: string
  name: string
  slug: string
  type: ProductType
  price: number
  description: string
}

type LabTestProduct_CoachingProduct_Fragment = {
  __typename: 'CoachingProduct'
  sku: string
  name: string
  slug: string
  type: ProductType
  price: number
  description: string
}

type LabTestProduct_GiftCardProduct_Fragment = {
  __typename: 'GiftCardProduct'
  sku: string
  name: string
  slug: string
  type: ProductType
  price: number
  description: string
}

type LabTestProduct_LabTestProduct_Fragment = {
  __typename: 'LabTestProduct'
  categories: Array<string>
  biomarkers: number
  sampleLocation: SampleLocation
  turnaroundTime: number
  sampleTypes: Array<SampleType>
  sku: string
  name: string
  slug: string
  type: ProductType
  price: number
  description: string
  testGroups: Array<{ name: string }>
  bloodCollectionOptions: Array<{
    id: string
    code: string
    name: string
    description: string
    price: number
    bloodDrawType: BloodDrawType
  }>
}

type LabTestProduct_SupplementProduct_Fragment = {
  __typename: 'SupplementProduct'
  sku: string
  name: string
  slug: string
  type: ProductType
  price: number
  description: string
}

export type LabTestProductFragment =
  | LabTestProduct_BespokeLabTestProduct_Fragment
  | LabTestProduct_CgmProduct_Fragment
  | LabTestProduct_CoachingProduct_Fragment
  | LabTestProduct_GiftCardProduct_Fragment
  | LabTestProduct_LabTestProduct_Fragment
  | LabTestProduct_SupplementProduct_Fragment

export type CompleteUserActivationMutationVariables = Exact<{ [key: string]: never }>

export type CompleteUserActivationMutation = { completeActivation: { message?: string | null; success: boolean } }

export type ResendActivationEmailMutationVariables = Exact<{
  email: Scalars['String']
}>

export type ResendActivationEmailMutation = { resendActivationEmail: { message?: string | null; success: boolean } }

export type VerifyActivationTokenMutationVariables = Exact<{
  token: Scalars['ID']
}>

export type VerifyActivationTokenMutation = {
  verifyToken: {
    email?: string | null
    message?: string | null
    success: boolean
    outcome: VerifyTokenOutcome
    redirectTo?: string | null
  }
}

export type TestQueryQueryVariables = Exact<{ [key: string]: never }>

export type TestQueryQuery = { viewer?: { user?: { email: string } | null } | null }

export const ShipmentForPackingListFragmentDoc = gql`
  fragment ShipmentForPackingList on Shipment {
    id
    kitRequests {
      id
      packedKit {
        id
      }
      kitDefinition {
        sku
      }
      orderedProduct {
        id
        productDefinition {
          sku
          name
        }
      }
    }
    order {
      createdAt
      id
      firstName
      lastName
      user {
        id
      }
    }
    shippingAddress {
      line1
      line2
      city
      postcode
    }
  }
`
export const OrderedProductUserActionFragmentDoc = gql`
  fragment OrderedProductUserAction on LabTestOrderedProductUserAction {
    orderedProduct {
      bloodCollectionOption {
        id
        code
        name
        bloodDrawType
        clinicId
        provider
      }
      id
      reportStatus
      productDefinition {
        sku
        slug
        type
        name
      }
      order {
        id
        createdAt
      }
      kitRequests {
        id
        status
        kitDefinition {
          sku
          labCode
          sampleType
          bloodDrawType
        }
        kit {
          id
        }
        requestedTests {
          id
          instructions
          turnaroundTime
        }
        tdlResults {
          filename
          labStatus
        }
        reportedTdlResult {
          id
        }
        currentTdlResult {
          id
        }
      }
    }
  }
`
export const BlogLinkFragmentDoc = gql`
  fragment BlogLink on Article {
    __typename
    title
    path
    slug
  }
`
export const HelpfulInstructionProdInfoFragmentDoc = gql`
  fragment HelpfulInstructionProdInfo on LabTestOrderedProduct {
    __typename
    bloodCollectionOption {
      code
      provider
    }
    kitRequests {
      status
      kitDefinition {
        sampleType
      }
    }
  }
`
export const OrderedProductUserResultFragmentDoc = gql`
  fragment OrderedProductUserResult on LabTestOrderedProduct {
    id
    reportStatus
    reportPublishedAt
    productDefinition {
      sku
      slug
      type
      name
      description
    }
    order {
      id
      createdAt
    }
    kitRequests {
      id
      status
      activationInfo {
        firstName
        lastName
        dob
      }
      kitDefinition {
        sku
        sampleType
      }
      kit {
        id
        activatedAt
      }
      requestedTests {
        id
        name
        description
        turnaroundTime
      }
      tdlResults {
        filename
        labStatus
      }
      reportedTdlResult {
        id
      }
      currentTdlResult {
        id
      }
    }
  }
`
export const OrderDiscountFragmentDoc = gql`
  fragment OrderDiscount on BasketDiscount {
    __typename
    amount
    code
    description
    failureMessage
  }
`
export const BlogCardFragmentDoc = gql`
  fragment BlogCard on Article {
    __typename
    title
    path
    slug
    publishedAt
    updatedAt
    readingTimeMinutes
    categories
    hero {
      src
    }
  }
`
export const TestInfoFragmentDoc = gql`
  fragment TestInfo on TestInfo {
    __typename
    id
    name
    description
    capillary
    categories
    sample
    sampleTube
    analyses {
      id
      name
      description
    }
    turnaroundTime
  }
`
export const BloodCollectionOptionsFragmentDoc = gql`
  fragment BloodCollectionOptions on LabTestProduct {
    __typename
    bloodCollectionOptions {
      id
      code
      name
      description
      price
      bloodDrawType
    }
  }
`
export const RecommendedAddonsFragmentDoc = gql`
  fragment RecommendedAddons on LabTestProduct {
    __typename
    recommendedAddons {
      testAddon {
        code
        price
        standalonePrice
        name
        test {
          friendlyName
          capillary
          sample
        }
      }
      why
    }
  }
`
export const TestProductFragmentDoc = gql`
  fragment TestProduct on LabTestProduct {
    __typename
    sku
    name
    type
    slug
    price
    allowPurchase
    showInListings
    description
    intro
    metaDescription
    metaTitle
    features
    spokesperson {
      title
      firstName
      lastName
      role
      headImage {
        src
        name
      }
      cutoutImage {
        src
        name
      }
    }
    featuredImage {
      src
      name
    }
    otherImages {
      src
      name
    }
    shoppingFeedImage {
      src
    }
    categories
    valueProposition
    whatsTestedHeader
    personaliseHeader
    blogsHeader
    blogArticles {
      ...BlogCard
    }
    faqHeader
    faqItems {
      question
      answer
    }
    whatsTestedCopy
    testGroups {
      hideAnalyses
      analyses {
        id
      }
      name
      tests {
        ...TestInfo
      }
      why
    }
    biomarkers
    sampleLocation
    analysisLocation
    turnaroundTime
    sampleTypes
    whoFor
    reviews {
      source
      date
      rating
      title
      content
      link
      author
    }
    ...BloodCollectionOptions
    ...RecommendedAddons
  }
  ${BlogCardFragmentDoc}
  ${TestInfoFragmentDoc}
  ${BloodCollectionOptionsFragmentDoc}
  ${RecommendedAddonsFragmentDoc}
`
export const ProductFragmentDoc = gql`
  fragment Product on Product {
    __typename
    sku
    name
    slug
    description
    active
    price
    type
    featuredImage {
      src
      name
    }
    ...TestProduct
  }
  ${TestProductFragmentDoc}
`
export const BasketLineModifiersFragmentDoc = gql`
  fragment BasketLineModifiers on BasketLine {
    __typename
    modifiers {
      code
      name
      price
    }
  }
`
export const LineFragmentDoc = gql`
  fragment Line on BasketLine {
    __typename
    product {
      ...Product
    }
    quantity
    totalPrice
    ...BasketLineModifiers
  }
  ${ProductFragmentDoc}
  ${BasketLineModifiersFragmentDoc}
`
export const CheckoutOrderFragmentDoc = gql`
  fragment CheckoutOrder on BasketPriceDetails {
    __typename
    discount {
      amount
      code
      description
      failureMessage
    }
    lines {
      product {
        ...Product
      }
      quantity
      totalPrice
    }
    requiresShipping
    requiresAccountActivation
    subTotal
    total
  }
  ${ProductFragmentDoc}
`
export const CustomerOrderedProductFragmentDoc = gql`
  fragment CustomerOrderedProduct on Product {
    name
    type
    slug
    price
    description
    featuredImage {
      src
      name
    }
  }
`
export const CustomerOrderSummaryFragmentDoc = gql`
  fragment CustomerOrderSummary on Order {
    id
    createdAt
    orderPriceDetails {
      lines {
        product {
          sku
          ...CustomerOrderedProduct
        }
        quantity
        totalPrice
        modifiers {
          name
          code
          price
        }
      }
      subTotal
      total
    }
    shipments {
      dispatchedAt
      trackingNo
    }
    salesChannel
  }
  ${CustomerOrderedProductFragmentDoc}
`
export const ArticleTeamMemberFragmentDoc = gql`
  fragment ArticleTeamMember on TeamMember {
    firstName
    lastName
    title
    postNominals
    role
    primaryImage {
      src
      name
    }
  }
`
export const ArticleAttributesFragmentDoc = gql`
  fragment ArticleAttributes on Article {
    title
    metaTitle
    template
    publishedAt
    updatedAt
    categories
    takeaways
    takeawayHeader
    summary
    hero {
      src
    }
    author {
      ...ArticleTeamMember
    }
    reviewer {
      ...ArticleTeamMember
    }
    relatedArticles {
      ...BlogCard
    }
    relatedProducts {
      __typename
      sku
      name
      type
      slug
      featuredImage {
        src
      }
    }
    body
  }
  ${ArticleTeamMemberFragmentDoc}
  ${BlogCardFragmentDoc}
`
export const AnalysisFragmentDoc = gql`
  fragment Analysis on AnalysisResult {
    analysisCode
    analysisDefinition {
      id
      name
      units
      scaleType
      scaleType
      description
    }
    obxStatus
    notes
    scalePoints {
      val
      type
    }
    ranges {
      fromVal
      toVal
      type
    }
    referenceRangeRaw
    calculatedLevel
    calculatedLevelLabel
    message
    valueText
    valueNumeric
    centileRanges {
      centile
      value
    }
    hl7filename
  }
`
export const UserResultFragmentDoc = gql`
  fragment UserResult on TestResult {
    sampleTime
    testCode
    testDefinition {
      id
      name
      sample
    }
    obrStatus
    analyses {
      ...Analysis
    }
  }
  ${AnalysisFragmentDoc}
`
export const UserTdlResultFragmentDoc = gql`
  fragment UserTdlResult on KitRequest {
    currentTdlResult {
      id
      results {
        ...UserResult
      }
      cumulativeResults {
        ...UserResult
      }
    }
    reportedTdlResult {
      id
      results {
        ...UserResult
      }
      cumulativeResults {
        ...UserResult
      }
    }
  }
  ${UserResultFragmentDoc}
`
export const UserLabTestOrderedProductFragmentDoc = gql`
  fragment UserLabTestOrderedProduct on LabTestOrderedProduct {
    id
    reportStatus
    reportText
    reportPublishedAt
    reportRecommendations
    reportAnalysesToExclude
    productDefinition {
      sku
      name
    }
    order {
      id
      createdAt
    }
    kitRequests {
      status
      activationInfo {
        firstName
        lastName
        dob
      }
      kit {
        id
      }
      kitDefinition {
        sku
        sampleType
      }
      requestedTests {
        id
        name
      }
      ...UserTdlResult
    }
  }
  ${UserTdlResultFragmentDoc}
`
export const UserAnalysisFragmentDoc = gql`
  fragment UserAnalysis on AnalysisResult {
    analysisCode
    analysisDefinition {
      id
      name
      units
      scaleType
      description
    }
    obxStatus
    notes
    ranges {
      fromVal
      toVal
      type
    }
    scalePoints {
      val
      type
    }
    referenceRangeRaw
    calculatedLevel
    calculatedLevelLabel
    message
    valueText
    valueNumeric
    centileRanges {
      centile
      value
    }
    hl7filename
  }
`
export const GiftCardOrderDetailsFragmentDoc = gql`
  fragment GiftCardOrderDetails on GiftCardOrderedProduct {
    giftCard {
      id
      startingBalance
    }
  }
`
export const CustomerOrderDetailedFragmentDoc = gql`
  fragment CustomerOrderDetailed on Order {
    id
    createdAt
    firstName
    lastName
    phone
    user {
      email
      id
    }
    shipments {
      id
      shippingAddress {
        line1
        line2
        postcode
        city
      }
    }
    orderedProducts {
      __typename
      id
      ... on GiftCardOrderedProduct {
        ...GiftCardOrderDetails
      }
    }
    orderPriceDetails {
      discount {
        amount
        code
        description
        failureMessage
      }
      lines {
        capturedProductName
        capturedProductPrice
        product {
          sku
          ...CustomerOrderedProduct
        }
        quantity
        totalPrice
        modifiers {
          code
          name
          price
        }
      }
      subTotal
      total
    }
    salesChannel
  }
  ${GiftCardOrderDetailsFragmentDoc}
  ${CustomerOrderedProductFragmentDoc}
`
export const UserHistoryAnalysisFragmentDoc = gql`
  fragment UserHistoryAnalysis on AnalysisResult {
    analysisCode
    obxStatus
    notes
    referenceRangeRaw
    calculatedLevel
    calculatedLevelLabel
    message
    valueText
    valueNumeric
    analysisDefinition {
      id
      name
      units
      scaleType
      description
    }
    ranges {
      fromVal
      toVal
      type
    }
    scalePoints {
      val
      type
    }
  }
`
export const UserHistoryTdlResultFragmentDoc = gql`
  fragment UserHistoryTdlResult on TestResult {
    testCode
    sampleTime
    obrStatus
    testDefinition {
      id
      name
    }
    analyses {
      ...UserHistoryAnalysis
    }
  }
  ${UserHistoryAnalysisFragmentDoc}
`
export const UserHistoryKitRequestFragmentDoc = gql`
  fragment UserHistoryKitRequest on KitRequest {
    id
    kitDefinition {
      sampleType
    }
    reportedTdlResult {
      id
      results {
        ...UserHistoryTdlResult
      }
    }
  }
  ${UserHistoryTdlResultFragmentDoc}
`
export const UserHistoryLabTestFragmentDoc = gql`
  fragment UserHistoryLabTest on LabTestOrderedProduct {
    __typename
    id
    reportStatus
    reportPublishedAt
    reportAnalysesToExclude
    kitRequests {
      ...UserHistoryKitRequest
    }
  }
  ${UserHistoryKitRequestFragmentDoc}
`
export const UserHistoryProductFragmentDoc = gql`
  fragment UserHistoryProduct on OrderedProduct {
    __typename
    id
    ... on LabTestOrderedProduct {
      __typename
      id
      ...UserHistoryLabTest
    }
  }
  ${UserHistoryLabTestFragmentDoc}
`
export const UserHistoryOrdersFragmentDoc = gql`
  fragment UserHistoryOrders on Order {
    id
    orderedProducts {
      __typename
      id
      ...UserHistoryProduct
    }
  }
  ${UserHistoryProductFragmentDoc}
`
export const ReportVersionFragmentDoc = gql`
  fragment ReportVersion on ReportVersion {
    id
    recommendations
    report
    status
    updatedAt
    publishedAt
    accessLog {
      time
      user {
        id
        firstName
        lastName
      }
    }
    authorInfo {
      firstName
      lastName
      primaryImage {
        name
        src
      }
      title
      role
    }
  }
`
export const CgmOrderedProductFragmentDoc = gql`
  fragment CgmOrderedProduct on CgmOrderedProduct {
    __typename
    id
    productDefinition {
      name
    }
    user {
      email
      id
      friendlyName
      firstName
      lastName
    }
    order {
      id
      createdAt
      firstName
      lastName
      user {
        email
        id
      }
      shipments {
        shippingAddress {
          line1
          line2
          postcode
          city
        }
      }
    }
    analysisRuns {
      id
      fromTimestamp
      toTimestamp
      analyses {
        analysisCode
        calculatedLevel
        calculatedCentile
        analysisDefinition {
          code
          name
          description
          units
          scale
          max
          min
        }
        value
        definedRanges {
          fromVal
          toVal
          level
        }
        centileRanges {
          centile
          value
        }
      }
    }
    latestReportVersion {
      ...ReportVersion
    }
  }
  ${ReportVersionFragmentDoc}
`
export const UserInfoFragmentDoc = gql`
  fragment UserInfo on User {
    id
    friendlyName
    firstName
    lastName
    dob
    biologicalSex
    phoneNumber
    email
    activated
    activationCodeCreatedAt
    orderedProducts {
      id
      createdAt
      __typename
      ... on LabTestOrderedProduct {
        reportStatus
        reportPublishedAt
      }
      ... on CgmOrderedProduct {
        publishedReportVersion {
          publishedAt
        }
      }
      productDefinition {
        sku
        name
      }
    }
    orders {
      id
      createdAt
      firstName
      lastName
      orderPriceDetails {
        total
        lines {
          product {
            name
          }
          quantity
        }
      }
      salesChannel
    }
  }
`
export const AdminOrderedProductFragmentDoc = gql`
  fragment AdminOrderedProduct on Product {
    __typename
    sku
    name
    type
    slug
    price
    description
    featuredImage {
      src
      name
    }
    ... on LabTestProduct {
      testGroups {
        why
        tests {
          id
          name
          description
          sample
          sampleTube
          analyses {
            id
            name
            description
          }
          turnaroundTime
        }
      }
    }
  }
`
export const LabTestDataFragmentDoc = gql`
  fragment LabTestData on LabTestProduct {
    __typename
    sampleTypes
    testGroups {
      name
      tests {
        ...TestInfo
      }
    }
    ...BloodCollectionOptions
  }
  ${TestInfoFragmentDoc}
  ${BloodCollectionOptionsFragmentDoc}
`
export const AdminDownloadLabTestOrderedProductFragmentDoc = gql`
  fragment AdminDownloadLabTestOrderedProduct on LabTestOrderedProduct {
    __typename
    id
    reportStatus
    reportPublishedAt
    productDefinition {
      name
      sku
    }
    kitRequests {
      status
      activationInfo {
        dob
        biologicalSex
      }
      kit {
        activatedAt
      }
      kitDefinition {
        sampleType
      }
    }
    bloodCollectionOption {
      code
    }
  }
`
export const KitRequestsProductDefinitionFragmentDoc = gql`
  fragment KitRequestsProductDefinition on LabTestOrderedProduct {
    __typename
    productDefinition {
      __typename
      type
      name
      ... on LabTestProduct {
        testGroups {
          name
          tests {
            ...TestInfo
          }
        }
      }
    }
  }
  ${TestInfoFragmentDoc}
`
export const ResultFragmentDoc = gql`
  fragment Result on TestResult {
    sampleTime
    testCode
    testDefinition {
      id
      name
      sample
    }
    obrStatus
    analyses {
      ...Analysis
    }
  }
  ${AnalysisFragmentDoc}
`
export const TdlResultFragmentDoc = gql`
  fragment TdlResult on KitRequest {
    reportedTdlResult {
      id
      results {
        ...Result
      }
      cumulativeResults {
        ...Result
      }
    }
    currentTdlResult {
      id
      filename
      labRef
      labStatus
      patientName {
        familyName
        givenName
      }
      patientDob
      patientSex
      results {
        ...Result
      }
      cumulativeResults {
        ...Result
      }
    }
  }
  ${ResultFragmentDoc}
`
export const LabTestOrderedProductFragmentDoc = gql`
  fragment LabTestOrderedProduct on LabTestOrderedProduct {
    __typename
    id
    canceled
    blockedForReporting
    reportStatus
    reportText
    reportPublishedAt
    reportRecommendations
    reportAnalysesToExclude
    user {
      email
      id
      friendlyName
      firstName
      lastName
    }
    bloodCollectionOption {
      code
    }
    productDefinition {
      sku
      name
    }
    order {
      id
      createdAt
      firstName
      lastName
      shipments {
        shippingAddress {
          line1
          line2
          postcode
          city
        }
      }
    }
    kitRequests {
      id
      status
      canceled
      rawRequestCodes
      activationInfo {
        firstName
        lastName
        dob
        sampleDate
        biologicalSex
        trackingNumber
        shippingLatestEvent
        extraData
      }
      requestedTests {
        id
        name
      }
      kitDefinition {
        sku
        sampleType
      }
      kit {
        id
        activatedAt
        canceled
        notes {
          id
          createdAt
          author
          note
          subject
        }
      }
      orderedProduct {
        id
        ...KitRequestsProductDefinition
      }
      ...TdlResult
    }
  }
  ${KitRequestsProductDefinitionFragmentDoc}
  ${TdlResultFragmentDoc}
`
export const HistoryAnalysisFragmentDoc = gql`
  fragment HistoryAnalysis on AnalysisResult {
    analysisCode
    valueNumeric
    valueText
    analysisDefinition {
      id
      name
      units
    }
    calculatedLevelLabel
    calculatedLevel
  }
`
export const HistoryLabTestFragmentDoc = gql`
  fragment HistoryLabTest on LabTestOrderedProduct {
    id
    __typename
    kitRequests {
      id
      reportedTdlResult {
        cumulativeResults {
          testCode
          sampleTime
          analyses {
            ...HistoryAnalysis
          }
        }
      }
    }
  }
  ${HistoryAnalysisFragmentDoc}
`
export const HistoryProductFragmentDoc = gql`
  fragment HistoryProduct on OrderedProduct {
    id
    __typename
    ...HistoryLabTest
  }
  ${HistoryLabTestFragmentDoc}
`
export const HistoryOrderFragmentDoc = gql`
  fragment HistoryOrder on Order {
    id
    orderedProducts {
      id
      __typename
      ...HistoryProduct
    }
  }
  ${HistoryProductFragmentDoc}
`
export const LabTestTdlResultsFragmentDoc = gql`
  fragment LabTestTdlResults on LabTestOrderedProduct {
    __typename
    id
    canceled
    productDefinition {
      __typename
      name
    }
    order {
      __typename
      id
    }
    kitRequests {
      __typename
      kit {
        __typename
        id
      }
      kitDefinition {
        __typename
        name
        sku
        sampleType
      }
      tdlResults {
        __typename
        id
        filename
        labRef
        labStatus
        ignored
        results {
          __typename
          testCode
        }
      }
    }
  }
`
export const LabTestOrderedProductSummaryFragmentDoc = gql`
  fragment LabTestOrderedProductSummary on LabTestOrderedProduct {
    __typename
    id
    canceled
    reportStatus
    reportPublishedAt
    productDefinition {
      name
    }
    order {
      id
    }
    kitRequests {
      id
      status
      canceled
      activationInfo {
        firstName
        lastName
        shippingLatestEvent
        trackingNumber
      }
      requestedTests {
        id
        name
        turnaroundTime
      }
      kitDefinition {
        sku
        sampleType
      }
      kit {
        id
        activatedAt
        canceled
        notes {
          id
          createdAt
          author
          subject
          note
        }
      }
      orderedProduct {
        id
        ...KitRequestsProductDefinition
      }
      tdlResults {
        filename
        labStatus
      }
      ...TdlResult
    }
  }
  ${KitRequestsProductDefinitionFragmentDoc}
  ${TdlResultFragmentDoc}
`
export const TdlResultsFileFragmentDoc = gql`
  fragment TdlResultsFile on TdlResult {
    __typename
    filename
    id
    ignored
    kitId
    kit {
      __typename
      activatedKitRequest {
        __typename
        orderedProduct {
          __typename
          id
          user {
            id
          }
          productDefinition {
            name
          }
        }
        kitDefinition {
          __typename
          sku
          sampleType
        }
      }
    }
    labRef
    labStatus
    patientName {
      __typename
      familyName
      givenName
      middleName
    }
    patientDob
    patientSex
    raw
  }
`
export const LabTestProductFragmentDoc = gql`
  fragment LabTestProduct on Product {
    __typename
    sku
    name
    slug
    type
    price
    description
    ... on LabTestProduct {
      categories
      biomarkers
      sampleLocation
      turnaroundTime
      sampleTypes
      testGroups {
        name
      }
      ...BloodCollectionOptions
    }
  }
  ${BloodCollectionOptionsFragmentDoc}
`
export const UpdateKitActivationInfoDocument = gql`
  mutation UpdateKitActivationInfo($input: UpdateKitActivationInfoInput!) {
    updateKitActivationInfo(input: $input) {
      kit {
        id
        activatedKitRequest {
          id
          activationInfo {
            firstName
            lastName
            dob
            biologicalSex
          }
        }
      }
    }
  }
`
export type UpdateKitActivationInfoMutationFn = Apollo.MutationFunction<
  UpdateKitActivationInfoMutation,
  UpdateKitActivationInfoMutationVariables
>

/**
 * __useUpdateKitActivationInfoMutation__
 *
 * To run a mutation, you first call `useUpdateKitActivationInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateKitActivationInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateKitActivationInfoMutation, { data, loading, error }] = useUpdateKitActivationInfoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateKitActivationInfoMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateKitActivationInfoMutation, UpdateKitActivationInfoMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateKitActivationInfoMutation, UpdateKitActivationInfoMutationVariables>(
    UpdateKitActivationInfoDocument,
    options,
  )
}
export type UpdateKitActivationInfoMutationHookResult = ReturnType<typeof useUpdateKitActivationInfoMutation>
export type UpdateKitActivationInfoMutationResult = Apollo.MutationResult<UpdateKitActivationInfoMutation>
export type UpdateKitActivationInfoMutationOptions = Apollo.BaseMutationOptions<
  UpdateKitActivationInfoMutation,
  UpdateKitActivationInfoMutationVariables
>
export const CalculateRequestDocument = gql`
  query calculateRequest($codes: [ID!]!, $bloodDrawType: BloodDrawType) {
    calculateRequest(codes: $codes, bloodDrawType: $bloodDrawType) {
      kitSku
      requestCodes
    }
  }
`

/**
 * __useCalculateRequestQuery__
 *
 * To run a query within a React component, call `useCalculateRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalculateRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalculateRequestQuery({
 *   variables: {
 *      codes: // value for 'codes'
 *      bloodDrawType: // value for 'bloodDrawType'
 *   },
 * });
 */
export function useCalculateRequestQuery(
  baseOptions: Apollo.QueryHookOptions<CalculateRequestQuery, CalculateRequestQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CalculateRequestQuery, CalculateRequestQueryVariables>(CalculateRequestDocument, options)
}
export function useCalculateRequestLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CalculateRequestQuery, CalculateRequestQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CalculateRequestQuery, CalculateRequestQueryVariables>(CalculateRequestDocument, options)
}
export type CalculateRequestQueryHookResult = ReturnType<typeof useCalculateRequestQuery>
export type CalculateRequestLazyQueryHookResult = ReturnType<typeof useCalculateRequestLazyQuery>
export type CalculateRequestQueryResult = Apollo.QueryResult<CalculateRequestQuery, CalculateRequestQueryVariables>
export const RepeatLabTestDocument = gql`
  mutation RepeatLabTest($input: RepeatLabTestInput!) {
    repeatLabTest(input: $input) {
      success
      message
      shipment {
        id
        status
      }
      orderedProducts {
        id
        kitRequests {
          id
        }
      }
    }
  }
`
export type RepeatLabTestMutationFn = Apollo.MutationFunction<RepeatLabTestMutation, RepeatLabTestMutationVariables>

/**
 * __useRepeatLabTestMutation__
 *
 * To run a mutation, you first call `useRepeatLabTestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRepeatLabTestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [repeatLabTestMutation, { data, loading, error }] = useRepeatLabTestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRepeatLabTestMutation(
  baseOptions?: Apollo.MutationHookOptions<RepeatLabTestMutation, RepeatLabTestMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RepeatLabTestMutation, RepeatLabTestMutationVariables>(RepeatLabTestDocument, options)
}
export type RepeatLabTestMutationHookResult = ReturnType<typeof useRepeatLabTestMutation>
export type RepeatLabTestMutationResult = Apollo.MutationResult<RepeatLabTestMutation>
export type RepeatLabTestMutationOptions = Apollo.BaseMutationOptions<
  RepeatLabTestMutation,
  RepeatLabTestMutationVariables
>
export const ShipmentsReadyToShipCountDocument = gql`
  query ShipmentsReadyToShipCount {
    shipmentsReadyToShipCount
  }
`

/**
 * __useShipmentsReadyToShipCountQuery__
 *
 * To run a query within a React component, call `useShipmentsReadyToShipCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useShipmentsReadyToShipCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShipmentsReadyToShipCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useShipmentsReadyToShipCountQuery(
  baseOptions?: Apollo.QueryHookOptions<ShipmentsReadyToShipCountQuery, ShipmentsReadyToShipCountQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ShipmentsReadyToShipCountQuery, ShipmentsReadyToShipCountQueryVariables>(
    ShipmentsReadyToShipCountDocument,
    options,
  )
}
export function useShipmentsReadyToShipCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ShipmentsReadyToShipCountQuery, ShipmentsReadyToShipCountQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ShipmentsReadyToShipCountQuery, ShipmentsReadyToShipCountQueryVariables>(
    ShipmentsReadyToShipCountDocument,
    options,
  )
}
export type ShipmentsReadyToShipCountQueryHookResult = ReturnType<typeof useShipmentsReadyToShipCountQuery>
export type ShipmentsReadyToShipCountLazyQueryHookResult = ReturnType<typeof useShipmentsReadyToShipCountLazyQuery>
export type ShipmentsReadyToShipCountQueryResult = Apollo.QueryResult<
  ShipmentsReadyToShipCountQuery,
  ShipmentsReadyToShipCountQueryVariables
>
export const AdminCreateShipmentBatchDocument = gql`
  mutation AdminCreateShipmentBatch {
    createShipmentBatch {
      message
      shipmentBatch {
        createdAt
        createdByEmail
        id
        shipmentCount
        shipments {
          id
          kitRequests {
            id
            packedKit {
              id
            }
            kitDefinition {
              sku
            }
            orderedProduct {
              id
              productDefinition {
                sku
                name
              }
            }
          }
          order {
            createdAt
            id
            phone
            firstName
            lastName
            user {
              email
            }
            orderPriceDetails {
              subTotal
              total
            }
          }
          shippingAddress {
            line1
            line2
            city
            postcode
          }
          status
        }
      }
      success
    }
  }
`
export type AdminCreateShipmentBatchMutationFn = Apollo.MutationFunction<
  AdminCreateShipmentBatchMutation,
  AdminCreateShipmentBatchMutationVariables
>

/**
 * __useAdminCreateShipmentBatchMutation__
 *
 * To run a mutation, you first call `useAdminCreateShipmentBatchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminCreateShipmentBatchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminCreateShipmentBatchMutation, { data, loading, error }] = useAdminCreateShipmentBatchMutation({
 *   variables: {
 *   },
 * });
 */
export function useAdminCreateShipmentBatchMutation(
  baseOptions?: Apollo.MutationHookOptions<AdminCreateShipmentBatchMutation, AdminCreateShipmentBatchMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<AdminCreateShipmentBatchMutation, AdminCreateShipmentBatchMutationVariables>(
    AdminCreateShipmentBatchDocument,
    options,
  )
}
export type AdminCreateShipmentBatchMutationHookResult = ReturnType<typeof useAdminCreateShipmentBatchMutation>
export type AdminCreateShipmentBatchMutationResult = Apollo.MutationResult<AdminCreateShipmentBatchMutation>
export type AdminCreateShipmentBatchMutationOptions = Apollo.BaseMutationOptions<
  AdminCreateShipmentBatchMutation,
  AdminCreateShipmentBatchMutationVariables
>
export const MarkShipmentDispatchedDocument = gql`
  mutation MarkShipmentDispatched($input: MarkShipmentDispatchedInput!) {
    markShipmentDispatched(input: $input) {
      message
      success
    }
  }
`
export type MarkShipmentDispatchedMutationFn = Apollo.MutationFunction<
  MarkShipmentDispatchedMutation,
  MarkShipmentDispatchedMutationVariables
>

/**
 * __useMarkShipmentDispatchedMutation__
 *
 * To run a mutation, you first call `useMarkShipmentDispatchedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkShipmentDispatchedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markShipmentDispatchedMutation, { data, loading, error }] = useMarkShipmentDispatchedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarkShipmentDispatchedMutation(
  baseOptions?: Apollo.MutationHookOptions<MarkShipmentDispatchedMutation, MarkShipmentDispatchedMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<MarkShipmentDispatchedMutation, MarkShipmentDispatchedMutationVariables>(
    MarkShipmentDispatchedDocument,
    options,
  )
}
export type MarkShipmentDispatchedMutationHookResult = ReturnType<typeof useMarkShipmentDispatchedMutation>
export type MarkShipmentDispatchedMutationResult = Apollo.MutationResult<MarkShipmentDispatchedMutation>
export type MarkShipmentDispatchedMutationOptions = Apollo.BaseMutationOptions<
  MarkShipmentDispatchedMutation,
  MarkShipmentDispatchedMutationVariables
>
export const MarkKitPackedDocument = gql`
  mutation MarkKitPacked($input: MarkKitPackedInput!) {
    markKitPacked(input: $input) {
      success
      kitRequest {
        id
        packedKit {
          id
        }
      }
    }
  }
`
export type MarkKitPackedMutationFn = Apollo.MutationFunction<MarkKitPackedMutation, MarkKitPackedMutationVariables>

/**
 * __useMarkKitPackedMutation__
 *
 * To run a mutation, you first call `useMarkKitPackedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkKitPackedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markKitPackedMutation, { data, loading, error }] = useMarkKitPackedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarkKitPackedMutation(
  baseOptions?: Apollo.MutationHookOptions<MarkKitPackedMutation, MarkKitPackedMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<MarkKitPackedMutation, MarkKitPackedMutationVariables>(MarkKitPackedDocument, options)
}
export type MarkKitPackedMutationHookResult = ReturnType<typeof useMarkKitPackedMutation>
export type MarkKitPackedMutationResult = Apollo.MutationResult<MarkKitPackedMutation>
export type MarkKitPackedMutationOptions = Apollo.BaseMutationOptions<
  MarkKitPackedMutation,
  MarkKitPackedMutationVariables
>
export const AllTestsDocument = gql`
  query allTests {
    testDefinitions {
      id
      name
      sample
      capillary
      addonPrice
    }
  }
`

/**
 * __useAllTestsQuery__
 *
 * To run a query within a React component, call `useAllTestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTestsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllTestsQuery(baseOptions?: Apollo.QueryHookOptions<AllTestsQuery, AllTestsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AllTestsQuery, AllTestsQueryVariables>(AllTestsDocument, options)
}
export function useAllTestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllTestsQuery, AllTestsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AllTestsQuery, AllTestsQueryVariables>(AllTestsDocument, options)
}
export type AllTestsQueryHookResult = ReturnType<typeof useAllTestsQuery>
export type AllTestsLazyQueryHookResult = ReturnType<typeof useAllTestsLazyQuery>
export type AllTestsQueryResult = Apollo.QueryResult<AllTestsQuery, AllTestsQueryVariables>
export const UpdateKitRequestsDocument = gql`
  mutation UpdateKitRequests($input: UpdateKitRequestRequests!) {
    updateKitRequestRequests(input: $input) {
      kitRequest {
        id
        rawRequestCodes
      }
    }
  }
`
export type UpdateKitRequestsMutationFn = Apollo.MutationFunction<
  UpdateKitRequestsMutation,
  UpdateKitRequestsMutationVariables
>

/**
 * __useUpdateKitRequestsMutation__
 *
 * To run a mutation, you first call `useUpdateKitRequestsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateKitRequestsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateKitRequestsMutation, { data, loading, error }] = useUpdateKitRequestsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateKitRequestsMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateKitRequestsMutation, UpdateKitRequestsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateKitRequestsMutation, UpdateKitRequestsMutationVariables>(
    UpdateKitRequestsDocument,
    options,
  )
}
export type UpdateKitRequestsMutationHookResult = ReturnType<typeof useUpdateKitRequestsMutation>
export type UpdateKitRequestsMutationResult = Apollo.MutationResult<UpdateKitRequestsMutation>
export type UpdateKitRequestsMutationOptions = Apollo.BaseMutationOptions<
  UpdateKitRequestsMutation,
  UpdateKitRequestsMutationVariables
>
export const ProductRelatedContentDocument = gql`
  query productRelatedContent {
    viewer {
      user {
        orders {
          orderPriceDetails {
            lines {
              product {
                blogArticles {
                  ...BlogCard
                }
              }
            }
          }
        }
      }
    }
  }
  ${BlogCardFragmentDoc}
`

/**
 * __useProductRelatedContentQuery__
 *
 * To run a query within a React component, call `useProductRelatedContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductRelatedContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductRelatedContentQuery({
 *   variables: {
 *   },
 * });
 */
export function useProductRelatedContentQuery(
  baseOptions?: Apollo.QueryHookOptions<ProductRelatedContentQuery, ProductRelatedContentQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ProductRelatedContentQuery, ProductRelatedContentQueryVariables>(
    ProductRelatedContentDocument,
    options,
  )
}
export function useProductRelatedContentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProductRelatedContentQuery, ProductRelatedContentQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ProductRelatedContentQuery, ProductRelatedContentQueryVariables>(
    ProductRelatedContentDocument,
    options,
  )
}
export type ProductRelatedContentQueryHookResult = ReturnType<typeof useProductRelatedContentQuery>
export type ProductRelatedContentLazyQueryHookResult = ReturnType<typeof useProductRelatedContentLazyQuery>
export type ProductRelatedContentQueryResult = Apollo.QueryResult<
  ProductRelatedContentQuery,
  ProductRelatedContentQueryVariables
>
export const AvailableActionsDocument = gql`
  query AvailableActions {
    viewer {
      availableActions {
        __typename
        createdAt
        ... on LabTestOrderedProductUserAction {
          ...OrderedProductUserAction
        }
      }
    }
  }
  ${OrderedProductUserActionFragmentDoc}
`

/**
 * __useAvailableActionsQuery__
 *
 * To run a query within a React component, call `useAvailableActionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableActionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableActionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAvailableActionsQuery(
  baseOptions?: Apollo.QueryHookOptions<AvailableActionsQuery, AvailableActionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AvailableActionsQuery, AvailableActionsQueryVariables>(AvailableActionsDocument, options)
}
export function useAvailableActionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AvailableActionsQuery, AvailableActionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AvailableActionsQuery, AvailableActionsQueryVariables>(AvailableActionsDocument, options)
}
export type AvailableActionsQueryHookResult = ReturnType<typeof useAvailableActionsQuery>
export type AvailableActionsLazyQueryHookResult = ReturnType<typeof useAvailableActionsLazyQuery>
export type AvailableActionsQueryResult = Apollo.QueryResult<AvailableActionsQuery, AvailableActionsQueryVariables>
export const CgmCardDataDocument = gql`
  query CgmCardData($articleSlugs: [String!]!) {
    viewer {
      user {
        id
        orderedProducts {
          __typename
          id
          ...CgmOrderedProduct
        }
      }
    }
    articlesBySlug(slugs: $articleSlugs) {
      ...BlogLink
    }
  }
  ${CgmOrderedProductFragmentDoc}
  ${BlogLinkFragmentDoc}
`

/**
 * __useCgmCardDataQuery__
 *
 * To run a query within a React component, call `useCgmCardDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useCgmCardDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCgmCardDataQuery({
 *   variables: {
 *      articleSlugs: // value for 'articleSlugs'
 *   },
 * });
 */
export function useCgmCardDataQuery(baseOptions: Apollo.QueryHookOptions<CgmCardDataQuery, CgmCardDataQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CgmCardDataQuery, CgmCardDataQueryVariables>(CgmCardDataDocument, options)
}
export function useCgmCardDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CgmCardDataQuery, CgmCardDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CgmCardDataQuery, CgmCardDataQueryVariables>(CgmCardDataDocument, options)
}
export type CgmCardDataQueryHookResult = ReturnType<typeof useCgmCardDataQuery>
export type CgmCardDataLazyQueryHookResult = ReturnType<typeof useCgmCardDataLazyQuery>
export type CgmCardDataQueryResult = Apollo.QueryResult<CgmCardDataQuery, CgmCardDataQueryVariables>
export const HelpfulInstructionsDocument = gql`
  query HelpfulInstructions {
    viewer {
      user {
        orderedProducts {
          __typename
          id
          ... on LabTestOrderedProduct {
            ...HelpfulInstructionProdInfo
          }
        }
      }
    }
  }
  ${HelpfulInstructionProdInfoFragmentDoc}
`

/**
 * __useHelpfulInstructionsQuery__
 *
 * To run a query within a React component, call `useHelpfulInstructionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHelpfulInstructionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHelpfulInstructionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useHelpfulInstructionsQuery(
  baseOptions?: Apollo.QueryHookOptions<HelpfulInstructionsQuery, HelpfulInstructionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<HelpfulInstructionsQuery, HelpfulInstructionsQueryVariables>(
    HelpfulInstructionsDocument,
    options,
  )
}
export function useHelpfulInstructionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<HelpfulInstructionsQuery, HelpfulInstructionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<HelpfulInstructionsQuery, HelpfulInstructionsQueryVariables>(
    HelpfulInstructionsDocument,
    options,
  )
}
export type HelpfulInstructionsQueryHookResult = ReturnType<typeof useHelpfulInstructionsQuery>
export type HelpfulInstructionsLazyQueryHookResult = ReturnType<typeof useHelpfulInstructionsLazyQuery>
export type HelpfulInstructionsQueryResult = Apollo.QueryResult<
  HelpfulInstructionsQuery,
  HelpfulInstructionsQueryVariables
>
export const AvailableResultsDocument = gql`
  query AvailableResults {
    viewer {
      results {
        ...OrderedProductUserResult
      }
    }
  }
  ${OrderedProductUserResultFragmentDoc}
`

/**
 * __useAvailableResultsQuery__
 *
 * To run a query within a React component, call `useAvailableResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableResultsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAvailableResultsQuery(
  baseOptions?: Apollo.QueryHookOptions<AvailableResultsQuery, AvailableResultsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AvailableResultsQuery, AvailableResultsQueryVariables>(AvailableResultsDocument, options)
}
export function useAvailableResultsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AvailableResultsQuery, AvailableResultsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AvailableResultsQuery, AvailableResultsQueryVariables>(AvailableResultsDocument, options)
}
export type AvailableResultsQueryHookResult = ReturnType<typeof useAvailableResultsQuery>
export type AvailableResultsLazyQueryHookResult = ReturnType<typeof useAvailableResultsLazyQuery>
export type AvailableResultsQueryResult = Apollo.QueryResult<AvailableResultsQuery, AvailableResultsQueryVariables>
export const ProductBySkuDocument = gql`
  query productBySku($sku: ID!) {
    productBySku(sku: $sku) {
      __typename
      sku
      name
      allowPurchase
      description
      price
      type
      slug
      featuredImage {
        name
        src
      }
    }
  }
`

/**
 * __useProductBySkuQuery__
 *
 * To run a query within a React component, call `useProductBySkuQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductBySkuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductBySkuQuery({
 *   variables: {
 *      sku: // value for 'sku'
 *   },
 * });
 */
export function useProductBySkuQuery(
  baseOptions: Apollo.QueryHookOptions<ProductBySkuQuery, ProductBySkuQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ProductBySkuQuery, ProductBySkuQueryVariables>(ProductBySkuDocument, options)
}
export function useProductBySkuLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProductBySkuQuery, ProductBySkuQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ProductBySkuQuery, ProductBySkuQueryVariables>(ProductBySkuDocument, options)
}
export type ProductBySkuQueryHookResult = ReturnType<typeof useProductBySkuQuery>
export type ProductBySkuLazyQueryHookResult = ReturnType<typeof useProductBySkuLazyQuery>
export type ProductBySkuQueryResult = Apollo.QueryResult<ProductBySkuQuery, ProductBySkuQueryVariables>
export const CheckoutCalculateOrderDocument = gql`
  query CheckoutCalculateOrder($input: CalculateOrderInput!) {
    calculateOrder(input: $input) {
      __typename
      lines {
        ...Line
      }
      total
      subTotal
      requiresShipping
      requiresAccountActivation
      discount {
        ...OrderDiscount
      }
    }
  }
  ${LineFragmentDoc}
  ${OrderDiscountFragmentDoc}
`

/**
 * __useCheckoutCalculateOrderQuery__
 *
 * To run a query within a React component, call `useCheckoutCalculateOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckoutCalculateOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckoutCalculateOrderQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCheckoutCalculateOrderQuery(
  baseOptions: Apollo.QueryHookOptions<CheckoutCalculateOrderQuery, CheckoutCalculateOrderQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CheckoutCalculateOrderQuery, CheckoutCalculateOrderQueryVariables>(
    CheckoutCalculateOrderDocument,
    options,
  )
}
export function useCheckoutCalculateOrderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CheckoutCalculateOrderQuery, CheckoutCalculateOrderQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CheckoutCalculateOrderQuery, CheckoutCalculateOrderQueryVariables>(
    CheckoutCalculateOrderDocument,
    options,
  )
}
export type CheckoutCalculateOrderQueryHookResult = ReturnType<typeof useCheckoutCalculateOrderQuery>
export type CheckoutCalculateOrderLazyQueryHookResult = ReturnType<typeof useCheckoutCalculateOrderLazyQuery>
export type CheckoutCalculateOrderQueryResult = Apollo.QueryResult<
  CheckoutCalculateOrderQuery,
  CheckoutCalculateOrderQueryVariables
>
export const CheckoutPlaceOrderDocument = gql`
  mutation CheckoutPlaceOrder($input: PlaceOrderInput!) {
    placeOrder(input: $input) {
      clientSecret
      orderId
      orderPriceDetails {
        ...CheckoutOrder
      }
      success
    }
  }
  ${CheckoutOrderFragmentDoc}
`
export type CheckoutPlaceOrderMutationFn = Apollo.MutationFunction<
  CheckoutPlaceOrderMutation,
  CheckoutPlaceOrderMutationVariables
>

/**
 * __useCheckoutPlaceOrderMutation__
 *
 * To run a mutation, you first call `useCheckoutPlaceOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckoutPlaceOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkoutPlaceOrderMutation, { data, loading, error }] = useCheckoutPlaceOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCheckoutPlaceOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<CheckoutPlaceOrderMutation, CheckoutPlaceOrderMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CheckoutPlaceOrderMutation, CheckoutPlaceOrderMutationVariables>(
    CheckoutPlaceOrderDocument,
    options,
  )
}
export type CheckoutPlaceOrderMutationHookResult = ReturnType<typeof useCheckoutPlaceOrderMutation>
export type CheckoutPlaceOrderMutationResult = Apollo.MutationResult<CheckoutPlaceOrderMutation>
export type CheckoutPlaceOrderMutationOptions = Apollo.BaseMutationOptions<
  CheckoutPlaceOrderMutation,
  CheckoutPlaceOrderMutationVariables
>
export const DownloadReportDocument = gql`
  mutation downloadReport($tdlResultId: ID!) {
    downloadReportPdf(tdlResultId: $tdlResultId) {
      url
    }
  }
`
export type DownloadReportMutationFn = Apollo.MutationFunction<DownloadReportMutation, DownloadReportMutationVariables>

/**
 * __useDownloadReportMutation__
 *
 * To run a mutation, you first call `useDownloadReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDownloadReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [downloadReportMutation, { data, loading, error }] = useDownloadReportMutation({
 *   variables: {
 *      tdlResultId: // value for 'tdlResultId'
 *   },
 * });
 */
export function useDownloadReportMutation(
  baseOptions?: Apollo.MutationHookOptions<DownloadReportMutation, DownloadReportMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DownloadReportMutation, DownloadReportMutationVariables>(DownloadReportDocument, options)
}
export type DownloadReportMutationHookResult = ReturnType<typeof useDownloadReportMutation>
export type DownloadReportMutationResult = Apollo.MutationResult<DownloadReportMutation>
export type DownloadReportMutationOptions = Apollo.BaseMutationOptions<
  DownloadReportMutation,
  DownloadReportMutationVariables
>
export const LoginRequiredToOrderDocument = gql`
  query LoginRequiredToOrder($email: String!) {
    loginRequiredToOrder(email: $email)
  }
`

/**
 * __useLoginRequiredToOrderQuery__
 *
 * To run a query within a React component, call `useLoginRequiredToOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoginRequiredToOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoginRequiredToOrderQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useLoginRequiredToOrderQuery(
  baseOptions: Apollo.QueryHookOptions<LoginRequiredToOrderQuery, LoginRequiredToOrderQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<LoginRequiredToOrderQuery, LoginRequiredToOrderQueryVariables>(
    LoginRequiredToOrderDocument,
    options,
  )
}
export function useLoginRequiredToOrderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LoginRequiredToOrderQuery, LoginRequiredToOrderQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<LoginRequiredToOrderQuery, LoginRequiredToOrderQueryVariables>(
    LoginRequiredToOrderDocument,
    options,
  )
}
export type LoginRequiredToOrderQueryHookResult = ReturnType<typeof useLoginRequiredToOrderQuery>
export type LoginRequiredToOrderLazyQueryHookResult = ReturnType<typeof useLoginRequiredToOrderLazyQuery>
export type LoginRequiredToOrderQueryResult = Apollo.QueryResult<
  LoginRequiredToOrderQuery,
  LoginRequiredToOrderQueryVariables
>
export const UserInfoDocument = gql`
  query UserInfo {
    viewer {
      user {
        email
        firstName
        lastName
        friendlyName
        dob
        phoneNumber
        biologicalSex
      }
    }
  }
`

/**
 * __useUserInfoQuery__
 *
 * To run a query within a React component, call `useUserInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserInfoQuery(baseOptions?: Apollo.QueryHookOptions<UserInfoQuery, UserInfoQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<UserInfoQuery, UserInfoQueryVariables>(UserInfoDocument, options)
}
export function useUserInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserInfoQuery, UserInfoQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<UserInfoQuery, UserInfoQueryVariables>(UserInfoDocument, options)
}
export type UserInfoQueryHookResult = ReturnType<typeof useUserInfoQuery>
export type UserInfoLazyQueryHookResult = ReturnType<typeof useUserInfoLazyQuery>
export type UserInfoQueryResult = Apollo.QueryResult<UserInfoQuery, UserInfoQueryVariables>
export const ArticleDataBySlugDocument = gql`
  query articleDataBySlug($slug: String!) {
    articleBySlug(slug: $slug) {
      __typename
      ...ArticleAttributes
    }
  }
  ${ArticleAttributesFragmentDoc}
`

/**
 * __useArticleDataBySlugQuery__
 *
 * To run a query within a React component, call `useArticleDataBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticleDataBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticleDataBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useArticleDataBySlugQuery(
  baseOptions: Apollo.QueryHookOptions<ArticleDataBySlugQuery, ArticleDataBySlugQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ArticleDataBySlugQuery, ArticleDataBySlugQueryVariables>(ArticleDataBySlugDocument, options)
}
export function useArticleDataBySlugLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ArticleDataBySlugQuery, ArticleDataBySlugQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ArticleDataBySlugQuery, ArticleDataBySlugQueryVariables>(
    ArticleDataBySlugDocument,
    options,
  )
}
export type ArticleDataBySlugQueryHookResult = ReturnType<typeof useArticleDataBySlugQuery>
export type ArticleDataBySlugLazyQueryHookResult = ReturnType<typeof useArticleDataBySlugLazyQuery>
export type ArticleDataBySlugQueryResult = Apollo.QueryResult<ArticleDataBySlugQuery, ArticleDataBySlugQueryVariables>
export const AllArticlesPathsDocument = gql`
  query AllArticlesPaths {
    allArticles {
      path
    }
  }
`

/**
 * __useAllArticlesPathsQuery__
 *
 * To run a query within a React component, call `useAllArticlesPathsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllArticlesPathsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllArticlesPathsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllArticlesPathsQuery(
  baseOptions?: Apollo.QueryHookOptions<AllArticlesPathsQuery, AllArticlesPathsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AllArticlesPathsQuery, AllArticlesPathsQueryVariables>(AllArticlesPathsDocument, options)
}
export function useAllArticlesPathsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AllArticlesPathsQuery, AllArticlesPathsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AllArticlesPathsQuery, AllArticlesPathsQueryVariables>(AllArticlesPathsDocument, options)
}
export type AllArticlesPathsQueryHookResult = ReturnType<typeof useAllArticlesPathsQuery>
export type AllArticlesPathsLazyQueryHookResult = ReturnType<typeof useAllArticlesPathsLazyQuery>
export type AllArticlesPathsQueryResult = Apollo.QueryResult<AllArticlesPathsQuery, AllArticlesPathsQueryVariables>
export const UserOrderedProductByIdDocument = gql`
  query userOrderedProductById($id: ID!) {
    orderedProductById(id: $id) {
      id
      __typename
      ... on CgmOrderedProduct {
        ...CgmOrderedProduct
      }
      ... on LabTestOrderedProduct {
        ...UserLabTestOrderedProduct
      }
    }
  }
  ${CgmOrderedProductFragmentDoc}
  ${UserLabTestOrderedProductFragmentDoc}
`

/**
 * __useUserOrderedProductByIdQuery__
 *
 * To run a query within a React component, call `useUserOrderedProductByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserOrderedProductByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserOrderedProductByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserOrderedProductByIdQuery(
  baseOptions: Apollo.QueryHookOptions<UserOrderedProductByIdQuery, UserOrderedProductByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<UserOrderedProductByIdQuery, UserOrderedProductByIdQueryVariables>(
    UserOrderedProductByIdDocument,
    options,
  )
}
export function useUserOrderedProductByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserOrderedProductByIdQuery, UserOrderedProductByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<UserOrderedProductByIdQuery, UserOrderedProductByIdQueryVariables>(
    UserOrderedProductByIdDocument,
    options,
  )
}
export type UserOrderedProductByIdQueryHookResult = ReturnType<typeof useUserOrderedProductByIdQuery>
export type UserOrderedProductByIdLazyQueryHookResult = ReturnType<typeof useUserOrderedProductByIdLazyQuery>
export type UserOrderedProductByIdQueryResult = Apollo.QueryResult<
  UserOrderedProductByIdQuery,
  UserOrderedProductByIdQueryVariables
>
export const UserOrderHistoryDocument = gql`
  query userOrderHistory {
    viewer {
      user {
        orders {
          id
          ...HistoryOrder
        }
      }
    }
  }
  ${HistoryOrderFragmentDoc}
`

/**
 * __useUserOrderHistoryQuery__
 *
 * To run a query within a React component, call `useUserOrderHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserOrderHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserOrderHistoryQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserOrderHistoryQuery(
  baseOptions?: Apollo.QueryHookOptions<UserOrderHistoryQuery, UserOrderHistoryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<UserOrderHistoryQuery, UserOrderHistoryQueryVariables>(UserOrderHistoryDocument, options)
}
export function useUserOrderHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserOrderHistoryQuery, UserOrderHistoryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<UserOrderHistoryQuery, UserOrderHistoryQueryVariables>(UserOrderHistoryDocument, options)
}
export type UserOrderHistoryQueryHookResult = ReturnType<typeof useUserOrderHistoryQuery>
export type UserOrderHistoryLazyQueryHookResult = ReturnType<typeof useUserOrderHistoryLazyQuery>
export type UserOrderHistoryQueryResult = Apollo.QueryResult<UserOrderHistoryQuery, UserOrderHistoryQueryVariables>
export const ClaimOffPlatformKitDocument = gql`
  mutation claimOffPlatformKit($input: ClaimOffPlatformKitInput!) {
    claimOffPlatformKit(input: $input) {
      kitRequest {
        id
      }
    }
  }
`
export type ClaimOffPlatformKitMutationFn = Apollo.MutationFunction<
  ClaimOffPlatformKitMutation,
  ClaimOffPlatformKitMutationVariables
>

/**
 * __useClaimOffPlatformKitMutation__
 *
 * To run a mutation, you first call `useClaimOffPlatformKitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClaimOffPlatformKitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [claimOffPlatformKitMutation, { data, loading, error }] = useClaimOffPlatformKitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useClaimOffPlatformKitMutation(
  baseOptions?: Apollo.MutationHookOptions<ClaimOffPlatformKitMutation, ClaimOffPlatformKitMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ClaimOffPlatformKitMutation, ClaimOffPlatformKitMutationVariables>(
    ClaimOffPlatformKitDocument,
    options,
  )
}
export type ClaimOffPlatformKitMutationHookResult = ReturnType<typeof useClaimOffPlatformKitMutation>
export type ClaimOffPlatformKitMutationResult = Apollo.MutationResult<ClaimOffPlatformKitMutation>
export type ClaimOffPlatformKitMutationOptions = Apollo.BaseMutationOptions<
  ClaimOffPlatformKitMutation,
  ClaimOffPlatformKitMutationVariables
>
export const FriendlyUserNameDocument = gql`
  query friendlyUserName {
    viewer {
      user {
        friendlyName
      }
    }
  }
`

/**
 * __useFriendlyUserNameQuery__
 *
 * To run a query within a React component, call `useFriendlyUserNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useFriendlyUserNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFriendlyUserNameQuery({
 *   variables: {
 *   },
 * });
 */
export function useFriendlyUserNameQuery(
  baseOptions?: Apollo.QueryHookOptions<FriendlyUserNameQuery, FriendlyUserNameQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FriendlyUserNameQuery, FriendlyUserNameQueryVariables>(FriendlyUserNameDocument, options)
}
export function useFriendlyUserNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FriendlyUserNameQuery, FriendlyUserNameQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FriendlyUserNameQuery, FriendlyUserNameQueryVariables>(FriendlyUserNameDocument, options)
}
export type FriendlyUserNameQueryHookResult = ReturnType<typeof useFriendlyUserNameQuery>
export type FriendlyUserNameLazyQueryHookResult = ReturnType<typeof useFriendlyUserNameLazyQuery>
export type FriendlyUserNameQueryResult = Apollo.QueryResult<FriendlyUserNameQuery, FriendlyUserNameQueryVariables>
export const CustomerOrderByIdDocument = gql`
  query customerOrderById($id: ID!) {
    orderById(orderId: $id) {
      ...CustomerOrderDetailed
    }
  }
  ${CustomerOrderDetailedFragmentDoc}
`

/**
 * __useCustomerOrderByIdQuery__
 *
 * To run a query within a React component, call `useCustomerOrderByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerOrderByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerOrderByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCustomerOrderByIdQuery(
  baseOptions: Apollo.QueryHookOptions<CustomerOrderByIdQuery, CustomerOrderByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CustomerOrderByIdQuery, CustomerOrderByIdQueryVariables>(CustomerOrderByIdDocument, options)
}
export function useCustomerOrderByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CustomerOrderByIdQuery, CustomerOrderByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CustomerOrderByIdQuery, CustomerOrderByIdQueryVariables>(
    CustomerOrderByIdDocument,
    options,
  )
}
export type CustomerOrderByIdQueryHookResult = ReturnType<typeof useCustomerOrderByIdQuery>
export type CustomerOrderByIdLazyQueryHookResult = ReturnType<typeof useCustomerOrderByIdLazyQuery>
export type CustomerOrderByIdQueryResult = Apollo.QueryResult<CustomerOrderByIdQuery, CustomerOrderByIdQueryVariables>
export const OrderByIdDocument = gql`
  query orderById($id: ID!) {
    orderById(orderId: $id) {
      id
      createdAt
      firstName
      lastName
      phone
      user {
        id
        email
      }
      shipments {
        id
        shippingAddress {
          line1
          line2
          postcode
          city
        }
      }
      orderPriceDetails {
        discount {
          amount
          code
          description
          failureMessage
        }
        lines {
          product {
            ...TestProduct
          }
          quantity
          totalPrice
          modifiers {
            code
            name
            price
          }
        }
        subTotal
        total
      }
    }
  }
  ${TestProductFragmentDoc}
`

/**
 * __useOrderByIdQuery__
 *
 * To run a query within a React component, call `useOrderByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrderByIdQuery(baseOptions: Apollo.QueryHookOptions<OrderByIdQuery, OrderByIdQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<OrderByIdQuery, OrderByIdQueryVariables>(OrderByIdDocument, options)
}
export function useOrderByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrderByIdQuery, OrderByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<OrderByIdQuery, OrderByIdQueryVariables>(OrderByIdDocument, options)
}
export type OrderByIdQueryHookResult = ReturnType<typeof useOrderByIdQuery>
export type OrderByIdLazyQueryHookResult = ReturnType<typeof useOrderByIdLazyQuery>
export type OrderByIdQueryResult = Apollo.QueryResult<OrderByIdQuery, OrderByIdQueryVariables>
export const CustomerOrdersDocument = gql`
  query customerOrders {
    viewer {
      user {
        id
        email
        orders {
          ...CustomerOrderSummary
        }
      }
    }
  }
  ${CustomerOrderSummaryFragmentDoc}
`

/**
 * __useCustomerOrdersQuery__
 *
 * To run a query within a React component, call `useCustomerOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerOrdersQuery({
 *   variables: {
 *   },
 * });
 */
export function useCustomerOrdersQuery(
  baseOptions?: Apollo.QueryHookOptions<CustomerOrdersQuery, CustomerOrdersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CustomerOrdersQuery, CustomerOrdersQueryVariables>(CustomerOrdersDocument, options)
}
export function useCustomerOrdersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CustomerOrdersQuery, CustomerOrdersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CustomerOrdersQuery, CustomerOrdersQueryVariables>(CustomerOrdersDocument, options)
}
export type CustomerOrdersQueryHookResult = ReturnType<typeof useCustomerOrdersQuery>
export type CustomerOrdersLazyQueryHookResult = ReturnType<typeof useCustomerOrdersLazyQuery>
export type CustomerOrdersQueryResult = Apollo.QueryResult<CustomerOrdersQuery, CustomerOrdersQueryVariables>
export const GetUserResultsDocument = gql`
  query getUserResults {
    viewer {
      user {
        id
        friendlyName
        orders {
          id
          ...UserHistoryOrders
        }
      }
    }
  }
  ${UserHistoryOrdersFragmentDoc}
`

/**
 * __useGetUserResultsQuery__
 *
 * To run a query within a React component, call `useGetUserResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserResultsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserResultsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetUserResultsQuery, GetUserResultsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetUserResultsQuery, GetUserResultsQueryVariables>(GetUserResultsDocument, options)
}
export function useGetUserResultsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUserResultsQuery, GetUserResultsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetUserResultsQuery, GetUserResultsQueryVariables>(GetUserResultsDocument, options)
}
export type GetUserResultsQueryHookResult = ReturnType<typeof useGetUserResultsQuery>
export type GetUserResultsLazyQueryHookResult = ReturnType<typeof useGetUserResultsLazyQuery>
export type GetUserResultsQueryResult = Apollo.QueryResult<GetUserResultsQuery, GetUserResultsQueryVariables>
export const GetUserDetailsDocument = gql`
  query getUserDetails {
    viewer {
      user {
        id
        email
        friendlyName
        firstName
        lastName
        dob
        biologicalSex
      }
    }
  }
`

/**
 * __useGetUserDetailsQuery__
 *
 * To run a query within a React component, call `useGetUserDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserDetailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserDetailsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetUserDetailsQuery, GetUserDetailsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetUserDetailsQuery, GetUserDetailsQueryVariables>(GetUserDetailsDocument, options)
}
export function useGetUserDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUserDetailsQuery, GetUserDetailsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetUserDetailsQuery, GetUserDetailsQueryVariables>(GetUserDetailsDocument, options)
}
export type GetUserDetailsQueryHookResult = ReturnType<typeof useGetUserDetailsQuery>
export type GetUserDetailsLazyQueryHookResult = ReturnType<typeof useGetUserDetailsLazyQuery>
export type GetUserDetailsQueryResult = Apollo.QueryResult<GetUserDetailsQuery, GetUserDetailsQueryVariables>
export const ChangeUserDetailsDocument = gql`
  mutation ChangeUserDetails($input: ChangeUserDetailsInput!) {
    changeUserDetails(input: $input) {
      user {
        id
        firstName
        lastName
        friendlyName
        dob
        biologicalSex
      }
    }
  }
`
export type ChangeUserDetailsMutationFn = Apollo.MutationFunction<
  ChangeUserDetailsMutation,
  ChangeUserDetailsMutationVariables
>

/**
 * __useChangeUserDetailsMutation__
 *
 * To run a mutation, you first call `useChangeUserDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeUserDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeUserDetailsMutation, { data, loading, error }] = useChangeUserDetailsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeUserDetailsMutation(
  baseOptions?: Apollo.MutationHookOptions<ChangeUserDetailsMutation, ChangeUserDetailsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ChangeUserDetailsMutation, ChangeUserDetailsMutationVariables>(
    ChangeUserDetailsDocument,
    options,
  )
}
export type ChangeUserDetailsMutationHookResult = ReturnType<typeof useChangeUserDetailsMutation>
export type ChangeUserDetailsMutationResult = Apollo.MutationResult<ChangeUserDetailsMutation>
export type ChangeUserDetailsMutationOptions = Apollo.BaseMutationOptions<
  ChangeUserDetailsMutation,
  ChangeUserDetailsMutationVariables
>
export const ActivateKitDocument = gql`
  mutation ActivateKit($input: ActivateKitInput!) {
    activateKit(input: $input) {
      message
      success
      kit {
        id
      }
    }
  }
`
export type ActivateKitMutationFn = Apollo.MutationFunction<ActivateKitMutation, ActivateKitMutationVariables>

/**
 * __useActivateKitMutation__
 *
 * To run a mutation, you first call `useActivateKitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateKitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateKitMutation, { data, loading, error }] = useActivateKitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useActivateKitMutation(
  baseOptions?: Apollo.MutationHookOptions<ActivateKitMutation, ActivateKitMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ActivateKitMutation, ActivateKitMutationVariables>(ActivateKitDocument, options)
}
export type ActivateKitMutationHookResult = ReturnType<typeof useActivateKitMutation>
export type ActivateKitMutationResult = Apollo.MutationResult<ActivateKitMutation>
export type ActivateKitMutationOptions = Apollo.BaseMutationOptions<ActivateKitMutation, ActivateKitMutationVariables>
export const KitActivationStatusDocument = gql`
  query KitActivationStatus($kitId: ID!) {
    kitActivationStatus(kitId: $kitId) {
      canActivate
      reason
      offPlatformPurchase
    }
  }
`

/**
 * __useKitActivationStatusQuery__
 *
 * To run a query within a React component, call `useKitActivationStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useKitActivationStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKitActivationStatusQuery({
 *   variables: {
 *      kitId: // value for 'kitId'
 *   },
 * });
 */
export function useKitActivationStatusQuery(
  baseOptions: Apollo.QueryHookOptions<KitActivationStatusQuery, KitActivationStatusQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<KitActivationStatusQuery, KitActivationStatusQueryVariables>(
    KitActivationStatusDocument,
    options,
  )
}
export function useKitActivationStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<KitActivationStatusQuery, KitActivationStatusQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<KitActivationStatusQuery, KitActivationStatusQueryVariables>(
    KitActivationStatusDocument,
    options,
  )
}
export type KitActivationStatusQueryHookResult = ReturnType<typeof useKitActivationStatusQuery>
export type KitActivationStatusLazyQueryHookResult = ReturnType<typeof useKitActivationStatusLazyQuery>
export type KitActivationStatusQueryResult = Apollo.QueryResult<
  KitActivationStatusQuery,
  KitActivationStatusQueryVariables
>
export const CustomerKitRequestByIdDocument = gql`
  query CustomerKitRequestById($id: ID!) {
    kitRequestById(id: $id) {
      id
      status
      kitDefinition {
        bloodDrawType
        sku
        labCode
        sampleType
        sampleTubes
        contents
      }
      requestedTests {
        id
        name
        instructions
      }
      orderedProduct {
        productDefinition {
          name
        }
        bloodCollectionOption {
          id
          code
          bloodDrawType
          provider
          clinicId
        }
        user {
          email
          phoneNumber
        }
        order {
          phone
          shipments {
            shippingAddress {
              city
              postcode
              line1
              line2
            }
          }
        }
      }
    }
  }
`

/**
 * __useCustomerKitRequestByIdQuery__
 *
 * To run a query within a React component, call `useCustomerKitRequestByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerKitRequestByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerKitRequestByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCustomerKitRequestByIdQuery(
  baseOptions: Apollo.QueryHookOptions<CustomerKitRequestByIdQuery, CustomerKitRequestByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CustomerKitRequestByIdQuery, CustomerKitRequestByIdQueryVariables>(
    CustomerKitRequestByIdDocument,
    options,
  )
}
export function useCustomerKitRequestByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CustomerKitRequestByIdQuery, CustomerKitRequestByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CustomerKitRequestByIdQuery, CustomerKitRequestByIdQueryVariables>(
    CustomerKitRequestByIdDocument,
    options,
  )
}
export type CustomerKitRequestByIdQueryHookResult = ReturnType<typeof useCustomerKitRequestByIdQuery>
export type CustomerKitRequestByIdLazyQueryHookResult = ReturnType<typeof useCustomerKitRequestByIdLazyQuery>
export type CustomerKitRequestByIdQueryResult = Apollo.QueryResult<
  CustomerKitRequestByIdQuery,
  CustomerKitRequestByIdQueryVariables
>
export const WriteCgmReportDocument = gql`
  mutation writeCgmReport($input: WriteCgmReportInput!) {
    writeCgmReport(input: $input) {
      reportVersion {
        ...ReportVersion
      }
    }
  }
  ${ReportVersionFragmentDoc}
`
export type WriteCgmReportMutationFn = Apollo.MutationFunction<WriteCgmReportMutation, WriteCgmReportMutationVariables>

/**
 * __useWriteCgmReportMutation__
 *
 * To run a mutation, you first call `useWriteCgmReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWriteCgmReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [writeCgmReportMutation, { data, loading, error }] = useWriteCgmReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWriteCgmReportMutation(
  baseOptions?: Apollo.MutationHookOptions<WriteCgmReportMutation, WriteCgmReportMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<WriteCgmReportMutation, WriteCgmReportMutationVariables>(WriteCgmReportDocument, options)
}
export type WriteCgmReportMutationHookResult = ReturnType<typeof useWriteCgmReportMutation>
export type WriteCgmReportMutationResult = Apollo.MutationResult<WriteCgmReportMutation>
export type WriteCgmReportMutationOptions = Apollo.BaseMutationOptions<
  WriteCgmReportMutation,
  WriteCgmReportMutationVariables
>
export const UnpublishCgmReportDocument = gql`
  mutation unpublishCgmReport($input: UnpublishReportVersionInput!) {
    unpublishReportVersion(input: $input) {
      reportVersion {
        ...ReportVersion
      }
    }
  }
  ${ReportVersionFragmentDoc}
`
export type UnpublishCgmReportMutationFn = Apollo.MutationFunction<
  UnpublishCgmReportMutation,
  UnpublishCgmReportMutationVariables
>

/**
 * __useUnpublishCgmReportMutation__
 *
 * To run a mutation, you first call `useUnpublishCgmReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnpublishCgmReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unpublishCgmReportMutation, { data, loading, error }] = useUnpublishCgmReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnpublishCgmReportMutation(
  baseOptions?: Apollo.MutationHookOptions<UnpublishCgmReportMutation, UnpublishCgmReportMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UnpublishCgmReportMutation, UnpublishCgmReportMutationVariables>(
    UnpublishCgmReportDocument,
    options,
  )
}
export type UnpublishCgmReportMutationHookResult = ReturnType<typeof useUnpublishCgmReportMutation>
export type UnpublishCgmReportMutationResult = Apollo.MutationResult<UnpublishCgmReportMutation>
export type UnpublishCgmReportMutationOptions = Apollo.BaseMutationOptions<
  UnpublishCgmReportMutation,
  UnpublishCgmReportMutationVariables
>
export const CgmOrderedProductByIdDocument = gql`
  query cgmOrderedProductById($id: ID!) {
    orderedProductById(id: $id) {
      __typename
      id
      ... on CgmOrderedProduct {
        ...CgmOrderedProduct
      }
    }
  }
  ${CgmOrderedProductFragmentDoc}
`

/**
 * __useCgmOrderedProductByIdQuery__
 *
 * To run a query within a React component, call `useCgmOrderedProductByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useCgmOrderedProductByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCgmOrderedProductByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCgmOrderedProductByIdQuery(
  baseOptions: Apollo.QueryHookOptions<CgmOrderedProductByIdQuery, CgmOrderedProductByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CgmOrderedProductByIdQuery, CgmOrderedProductByIdQueryVariables>(
    CgmOrderedProductByIdDocument,
    options,
  )
}
export function useCgmOrderedProductByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CgmOrderedProductByIdQuery, CgmOrderedProductByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CgmOrderedProductByIdQuery, CgmOrderedProductByIdQueryVariables>(
    CgmOrderedProductByIdDocument,
    options,
  )
}
export type CgmOrderedProductByIdQueryHookResult = ReturnType<typeof useCgmOrderedProductByIdQuery>
export type CgmOrderedProductByIdLazyQueryHookResult = ReturnType<typeof useCgmOrderedProductByIdLazyQuery>
export type CgmOrderedProductByIdQueryResult = Apollo.QueryResult<
  CgmOrderedProductByIdQuery,
  CgmOrderedProductByIdQueryVariables
>
export const PublishReportVersionDocument = gql`
  mutation publishReportVersion($input: PublishReportVersionInput!) {
    publishReportVersion(input: $input) {
      reportVersion {
        ...ReportVersion
      }
    }
  }
  ${ReportVersionFragmentDoc}
`
export type PublishReportVersionMutationFn = Apollo.MutationFunction<
  PublishReportVersionMutation,
  PublishReportVersionMutationVariables
>

/**
 * __usePublishReportVersionMutation__
 *
 * To run a mutation, you first call `usePublishReportVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishReportVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishReportVersionMutation, { data, loading, error }] = usePublishReportVersionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePublishReportVersionMutation(
  baseOptions?: Apollo.MutationHookOptions<PublishReportVersionMutation, PublishReportVersionMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<PublishReportVersionMutation, PublishReportVersionMutationVariables>(
    PublishReportVersionDocument,
    options,
  )
}
export type PublishReportVersionMutationHookResult = ReturnType<typeof usePublishReportVersionMutation>
export type PublishReportVersionMutationResult = Apollo.MutationResult<PublishReportVersionMutation>
export type PublishReportVersionMutationOptions = Apollo.BaseMutationOptions<
  PublishReportVersionMutation,
  PublishReportVersionMutationVariables
>
export const ChangeUserEmailDocument = gql`
  mutation changeUserEmail($input: ChangeUserEmailInput!) {
    changeUserEmail(input: $input) {
      success
    }
  }
`
export type ChangeUserEmailMutationFn = Apollo.MutationFunction<
  ChangeUserEmailMutation,
  ChangeUserEmailMutationVariables
>

/**
 * __useChangeUserEmailMutation__
 *
 * To run a mutation, you first call `useChangeUserEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeUserEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeUserEmailMutation, { data, loading, error }] = useChangeUserEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeUserEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<ChangeUserEmailMutation, ChangeUserEmailMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ChangeUserEmailMutation, ChangeUserEmailMutationVariables>(ChangeUserEmailDocument, options)
}
export type ChangeUserEmailMutationHookResult = ReturnType<typeof useChangeUserEmailMutation>
export type ChangeUserEmailMutationResult = Apollo.MutationResult<ChangeUserEmailMutation>
export type ChangeUserEmailMutationOptions = Apollo.BaseMutationOptions<
  ChangeUserEmailMutation,
  ChangeUserEmailMutationVariables
>
export const UserByEmailDocument = gql`
  query userByEmail($email: String!) {
    userByEmail(email: $email) {
      ...UserInfo
    }
  }
  ${UserInfoFragmentDoc}
`

/**
 * __useUserByEmailQuery__
 *
 * To run a query within a React component, call `useUserByEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserByEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserByEmailQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useUserByEmailQuery(baseOptions: Apollo.QueryHookOptions<UserByEmailQuery, UserByEmailQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<UserByEmailQuery, UserByEmailQueryVariables>(UserByEmailDocument, options)
}
export function useUserByEmailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserByEmailQuery, UserByEmailQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<UserByEmailQuery, UserByEmailQueryVariables>(UserByEmailDocument, options)
}
export type UserByEmailQueryHookResult = ReturnType<typeof useUserByEmailQuery>
export type UserByEmailLazyQueryHookResult = ReturnType<typeof useUserByEmailLazyQuery>
export type UserByEmailQueryResult = Apollo.QueryResult<UserByEmailQuery, UserByEmailQueryVariables>
export const UserByIdDocument = gql`
  query userById($id: ID!) {
    userById(id: $id) {
      ...UserInfo
    }
  }
  ${UserInfoFragmentDoc}
`

/**
 * __useUserByIdQuery__
 *
 * To run a query within a React component, call `useUserByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserByIdQuery(baseOptions: Apollo.QueryHookOptions<UserByIdQuery, UserByIdQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<UserByIdQuery, UserByIdQueryVariables>(UserByIdDocument, options)
}
export function useUserByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserByIdQuery, UserByIdQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<UserByIdQuery, UserByIdQueryVariables>(UserByIdDocument, options)
}
export type UserByIdQueryHookResult = ReturnType<typeof useUserByIdQuery>
export type UserByIdLazyQueryHookResult = ReturnType<typeof useUserByIdLazyQuery>
export type UserByIdQueryResult = Apollo.QueryResult<UserByIdQuery, UserByIdQueryVariables>
export const AdminSalesOverviewDocument = gql`
  query AdminSalesOverview($filter: OrderListFilter) {
    orders(filter: $filter) {
      __typename
      id
    }
  }
`

/**
 * __useAdminSalesOverviewQuery__
 *
 * To run a query within a React component, call `useAdminSalesOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminSalesOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminSalesOverviewQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAdminSalesOverviewQuery(
  baseOptions?: Apollo.QueryHookOptions<AdminSalesOverviewQuery, AdminSalesOverviewQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AdminSalesOverviewQuery, AdminSalesOverviewQueryVariables>(AdminSalesOverviewDocument, options)
}
export function useAdminSalesOverviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AdminSalesOverviewQuery, AdminSalesOverviewQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AdminSalesOverviewQuery, AdminSalesOverviewQueryVariables>(
    AdminSalesOverviewDocument,
    options,
  )
}
export type AdminSalesOverviewQueryHookResult = ReturnType<typeof useAdminSalesOverviewQuery>
export type AdminSalesOverviewLazyQueryHookResult = ReturnType<typeof useAdminSalesOverviewLazyQuery>
export type AdminSalesOverviewQueryResult = Apollo.QueryResult<
  AdminSalesOverviewQuery,
  AdminSalesOverviewQueryVariables
>
export const TdlResultsDocument = gql`
  query tdlResults {
    tdlResults {
      id
      filename
      kitId
      labRef
      labStatus
      ignored
      results {
        __typename
        testCode
      }
      kit {
        id
        activatedAt
      }
    }
  }
`

/**
 * __useTdlResultsQuery__
 *
 * To run a query within a React component, call `useTdlResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTdlResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTdlResultsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTdlResultsQuery(baseOptions?: Apollo.QueryHookOptions<TdlResultsQuery, TdlResultsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<TdlResultsQuery, TdlResultsQueryVariables>(TdlResultsDocument, options)
}
export function useTdlResultsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TdlResultsQuery, TdlResultsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<TdlResultsQuery, TdlResultsQueryVariables>(TdlResultsDocument, options)
}
export type TdlResultsQueryHookResult = ReturnType<typeof useTdlResultsQuery>
export type TdlResultsLazyQueryHookResult = ReturnType<typeof useTdlResultsLazyQuery>
export type TdlResultsQueryResult = Apollo.QueryResult<TdlResultsQuery, TdlResultsQueryVariables>
export const KitByIdDocument = gql`
  query kitById($id: ID!) {
    kitById(id: $id) {
      id
      canceled
      activatedAt
      jobs {
        __typename
        id
        status
        ... on TdlRegUploadJob {
          filename
          csv
        }
      }
      offPlatformSku
      claimedKitRequest {
        id
        orderedProduct {
          id
          createdAt
          user {
            id
            firstName
            lastName
            email
          }
        }
      }
      activatedKitRequest {
        id
        status
        activationInfo {
          firstName
          lastName
          dob
          sampleDate
          biologicalSex
          trackingNumber
          shippingLatestEvent
          extraData
        }
        rawRequestCodes
        tdlResults {
          filename
          labStatus
        }
        currentTdlResult {
          labRef
          patientName {
            givenName
            familyName
          }
          patientDob
          results {
            testCode
            sampleTime
          }
        }
        requestedTests {
          id
        }
        kitDefinition {
          sku
          sampleType
        }
        kit {
          id
          canceled
          activatedAt
        }
        orderedProduct {
          id
          canceled
          productDefinition {
            name
          }
          user {
            firstName
            lastName
            id
          }
        }
      }
      dispatchedShipment {
        dispatchedAt
        trackingNo
        shippingStatus
        shippinglastUpdate
        shippingLatestEvent
        order {
          id
          createdAt
          firstName
          lastName
          user {
            id
          }
        }
      }
      dispatchedKitDefinition {
        sku
        sampleType
      }
      notes {
        id
        createdAt
        author
        subject
        note
      }
    }
  }
`

/**
 * __useKitByIdQuery__
 *
 * To run a query within a React component, call `useKitByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useKitByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKitByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useKitByIdQuery(baseOptions: Apollo.QueryHookOptions<KitByIdQuery, KitByIdQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<KitByIdQuery, KitByIdQueryVariables>(KitByIdDocument, options)
}
export function useKitByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KitByIdQuery, KitByIdQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<KitByIdQuery, KitByIdQueryVariables>(KitByIdDocument, options)
}
export type KitByIdQueryHookResult = ReturnType<typeof useKitByIdQuery>
export type KitByIdLazyQueryHookResult = ReturnType<typeof useKitByIdLazyQuery>
export type KitByIdQueryResult = Apollo.QueryResult<KitByIdQuery, KitByIdQueryVariables>
export const CancelKitDocument = gql`
  mutation cancelKit($id: ID!) {
    cancelKit(id: $id) {
      success
      kit {
        canceled
      }
    }
  }
`
export type CancelKitMutationFn = Apollo.MutationFunction<CancelKitMutation, CancelKitMutationVariables>

/**
 * __useCancelKitMutation__
 *
 * To run a mutation, you first call `useCancelKitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelKitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelKitMutation, { data, loading, error }] = useCancelKitMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCancelKitMutation(
  baseOptions?: Apollo.MutationHookOptions<CancelKitMutation, CancelKitMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CancelKitMutation, CancelKitMutationVariables>(CancelKitDocument, options)
}
export type CancelKitMutationHookResult = ReturnType<typeof useCancelKitMutation>
export type CancelKitMutationResult = Apollo.MutationResult<CancelKitMutation>
export type CancelKitMutationOptions = Apollo.BaseMutationOptions<CancelKitMutation, CancelKitMutationVariables>
export const CancelKitRequestDocument = gql`
  mutation cancelKitRequest($input: CancelKitRequestInput!) {
    cancelKitRequest(input: $input) {
      kitRequest {
        id
        status
      }
    }
  }
`
export type CancelKitRequestMutationFn = Apollo.MutationFunction<
  CancelKitRequestMutation,
  CancelKitRequestMutationVariables
>

/**
 * __useCancelKitRequestMutation__
 *
 * To run a mutation, you first call `useCancelKitRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelKitRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelKitRequestMutation, { data, loading, error }] = useCancelKitRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelKitRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<CancelKitRequestMutation, CancelKitRequestMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CancelKitRequestMutation, CancelKitRequestMutationVariables>(
    CancelKitRequestDocument,
    options,
  )
}
export type CancelKitRequestMutationHookResult = ReturnType<typeof useCancelKitRequestMutation>
export type CancelKitRequestMutationResult = Apollo.MutationResult<CancelKitRequestMutation>
export type CancelKitRequestMutationOptions = Apollo.BaseMutationOptions<
  CancelKitRequestMutation,
  CancelKitRequestMutationVariables
>
export const AddKitNoteDocument = gql`
  mutation AddKitNote($input: AddKitNoteInput!) {
    addKitNote(input: $input) {
      success
    }
  }
`
export type AddKitNoteMutationFn = Apollo.MutationFunction<AddKitNoteMutation, AddKitNoteMutationVariables>

/**
 * __useAddKitNoteMutation__
 *
 * To run a mutation, you first call `useAddKitNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddKitNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addKitNoteMutation, { data, loading, error }] = useAddKitNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddKitNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<AddKitNoteMutation, AddKitNoteMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<AddKitNoteMutation, AddKitNoteMutationVariables>(AddKitNoteDocument, options)
}
export type AddKitNoteMutationHookResult = ReturnType<typeof useAddKitNoteMutation>
export type AddKitNoteMutationResult = Apollo.MutationResult<AddKitNoteMutation>
export type AddKitNoteMutationOptions = Apollo.BaseMutationOptions<AddKitNoteMutation, AddKitNoteMutationVariables>
export const GenerateKitIdsDocument = gql`
  mutation GenerateKitIds($input: GenerateKitIdsInput!) {
    generateKitIds(input: $input) {
      ids
      success
    }
  }
`
export type GenerateKitIdsMutationFn = Apollo.MutationFunction<GenerateKitIdsMutation, GenerateKitIdsMutationVariables>

/**
 * __useGenerateKitIdsMutation__
 *
 * To run a mutation, you first call `useGenerateKitIdsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateKitIdsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateKitIdsMutation, { data, loading, error }] = useGenerateKitIdsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateKitIdsMutation(
  baseOptions?: Apollo.MutationHookOptions<GenerateKitIdsMutation, GenerateKitIdsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<GenerateKitIdsMutation, GenerateKitIdsMutationVariables>(GenerateKitIdsDocument, options)
}
export type GenerateKitIdsMutationHookResult = ReturnType<typeof useGenerateKitIdsMutation>
export type GenerateKitIdsMutationResult = Apollo.MutationResult<GenerateKitIdsMutation>
export type GenerateKitIdsMutationOptions = Apollo.BaseMutationOptions<
  GenerateKitIdsMutation,
  GenerateKitIdsMutationVariables
>
export const RecentJobsDocument = gql`
  query recentJobs {
    recentJobs {
      __typename
      id
      status
      ... on EmailJob {
        template
      }
      ... on TdlRegUploadJob {
        csv
        filename
      }
    }
  }
`

/**
 * __useRecentJobsQuery__
 *
 * To run a query within a React component, call `useRecentJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecentJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecentJobsQuery({
 *   variables: {
 *   },
 * });
 */
export function useRecentJobsQuery(baseOptions?: Apollo.QueryHookOptions<RecentJobsQuery, RecentJobsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<RecentJobsQuery, RecentJobsQueryVariables>(RecentJobsDocument, options)
}
export function useRecentJobsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RecentJobsQuery, RecentJobsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<RecentJobsQuery, RecentJobsQueryVariables>(RecentJobsDocument, options)
}
export type RecentJobsQueryHookResult = ReturnType<typeof useRecentJobsQuery>
export type RecentJobsLazyQueryHookResult = ReturnType<typeof useRecentJobsLazyQuery>
export type RecentJobsQueryResult = Apollo.QueryResult<RecentJobsQuery, RecentJobsQueryVariables>
export const AdminOrderByIdDocument = gql`
  query adminOrderById($id: ID!) {
    orderById(orderId: $id) {
      id
      createdAt
      firstName
      lastName
      phone
      user {
        email
        id
      }
      bloodCollectionAddress {
        line1
        line2
        postcode
        city
      }
      shipments {
        id
        dispatchedAt
        status
        trackingNo
        shippingLatestEvent
        shippingAddress {
          line1
          line2
          postcode
          city
        }
        dispatchedKits {
          id
          tdlQrData
          dispatchedKitDefinition {
            sku
          }
        }
      }
      orderPriceDetails {
        discount {
          amount
          code
          description
          failureMessage
          giftCard {
            id
          }
        }
        lines {
          capturedProductName
          capturedProductPrice
          product {
            ...AdminOrderedProduct
          }
          quantity
          totalPrice
          modifiers {
            code
            name
            price
          }
        }
        subTotal
        total
      }
      orderedProducts {
        __typename
        id
        productDefinition {
          name
        }
        ... on LabTestOrderedProduct {
          ...LabTestOrderedProduct
        }
        ... on CgmOrderedProduct {
          analysisRuns {
            id
          }
        }
        ... on GiftCardOrderedProduct {
          ...GiftCardOrderDetails
        }
      }
      placedBy {
        email
        friendlyName
      }
      salesChannel
      externalRef
    }
  }
  ${AdminOrderedProductFragmentDoc}
  ${LabTestOrderedProductFragmentDoc}
  ${GiftCardOrderDetailsFragmentDoc}
`

/**
 * __useAdminOrderByIdQuery__
 *
 * To run a query within a React component, call `useAdminOrderByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminOrderByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminOrderByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminOrderByIdQuery(
  baseOptions: Apollo.QueryHookOptions<AdminOrderByIdQuery, AdminOrderByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AdminOrderByIdQuery, AdminOrderByIdQueryVariables>(AdminOrderByIdDocument, options)
}
export function useAdminOrderByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AdminOrderByIdQuery, AdminOrderByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AdminOrderByIdQuery, AdminOrderByIdQueryVariables>(AdminOrderByIdDocument, options)
}
export type AdminOrderByIdQueryHookResult = ReturnType<typeof useAdminOrderByIdQuery>
export type AdminOrderByIdLazyQueryHookResult = ReturnType<typeof useAdminOrderByIdLazyQuery>
export type AdminOrderByIdQueryResult = Apollo.QueryResult<AdminOrderByIdQuery, AdminOrderByIdQueryVariables>
export const AllProductsDocument = gql`
  query AllProducts($filter: ProductListFilter) {
    products(filter: $filter) {
      __typename
      sku
      name
      slug
      type
      price
      description
      allowPurchase
      ... on LabTestProduct {
        __typename
        name
        ...LabTestData
      }
    }
  }
  ${LabTestDataFragmentDoc}
`

/**
 * __useAllProductsQuery__
 *
 * To run a query within a React component, call `useAllProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllProductsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAllProductsQuery(
  baseOptions?: Apollo.QueryHookOptions<AllProductsQuery, AllProductsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AllProductsQuery, AllProductsQueryVariables>(AllProductsDocument, options)
}
export function useAllProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AllProductsQuery, AllProductsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AllProductsQuery, AllProductsQueryVariables>(AllProductsDocument, options)
}
export type AllProductsQueryHookResult = ReturnType<typeof useAllProductsQuery>
export type AllProductsLazyQueryHookResult = ReturnType<typeof useAllProductsLazyQuery>
export type AllProductsQueryResult = Apollo.QueryResult<AllProductsQuery, AllProductsQueryVariables>
export const UserByEmailForAdminOrderDocument = gql`
  query userByEmailForAdminOrder($email: String!) {
    userByEmail(email: $email) {
      id
      email
      activated
      orders {
        id
        createdAt
        firstName
        lastName
        phone
        shipments {
          shippingAddress {
            city
            line1
            line2
            postcode
          }
        }
      }
    }
  }
`

/**
 * __useUserByEmailForAdminOrderQuery__
 *
 * To run a query within a React component, call `useUserByEmailForAdminOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserByEmailForAdminOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserByEmailForAdminOrderQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useUserByEmailForAdminOrderQuery(
  baseOptions: Apollo.QueryHookOptions<UserByEmailForAdminOrderQuery, UserByEmailForAdminOrderQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<UserByEmailForAdminOrderQuery, UserByEmailForAdminOrderQueryVariables>(
    UserByEmailForAdminOrderDocument,
    options,
  )
}
export function useUserByEmailForAdminOrderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserByEmailForAdminOrderQuery, UserByEmailForAdminOrderQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<UserByEmailForAdminOrderQuery, UserByEmailForAdminOrderQueryVariables>(
    UserByEmailForAdminOrderDocument,
    options,
  )
}
export type UserByEmailForAdminOrderQueryHookResult = ReturnType<typeof useUserByEmailForAdminOrderQuery>
export type UserByEmailForAdminOrderLazyQueryHookResult = ReturnType<typeof useUserByEmailForAdminOrderLazyQuery>
export type UserByEmailForAdminOrderQueryResult = Apollo.QueryResult<
  UserByEmailForAdminOrderQuery,
  UserByEmailForAdminOrderQueryVariables
>
export const ShipmentBatchesDocument = gql`
  query shipmentBatches {
    shipmentBatches {
      createdAt
      createdByEmail
      id
      shipmentCount
    }
  }
`

/**
 * __useShipmentBatchesQuery__
 *
 * To run a query within a React component, call `useShipmentBatchesQuery` and pass it any options that fit your needs.
 * When your component renders, `useShipmentBatchesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShipmentBatchesQuery({
 *   variables: {
 *   },
 * });
 */
export function useShipmentBatchesQuery(
  baseOptions?: Apollo.QueryHookOptions<ShipmentBatchesQuery, ShipmentBatchesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ShipmentBatchesQuery, ShipmentBatchesQueryVariables>(ShipmentBatchesDocument, options)
}
export function useShipmentBatchesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ShipmentBatchesQuery, ShipmentBatchesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ShipmentBatchesQuery, ShipmentBatchesQueryVariables>(ShipmentBatchesDocument, options)
}
export type ShipmentBatchesQueryHookResult = ReturnType<typeof useShipmentBatchesQuery>
export type ShipmentBatchesLazyQueryHookResult = ReturnType<typeof useShipmentBatchesLazyQuery>
export type ShipmentBatchesQueryResult = Apollo.QueryResult<ShipmentBatchesQuery, ShipmentBatchesQueryVariables>
export const RecentShipmentsDocument = gql`
  query RecentShipments($maxDaysOld: Int) {
    shipments(maxDaysOld: $maxDaysOld) {
      id
      status
      createdAt
      kitRequests {
        orderedProduct {
          productDefinition {
            name
            sku
          }
        }
        kitDefinition {
          sku
        }
        orderedProduct {
          id
          order {
            orderPriceDetails {
              lines {
                product {
                  sku
                }
                quantity
              }
            }
          }
        }
      }
      order {
        createdAt
        id
        firstName
        lastName
        user {
          id
          email
        }
      }
      shippingAddress {
        line1
        line2
        city
        postcode
      }
    }
  }
`

/**
 * __useRecentShipmentsQuery__
 *
 * To run a query within a React component, call `useRecentShipmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecentShipmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecentShipmentsQuery({
 *   variables: {
 *      maxDaysOld: // value for 'maxDaysOld'
 *   },
 * });
 */
export function useRecentShipmentsQuery(
  baseOptions?: Apollo.QueryHookOptions<RecentShipmentsQuery, RecentShipmentsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<RecentShipmentsQuery, RecentShipmentsQueryVariables>(RecentShipmentsDocument, options)
}
export function useRecentShipmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RecentShipmentsQuery, RecentShipmentsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<RecentShipmentsQuery, RecentShipmentsQueryVariables>(RecentShipmentsDocument, options)
}
export type RecentShipmentsQueryHookResult = ReturnType<typeof useRecentShipmentsQuery>
export type RecentShipmentsLazyQueryHookResult = ReturnType<typeof useRecentShipmentsLazyQuery>
export type RecentShipmentsQueryResult = Apollo.QueryResult<RecentShipmentsQuery, RecentShipmentsQueryVariables>
export const AdminDownloadByLineItemDocument = gql`
  query AdminDownloadByLineItem($filter: OrderListFilter) {
    orders(filter: $filter) {
      __typename
      createdAt
      id
      user {
        id
      }
      orderPriceDetails {
        __typename
        lines {
          product {
            __typename
            sku
            name
          }
          modifiers {
            code
          }
          quantity
          totalPrice
        }
      }
    }
  }
`

/**
 * __useAdminDownloadByLineItemQuery__
 *
 * To run a query within a React component, call `useAdminDownloadByLineItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminDownloadByLineItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminDownloadByLineItemQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAdminDownloadByLineItemQuery(
  baseOptions?: Apollo.QueryHookOptions<AdminDownloadByLineItemQuery, AdminDownloadByLineItemQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AdminDownloadByLineItemQuery, AdminDownloadByLineItemQueryVariables>(
    AdminDownloadByLineItemDocument,
    options,
  )
}
export function useAdminDownloadByLineItemLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AdminDownloadByLineItemQuery, AdminDownloadByLineItemQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AdminDownloadByLineItemQuery, AdminDownloadByLineItemQueryVariables>(
    AdminDownloadByLineItemDocument,
    options,
  )
}
export type AdminDownloadByLineItemQueryHookResult = ReturnType<typeof useAdminDownloadByLineItemQuery>
export type AdminDownloadByLineItemLazyQueryHookResult = ReturnType<typeof useAdminDownloadByLineItemLazyQuery>
export type AdminDownloadByLineItemQueryResult = Apollo.QueryResult<
  AdminDownloadByLineItemQuery,
  AdminDownloadByLineItemQueryVariables
>
export const AdminDownloadByOrderDocument = gql`
  query AdminDownloadByOrder($filter: OrderListFilter) {
    orders(filter: $filter) {
      __typename
      id
      createdAt
      user {
        id
      }
      orderPriceDetails {
        __typename
        lines {
          product {
            __typename
            sku
          }
          quantity
        }
        discount {
          amount
          code
        }
        total
      }
    }
  }
`

/**
 * __useAdminDownloadByOrderQuery__
 *
 * To run a query within a React component, call `useAdminDownloadByOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminDownloadByOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminDownloadByOrderQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAdminDownloadByOrderQuery(
  baseOptions?: Apollo.QueryHookOptions<AdminDownloadByOrderQuery, AdminDownloadByOrderQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AdminDownloadByOrderQuery, AdminDownloadByOrderQueryVariables>(
    AdminDownloadByOrderDocument,
    options,
  )
}
export function useAdminDownloadByOrderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AdminDownloadByOrderQuery, AdminDownloadByOrderQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AdminDownloadByOrderQuery, AdminDownloadByOrderQueryVariables>(
    AdminDownloadByOrderDocument,
    options,
  )
}
export type AdminDownloadByOrderQueryHookResult = ReturnType<typeof useAdminDownloadByOrderQuery>
export type AdminDownloadByOrderLazyQueryHookResult = ReturnType<typeof useAdminDownloadByOrderLazyQuery>
export type AdminDownloadByOrderQueryResult = Apollo.QueryResult<
  AdminDownloadByOrderQuery,
  AdminDownloadByOrderQueryVariables
>
export const AdminDownloadByOrderedProductDocument = gql`
  query AdminDownloadByOrderedProduct($filter: OrderListFilter) {
    orders(filter: $filter) {
      __typename
      createdAt
      id
      user {
        id
      }
      orderedProducts {
        __typename
        id
        ... on LabTestOrderedProduct {
          ...AdminDownloadLabTestOrderedProduct
        }
      }
    }
  }
  ${AdminDownloadLabTestOrderedProductFragmentDoc}
`

/**
 * __useAdminDownloadByOrderedProductQuery__
 *
 * To run a query within a React component, call `useAdminDownloadByOrderedProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminDownloadByOrderedProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminDownloadByOrderedProductQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAdminDownloadByOrderedProductQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AdminDownloadByOrderedProductQuery,
    AdminDownloadByOrderedProductQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AdminDownloadByOrderedProductQuery, AdminDownloadByOrderedProductQueryVariables>(
    AdminDownloadByOrderedProductDocument,
    options,
  )
}
export function useAdminDownloadByOrderedProductLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminDownloadByOrderedProductQuery,
    AdminDownloadByOrderedProductQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AdminDownloadByOrderedProductQuery, AdminDownloadByOrderedProductQueryVariables>(
    AdminDownloadByOrderedProductDocument,
    options,
  )
}
export type AdminDownloadByOrderedProductQueryHookResult = ReturnType<typeof useAdminDownloadByOrderedProductQuery>
export type AdminDownloadByOrderedProductLazyQueryHookResult = ReturnType<
  typeof useAdminDownloadByOrderedProductLazyQuery
>
export type AdminDownloadByOrderedProductQueryResult = Apollo.QueryResult<
  AdminDownloadByOrderedProductQuery,
  AdminDownloadByOrderedProductQueryVariables
>
export const SalesSummaryDocument = gql`
  query SalesSummary($filter: OrderListFilter) {
    orders(filter: $filter) {
      id
      createdAt
      orderPriceDetails {
        total
      }
      orderedProducts {
        id
      }
    }
  }
`

/**
 * __useSalesSummaryQuery__
 *
 * To run a query within a React component, call `useSalesSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesSummaryQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSalesSummaryQuery(
  baseOptions?: Apollo.QueryHookOptions<SalesSummaryQuery, SalesSummaryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SalesSummaryQuery, SalesSummaryQueryVariables>(SalesSummaryDocument, options)
}
export function useSalesSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SalesSummaryQuery, SalesSummaryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SalesSummaryQuery, SalesSummaryQueryVariables>(SalesSummaryDocument, options)
}
export type SalesSummaryQueryHookResult = ReturnType<typeof useSalesSummaryQuery>
export type SalesSummaryLazyQueryHookResult = ReturnType<typeof useSalesSummaryLazyQuery>
export type SalesSummaryQueryResult = Apollo.QueryResult<SalesSummaryQuery, SalesSummaryQueryVariables>
export const LabTestOrderedProductByIdDocument = gql`
  query labTestOrderedProductById($id: ID!) {
    labTestOrderedProductById(id: $id) {
      ...LabTestOrderedProduct
    }
  }
  ${LabTestOrderedProductFragmentDoc}
`

/**
 * __useLabTestOrderedProductByIdQuery__
 *
 * To run a query within a React component, call `useLabTestOrderedProductByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useLabTestOrderedProductByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLabTestOrderedProductByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLabTestOrderedProductByIdQuery(
  baseOptions: Apollo.QueryHookOptions<LabTestOrderedProductByIdQuery, LabTestOrderedProductByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<LabTestOrderedProductByIdQuery, LabTestOrderedProductByIdQueryVariables>(
    LabTestOrderedProductByIdDocument,
    options,
  )
}
export function useLabTestOrderedProductByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LabTestOrderedProductByIdQuery, LabTestOrderedProductByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<LabTestOrderedProductByIdQuery, LabTestOrderedProductByIdQueryVariables>(
    LabTestOrderedProductByIdDocument,
    options,
  )
}
export type LabTestOrderedProductByIdQueryHookResult = ReturnType<typeof useLabTestOrderedProductByIdQuery>
export type LabTestOrderedProductByIdLazyQueryHookResult = ReturnType<typeof useLabTestOrderedProductByIdLazyQuery>
export type LabTestOrderedProductByIdQueryResult = Apollo.QueryResult<
  LabTestOrderedProductByIdQuery,
  LabTestOrderedProductByIdQueryVariables
>
export const CancelTestPanelDocument = gql`
  mutation cancelTestPanel($id: ID!) {
    cancelLabTestOrderedProduct(id: $id) {
      success
    }
  }
`
export type CancelTestPanelMutationFn = Apollo.MutationFunction<
  CancelTestPanelMutation,
  CancelTestPanelMutationVariables
>

/**
 * __useCancelTestPanelMutation__
 *
 * To run a mutation, you first call `useCancelTestPanelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelTestPanelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelTestPanelMutation, { data, loading, error }] = useCancelTestPanelMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCancelTestPanelMutation(
  baseOptions?: Apollo.MutationHookOptions<CancelTestPanelMutation, CancelTestPanelMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CancelTestPanelMutation, CancelTestPanelMutationVariables>(CancelTestPanelDocument, options)
}
export type CancelTestPanelMutationHookResult = ReturnType<typeof useCancelTestPanelMutation>
export type CancelTestPanelMutationResult = Apollo.MutationResult<CancelTestPanelMutation>
export type CancelTestPanelMutationOptions = Apollo.BaseMutationOptions<
  CancelTestPanelMutation,
  CancelTestPanelMutationVariables
>
export const PublishReportDocument = gql`
  mutation publishReport($orderedProductId: ID!, $unpublish: Boolean, $silent: Boolean) {
    publishReport(orderedProductId: $orderedProductId, unpublish: $unpublish, silent: $silent) {
      orderedProduct {
        id
        reportStatus
        reportText
        reportRecommendations
        kitRequests {
          currentTdlResult {
            id
          }
          reportedTdlResult {
            id
          }
        }
      }
    }
  }
`
export type PublishReportMutationFn = Apollo.MutationFunction<PublishReportMutation, PublishReportMutationVariables>

/**
 * __usePublishReportMutation__
 *
 * To run a mutation, you first call `usePublishReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishReportMutation, { data, loading, error }] = usePublishReportMutation({
 *   variables: {
 *      orderedProductId: // value for 'orderedProductId'
 *      unpublish: // value for 'unpublish'
 *      silent: // value for 'silent'
 *   },
 * });
 */
export function usePublishReportMutation(
  baseOptions?: Apollo.MutationHookOptions<PublishReportMutation, PublishReportMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<PublishReportMutation, PublishReportMutationVariables>(PublishReportDocument, options)
}
export type PublishReportMutationHookResult = ReturnType<typeof usePublishReportMutation>
export type PublishReportMutationResult = Apollo.MutationResult<PublishReportMutation>
export type PublishReportMutationOptions = Apollo.BaseMutationOptions<
  PublishReportMutation,
  PublishReportMutationVariables
>
export const UserOrderHistoryByIdDocument = gql`
  query userOrderHistoryById($userId: ID!) {
    userById(id: $userId) {
      orders {
        id
        ...HistoryOrder
      }
    }
  }
  ${HistoryOrderFragmentDoc}
`

/**
 * __useUserOrderHistoryByIdQuery__
 *
 * To run a query within a React component, call `useUserOrderHistoryByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserOrderHistoryByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserOrderHistoryByIdQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserOrderHistoryByIdQuery(
  baseOptions: Apollo.QueryHookOptions<UserOrderHistoryByIdQuery, UserOrderHistoryByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<UserOrderHistoryByIdQuery, UserOrderHistoryByIdQueryVariables>(
    UserOrderHistoryByIdDocument,
    options,
  )
}
export function useUserOrderHistoryByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserOrderHistoryByIdQuery, UserOrderHistoryByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<UserOrderHistoryByIdQuery, UserOrderHistoryByIdQueryVariables>(
    UserOrderHistoryByIdDocument,
    options,
  )
}
export type UserOrderHistoryByIdQueryHookResult = ReturnType<typeof useUserOrderHistoryByIdQuery>
export type UserOrderHistoryByIdLazyQueryHookResult = ReturnType<typeof useUserOrderHistoryByIdLazyQuery>
export type UserOrderHistoryByIdQueryResult = Apollo.QueryResult<
  UserOrderHistoryByIdQuery,
  UserOrderHistoryByIdQueryVariables
>
export const LabTestOrderedProductTdlFilesDocument = gql`
  query labTestOrderedProductTdlFiles($id: ID!) {
    labTestOrderedProductById(id: $id) {
      ...LabTestTdlResults
    }
  }
  ${LabTestTdlResultsFragmentDoc}
`

/**
 * __useLabTestOrderedProductTdlFilesQuery__
 *
 * To run a query within a React component, call `useLabTestOrderedProductTdlFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLabTestOrderedProductTdlFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLabTestOrderedProductTdlFilesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLabTestOrderedProductTdlFilesQuery(
  baseOptions: Apollo.QueryHookOptions<LabTestOrderedProductTdlFilesQuery, LabTestOrderedProductTdlFilesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<LabTestOrderedProductTdlFilesQuery, LabTestOrderedProductTdlFilesQueryVariables>(
    LabTestOrderedProductTdlFilesDocument,
    options,
  )
}
export function useLabTestOrderedProductTdlFilesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LabTestOrderedProductTdlFilesQuery,
    LabTestOrderedProductTdlFilesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<LabTestOrderedProductTdlFilesQuery, LabTestOrderedProductTdlFilesQueryVariables>(
    LabTestOrderedProductTdlFilesDocument,
    options,
  )
}
export type LabTestOrderedProductTdlFilesQueryHookResult = ReturnType<typeof useLabTestOrderedProductTdlFilesQuery>
export type LabTestOrderedProductTdlFilesLazyQueryHookResult = ReturnType<
  typeof useLabTestOrderedProductTdlFilesLazyQuery
>
export type LabTestOrderedProductTdlFilesQueryResult = Apollo.QueryResult<
  LabTestOrderedProductTdlFilesQuery,
  LabTestOrderedProductTdlFilesQueryVariables
>
export const WriteReportDocument = gql`
  mutation writeReport($input: WriteReportInput!) {
    writeReport(input: $input) {
      orderedProduct {
        id
        reportStatus
        reportPublishedAt
        reportText
        reportRecommendations
        reportAnalysesToExclude
        kitRequests {
          id
          ...TdlResult
        }
      }
    }
  }
  ${TdlResultFragmentDoc}
`
export type WriteReportMutationFn = Apollo.MutationFunction<WriteReportMutation, WriteReportMutationVariables>

/**
 * __useWriteReportMutation__
 *
 * To run a mutation, you first call `useWriteReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWriteReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [writeReportMutation, { data, loading, error }] = useWriteReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWriteReportMutation(
  baseOptions?: Apollo.MutationHookOptions<WriteReportMutation, WriteReportMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<WriteReportMutation, WriteReportMutationVariables>(WriteReportDocument, options)
}
export type WriteReportMutationHookResult = ReturnType<typeof useWriteReportMutation>
export type WriteReportMutationResult = Apollo.MutationResult<WriteReportMutation>
export type WriteReportMutationOptions = Apollo.BaseMutationOptions<WriteReportMutation, WriteReportMutationVariables>
export const BlockForReportingDocument = gql`
  mutation blockForReporting($input: MarkOrderedProductBlockedInput!) {
    markOrderedProductBlockedForReporting(input: $input) {
      orderedProduct {
        id
        blockedForReporting
      }
    }
  }
`
export type BlockForReportingMutationFn = Apollo.MutationFunction<
  BlockForReportingMutation,
  BlockForReportingMutationVariables
>

/**
 * __useBlockForReportingMutation__
 *
 * To run a mutation, you first call `useBlockForReportingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBlockForReportingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [blockForReportingMutation, { data, loading, error }] = useBlockForReportingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBlockForReportingMutation(
  baseOptions?: Apollo.MutationHookOptions<BlockForReportingMutation, BlockForReportingMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<BlockForReportingMutation, BlockForReportingMutationVariables>(
    BlockForReportingDocument,
    options,
  )
}
export type BlockForReportingMutationHookResult = ReturnType<typeof useBlockForReportingMutation>
export type BlockForReportingMutationResult = Apollo.MutationResult<BlockForReportingMutation>
export type BlockForReportingMutationOptions = Apollo.BaseMutationOptions<
  BlockForReportingMutation,
  BlockForReportingMutationVariables
>
export const LabTestOrderedProductsInFlightDocument = gql`
  query labTestOrderedProductsInFlight {
    labTestOrderedProductsInFlight {
      __typename
      edges {
        node {
          ...LabTestOrderedProductSummary
        }
      }
    }
  }
  ${LabTestOrderedProductSummaryFragmentDoc}
`

/**
 * __useLabTestOrderedProductsInFlightQuery__
 *
 * To run a query within a React component, call `useLabTestOrderedProductsInFlightQuery` and pass it any options that fit your needs.
 * When your component renders, `useLabTestOrderedProductsInFlightQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLabTestOrderedProductsInFlightQuery({
 *   variables: {
 *   },
 * });
 */
export function useLabTestOrderedProductsInFlightQuery(
  baseOptions?: Apollo.QueryHookOptions<
    LabTestOrderedProductsInFlightQuery,
    LabTestOrderedProductsInFlightQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<LabTestOrderedProductsInFlightQuery, LabTestOrderedProductsInFlightQueryVariables>(
    LabTestOrderedProductsInFlightDocument,
    options,
  )
}
export function useLabTestOrderedProductsInFlightLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LabTestOrderedProductsInFlightQuery,
    LabTestOrderedProductsInFlightQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<LabTestOrderedProductsInFlightQuery, LabTestOrderedProductsInFlightQueryVariables>(
    LabTestOrderedProductsInFlightDocument,
    options,
  )
}
export type LabTestOrderedProductsInFlightQueryHookResult = ReturnType<typeof useLabTestOrderedProductsInFlightQuery>
export type LabTestOrderedProductsInFlightLazyQueryHookResult = ReturnType<
  typeof useLabTestOrderedProductsInFlightLazyQuery
>
export type LabTestOrderedProductsInFlightQueryResult = Apollo.QueryResult<
  LabTestOrderedProductsInFlightQuery,
  LabTestOrderedProductsInFlightQueryVariables
>
export const LabTestOrderedProductsReadyForReportingDocument = gql`
  query labTestOrderedProductsReadyForReporting {
    labTestOrderedProductsReadyToReport {
      __typename
      edges {
        node {
          id
          blockedForReporting
          reportStatus
          reportPublishedAt
          productDefinition {
            name
          }
          order {
            id
          }
          kitRequests {
            status
            requestedTests {
              id
            }
            activationInfo {
              firstName
              lastName
            }
            reportedTdlResult {
              id
            }
            currentTdlResult {
              id
              results {
                ...Result
              }
            }
          }
        }
      }
    }
  }
  ${ResultFragmentDoc}
`

/**
 * __useLabTestOrderedProductsReadyForReportingQuery__
 *
 * To run a query within a React component, call `useLabTestOrderedProductsReadyForReportingQuery` and pass it any options that fit your needs.
 * When your component renders, `useLabTestOrderedProductsReadyForReportingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLabTestOrderedProductsReadyForReportingQuery({
 *   variables: {
 *   },
 * });
 */
export function useLabTestOrderedProductsReadyForReportingQuery(
  baseOptions?: Apollo.QueryHookOptions<
    LabTestOrderedProductsReadyForReportingQuery,
    LabTestOrderedProductsReadyForReportingQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    LabTestOrderedProductsReadyForReportingQuery,
    LabTestOrderedProductsReadyForReportingQueryVariables
  >(LabTestOrderedProductsReadyForReportingDocument, options)
}
export function useLabTestOrderedProductsReadyForReportingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LabTestOrderedProductsReadyForReportingQuery,
    LabTestOrderedProductsReadyForReportingQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    LabTestOrderedProductsReadyForReportingQuery,
    LabTestOrderedProductsReadyForReportingQueryVariables
  >(LabTestOrderedProductsReadyForReportingDocument, options)
}
export type LabTestOrderedProductsReadyForReportingQueryHookResult = ReturnType<
  typeof useLabTestOrderedProductsReadyForReportingQuery
>
export type LabTestOrderedProductsReadyForReportingLazyQueryHookResult = ReturnType<
  typeof useLabTestOrderedProductsReadyForReportingLazyQuery
>
export type LabTestOrderedProductsReadyForReportingQueryResult = Apollo.QueryResult<
  LabTestOrderedProductsReadyForReportingQuery,
  LabTestOrderedProductsReadyForReportingQueryVariables
>
export const TdlResultsByFilenameDocument = gql`
  query tdlResultsByFilename($filename: String!) {
    tdlResultByFilename(filename: $filename) {
      ...TdlResultsFile
    }
  }
  ${TdlResultsFileFragmentDoc}
`

/**
 * __useTdlResultsByFilenameQuery__
 *
 * To run a query within a React component, call `useTdlResultsByFilenameQuery` and pass it any options that fit your needs.
 * When your component renders, `useTdlResultsByFilenameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTdlResultsByFilenameQuery({
 *   variables: {
 *      filename: // value for 'filename'
 *   },
 * });
 */
export function useTdlResultsByFilenameQuery(
  baseOptions: Apollo.QueryHookOptions<TdlResultsByFilenameQuery, TdlResultsByFilenameQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<TdlResultsByFilenameQuery, TdlResultsByFilenameQueryVariables>(
    TdlResultsByFilenameDocument,
    options,
  )
}
export function useTdlResultsByFilenameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TdlResultsByFilenameQuery, TdlResultsByFilenameQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<TdlResultsByFilenameQuery, TdlResultsByFilenameQueryVariables>(
    TdlResultsByFilenameDocument,
    options,
  )
}
export type TdlResultsByFilenameQueryHookResult = ReturnType<typeof useTdlResultsByFilenameQuery>
export type TdlResultsByFilenameLazyQueryHookResult = ReturnType<typeof useTdlResultsByFilenameLazyQuery>
export type TdlResultsByFilenameQueryResult = Apollo.QueryResult<
  TdlResultsByFilenameQuery,
  TdlResultsByFilenameQueryVariables
>
export const IgnoreHl7FileDocument = gql`
  mutation ignoreHl7File($id: String!) {
    ignoreHl7(id: $id) {
      tdlResult {
        id
        ignored
      }
    }
  }
`
export type IgnoreHl7FileMutationFn = Apollo.MutationFunction<IgnoreHl7FileMutation, IgnoreHl7FileMutationVariables>

/**
 * __useIgnoreHl7FileMutation__
 *
 * To run a mutation, you first call `useIgnoreHl7FileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIgnoreHl7FileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [ignoreHl7FileMutation, { data, loading, error }] = useIgnoreHl7FileMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useIgnoreHl7FileMutation(
  baseOptions?: Apollo.MutationHookOptions<IgnoreHl7FileMutation, IgnoreHl7FileMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<IgnoreHl7FileMutation, IgnoreHl7FileMutationVariables>(IgnoreHl7FileDocument, options)
}
export type IgnoreHl7FileMutationHookResult = ReturnType<typeof useIgnoreHl7FileMutation>
export type IgnoreHl7FileMutationResult = Apollo.MutationResult<IgnoreHl7FileMutation>
export type IgnoreHl7FileMutationOptions = Apollo.BaseMutationOptions<
  IgnoreHl7FileMutation,
  IgnoreHl7FileMutationVariables
>
export const UnignoreHl7FileDocument = gql`
  mutation unignoreHl7File($id: String!) {
    unignoreHl7(id: $id) {
      tdlResult {
        id
        ignored
      }
    }
  }
`
export type UnignoreHl7FileMutationFn = Apollo.MutationFunction<
  UnignoreHl7FileMutation,
  UnignoreHl7FileMutationVariables
>

/**
 * __useUnignoreHl7FileMutation__
 *
 * To run a mutation, you first call `useUnignoreHl7FileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnignoreHl7FileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unignoreHl7FileMutation, { data, loading, error }] = useUnignoreHl7FileMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnignoreHl7FileMutation(
  baseOptions?: Apollo.MutationHookOptions<UnignoreHl7FileMutation, UnignoreHl7FileMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UnignoreHl7FileMutation, UnignoreHl7FileMutationVariables>(UnignoreHl7FileDocument, options)
}
export type UnignoreHl7FileMutationHookResult = ReturnType<typeof useUnignoreHl7FileMutation>
export type UnignoreHl7FileMutationResult = Apollo.MutationResult<UnignoreHl7FileMutation>
export type UnignoreHl7FileMutationOptions = Apollo.BaseMutationOptions<
  UnignoreHl7FileMutation,
  UnignoreHl7FileMutationVariables
>
export const ParseHl7DataDocument = gql`
  query parseHl7Data($hl7: String!) {
    parseHl7(hl7: $hl7) {
      __typename
      ...Result
    }
  }
  ${ResultFragmentDoc}
`

/**
 * __useParseHl7DataQuery__
 *
 * To run a query within a React component, call `useParseHl7DataQuery` and pass it any options that fit your needs.
 * When your component renders, `useParseHl7DataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useParseHl7DataQuery({
 *   variables: {
 *      hl7: // value for 'hl7'
 *   },
 * });
 */
export function useParseHl7DataQuery(
  baseOptions: Apollo.QueryHookOptions<ParseHl7DataQuery, ParseHl7DataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ParseHl7DataQuery, ParseHl7DataQueryVariables>(ParseHl7DataDocument, options)
}
export function useParseHl7DataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ParseHl7DataQuery, ParseHl7DataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ParseHl7DataQuery, ParseHl7DataQueryVariables>(ParseHl7DataDocument, options)
}
export type ParseHl7DataQueryHookResult = ReturnType<typeof useParseHl7DataQuery>
export type ParseHl7DataLazyQueryHookResult = ReturnType<typeof useParseHl7DataLazyQuery>
export type ParseHl7DataQueryResult = Apollo.QueryResult<ParseHl7DataQuery, ParseHl7DataQueryVariables>
export const AmendHl7FileDocument = gql`
  mutation amendHl7File($input: AmendHl7Input!) {
    amendHl7(input: $input) {
      __typename
      tdlResult {
        __typename
        id
        raw
      }
    }
  }
`
export type AmendHl7FileMutationFn = Apollo.MutationFunction<AmendHl7FileMutation, AmendHl7FileMutationVariables>

/**
 * __useAmendHl7FileMutation__
 *
 * To run a mutation, you first call `useAmendHl7FileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAmendHl7FileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [amendHl7FileMutation, { data, loading, error }] = useAmendHl7FileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAmendHl7FileMutation(
  baseOptions?: Apollo.MutationHookOptions<AmendHl7FileMutation, AmendHl7FileMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<AmendHl7FileMutation, AmendHl7FileMutationVariables>(AmendHl7FileDocument, options)
}
export type AmendHl7FileMutationHookResult = ReturnType<typeof useAmendHl7FileMutation>
export type AmendHl7FileMutationResult = Apollo.MutationResult<AmendHl7FileMutation>
export type AmendHl7FileMutationOptions = Apollo.BaseMutationOptions<
  AmendHl7FileMutation,
  AmendHl7FileMutationVariables
>
export const ProductListAdminDocument = gql`
  query ProductListAdmin($filter: ProductListFilter) {
    products(filter: $filter) {
      __typename
      sku
      name
      slug
      price
      description
      featuredImage {
        src
      }
      ... on LabTestProduct {
        testGroups {
          tests {
            id
          }
        }
        recommendedAddons {
          testAddon {
            code
            name
            price
          }
        }
      }
    }
  }
`

/**
 * __useProductListAdminQuery__
 *
 * To run a query within a React component, call `useProductListAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductListAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductListAdminQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useProductListAdminQuery(
  baseOptions?: Apollo.QueryHookOptions<ProductListAdminQuery, ProductListAdminQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ProductListAdminQuery, ProductListAdminQueryVariables>(ProductListAdminDocument, options)
}
export function useProductListAdminLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProductListAdminQuery, ProductListAdminQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ProductListAdminQuery, ProductListAdminQueryVariables>(ProductListAdminDocument, options)
}
export type ProductListAdminQueryHookResult = ReturnType<typeof useProductListAdminQuery>
export type ProductListAdminLazyQueryHookResult = ReturnType<typeof useProductListAdminLazyQuery>
export type ProductListAdminQueryResult = Apollo.QueryResult<ProductListAdminQuery, ProductListAdminQueryVariables>
export const ProductListHekaDocument = gql`
  query ProductListHeka($filter: ProductListFilter) {
    products(filter: $filter) {
      __typename
      sku
      name
      slug
      price
      description
      ... on LabTestProduct {
        valueProposition
        faqItems {
          question
          answer
        }
        testGroups {
          analyses {
            id
          }
          name
          tests {
            id
            name
            description
            categories
            sample
            sampleTube
            analyses {
              id
              name
              description
            }
            turnaroundTime
          }
          why
        }
        biomarkers
        sampleLocation
        analysisLocation
        turnaroundTime
        sampleTypes
        whoFor
      }
    }
  }
`

/**
 * __useProductListHekaQuery__
 *
 * To run a query within a React component, call `useProductListHekaQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductListHekaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductListHekaQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useProductListHekaQuery(
  baseOptions?: Apollo.QueryHookOptions<ProductListHekaQuery, ProductListHekaQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ProductListHekaQuery, ProductListHekaQueryVariables>(ProductListHekaDocument, options)
}
export function useProductListHekaLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProductListHekaQuery, ProductListHekaQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ProductListHekaQuery, ProductListHekaQueryVariables>(ProductListHekaDocument, options)
}
export type ProductListHekaQueryHookResult = ReturnType<typeof useProductListHekaQuery>
export type ProductListHekaLazyQueryHookResult = ReturnType<typeof useProductListHekaLazyQuery>
export type ProductListHekaQueryResult = Apollo.QueryResult<ProductListHekaQuery, ProductListHekaQueryVariables>
export const ProductListDocument = gql`
  query ProductList($filter: ProductListFilter) {
    products(filter: $filter) {
      __typename
      sku
      name
      slug
      price
      description
      featuredImage {
        src
      }
      ... on LabTestProduct {
        shoppingFeedImage {
          src
        }
      }
    }
  }
`

/**
 * __useProductListQuery__
 *
 * To run a query within a React component, call `useProductListQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useProductListQuery(
  baseOptions?: Apollo.QueryHookOptions<ProductListQuery, ProductListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ProductListQuery, ProductListQueryVariables>(ProductListDocument, options)
}
export function useProductListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProductListQuery, ProductListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ProductListQuery, ProductListQueryVariables>(ProductListDocument, options)
}
export type ProductListQueryHookResult = ReturnType<typeof useProductListQuery>
export type ProductListLazyQueryHookResult = ReturnType<typeof useProductListLazyQuery>
export type ProductListQueryResult = Apollo.QueryResult<ProductListQuery, ProductListQueryVariables>
export const MailchimpSaveCalculateOrderDocument = gql`
  query MailchimpSaveCalculateOrder($input: CalculateOrderInput!) {
    calculateOrder(input: $input) {
      __typename
      lines {
        product {
          sku
        }
        modifiers {
          code
        }
        quantity
        totalPrice
      }
      total
      subTotal
    }
  }
`

/**
 * __useMailchimpSaveCalculateOrderQuery__
 *
 * To run a query within a React component, call `useMailchimpSaveCalculateOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useMailchimpSaveCalculateOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMailchimpSaveCalculateOrderQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMailchimpSaveCalculateOrderQuery(
  baseOptions: Apollo.QueryHookOptions<MailchimpSaveCalculateOrderQuery, MailchimpSaveCalculateOrderQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<MailchimpSaveCalculateOrderQuery, MailchimpSaveCalculateOrderQueryVariables>(
    MailchimpSaveCalculateOrderDocument,
    options,
  )
}
export function useMailchimpSaveCalculateOrderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MailchimpSaveCalculateOrderQuery,
    MailchimpSaveCalculateOrderQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<MailchimpSaveCalculateOrderQuery, MailchimpSaveCalculateOrderQueryVariables>(
    MailchimpSaveCalculateOrderDocument,
    options,
  )
}
export type MailchimpSaveCalculateOrderQueryHookResult = ReturnType<typeof useMailchimpSaveCalculateOrderQuery>
export type MailchimpSaveCalculateOrderLazyQueryHookResult = ReturnType<typeof useMailchimpSaveCalculateOrderLazyQuery>
export type MailchimpSaveCalculateOrderQueryResult = Apollo.QueryResult<
  MailchimpSaveCalculateOrderQuery,
  MailchimpSaveCalculateOrderQueryVariables
>
export const ConfirmUserOrderDocument = gql`
  mutation confirmUserOrder($input: ConfirmOrderInput!) {
    confirmOrder(input: $input) {
      __typename
      orderId
    }
  }
`
export type ConfirmUserOrderMutationFn = Apollo.MutationFunction<
  ConfirmUserOrderMutation,
  ConfirmUserOrderMutationVariables
>

/**
 * __useConfirmUserOrderMutation__
 *
 * To run a mutation, you first call `useConfirmUserOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmUserOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmUserOrderMutation, { data, loading, error }] = useConfirmUserOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConfirmUserOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<ConfirmUserOrderMutation, ConfirmUserOrderMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ConfirmUserOrderMutation, ConfirmUserOrderMutationVariables>(
    ConfirmUserOrderDocument,
    options,
  )
}
export type ConfirmUserOrderMutationHookResult = ReturnType<typeof useConfirmUserOrderMutation>
export type ConfirmUserOrderMutationResult = Apollo.MutationResult<ConfirmUserOrderMutation>
export type ConfirmUserOrderMutationOptions = Apollo.BaseMutationOptions<
  ConfirmUserOrderMutation,
  ConfirmUserOrderMutationVariables
>
export const CoachProductBySlugDocument = gql`
  query coachProductBySlug($slug: ID!) {
    productBySlug(slug: $slug) {
      __typename
      sku
      active
      type
      name
      slug
      price
      description
      featuredImage {
        src
        name
      }
    }
  }
`

/**
 * __useCoachProductBySlugQuery__
 *
 * To run a query within a React component, call `useCoachProductBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoachProductBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoachProductBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useCoachProductBySlugQuery(
  baseOptions: Apollo.QueryHookOptions<CoachProductBySlugQuery, CoachProductBySlugQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CoachProductBySlugQuery, CoachProductBySlugQueryVariables>(CoachProductBySlugDocument, options)
}
export function useCoachProductBySlugLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CoachProductBySlugQuery, CoachProductBySlugQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CoachProductBySlugQuery, CoachProductBySlugQueryVariables>(
    CoachProductBySlugDocument,
    options,
  )
}
export type CoachProductBySlugQueryHookResult = ReturnType<typeof useCoachProductBySlugQuery>
export type CoachProductBySlugLazyQueryHookResult = ReturnType<typeof useCoachProductBySlugLazyQuery>
export type CoachProductBySlugQueryResult = Apollo.QueryResult<
  CoachProductBySlugQuery,
  CoachProductBySlugQueryVariables
>
export const CoachProductsDocument = gql`
  query CoachProducts($filter: ProductListFilter) {
    products(filter: $filter) {
      __typename
      active
      description
      featuredImage {
        name
        src
      }
      name
      price
      sku
      slug
      type
    }
  }
`

/**
 * __useCoachProductsQuery__
 *
 * To run a query within a React component, call `useCoachProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoachProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoachProductsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCoachProductsQuery(
  baseOptions?: Apollo.QueryHookOptions<CoachProductsQuery, CoachProductsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CoachProductsQuery, CoachProductsQueryVariables>(CoachProductsDocument, options)
}
export function useCoachProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CoachProductsQuery, CoachProductsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CoachProductsQuery, CoachProductsQueryVariables>(CoachProductsDocument, options)
}
export type CoachProductsQueryHookResult = ReturnType<typeof useCoachProductsQuery>
export type CoachProductsLazyQueryHookResult = ReturnType<typeof useCoachProductsLazyQuery>
export type CoachProductsQueryResult = Apollo.QueryResult<CoachProductsQuery, CoachProductsQueryVariables>
export const CgmProductBySlugDocument = gql`
  query cgmProductBySlug($slug: ID!) {
    productBySlug(slug: $slug) {
      __typename
      sku
      active
      type
      name
      slug
      price
      description
      featuredImage {
        src
        name
      }
      blogArticles {
        __typename
        ...BlogCard
      }
      allowPurchase
    }
  }
  ${BlogCardFragmentDoc}
`

/**
 * __useCgmProductBySlugQuery__
 *
 * To run a query within a React component, call `useCgmProductBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useCgmProductBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCgmProductBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useCgmProductBySlugQuery(
  baseOptions: Apollo.QueryHookOptions<CgmProductBySlugQuery, CgmProductBySlugQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CgmProductBySlugQuery, CgmProductBySlugQueryVariables>(CgmProductBySlugDocument, options)
}
export function useCgmProductBySlugLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CgmProductBySlugQuery, CgmProductBySlugQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CgmProductBySlugQuery, CgmProductBySlugQueryVariables>(CgmProductBySlugDocument, options)
}
export type CgmProductBySlugQueryHookResult = ReturnType<typeof useCgmProductBySlugQuery>
export type CgmProductBySlugLazyQueryHookResult = ReturnType<typeof useCgmProductBySlugLazyQuery>
export type CgmProductBySlugQueryResult = Apollo.QueryResult<CgmProductBySlugQuery, CgmProductBySlugQueryVariables>
export const CheckBalanceDocument = gql`
  query CheckBalance($id: String!) {
    giftCardBalance(id: $id) {
      __typename
      balance
    }
  }
`

/**
 * __useCheckBalanceQuery__
 *
 * To run a query within a React component, call `useCheckBalanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckBalanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckBalanceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCheckBalanceQuery(
  baseOptions: Apollo.QueryHookOptions<CheckBalanceQuery, CheckBalanceQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CheckBalanceQuery, CheckBalanceQueryVariables>(CheckBalanceDocument, options)
}
export function useCheckBalanceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CheckBalanceQuery, CheckBalanceQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CheckBalanceQuery, CheckBalanceQueryVariables>(CheckBalanceDocument, options)
}
export type CheckBalanceQueryHookResult = ReturnType<typeof useCheckBalanceQuery>
export type CheckBalanceLazyQueryHookResult = ReturnType<typeof useCheckBalanceLazyQuery>
export type CheckBalanceQueryResult = Apollo.QueryResult<CheckBalanceQuery, CheckBalanceQueryVariables>
export const GiftCardsProductsDocument = gql`
  query GiftCardsProducts($filter: ProductListFilter) {
    products(filter: $filter) {
      __typename
      sku
      name
      slug
      type
      price
      description
      featuredImage {
        __typename
        src
        name
      }
    }
  }
`

/**
 * __useGiftCardsProductsQuery__
 *
 * To run a query within a React component, call `useGiftCardsProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGiftCardsProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGiftCardsProductsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGiftCardsProductsQuery(
  baseOptions?: Apollo.QueryHookOptions<GiftCardsProductsQuery, GiftCardsProductsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GiftCardsProductsQuery, GiftCardsProductsQueryVariables>(GiftCardsProductsDocument, options)
}
export function useGiftCardsProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GiftCardsProductsQuery, GiftCardsProductsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GiftCardsProductsQuery, GiftCardsProductsQueryVariables>(
    GiftCardsProductsDocument,
    options,
  )
}
export type GiftCardsProductsQueryHookResult = ReturnType<typeof useGiftCardsProductsQuery>
export type GiftCardsProductsLazyQueryHookResult = ReturnType<typeof useGiftCardsProductsLazyQuery>
export type GiftCardsProductsQueryResult = Apollo.QueryResult<GiftCardsProductsQuery, GiftCardsProductsQueryVariables>
export const TestProductSummaryBySlugDocument = gql`
  query testProductSummaryBySlug($slug: ID!) {
    productBySlug(slug: $slug) {
      __typename
      ... on LabTestProduct {
        sku
        name
        slug
        featuredImage {
          src
          name
        }
      }
    }
  }
`

/**
 * __useTestProductSummaryBySlugQuery__
 *
 * To run a query within a React component, call `useTestProductSummaryBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useTestProductSummaryBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTestProductSummaryBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useTestProductSummaryBySlugQuery(
  baseOptions: Apollo.QueryHookOptions<TestProductSummaryBySlugQuery, TestProductSummaryBySlugQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<TestProductSummaryBySlugQuery, TestProductSummaryBySlugQueryVariables>(
    TestProductSummaryBySlugDocument,
    options,
  )
}
export function useTestProductSummaryBySlugLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TestProductSummaryBySlugQuery, TestProductSummaryBySlugQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<TestProductSummaryBySlugQuery, TestProductSummaryBySlugQueryVariables>(
    TestProductSummaryBySlugDocument,
    options,
  )
}
export type TestProductSummaryBySlugQueryHookResult = ReturnType<typeof useTestProductSummaryBySlugQuery>
export type TestProductSummaryBySlugLazyQueryHookResult = ReturnType<typeof useTestProductSummaryBySlugLazyQuery>
export type TestProductSummaryBySlugQueryResult = Apollo.QueryResult<
  TestProductSummaryBySlugQuery,
  TestProductSummaryBySlugQueryVariables
>
export const MainPageArticlesDocument = gql`
  query MainPageArticles($articleSlugs: [String!]!) {
    articlesBySlug(slugs: $articleSlugs) {
      __typename
      ...BlogCard
    }
  }
  ${BlogCardFragmentDoc}
`

/**
 * __useMainPageArticlesQuery__
 *
 * To run a query within a React component, call `useMainPageArticlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMainPageArticlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMainPageArticlesQuery({
 *   variables: {
 *      articleSlugs: // value for 'articleSlugs'
 *   },
 * });
 */
export function useMainPageArticlesQuery(
  baseOptions: Apollo.QueryHookOptions<MainPageArticlesQuery, MainPageArticlesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<MainPageArticlesQuery, MainPageArticlesQueryVariables>(MainPageArticlesDocument, options)
}
export function useMainPageArticlesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MainPageArticlesQuery, MainPageArticlesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<MainPageArticlesQuery, MainPageArticlesQueryVariables>(MainPageArticlesDocument, options)
}
export type MainPageArticlesQueryHookResult = ReturnType<typeof useMainPageArticlesQuery>
export type MainPageArticlesLazyQueryHookResult = ReturnType<typeof useMainPageArticlesLazyQuery>
export type MainPageArticlesQueryResult = Apollo.QueryResult<MainPageArticlesQuery, MainPageArticlesQueryVariables>
export const CgmPageDataDocument = gql`
  query CgmPageData($articleSlugs: [String!]!) {
    articlesBySlug(slugs: $articleSlugs) {
      __typename
      ...BlogCard
    }
  }
  ${BlogCardFragmentDoc}
`

/**
 * __useCgmPageDataQuery__
 *
 * To run a query within a React component, call `useCgmPageDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useCgmPageDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCgmPageDataQuery({
 *   variables: {
 *      articleSlugs: // value for 'articleSlugs'
 *   },
 * });
 */
export function useCgmPageDataQuery(baseOptions: Apollo.QueryHookOptions<CgmPageDataQuery, CgmPageDataQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CgmPageDataQuery, CgmPageDataQueryVariables>(CgmPageDataDocument, options)
}
export function useCgmPageDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CgmPageDataQuery, CgmPageDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CgmPageDataQuery, CgmPageDataQueryVariables>(CgmPageDataDocument, options)
}
export type CgmPageDataQueryHookResult = ReturnType<typeof useCgmPageDataQuery>
export type CgmPageDataLazyQueryHookResult = ReturnType<typeof useCgmPageDataLazyQuery>
export type CgmPageDataQueryResult = Apollo.QueryResult<CgmPageDataQuery, CgmPageDataQueryVariables>
export const GeneralHealthPageDataDocument = gql`
  query GeneralHealthPageData($path: String!, $buttonSlugs: [String!]!) {
    allArticles: articlesByPath(path: $path) {
      __typename
      ...BlogCard
    }
    headerButtons: articlesBySlug(slugs: $buttonSlugs) {
      path
      slug
    }
  }
  ${BlogCardFragmentDoc}
`

/**
 * __useGeneralHealthPageDataQuery__
 *
 * To run a query within a React component, call `useGeneralHealthPageDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGeneralHealthPageDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGeneralHealthPageDataQuery({
 *   variables: {
 *      path: // value for 'path'
 *      buttonSlugs: // value for 'buttonSlugs'
 *   },
 * });
 */
export function useGeneralHealthPageDataQuery(
  baseOptions: Apollo.QueryHookOptions<GeneralHealthPageDataQuery, GeneralHealthPageDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GeneralHealthPageDataQuery, GeneralHealthPageDataQueryVariables>(
    GeneralHealthPageDataDocument,
    options,
  )
}
export function useGeneralHealthPageDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GeneralHealthPageDataQuery, GeneralHealthPageDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GeneralHealthPageDataQuery, GeneralHealthPageDataQueryVariables>(
    GeneralHealthPageDataDocument,
    options,
  )
}
export type GeneralHealthPageDataQueryHookResult = ReturnType<typeof useGeneralHealthPageDataQuery>
export type GeneralHealthPageDataLazyQueryHookResult = ReturnType<typeof useGeneralHealthPageDataLazyQuery>
export type GeneralHealthPageDataQueryResult = Apollo.QueryResult<
  GeneralHealthPageDataQuery,
  GeneralHealthPageDataQueryVariables
>
export const GutHealthPageDataDocument = gql`
  query GutHealthPageData($path: String!, $allSlugs: [String!]!) {
    articlesByPath(path: $path) {
      __typename
      ...BlogCard
    }
    articlesBySlug(slugs: $allSlugs) {
      __typename
      ...BlogCard
    }
  }
  ${BlogCardFragmentDoc}
`

/**
 * __useGutHealthPageDataQuery__
 *
 * To run a query within a React component, call `useGutHealthPageDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGutHealthPageDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGutHealthPageDataQuery({
 *   variables: {
 *      path: // value for 'path'
 *      allSlugs: // value for 'allSlugs'
 *   },
 * });
 */
export function useGutHealthPageDataQuery(
  baseOptions: Apollo.QueryHookOptions<GutHealthPageDataQuery, GutHealthPageDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GutHealthPageDataQuery, GutHealthPageDataQueryVariables>(GutHealthPageDataDocument, options)
}
export function useGutHealthPageDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GutHealthPageDataQuery, GutHealthPageDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GutHealthPageDataQuery, GutHealthPageDataQueryVariables>(
    GutHealthPageDataDocument,
    options,
  )
}
export type GutHealthPageDataQueryHookResult = ReturnType<typeof useGutHealthPageDataQuery>
export type GutHealthPageDataLazyQueryHookResult = ReturnType<typeof useGutHealthPageDataLazyQuery>
export type GutHealthPageDataQueryResult = Apollo.QueryResult<GutHealthPageDataQuery, GutHealthPageDataQueryVariables>
export const HealthOptimisationPageDataDocument = gql`
  query HealthOptimisationPageData($path: String!, $buttonSlugs: [String!]!) {
    allArticles: articlesByPath(path: $path) {
      __typename
      ...BlogCard
    }
    headerButtons: articlesBySlug(slugs: $buttonSlugs) {
      path
      slug
    }
  }
  ${BlogCardFragmentDoc}
`

/**
 * __useHealthOptimisationPageDataQuery__
 *
 * To run a query within a React component, call `useHealthOptimisationPageDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useHealthOptimisationPageDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHealthOptimisationPageDataQuery({
 *   variables: {
 *      path: // value for 'path'
 *      buttonSlugs: // value for 'buttonSlugs'
 *   },
 * });
 */
export function useHealthOptimisationPageDataQuery(
  baseOptions: Apollo.QueryHookOptions<HealthOptimisationPageDataQuery, HealthOptimisationPageDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<HealthOptimisationPageDataQuery, HealthOptimisationPageDataQueryVariables>(
    HealthOptimisationPageDataDocument,
    options,
  )
}
export function useHealthOptimisationPageDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<HealthOptimisationPageDataQuery, HealthOptimisationPageDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<HealthOptimisationPageDataQuery, HealthOptimisationPageDataQueryVariables>(
    HealthOptimisationPageDataDocument,
    options,
  )
}
export type HealthOptimisationPageDataQueryHookResult = ReturnType<typeof useHealthOptimisationPageDataQuery>
export type HealthOptimisationPageDataLazyQueryHookResult = ReturnType<typeof useHealthOptimisationPageDataLazyQuery>
export type HealthOptimisationPageDataQueryResult = Apollo.QueryResult<
  HealthOptimisationPageDataQuery,
  HealthOptimisationPageDataQueryVariables
>
export const LearnHubPageDataDocument = gql`
  query LearnHubPageData($trendingSlugs: [String!]!) {
    allArticles {
      __typename
      ...BlogCard
      summary
    }
    trendingArticles: articlesBySlug(slugs: $trendingSlugs) {
      __typename
      ...BlogCard
    }
  }
  ${BlogCardFragmentDoc}
`

/**
 * __useLearnHubPageDataQuery__
 *
 * To run a query within a React component, call `useLearnHubPageDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useLearnHubPageDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLearnHubPageDataQuery({
 *   variables: {
 *      trendingSlugs: // value for 'trendingSlugs'
 *   },
 * });
 */
export function useLearnHubPageDataQuery(
  baseOptions: Apollo.QueryHookOptions<LearnHubPageDataQuery, LearnHubPageDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<LearnHubPageDataQuery, LearnHubPageDataQueryVariables>(LearnHubPageDataDocument, options)
}
export function useLearnHubPageDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LearnHubPageDataQuery, LearnHubPageDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<LearnHubPageDataQuery, LearnHubPageDataQueryVariables>(LearnHubPageDataDocument, options)
}
export type LearnHubPageDataQueryHookResult = ReturnType<typeof useLearnHubPageDataQuery>
export type LearnHubPageDataLazyQueryHookResult = ReturnType<typeof useLearnHubPageDataLazyQuery>
export type LearnHubPageDataQueryResult = Apollo.QueryResult<LearnHubPageDataQuery, LearnHubPageDataQueryVariables>
export const BloodSugarPageDataDocument = gql`
  query BloodSugarPageData($path: String!, $buttonSlugs: [String!]!) {
    allArticles: articlesByPath(path: $path) {
      __typename
      ...BlogCard
    }
    headerButtons: articlesBySlug(slugs: $buttonSlugs) {
      path
      slug
    }
  }
  ${BlogCardFragmentDoc}
`

/**
 * __useBloodSugarPageDataQuery__
 *
 * To run a query within a React component, call `useBloodSugarPageDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useBloodSugarPageDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBloodSugarPageDataQuery({
 *   variables: {
 *      path: // value for 'path'
 *      buttonSlugs: // value for 'buttonSlugs'
 *   },
 * });
 */
export function useBloodSugarPageDataQuery(
  baseOptions: Apollo.QueryHookOptions<BloodSugarPageDataQuery, BloodSugarPageDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<BloodSugarPageDataQuery, BloodSugarPageDataQueryVariables>(BloodSugarPageDataDocument, options)
}
export function useBloodSugarPageDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BloodSugarPageDataQuery, BloodSugarPageDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<BloodSugarPageDataQuery, BloodSugarPageDataQueryVariables>(
    BloodSugarPageDataDocument,
    options,
  )
}
export type BloodSugarPageDataQueryHookResult = ReturnType<typeof useBloodSugarPageDataQuery>
export type BloodSugarPageDataLazyQueryHookResult = ReturnType<typeof useBloodSugarPageDataLazyQuery>
export type BloodSugarPageDataQueryResult = Apollo.QueryResult<
  BloodSugarPageDataQuery,
  BloodSugarPageDataQueryVariables
>
export const MetabolicHealthPageDataDocument = gql`
  query MetabolicHealthPageData($path: String!, $allSlugs: [String!]!) {
    articlesByPath(path: $path) {
      __typename
      ...BlogCard
    }
    articlesBySlug(slugs: $allSlugs) {
      __typename
      ...BlogCard
    }
  }
  ${BlogCardFragmentDoc}
`

/**
 * __useMetabolicHealthPageDataQuery__
 *
 * To run a query within a React component, call `useMetabolicHealthPageDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useMetabolicHealthPageDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMetabolicHealthPageDataQuery({
 *   variables: {
 *      path: // value for 'path'
 *      allSlugs: // value for 'allSlugs'
 *   },
 * });
 */
export function useMetabolicHealthPageDataQuery(
  baseOptions: Apollo.QueryHookOptions<MetabolicHealthPageDataQuery, MetabolicHealthPageDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<MetabolicHealthPageDataQuery, MetabolicHealthPageDataQueryVariables>(
    MetabolicHealthPageDataDocument,
    options,
  )
}
export function useMetabolicHealthPageDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MetabolicHealthPageDataQuery, MetabolicHealthPageDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<MetabolicHealthPageDataQuery, MetabolicHealthPageDataQueryVariables>(
    MetabolicHealthPageDataDocument,
    options,
  )
}
export type MetabolicHealthPageDataQueryHookResult = ReturnType<typeof useMetabolicHealthPageDataQuery>
export type MetabolicHealthPageDataLazyQueryHookResult = ReturnType<typeof useMetabolicHealthPageDataLazyQuery>
export type MetabolicHealthPageDataQueryResult = Apollo.QueryResult<
  MetabolicHealthPageDataQuery,
  MetabolicHealthPageDataQueryVariables
>
export const TestingPageDataDocument = gql`
  query TestingPageData($path: String!, $buttonSlugs: [String!]!) {
    allArticles: articlesByPath(path: $path) {
      __typename
      ...BlogCard
    }
    headerButtons: articlesBySlug(slugs: $buttonSlugs) {
      path
      slug
    }
  }
  ${BlogCardFragmentDoc}
`

/**
 * __useTestingPageDataQuery__
 *
 * To run a query within a React component, call `useTestingPageDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useTestingPageDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTestingPageDataQuery({
 *   variables: {
 *      path: // value for 'path'
 *      buttonSlugs: // value for 'buttonSlugs'
 *   },
 * });
 */
export function useTestingPageDataQuery(
  baseOptions: Apollo.QueryHookOptions<TestingPageDataQuery, TestingPageDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<TestingPageDataQuery, TestingPageDataQueryVariables>(TestingPageDataDocument, options)
}
export function useTestingPageDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TestingPageDataQuery, TestingPageDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<TestingPageDataQuery, TestingPageDataQueryVariables>(TestingPageDataDocument, options)
}
export type TestingPageDataQueryHookResult = ReturnType<typeof useTestingPageDataQuery>
export type TestingPageDataLazyQueryHookResult = ReturnType<typeof useTestingPageDataLazyQuery>
export type TestingPageDataQueryResult = Apollo.QueryResult<TestingPageDataQuery, TestingPageDataQueryVariables>
export const TeamMemberBySlugDocument = gql`
  query teamMemberBySlug($slug: String!) {
    teamMemberBySlug(slug: $slug) {
      id
      firstName
      lastName
      slug
      title
      role
      postNominals
      gmc
      primaryImage {
        name
        src
      }
      secondaryImage {
        name
        src
      }
      outdoorImage {
        name
        src
      }
      articles {
        ...BlogCard
      }
      miniBio
      fullBio
      statementHeader
      statement
      features
      publications
      expertise
    }
  }
  ${BlogCardFragmentDoc}
`

/**
 * __useTeamMemberBySlugQuery__
 *
 * To run a query within a React component, call `useTeamMemberBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamMemberBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamMemberBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useTeamMemberBySlugQuery(
  baseOptions: Apollo.QueryHookOptions<TeamMemberBySlugQuery, TeamMemberBySlugQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<TeamMemberBySlugQuery, TeamMemberBySlugQueryVariables>(TeamMemberBySlugDocument, options)
}
export function useTeamMemberBySlugLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TeamMemberBySlugQuery, TeamMemberBySlugQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<TeamMemberBySlugQuery, TeamMemberBySlugQueryVariables>(TeamMemberBySlugDocument, options)
}
export type TeamMemberBySlugQueryHookResult = ReturnType<typeof useTeamMemberBySlugQuery>
export type TeamMemberBySlugLazyQueryHookResult = ReturnType<typeof useTeamMemberBySlugLazyQuery>
export type TeamMemberBySlugQueryResult = Apollo.QueryResult<TeamMemberBySlugQuery, TeamMemberBySlugQueryVariables>
export const TeamDocument = gql`
  query Team {
    team {
      id
      firstName
      lastName
      slug
      title
      primaryImage {
        name
        src
      }
      miniBio
      role
      postNominals
    }
  }
`

/**
 * __useTeamQuery__
 *
 * To run a query within a React component, call `useTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamQuery({
 *   variables: {
 *   },
 * });
 */
export function useTeamQuery(baseOptions?: Apollo.QueryHookOptions<TeamQuery, TeamQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<TeamQuery, TeamQueryVariables>(TeamDocument, options)
}
export function useTeamLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TeamQuery, TeamQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<TeamQuery, TeamQueryVariables>(TeamDocument, options)
}
export type TeamQueryHookResult = ReturnType<typeof useTeamQuery>
export type TeamLazyQueryHookResult = ReturnType<typeof useTeamLazyQuery>
export type TeamQueryResult = Apollo.QueryResult<TeamQuery, TeamQueryVariables>
export const TestProductBySlugDocument = gql`
  query testProductBySlug($slug: ID!) {
    productBySlug(slug: $slug) {
      __typename
      ... on LabTestProduct {
        ...TestProduct
      }
    }
  }
  ${TestProductFragmentDoc}
`

/**
 * __useTestProductBySlugQuery__
 *
 * To run a query within a React component, call `useTestProductBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useTestProductBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTestProductBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useTestProductBySlugQuery(
  baseOptions: Apollo.QueryHookOptions<TestProductBySlugQuery, TestProductBySlugQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<TestProductBySlugQuery, TestProductBySlugQueryVariables>(TestProductBySlugDocument, options)
}
export function useTestProductBySlugLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TestProductBySlugQuery, TestProductBySlugQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<TestProductBySlugQuery, TestProductBySlugQueryVariables>(
    TestProductBySlugDocument,
    options,
  )
}
export type TestProductBySlugQueryHookResult = ReturnType<typeof useTestProductBySlugQuery>
export type TestProductBySlugLazyQueryHookResult = ReturnType<typeof useTestProductBySlugLazyQuery>
export type TestProductBySlugQueryResult = Apollo.QueryResult<TestProductBySlugQuery, TestProductBySlugQueryVariables>
export const AllTestProductsDocument = gql`
  query allTestProducts($filter: ProductListFilter) {
    products(filter: $filter) {
      slug
    }
  }
`

/**
 * __useAllTestProductsQuery__
 *
 * To run a query within a React component, call `useAllTestProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTestProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTestProductsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAllTestProductsQuery(
  baseOptions?: Apollo.QueryHookOptions<AllTestProductsQuery, AllTestProductsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AllTestProductsQuery, AllTestProductsQueryVariables>(AllTestProductsDocument, options)
}
export function useAllTestProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AllTestProductsQuery, AllTestProductsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AllTestProductsQuery, AllTestProductsQueryVariables>(AllTestProductsDocument, options)
}
export type AllTestProductsQueryHookResult = ReturnType<typeof useAllTestProductsQuery>
export type AllTestProductsLazyQueryHookResult = ReturnType<typeof useAllTestProductsLazyQuery>
export type AllTestProductsQueryResult = Apollo.QueryResult<AllTestProductsQuery, AllTestProductsQueryVariables>
export const AllBlogCardsDocument = gql`
  query allBlogCards {
    allArticles {
      __typename
      ...BlogCard
    }
  }
  ${BlogCardFragmentDoc}
`

/**
 * __useAllBlogCardsQuery__
 *
 * To run a query within a React component, call `useAllBlogCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllBlogCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllBlogCardsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllBlogCardsQuery(
  baseOptions?: Apollo.QueryHookOptions<AllBlogCardsQuery, AllBlogCardsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AllBlogCardsQuery, AllBlogCardsQueryVariables>(AllBlogCardsDocument, options)
}
export function useAllBlogCardsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AllBlogCardsQuery, AllBlogCardsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AllBlogCardsQuery, AllBlogCardsQueryVariables>(AllBlogCardsDocument, options)
}
export type AllBlogCardsQueryHookResult = ReturnType<typeof useAllBlogCardsQuery>
export type AllBlogCardsLazyQueryHookResult = ReturnType<typeof useAllBlogCardsLazyQuery>
export type AllBlogCardsQueryResult = Apollo.QueryResult<AllBlogCardsQuery, AllBlogCardsQueryVariables>
export const AllArticlesSitemapDocument = gql`
  query AllArticlesSitemap {
    allArticles {
      path
      publishedAt
      updatedAt
    }
  }
`

/**
 * __useAllArticlesSitemapQuery__
 *
 * To run a query within a React component, call `useAllArticlesSitemapQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllArticlesSitemapQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllArticlesSitemapQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllArticlesSitemapQuery(
  baseOptions?: Apollo.QueryHookOptions<AllArticlesSitemapQuery, AllArticlesSitemapQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AllArticlesSitemapQuery, AllArticlesSitemapQueryVariables>(AllArticlesSitemapDocument, options)
}
export function useAllArticlesSitemapLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AllArticlesSitemapQuery, AllArticlesSitemapQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AllArticlesSitemapQuery, AllArticlesSitemapQueryVariables>(
    AllArticlesSitemapDocument,
    options,
  )
}
export type AllArticlesSitemapQueryHookResult = ReturnType<typeof useAllArticlesSitemapQuery>
export type AllArticlesSitemapLazyQueryHookResult = ReturnType<typeof useAllArticlesSitemapLazyQuery>
export type AllArticlesSitemapQueryResult = Apollo.QueryResult<
  AllArticlesSitemapQuery,
  AllArticlesSitemapQueryVariables
>
export const AllProductsSitemapDocument = gql`
  query AllProductsSitemap($filter: ProductListFilter) {
    products(filter: $filter) {
      type
      slug
      updatedAt
    }
  }
`

/**
 * __useAllProductsSitemapQuery__
 *
 * To run a query within a React component, call `useAllProductsSitemapQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllProductsSitemapQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllProductsSitemapQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAllProductsSitemapQuery(
  baseOptions?: Apollo.QueryHookOptions<AllProductsSitemapQuery, AllProductsSitemapQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AllProductsSitemapQuery, AllProductsSitemapQueryVariables>(AllProductsSitemapDocument, options)
}
export function useAllProductsSitemapLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AllProductsSitemapQuery, AllProductsSitemapQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AllProductsSitemapQuery, AllProductsSitemapQueryVariables>(
    AllProductsSitemapDocument,
    options,
  )
}
export type AllProductsSitemapQueryHookResult = ReturnType<typeof useAllProductsSitemapQuery>
export type AllProductsSitemapLazyQueryHookResult = ReturnType<typeof useAllProductsSitemapLazyQuery>
export type AllProductsSitemapQueryResult = Apollo.QueryResult<
  AllProductsSitemapQuery,
  AllProductsSitemapQueryVariables
>
export const AllTeamMembersSitemapDocument = gql`
  query AllTeamMembersSitemap {
    team {
      slug
      updatedAt
    }
  }
`

/**
 * __useAllTeamMembersSitemapQuery__
 *
 * To run a query within a React component, call `useAllTeamMembersSitemapQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTeamMembersSitemapQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTeamMembersSitemapQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllTeamMembersSitemapQuery(
  baseOptions?: Apollo.QueryHookOptions<AllTeamMembersSitemapQuery, AllTeamMembersSitemapQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AllTeamMembersSitemapQuery, AllTeamMembersSitemapQueryVariables>(
    AllTeamMembersSitemapDocument,
    options,
  )
}
export function useAllTeamMembersSitemapLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AllTeamMembersSitemapQuery, AllTeamMembersSitemapQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AllTeamMembersSitemapQuery, AllTeamMembersSitemapQueryVariables>(
    AllTeamMembersSitemapDocument,
    options,
  )
}
export type AllTeamMembersSitemapQueryHookResult = ReturnType<typeof useAllTeamMembersSitemapQuery>
export type AllTeamMembersSitemapLazyQueryHookResult = ReturnType<typeof useAllTeamMembersSitemapLazyQuery>
export type AllTeamMembersSitemapQueryResult = Apollo.QueryResult<
  AllTeamMembersSitemapQuery,
  AllTeamMembersSitemapQueryVariables
>
export const SupplementProductPageDataDocument = gql`
  query SupplementProductPageData($slug: ID!, $articleSlugs: [String!]!) {
    productBySlug(slug: $slug) {
      __typename
      sku
      active
      type
      name
      slug
      price
      description
      featuredImage {
        src
        name
      }
      allowPurchase
    }
    articlesBySlug(slugs: $articleSlugs) {
      __typename
      ...BlogCard
    }
  }
  ${BlogCardFragmentDoc}
`

/**
 * __useSupplementProductPageDataQuery__
 *
 * To run a query within a React component, call `useSupplementProductPageDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useSupplementProductPageDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSupplementProductPageDataQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      articleSlugs: // value for 'articleSlugs'
 *   },
 * });
 */
export function useSupplementProductPageDataQuery(
  baseOptions: Apollo.QueryHookOptions<SupplementProductPageDataQuery, SupplementProductPageDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SupplementProductPageDataQuery, SupplementProductPageDataQueryVariables>(
    SupplementProductPageDataDocument,
    options,
  )
}
export function useSupplementProductPageDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SupplementProductPageDataQuery, SupplementProductPageDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SupplementProductPageDataQuery, SupplementProductPageDataQueryVariables>(
    SupplementProductPageDataDocument,
    options,
  )
}
export type SupplementProductPageDataQueryHookResult = ReturnType<typeof useSupplementProductPageDataQuery>
export type SupplementProductPageDataLazyQueryHookResult = ReturnType<typeof useSupplementProductPageDataLazyQuery>
export type SupplementProductPageDataQueryResult = Apollo.QueryResult<
  SupplementProductPageDataQuery,
  SupplementProductPageDataQueryVariables
>
export const BloodCollectionOptionsDocument = gql`
  query BloodCollectionOptions {
    bloodCollectionOptions {
      id
      code
      name
      description
      price
      bloodDrawType
    }
  }
`

/**
 * __useBloodCollectionOptionsQuery__
 *
 * To run a query within a React component, call `useBloodCollectionOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBloodCollectionOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBloodCollectionOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useBloodCollectionOptionsQuery(
  baseOptions?: Apollo.QueryHookOptions<BloodCollectionOptionsQuery, BloodCollectionOptionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<BloodCollectionOptionsQuery, BloodCollectionOptionsQueryVariables>(
    BloodCollectionOptionsDocument,
    options,
  )
}
export function useBloodCollectionOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BloodCollectionOptionsQuery, BloodCollectionOptionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<BloodCollectionOptionsQuery, BloodCollectionOptionsQueryVariables>(
    BloodCollectionOptionsDocument,
    options,
  )
}
export type BloodCollectionOptionsQueryHookResult = ReturnType<typeof useBloodCollectionOptionsQuery>
export type BloodCollectionOptionsLazyQueryHookResult = ReturnType<typeof useBloodCollectionOptionsLazyQuery>
export type BloodCollectionOptionsQueryResult = Apollo.QueryResult<
  BloodCollectionOptionsQuery,
  BloodCollectionOptionsQueryVariables
>
export const AllAvailableTestsDocument = gql`
  query allAvailableTests {
    testDefinitions {
      __typename
      id
      name
      friendlyName
      description
      capillary
      categories
      sample
      sampleTube
      analyses {
        id
        name
        description
      }
      turnaroundTime
      addonPrice
    }
  }
`

/**
 * __useAllAvailableTestsQuery__
 *
 * To run a query within a React component, call `useAllAvailableTestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllAvailableTestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllAvailableTestsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllAvailableTestsQuery(
  baseOptions?: Apollo.QueryHookOptions<AllAvailableTestsQuery, AllAvailableTestsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AllAvailableTestsQuery, AllAvailableTestsQueryVariables>(AllAvailableTestsDocument, options)
}
export function useAllAvailableTestsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AllAvailableTestsQuery, AllAvailableTestsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AllAvailableTestsQuery, AllAvailableTestsQueryVariables>(
    AllAvailableTestsDocument,
    options,
  )
}
export type AllAvailableTestsQueryHookResult = ReturnType<typeof useAllAvailableTestsQuery>
export type AllAvailableTestsLazyQueryHookResult = ReturnType<typeof useAllAvailableTestsLazyQuery>
export type AllAvailableTestsQueryResult = Apollo.QueryResult<AllAvailableTestsQuery, AllAvailableTestsQueryVariables>
export const TestsPageDataDocument = gql`
  query TestsPageData($filter: ProductListFilter, $articleSlugs: [String!]!) {
    tests: products(filter: $filter) {
      __typename
      ...LabTestProduct
    }
    articles: articlesBySlug(slugs: $articleSlugs) {
      __typename
      ...BlogCard
    }
  }
  ${LabTestProductFragmentDoc}
  ${BlogCardFragmentDoc}
`

/**
 * __useTestsPageDataQuery__
 *
 * To run a query within a React component, call `useTestsPageDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useTestsPageDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTestsPageDataQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      articleSlugs: // value for 'articleSlugs'
 *   },
 * });
 */
export function useTestsPageDataQuery(
  baseOptions: Apollo.QueryHookOptions<TestsPageDataQuery, TestsPageDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<TestsPageDataQuery, TestsPageDataQueryVariables>(TestsPageDataDocument, options)
}
export function useTestsPageDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TestsPageDataQuery, TestsPageDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<TestsPageDataQuery, TestsPageDataQueryVariables>(TestsPageDataDocument, options)
}
export type TestsPageDataQueryHookResult = ReturnType<typeof useTestsPageDataQuery>
export type TestsPageDataLazyQueryHookResult = ReturnType<typeof useTestsPageDataLazyQuery>
export type TestsPageDataQueryResult = Apollo.QueryResult<TestsPageDataQuery, TestsPageDataQueryVariables>
export const TestProductsDocument = gql`
  query TestProducts($filter: ProductListFilter) {
    products(filter: $filter) {
      __typename
      ...LabTestProduct
    }
  }
  ${LabTestProductFragmentDoc}
`

/**
 * __useTestProductsQuery__
 *
 * To run a query within a React component, call `useTestProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTestProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTestProductsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useTestProductsQuery(
  baseOptions?: Apollo.QueryHookOptions<TestProductsQuery, TestProductsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<TestProductsQuery, TestProductsQueryVariables>(TestProductsDocument, options)
}
export function useTestProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TestProductsQuery, TestProductsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<TestProductsQuery, TestProductsQueryVariables>(TestProductsDocument, options)
}
export type TestProductsQueryHookResult = ReturnType<typeof useTestProductsQuery>
export type TestProductsLazyQueryHookResult = ReturnType<typeof useTestProductsLazyQuery>
export type TestProductsQueryResult = Apollo.QueryResult<TestProductsQuery, TestProductsQueryVariables>
export const CompleteUserActivationDocument = gql`
  mutation CompleteUserActivation {
    completeActivation {
      message
      success
    }
  }
`
export type CompleteUserActivationMutationFn = Apollo.MutationFunction<
  CompleteUserActivationMutation,
  CompleteUserActivationMutationVariables
>

/**
 * __useCompleteUserActivationMutation__
 *
 * To run a mutation, you first call `useCompleteUserActivationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteUserActivationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeUserActivationMutation, { data, loading, error }] = useCompleteUserActivationMutation({
 *   variables: {
 *   },
 * });
 */
export function useCompleteUserActivationMutation(
  baseOptions?: Apollo.MutationHookOptions<CompleteUserActivationMutation, CompleteUserActivationMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CompleteUserActivationMutation, CompleteUserActivationMutationVariables>(
    CompleteUserActivationDocument,
    options,
  )
}
export type CompleteUserActivationMutationHookResult = ReturnType<typeof useCompleteUserActivationMutation>
export type CompleteUserActivationMutationResult = Apollo.MutationResult<CompleteUserActivationMutation>
export type CompleteUserActivationMutationOptions = Apollo.BaseMutationOptions<
  CompleteUserActivationMutation,
  CompleteUserActivationMutationVariables
>
export const ResendActivationEmailDocument = gql`
  mutation ResendActivationEmail($email: String!) {
    resendActivationEmail(email: $email) {
      message
      success
    }
  }
`
export type ResendActivationEmailMutationFn = Apollo.MutationFunction<
  ResendActivationEmailMutation,
  ResendActivationEmailMutationVariables
>

/**
 * __useResendActivationEmailMutation__
 *
 * To run a mutation, you first call `useResendActivationEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendActivationEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendActivationEmailMutation, { data, loading, error }] = useResendActivationEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useResendActivationEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<ResendActivationEmailMutation, ResendActivationEmailMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ResendActivationEmailMutation, ResendActivationEmailMutationVariables>(
    ResendActivationEmailDocument,
    options,
  )
}
export type ResendActivationEmailMutationHookResult = ReturnType<typeof useResendActivationEmailMutation>
export type ResendActivationEmailMutationResult = Apollo.MutationResult<ResendActivationEmailMutation>
export type ResendActivationEmailMutationOptions = Apollo.BaseMutationOptions<
  ResendActivationEmailMutation,
  ResendActivationEmailMutationVariables
>
export const VerifyActivationTokenDocument = gql`
  mutation VerifyActivationToken($token: ID!) {
    verifyToken(token: $token) {
      email
      message
      success
      outcome
      redirectTo
    }
  }
`
export type VerifyActivationTokenMutationFn = Apollo.MutationFunction<
  VerifyActivationTokenMutation,
  VerifyActivationTokenMutationVariables
>

/**
 * __useVerifyActivationTokenMutation__
 *
 * To run a mutation, you first call `useVerifyActivationTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyActivationTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyActivationTokenMutation, { data, loading, error }] = useVerifyActivationTokenMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useVerifyActivationTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<VerifyActivationTokenMutation, VerifyActivationTokenMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<VerifyActivationTokenMutation, VerifyActivationTokenMutationVariables>(
    VerifyActivationTokenDocument,
    options,
  )
}
export type VerifyActivationTokenMutationHookResult = ReturnType<typeof useVerifyActivationTokenMutation>
export type VerifyActivationTokenMutationResult = Apollo.MutationResult<VerifyActivationTokenMutation>
export type VerifyActivationTokenMutationOptions = Apollo.BaseMutationOptions<
  VerifyActivationTokenMutation,
  VerifyActivationTokenMutationVariables
>
export const TestQueryDocument = gql`
  query TestQuery {
    viewer {
      user {
        email
      }
    }
  }
`

/**
 * __useTestQueryQuery__
 *
 * To run a query within a React component, call `useTestQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useTestQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTestQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useTestQueryQuery(baseOptions?: Apollo.QueryHookOptions<TestQueryQuery, TestQueryQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<TestQueryQuery, TestQueryQueryVariables>(TestQueryDocument, options)
}
export function useTestQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TestQueryQuery, TestQueryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<TestQueryQuery, TestQueryQueryVariables>(TestQueryDocument, options)
}
export type TestQueryQueryHookResult = ReturnType<typeof useTestQueryQuery>
export type TestQueryLazyQueryHookResult = ReturnType<typeof useTestQueryLazyQuery>
export type TestQueryQueryResult = Apollo.QueryResult<TestQueryQuery, TestQueryQueryVariables>
