import { useRouter } from 'next/router'
import Link from 'next/link'
import { cn } from '../../helpers/tailwind'
import { useUi, type Breakpoints } from './use-ui'
import * as UI from '@/ui'

export type BreadcrumbProps = {
  size?: Breakpoints<BreadcrumbSizes>
  weight?: Breakpoints<BreadcrumbWeights>
  color?: Breakpoints<BreadcrumbColors>
  className?: string
}

export type BreadcrumbSizes = 'xs' | 'sm' | 'md' | 'lg'
export type BreadcrumbColors = 'darkBlue' | 'lightBlue' | 'midBlue' | 'hotPink' | 'white'
export type BreadcrumbWeights = 'normal' | 'medium' | 'semiBold'

export const Breadcrumb = ({ className, color = 'hotPink', weight = 'medium', size = 'md' }: BreadcrumbProps) => {
  const router = useRouter()
  const paths = router.asPath.split('?')[0]
  const is404 = router.pathname === '/404'

  const pathNames = paths.split('/').filter((crumb) => !excludedCrumbs.includes(crumb))

  const ui = useUi({
    styles: {
      sizes: { options: sizes, selected: size },
      colors: { options: colors, selected: color },
      weights: { options: weights, selected: weight },
    },
    name: 'Breadcrumb',
    className: `${className} my-1 md:my-4`,
  })

  if (!pathNames || isExcludedRoute(paths) || is404) return null

  return (
    <div className={ui.className} aria-label="breadcrumb" id="breadcrumb">
      <ol className="flex" itemScope itemType="https://schema.org/BreadcrumbList">
        <li
          className="text-selphBlue-500 hover:text-hotPink-500 hover:underline"
          itemProp="itemListElement"
          itemScope
          itemType="https://schema.org/ListItem"
        >
          <UI.Link to="root" color="none" itemProp="item">
            <span itemProp="name">Home</span>
          </UI.Link>
          <meta itemProp="position" content="1" />
        </li>

        {pathNames.map((link, index) => {
          const crumb = getCrumb({ pathNames, link, index })
          const isLastCrumb = index === pathNames.length - 1

          return (
            <li
              key={link + index}
              className="last:text-ellipsis last:overflow-hidden last:whitespace-nowrap"
              itemProp="itemListElement"
              itemScope
              itemType="https://schema.org/ListItem"
            >
              <span className="mx-1.5 text-hotPink-500">&#10148;</span>

              <Link
                href={crumb.href}
                className={cn('text-selphBlue-500 hover:text-hotPink-500 hover:underline truncate capitalize', {
                  'text-selphGrey-600 pointer-events-none': isLastCrumb,
                })}
                aria-disabled={isLastCrumb}
                tabIndex={isLastCrumb ? -1 : 0}
                itemProp="item"
              >
                <span itemProp="name">{crumb.itemLink}</span>
              </Link>
              <meta itemProp="position" content={`${index + 2}`} />
            </li>
          )
        })}
      </ol>
    </div>
  )
}

Breadcrumb.displayName = 'Breadcrumb'

export default Breadcrumb

const isExcludedRoute = (route: string) => {
  switch (true) {
    case route === '/':
    case route.includes('/account'):
    case route.includes('/user'):
    case route.includes('/checkout'):
    case route.includes('/ppc'):
    case route.includes('/activate'):
      return true
    default:
      return false
  }
}

const getCrumb = ({ pathNames, link, index }: { pathNames: string[]; link: string; index: number }) => {
  let href = `/${pathNames.slice(0, index + 1).join('/')}`
  const itemLink = link.replace(/-/g, ' ')

  if (link === 'continuous-glucose-monitors') {
    href = '/continuous-glucose-monitors'
  } else if (link === 'supplements') {
    href = '/supplements'
  }
  return {
    href,
    itemLink,
  }
}

const excludedCrumbs = ['', 'continuous-glucose-monitors', 'supplements', 'partners']

const colors = {
  white: 'text-white',
  lightBlue: 'text-selphBlue-400',
  midBlue: 'text-selphBlue-500',
  darkBlue: 'text-selphBlue-600',
  hotPink: 'text-hotPink-500',
}

const weights = {
  normal: 'font-normal',
  medium: 'font-medium',
  semiBold: 'font-semibold',
}

const sizes = {
  xs: 'text-xs',
  sm: 'text-sm',
  md: 'text-lg',
  lg: 'text-xl',
}
