import NextLink, { LinkProps as NextLinkProps } from 'next/link'
import { useRouter } from 'next/router'
import * as React from 'react'
import { routes, RoutesLink, getHref } from '../../routes'
import { useUi, type Breakpoints } from './use-ui'
import { Button, ButtonSizes, ButtonColors, ButtonThemes } from './button'
import { BasicButtonColors, BasicButtonSizes } from './button/basic-button'

export type LinkColors = LinkColorsText | BasicButtonColors
export type LinkSizes = LinkSizesText | BasicButtonSizes

export type LinkColorsText =
  | 'none'
  | 'white'
  | 'black'
  | 'info'
  | 'success'
  | 'danger'
  | 'warning'
  | 'primary'
  | 'footer'
  | 'navbar'
  | 'midBlue'
  | 'secondary'
export type LinkSizesText = 'small' | 'medium' | 'large' | 'xl'
export type LinkHighlights = 'none' | 'simple'

export type LinkProps = LinkPropsButton | LinkPropsText | LinkPropsBasic

export type LinkPropsNext = Omit<NextLinkProps, 'children' | 'icon' | 'active' | 'className' | 'to' | 'href'>
export type LinkPropsAnchor = Omit<
  React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>,
  'children' | 'icon' | 'active' | 'className' | 'to' | 'href'
>

export type LinkPropsShared = {
  href?: never // add routes to /frontend/routes.ts
  to: RoutesLink
  className?: string
  containerClassName?: string
  active?: boolean
  icon?: React.FC<React.ComponentProps<'svg'>>
  children?: React.ReactNode | string | (({ active }: { active: boolean }) => React.ReactNode)
  target?: React.HTMLAttributeAnchorTarget | undefined
} & (LinkPropsNext | LinkPropsAnchor)

export type LinkPropsButton = {
  type?: 'button'
  size?: Breakpoints<ButtonSizes> | string
  color?: ButtonColors | never
  theme?: ButtonThemes
  highlight?: never
  textSize?: string
} & LinkPropsShared

export type LinkPropsText = {
  type?: 'text' | 'heading'
  size?: Breakpoints<LinkSizesText>
  color?: Breakpoints<LinkColorsText>
  theme?: never
  highlight?: LinkHighlights
  textSize?: never
} & LinkPropsShared

export type LinkPropsBasic = {
  type?: 'basic'
  size?: never
  color?: never
  theme?: never
  highlight?: never
  textSize?: never
} & LinkPropsShared

export const Link = React.forwardRef<HTMLSpanElement, LinkProps>(
  (
    {
      to,
      size,
      icon,
      active = undefined,
      theme,
      color = 'primary',
      type = 'text',
      highlight = 'none',
      containerClassName,
      className,
      children,
      textSize,
      target,
      ...props
    },
    ref,
  ) => {
    const Icon = icon
    const router = useRouter()

    const toKey = (Array.isArray(to) ? to[0] : to) as string

    const href = getHref(to)

    if (!/^(http|www|mailto|tel)/.test(to as string) && !Object.keys(routes).includes(toKey)) {
      throw new Error(`Attribute Error (to="${toKey}") key "${toKey}" does not exist in /frontend/routes.ts`)
    }

    if (active === undefined) {
      active = router ? router.asPath === href : false
    }

    const sizes = {
      default: `${type === 'heading' ? 'font-semibold tracking-tight no-underline' : ''}`,
      small: `text-sm`,
      medium: '',
      large: 'text-lg',
      xl: 'text-xl',
    }

    const colors = {
      default: ``,

      none: '',
      white: 'text-white', // Tailwind: sm:text-blue-500  md:text-blue-500  lg:text-blue-500  xl:text-blue-500
      black: 'text-black', // Tailwind: sm:text-black  md:text-black  lg:text-black  xl:text-black
      primary: 'text-selphBlue-300 hover:underline decoration-hotPink-500 decoration-2', // Tailwind: sm:text-selphBlue-300  md:text-selphBlue-300  lg:text-selphBlue-300  xl:text-selphBlue-300
      secondary: 'text-selphGrey-500', // Tailwind: sm:text-gray-500  md:text-gray-500  lg:text-gray-500  xl:text-gray-500
      info: 'text-cyan-500', // Tailwind: sm:text-cyan-500  md:text-cyan-500  lg:text-cyan-500  xl:text-cyan-500
      success: 'text-green-500', // Tailwind: sm:text-green-500  md:text-green-500  lg:text-green-500  xl:text-green-500
      danger: 'text-red-500', // Tailwind: sm:text-red-500  md:text-red-500  lg:text-red-500 xl:text-red-500
      warning: 'text-yellow-500', // Tailwind: sm:text-yellow-500  md:text-yellow-500  lg:text-yellow-500  xl:text-yellow-500
      footer: 'text-selphGrey-100',
      navbar: 'text-selphBlue-600',
      midBlue: 'text-selphBlue-500',
    }

    const highlights = {
      none: '',
      simple: `${color !== 'none' && typeof children === 'string' && (active ? 'font-bold' : 'underline')}`,
    }

    const styles = ['text', 'heading'].includes(type)
      ? {
          sizes: { options: sizes, selected: size },
          colors: { options: colors, selected: color },
          highlights: { options: highlights, selected: highlight },
        }
      : undefined

    const ui = useUi({
      styles,
      name: 'Link',
      className: `${className} ${icon && children ? 'flex items-center' : ''}`,
    })

    const anchorAttributes = ['text', 'heading'].includes(type) ? { className: ui.className } : {}

    children = typeof children === 'function' ? children({ active }) : children

    const isExternal = /^(http|www|mailto|tel)/.test(href)

    return (
      <>
        {/^(http|www|mailto|tel)/.test(to as string) ? (
          <span className={`${type === 'text' ? '' : 'block'} cursor-pointer ${containerClassName}`} ref={ref}>
            <a
              {...anchorAttributes}
              href={to as string}
              {...(props as LinkPropsAnchor)}
              target={target ? target : '_blank'}
              rel="noopener"
            >
              {['text', 'heading', 'basic'].includes(type) && (
                <>
                  {Icon && <Icon className={`inline-block w-5 h-5 ${children ? 'mr-1' : ''}`} />}
                  {children}
                </>
              )}
              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
              {type === 'button' /* @ts-ignore */ && (
                <Button
                  color={color as ButtonColors}
                  size={size as ButtonSizes}
                  theme={theme as ButtonThemes}
                  className={className}
                >
                  {Icon && <Icon className={`inline-block w-5 h-5 ${children ? 'mr-1' : ''}`} />}
                  {children}
                </Button>
              )}
            </a>
          </span>
        ) : (
          <span className={`${type === 'text' ? '' : 'block'} cursor-pointer ${containerClassName}`} ref={ref}>
            <NextLink
              href={href}
              {...anchorAttributes}
              {...(props as LinkPropsNext)}
              target={target ? target : isExternal ? '_blank' : '_self'}
              rel={isExternal ? 'noopener external' : ''}
            >
              {['text', 'heading', 'basic'].includes(type) && (
                <>
                  {Icon && <Icon className={`inline-block w-5 h-5 ${children ? 'mr-1' : ''}`} />}
                  {children}
                </>
              )}
              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
              {type === 'button' /* @ts-ignore */ && (
                <Button
                  color={color as LinkPropsButton['color']}
                  size={size as ButtonSizes}
                  theme={theme}
                  textSize={textSize}
                  className={className}
                  id={(props as LinkPropsAnchor).id}
                >
                  {Icon && <Icon className={`inline-block w-5 h-5 ${children ? 'mr-1' : ''}`} />}
                  {children}
                </Button>
              )}
            </NextLink>
          </span>
        )}
      </>
    )
  },
)

Link.displayName = 'Link'

export default Link
