import * as UI from '@/ui'

export const GiftCardBanner = () => {
  return (
    <div className="bg-selphGrey-900 py-2 px-2 text-center">
      <span className="text-white sm:text-lg font-semiBold">
        Give the gift of health with a{' '}
        <UI.Link to="giftCard">
          <span className="text-hotPink-500 underline">Selph Gift Card</span>
        </UI.Link>
      </span>
    </div>
  )
}

export default GiftCardBanner
