import * as React from 'react'
import CircleBlur from '../../public/images/circleBlur.svg?svgr'
import Thumb from '../../public/images/thumb.png'
import Header from '../header'
import Footer from '../footer'
import { ContainerStyles } from '../ui/page'
import { useUi } from '../ui/use-ui'
import { useNotification } from '../ui/use-notification'
import * as UI from '@/ui'

export type BackgroundColour = 'grey' | 'lightGrey' | 'none'

export type LayoutProps = {
  children: React.ReactNode
  containerStyle?: ContainerStyles
  backgroundColour?: BackgroundColour
  thumbFooter?: boolean
}

export const Layout = ({ children, containerStyle, backgroundColour = 'grey', thumbFooter = true }: LayoutProps) => {
  const { content } = useNotification()

  const backgroundColours = {
    grey: 'bg-selphGrey-200',
    lightGrey: 'bg-selphGrey-50',
    none: '',
  }

  const { className } = useUi({
    styles: { layout: { options: backgroundColours, selected: backgroundColour } },
    name: 'Layout',
  })

  return (
    <div className={`relative flex flex-col min-h-screen overflow-clip ${className}`}>
      <CircleBlur className="absolute w-[800px] h-[800px] md:w-[1500px] md:h-[1500px] top-0 right-0 -translate-y-1/2 translate-x-1/2" />

      <Header />

      <UI.Page containerStyle={containerStyle}>
        {content && <UI.Notification {...content} className="mb-5" />}

        {thumbFooter && (
          <div className="absolute bottom-0 left-1/2 -translate-x-1/2 translate-y-[60%] md:translate-y-[60%] lg:translate-y-[65%]">
            <CircleBlur className="relative w-[400px] h-[400px] md:w-[800px] md:h-[800px] bottom-0" />

            <div className="absolute h-56 w-56 sm:w-64 sm:h-64 md:h-96 md:w-96 lg:h-[430px] lg:w-[430px] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
              <UI.Image src={Thumb} fill sizes="(max-width: 768px) 224px, (max-width: 1280px) 256px, 430px" alt="" />
            </div>
          </div>
        )}
        <div className={`relative ${thumbFooter ? 'mb-32' : 'mb-14'}`}>{children}</div>
      </UI.Page>

      <Footer />
    </div>
  )
}

export default Layout
