import trustPilotData from '../../data/trustpilot.json'
import TPMicro from './micro'
import TPMicroReview from './micro-review'
import TPMini from './mini'

type TrustpilotBoxProps = {
  boxStyle: 'micro' | 'microReview' | 'mini'
  darkBg?: boolean
}

export interface TrustPilotData {
  label: string
  rating: number
  reviews: number
  url: string
}

export const TrustpilotBox = ({ boxStyle, darkBg = false }: TrustpilotBoxProps) => {
  return (
    <>
      {boxStyle === 'micro' && <TPMicro trustData={trustPilotData} darkBg={darkBg} />}
      {boxStyle === 'microReview' && <TPMicroReview trustData={trustPilotData} />}
      {boxStyle === 'mini' && <TPMini trustData={trustPilotData} />}
    </>
  )
}

export default TrustpilotBox
